import React, {Fragment} from 'react'
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {Field, withForm} from "ductus";
import { Face, MailOutline, Home, PermIdentity, Phone, Lock, Message, QueryBuilder, Payment, AccountBalance, AccountBalanceWallet, ConfirmationNumber } from '@material-ui/icons';
import {DateTimeField, TextField} from "../../../components";
import {CheckboxField, ComboField} from "../../../components/fields";
import SwitchField from "../../../components/fields/SwitchField";
import {providerForm} from "../../../forms";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const Form = ({translate, userInfo}) => (
  <div style={{ padding: 15 }}>
    <div onClick={() => window.history.back()} className="goBack_btn_link"> 
      <KeyboardArrowLeftIcon  className="goBack_btn"/> 
      <span>უკან დაბრუნება</span>
    </div>
    <input type={'text'} name={'fakeTextInput'}
           style={{height: 0, overflow: 'hidden', padding: 0, margin: 0, border: 'none'}}/>
    <input type={'password'} name={'fakePasswordField'}
           style={{height: 0, overflow: 'hidden', padding: 0, margin: 0, border: 'none'}}/>
    <form className={'good-form'}>
      <GridItem xs={12} sm={12} md={6} lg={6}>
        <legend> {translate('systemData')} </legend>

        <Field
          name={'name'}
          label={translate('name')}
          icon={Face}
          component={TextField}
        />

        <Field
          name={'email'}
          label={translate('email')}
          icon={MailOutline}
          component={TextField}
        />

        <Field
          name={'phoneNumber'}
          label={translate('phoneNumber', {})}
          icon={Phone}
          component={TextField}
        />

        <Field
          name={'address'}
          label={translate('address')}
          icon={Home}
          component={TextField}
        />

      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={6}>
        <legend>{translate('legalData')}</legend>

        <Field
          name={'legalName'}
          label={translate('name')}
          icon={Face}
          component={TextField}
        />

        <Field
          name={'legalCode'}
          label={translate('identityNumber')}
          icon={PermIdentity}
          component={TextField}
        />

        <Field
          name={'legalAddress'}
          label={translate('address')}
          icon={Home}
          component={TextField}
        />

        <Field
          name={'bankName'}
          label={translate('bankName')}
          icon={AccountBalance}
          component={TextField}
        />

        <Field
          name={'bankAccountNumber'}
          label={translate('accountNumber')}
          icon={AccountBalanceWallet}
          component={TextField}
        />

        <Field
          name={'bankCode'}
          label={translate('bankCode')}
          icon={AccountBalance}
          component={TextField}
        />

      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={6}>
        <legend> {translate('contactPerson')}</legend>

        <Field
          name={'contactName'}
          label={translate('name')}
          icon={Face}
          component={TextField}
        />

        <Field
          name={'contactPhoneNumber'}
          label={translate('phoneNumber', {})}
          icon={Phone}
          component={TextField}
        />

        <Field
          name={'contactEmail'}
          label={translate('email')}
          icon={MailOutline}
          component={TextField}
        />

        <Field
          name={'comment'}
          label={translate('comment')}
          icon={Message}
          component={TextField}
          inputProps={{
            multiline: true,
            rows: 3
          }}
        />

      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={6}>
        <legend> {translate('hubs')}</legend>
        <Field
          name={'hubs'}
          multiple={true}
          label={translate('chooseHubs')}
          component={ComboField}
          items={userInfo.hubs}
        />
      </GridItem>


      <GridItem xs={12} sm={12} md={6} lg={6}>
        <legend>{translate('financialConfiguration')}</legend>

        {/* <Field
          name={'monthlyFee'}
          label={translate('monthlyFee')}
          component={TextField}
          icon={Payment}
        /> */}

        <Field
          name={'deliveryFee'}
          label={translate('deliveryFee')}
          component={TextField}
          icon={Payment}
        />


        {/* <GridContainer justify="center" align="center" style={{alignItems: 'center', padding: 0}}>
          <GridItem xs={6} sm={6} md={6}>
            <Field
              name={'serviceFee'}
              label={translate('serviceFee')}
              component={TextField}
              icon={Payment}
            />

          </GridItem>


          <GridItem xs={6} sm={6} md={6}>
            <Field
              name={'serviceFeeType'}
              label={translate('percentage')}
              component={SwitchField}
              items={{
                disabledValue: 'FIXED',
                enabledValue: 'PERCENT'
              }}
            />
          </GridItem>

        </GridContainer>

        <Field
          name={'hasDistrictFee'}
          label={translate('hasDistrictFee')}
          component={CheckboxField}
          icon={Face}
        />

        <Field
          name={'invoiceDay'}
          label={translate('invoiceDay')}
          component={TextField}
          icon={ConfirmationNumber}
        />

        <Field
          name={'invoiceTime'}
          label={translate('invoiceTime')}
          component={DateTimeField}
          dateFormat={false}
          timeFormat={'HH:mm'}
        />


        <Field
          name={'courierFee'}
          label={translate('courierFee')}
          component={TextField}
          icon={Face}
        />

        <Field
          name={'deactivatedAt'}
          label={translate('shutdownDate')}
          component={DateTimeField}
          dateFormat={'DD-MM-YYYY'}
          timeFormat={'HH:mm'}
        /> */}

        <Field
          name={'times'}
          label={translate('times')}
          component={TextField}
          icon={QueryBuilder}
        />

        <Field
          name={'isActive'}
          label={translate('active')}
          component={CheckboxField}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={6}>
        <legend> {translate('rights')}</legend>
        {/* <Field
          name={'finances_read_packages_price'}
          label={translate('financeCirculation')}
          component={CheckboxField}
        />

        <Field
          name={'packages_preoder'}
          label={translate('preOrder')}
          component={CheckboxField}
        /> */}

          <Field
            name={'sorting_history_panel'}
            label={translate('sortHistory')}
            component={CheckboxField}
          />

        <Field
          name={'packages_show_courier'}
          label={translate('viewCourier')}
          component={CheckboxField}
        />

        <Field
          name={'courier_arrival_times'}
          label={translate('viewCourierTime')}
          component={CheckboxField}
        />

        {/* <Field
          name={'packages_select_pickup_district'}
          label={translate('choosePickUpDistrict')}
          component={CheckboxField}
        /> */}

        {/* <Field
          name={'packages_select_delivery_district'}
          label={translate('chooseDeliveryDistrict')}
          component={CheckboxField}
        /> */}

        <Field
          name={'branches_own_panel'}
          label={translate('branches_own_panel')}
          component={CheckboxField}
        />

        <Field
          name={'courier_accept_notification'}
          label={translate('courier_accept_notification')}
          component={CheckboxField}
        />

        <Field
          name={'packages_upload'}
          label={translate('packages_upload')}
          component={CheckboxField}
        />

        <Field
            name={'packages_export'}
            label={translate('packages_export')}
            component={CheckboxField}
        />

        {/* <Field
            name={'locations_panel'}
            label={translate('locations_panel')}
            component={CheckboxField}
        /> */}

        <Field
          name={'password'}
          type={'password'}
          label={translate('password')}
          icon={Lock}
          component={TextField}
        />

        <Field
          name={'passwordConfirm'}
          type={'password'}
          label={translate('passwordConfirm')}
          icon={Lock}
          component={TextField}
        />

      </GridItem>
    </form>
  </div>
)

export default withForm(providerForm.edit)(Form)
