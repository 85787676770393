import {transformNumber} from "../transformers"
import {validateEmail, validateExistence, validateIdentityNumber, validatePhoneNumber} from "../validators"
import {combineValidators} from "ductus"

export default ({
  name: {
    initialValue: '',
  },
  email: {
    validate: validateEmail
  },
  address: {},
  identityNumber: {
    // transform: transformNumber(11),
    validate: validateIdentityNumber
  },
  phoneNumber: {
    transform: transformNumber(9),
    validate: combineValidators(
      validateExistence,
      validatePhoneNumber
    ),
  },
  altPhoneNumber: {
    transform: transformNumber(9),
    validate: validatePhoneNumber,
  },
  isActive: {
    initialValue: 1
  },
  locations_panel: {
    initialValue: 1
  },
  password: {},
  passwordConfirm: {},

  'couriers_panel': {},
  'couriers_read': {},
  'couriers_create': {},
  'couriers_update': {},
  'packages_upload': {},
  'packages_courier_bulk_assign': {}
})
