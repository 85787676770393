import React from 'react'
import { withForm, Field } from 'ductus'

import { districtForm } from '../../forms'
import {
  CheckboxField, 
  GridContainer, 
  GridItem, 
  TextField 
} from '../../components'
import { Face, GolfCourse } from '@material-ui/icons'

const CreateDistrictForm = ({ translate }) =>  (
  <GridContainer>
    <GridItem xs={12}>
      <Field
        name={'name'}
        label={translate('name')}
        icon={GolfCourse}
        component={TextField}
        required={true}
      />

      <Field
        name={'isActive'}
        label={translate('active')}
        icon={Face}
        component={CheckboxField}
      />
    </GridItem>
  </GridContainer>
)

export default withForm(districtForm.create)(CreateDistrictForm)