
import { connect } from 'react-redux'
import {packageServices} from "../../../ducks";

import History from './History'

const mapStateToProps = (state) => ({
  packageHistory: packageServices.history.getData(state),
  packageHistoryLoading: packageServices.history.getRequestPending(state),
})
const mapDispatchToProps = {
  getPackageHistory: packageServices.history.run,
}

export default connect(mapStateToProps, mapDispatchToProps)(History)