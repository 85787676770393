
import React from 'react'
import { providers, hubs } from '../../ducks'
import { statisticServices } from '../../ducks/services'
import { connect } from 'react-redux'

import GeneralStatistics from './GeneralStatistics'

const remove = (payload) => statisticServices.providers.removeStat( statisticServices.providers.REMOVE_STATISTIC, payload)

const mapStateToProps = (state) => ({
  items: providers.getList(state).data,
  hubs: hubs.getList(state).data,
  statistics: statisticServices.providers.getData(state),
  statisticsLoading: statisticServices.providers.getRequestPending(state),
  name: 'providers',
})
const mapDispatchToProps = {
  fetchItems: providers.fetchList,
  fetchHubs: hubs.fetchList,
  addStats: statisticServices.providers.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralStatistics)