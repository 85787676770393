
export const PACKAGE_CREATED = 'PACKAGE_CREATED'
export const PACKAGES_CREATED = 'PACKAGES_CREATED'
export const PACKAGE_UPDATED = 'PACKAGE_UPDATED'
export const PACKAGES_UPDATED = 'PACKAGES_UPDATED'
export const PACKAGE_STATUS_UPDATED = 'PACKAGE_STATUS_UPDATED'
export const PACKAGE_DELETED = 'PACKAGE_DELETED'
export const PACKAGE_PREORDER_CREATED = 'PACKAGE_PREORDER_CREATED'
export const PACKAGE_PREORDER_SHOW = 'PACKAGE_PREORDER_SHOW'

export const COURIER_WORK_START = 'COURIER_WORK_START'
export const COURIER_WORK_END = 'COURIER_WORK_END'
export const COURIER_STATS_UPDATE = 'COURIER_STATS_UPDATE'
export const COURIERS_LOCATIONS_UPDATE = 'COURIERS_LOCATIONS_UPDATE'

export const PACKAGE_TAB_UPDATE = 'PACKAGE_TAB_UPDATE'
export const DELIVERED_TO_POST = 'DELIVERED_TO_POST'
