import React from 'react'
import { withForm } from 'ductus'
import {sizesFilterForm} from "../../forms"
import {Field} from 'ductus'

import {ComboField, CheckboxField, TextField} from "../../components"

import {connect} from 'react-redux'
import {sizes} from "../../ducks";

import qs from 'query-string'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'

class SizesFilterForm extends React.Component {

  componentWillUnmount(){
    this.props.resetForm()
  }

  componentWillMount(){
    const { formValues, history } = this.props
    this.props.changeFormValues({...formValues, ...qs.parse(history.location.search)})
  }

  render(){
    const { userHubs, formValues, filterItems, translate, history } = this.props
    return (
      <GridContainer style={{alignItems: 'center'}}>
        <GridItem xs={12} sm={12} md={3} lg={2}>
          <Field
            name={'name'}
            label={translate('name')}
            component={TextField}
            showSuccess={false}
          />
        </GridItem>

        {/*<GridItem xs={12} sm={12} md={3} lg={2}>*/}
          {/*<Field*/}
            {/*name={'phoneNumber'}*/}
            {/*label={translate('phoneNumber', {})}*/}
            {/*component={TextField}*/}
            {/*showSuccess={false}*/}
          {/*/>*/}
        {/*</GridItem>*/}

        {/* <GridItem xs={12} sm={6} md={3} lg={2}>

          <Field
            name={'hubId'}
            label={translate('chooseHub')}
            component={ComboField}
            searchText={translate('search')}
            items={userHubs}
          />
        </GridItem> */}


        <GridItem xs={12} sm={6} md={3} lg={2}>
          <Field
            name={'isActive'}
            label={translate('active')}
            component={CheckboxField}
            // items={{
            //   disabledValue: 0,
            //   enabledValue: 1
            // }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={3} lg={2}>
          <Button color={'primary'} onClick={() => {
              filterItems({ query: formValues }) && history.push({ search: qs.stringify(formValues)})}
            }> {translate('search')} </Button>
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = state => ({
  formValues: sizesFilterForm.getValues(state)
})

export default connect(
  mapStateToProps,
  {
    filterItems: sizes.fetchList,
    changeFormValues: sizesFilterForm.changeValues,
    resetForm: sizesFilterForm.resetValues,
  }
)(withForm(sizesFilterForm)(SizesFilterForm))