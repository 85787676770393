import {createGroup} from "./functions/createGroup";
import { createResource } from '../helpers'
import {
  PACKAGE_CREATED, PACKAGE_DELETED,
  PACKAGE_PREORDER_CREATED, PACKAGE_PREORDER_SHOW, PACKAGE_STATUS_UPDATED,
  PACKAGE_UPDATED,
  PACKAGES_CREATED,
  PACKAGES_UPDATED
} from "../eventTypes";
export default createGroup({
  statePath: `dashboard.stats`,
  name: 'stats',
  generic: {
    initialData: {
      microtime: '',
      PROCESSING: 0,
      ACCEPTED: 0,
      AT_PROVIDER: 0,
      PICKED_UP: 0,
      AT_RECIPIENT: 0,
      ALL: 0,
    },
    transformData: (response) => response.body.data
  },
  createResource: createResource,
  additionalHandlers: {
    [PACKAGE_CREATED]: ( state) => ({
      ...state,
      data: {
        ...state.data,
        microtime: state.data.microtime + '--'
      }
    }),
    [PACKAGES_CREATED]: ( state) => ({
      ...state,
      data: {
        ...state.data,
        microtime: state.data.microtime + '--'
      }
    }),
    [PACKAGE_UPDATED]: ( state) => ({
      ...state,
      data: {
        ...state.data,
        microtime: state.data.microtime + '--'
      }
    }),
    [PACKAGES_UPDATED]: ( state) => ({
      ...state,
      data: {
        ...state.data,
        microtime: state.data.microtime + '--'
      }
    }),
    [PACKAGE_PREORDER_CREATED]: ( state) => ({
      ...state,
      data: {
        ...state.data,
        microtime: state.data.microtime + '--'
      }
    }),
    [PACKAGE_PREORDER_SHOW]: ( state) => ({
      ...state,
      data: {
        ...state.data,
        microtime: state.data.microtime + '--'
      }
    }),
    [PACKAGE_DELETED]: ( state) => ({
      ...state,
      data: {
        ...state.data,
        microtime: state.data.microtime + '--'
      }
    }),
    [PACKAGE_STATUS_UPDATED]: ( state) => ({
      ...state,
      data: {
        ...state.data,
        microtime: state.data.microtime + '--'
      }
    }),
    //
    //   return ({
    //     ...state,
    //     data: newData
    //   })
    // },
    // ['PACKAGE_CREATED']: ( state ) => ({
    //   ...state,
    //   data: {
    //     ...state.data,
    //     PROCESSING: state.data.PROCESSING + 1,
    //     ALL: state.data.ALL + 1
    //   }
    // }),
    // ['PACKAGE_DELETED']: ( state, { payload }) => ({
    //   ...state,
    //   data: {
    //     ...state.data,
    //     [payload.status]: state.data[payload.status] ? state.data[payload.status]  - 1 : 0,
    //     ALL: state.data.ALL - 1
    //   }
    // }),
    // ['PACKAGE_UPDATED']: ( state, { payload } ) => {
    //
    //   const { status } = payload.updatedFrom
    //
    //   let newState = {...state}
    //
    //   if(status){
    //     newState.data = {
    //       ...newState.data,
    //       [status]: newState.data[status] - 1,
    //       [payload.status]: newState.data[payload.status] + 1,
    //       ALL: (payload.status === 'DELIVERED') || (payload.status === 'REJECTED') ? newState.data.ALL - 1 : newState.data.ALL
    //     }
    //   }
    //
    //   return newState
    // },
  }
})
