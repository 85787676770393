import {validateExistence, validatePhoneNumber} from "../validators"
import {compose, combineValidators} from 'ductus'
import {transformToNumber, transformValueLengthExact} from "../transformers"
export default ({
  name: {
    validate: validateExistence
  },
  address: {
    validate: validateExistence
  },
  phoneNumber: {
    transform: compose(
      transformToNumber,
      transformValueLengthExact(9)
    ),
    validate: combineValidators(
      validatePhoneNumber,
      validateExistence
    )
  },
  districtId: {},
  isActive: {
    initialValue: 1
  }
})