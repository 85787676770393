import {groupPackageData} from '../../helpers'

const changedFalse = d => ({...d, changed: false })

export const onPackageUpdated = table => (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    data: payload.dashboardLocation === table
        ? state.data.data.find(d => d.id === payload.id )
            ? state.data.data.map(d => d.id === payload.id ? groupPackageData({...payload, changed: true}): d)
            : [groupPackageData({...payload, changed: false}), ...state.data.data]
        : state.data.data.filter(d => d.id !== payload.id ).map(changedFalse)
  }
})

export const onPackagesUpdated = table => (state, { payload }) => {
  let newData = state.data.data
  payload.forEach(p => {
    newData = p.dashboardLocation === table
        ? state.data.data.find(d => d.id === p.id )
            ? newData.map(d => d.id === p.id ? groupPackageData({...p, changed: true}): d)
            : [groupPackageData({...p, changed: false}), ...newData]
        : newData.filter(d => d.id !== p.id ).map(changedFalse)
  })
  
  return ({
    ...state,
    data: {
      ...state.data,
      data: newData
    }
  })
}

export const onPackageCreated = table => (state, { payload }) => {
  let data = state.data.data;
  if(payload.dashboardLocation === table){
    const newData = groupPackageData({...payload, changed: false});
    let rowChanged = false;
    for( const i in data ){
      if(data[i].id === newData.id){
        console.log("row changed");
        data[i] = newData;
        rowChanged = true;
      }
    }
    if(!rowChanged){
      console.log("row did not change");
      data.unshift(newData);
    }
  }
  return ({
    ...state,
    data: {
      ...state.data,
      data : data
    }
  });
}
//
// export const onPackagesCreated = table => (state, { payload }) => {
//   let newData = state.data.data;
//
//   payload.forEach(p => {
//     newData = p.dashboardLocation === table ? [groupPackageData({...p, changed: false}), ...newData] : newData
//   })
//
//   return ({
//     ...state,
//     data: {
//       ...state.data,
//       data: newData
//     }
//   })
// }

export const onPackageDeleted = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    data: state.data.data.filter(d => d.id !== payload.id ).map(changedFalse)
  }
})
