import React from "react";
import Wizard from "../../../components/Wizard/Wizard.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import { providerForm } from '../../../forms'
import { withForm } from 'ductus'
import SystemData from "./WizardSteps/1-SystemData.jsx";
import Rights from "./WizardSteps/2-Rights.jsx";
import Hubs from './WizardSteps/3-Hubs'
import FinancialConfiguration from './WizardSteps/4-FinalcialConfiguration'
import { toNumber } from '../../../helpers'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

class WizardView extends React.Component {

  componentWillUnmount(){
    this.props.resetForm()
  }

  componentWillReceiveProps(nextProps){
    const { storeLoading, resetCreateFormValues, history } = this.props

    if(storeLoading && !nextProps.storeLoading && nextProps.isValid){
      resetCreateFormValues()
      history.push('/providers')
    }

  }

  render(){
    const { values, createItem, isValid, userInfo, translate} = this.props
    return (
      <GridContainer justify="center">
        <div onClick={() => window.history.back()} className="goBack_btn_link creat"> 
          <KeyboardArrowLeftIcon  className="goBack_btn"/> 
          <span>უკან დაბრუნება</span>
        </div>
        <GridItem xs={12} sm={8}>
          <Wizard
            validate
            isValid={isValid}
            steps={[
              { stepName: translate('general'), stepComponent: SystemData, stepId: "systemData", getTranslator: translate },
              { stepName: translate('rights'), stepComponent: Rights, stepId: "rights", getTranslator: translate },
              { stepName: translate('hubs'), stepComponent: Hubs, stepId: "hubs", hubs: userInfo.hubs, getTranslator: translate },
              { stepName: translate('financial'), stepComponent: FinancialConfiguration, stepId: "financialConfiguration", getTranslator: translate }
            ]}
            title={translate('whichAction', { type: 'add', which: 'provider'})}
            subtitle={''}
            nextButtonText={translate('next')}
            previousButtonText={translate('back')}
            finishButtonText={translate('finish')}
            finishButtonClick={() => createItem({...values, times: values.times && values.times.split(',').map(toNumber) })}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

export default withForm(providerForm.create)(WizardView)
