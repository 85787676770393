import {RECEIVE_ERROR} from 'redux-net'
import {invalidateToken} from '../ducks/authorization'

export const routerMiddleware = (history) => () => next => action => {

  if (
    action.type === RECEIVE_ERROR
    && action.payload
    && (action.payload.code === 'token_not_provided'
    || (action.payload.code === 'invalid_credentials')
    || action.payload.code === 'token_expired')
  ) {
    next(invalidateToken())
  }

  next(action)

}