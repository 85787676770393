
import { connect } from 'react-redux'
// import { profileForm } from "../../forms";
import Profile from './Profile'
import { financeServices, statisticServices} from '../../ducks'

const mapStateToProps = (state) => ({
  weekFinances: financeServices.week.getData(state),
  // weekLoading
  monthFinances: financeServices.month.getData(state),
  quarterFinances: financeServices.quarter.getData(state),
})
const mapDispatchToProps = {
  weekFinancesGet: financeServices.week.run,
  monthFinancesGet: financeServices.month.run,
  quarterFinancesGet: financeServices.quarter.run,
}


export default connect(mapStateToProps, mapDispatchToProps)(Profile)

