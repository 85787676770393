
const permissionGroup = ( service ) => ({
  read: `${service}_read`,
  create: `${service}_create`,
  update: `${service}_update`,
  delete: `${service}_delete`,
  panel: `${service}_panel`,
  ownPanel: `${service}_own_panel`
})

export const districtPermissions = permissionGroup('districts')
export const providerPermissions = permissionGroup('providers')
export const branchPermissions = permissionGroup('branches')
export const packagePermissions = permissionGroup('packages')
export const managerPermissions = permissionGroup('managers')
export const dispatcherPermissions = permissionGroup('dispatchers')
export const hubPermissions = permissionGroup('hubs')
export const courierPermissions = permissionGroup('couriers')
export const providerPermissionsHistory = {
  panel: 'sorting_history_panel'
}
export const packages_upload = {
  panel: 'packages_upload'
}
export const packages_export = {
  panel: 'packages_export'
}
export const recipientPermissions = {
  read: 'recipients_read',
  panel: 'recipients_panel'
}

export const financePermissions = {
  panel: 'finances_panel',
  ownPanel: 'finances_panel_own'
}

export const statisticPermissions = {
  panel: 'statistics_panel',
  ownPanel: 'statistics_panel_own'
}

export const locationPermissions = {
  panel: 'locations_panel'
}

export const paymentPermissions = {
  panel: 'payments_panel'
}

export const warehousePanel = {
  panel: 'warehouse_panel'
}

export const locationsPanelPermission = 'locations_panel'