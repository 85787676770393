import {post, get} from 'redux-net'
import {
  AUTHORIZATION_INVALIDATE_TOKEN,
  AUTHORIZATION_RECEIVE_ERROR,
  AUTHORIZATION_RECEIVE_TOKEN,
  AUTHORIZATION_REQUEST,

  AUTHORIZATION_VERIFY,
  AUTHORIZATION_VERIFIED,
  AUTHORIZATION_NOT_VERIFIED,
} from "./actionTypes"

export const authorize = ({email, password}) => post('/auth', {
  begin: AUTHORIZATION_REQUEST,
  success: AUTHORIZATION_RECEIVE_TOKEN,
  error: AUTHORIZATION_RECEIVE_ERROR,
  body: {
    email,
    password,
  },
})

export const verify = () => get('/auth/verify', {
  begin: AUTHORIZATION_VERIFY,
  success: AUTHORIZATION_VERIFIED,
  error: AUTHORIZATION_NOT_VERIFIED,
})

export const invalidateToken = () => ({
  type: AUTHORIZATION_INVALIDATE_TOKEN,
})
