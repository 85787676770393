import React from 'react'

import Accordion from '../../components/Accordion/Accordion'

import {financePermissions} from '../../permissions'
import FinanceTable from '../Finances/FinanceTable'

import GridContainer from '../../components/Grid/GridContainer'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'

class Profile extends React.Component {

  onChange = (value, name) => this.setState({[name]: value})

  componentDidMount() {
    const {userInfo: {permissions}, weekFinancesGet, monthFinancesGet, quarterFinancesGet} = this.props

    if (permissions[financePermissions.ownPanel]) {
      weekFinancesGet({params: {path: '/finances/week'}})
      monthFinancesGet({params: {path: '/finances/month'}})
      quarterFinancesGet({params: {path: '/finances/quarter'}})
    }

  }

  handleSubmit = (values) => this.props.updateProfile(values)

  render() {
    const {translate, permissions, weekFinances, monthFinances, quarterFinances} = this.props


    return (
      <GridContainer>

        {
          permissions[financePermissions.ownPanel] ?
          <Accordion
            collapses={[
              {
                title: translate('weekFinances'),
                expanded: true,
                content:
                  <Card style={{margin: 0}}>
                    <CardBody>

                      <FinanceTable
                        data={weekFinances.data}
                        cols={weekFinances.headers}
                        translate={translate}
                      />
                    </CardBody>
                  </Card>
              },
              {
                title: translate('monthFinances'),
                expanded: false,
                content:
                  <Card style={{margin: 0}}>
                    <CardBody>

                      <FinanceTable
                        data={monthFinances.data}
                        cols={monthFinances.headers}
                        translate={translate}
                      />
                    </CardBody>
                  </Card>
              },
              {
                title: translate('quarterFinances'),
                expanded: true,
                content:
                  <Card style={{margin: 0}}>
                    <CardBody>

                      <FinanceTable
                        data={quarterFinances.data}
                        cols={quarterFinances.headers}
                        translate={translate}
                      />
                    </CardBody>
                  </Card>
              },
            ]}
          />
            : null
        }


      </GridContainer>
    )
  }
}

export default Profile