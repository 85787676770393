

import React from 'react'
import {dispatchers, hubs} from '../../ducks'
import { statisticServices } from '../../ducks/services'
import { connect } from 'react-redux'
import GeneralStatistics from './GeneralStatistics'

const remove = (payload) => statisticServices.dispatchers.removeStat( statisticServices.dispatchers.REMOVE_STATISTIC, payload)


const mapStateToProps = (state) => ({
  items: dispatchers.getList(state).data,
  hubs: hubs.getList(state).data,
  statistics: statisticServices.dispatchers.getData(state),
  statisticsLoading: statisticServices.dispatchers.getRequestPending(state),
  name: 'dispatchers',
})
const mapDispatchToProps = {
  fetchItems: dispatchers.fetchList,
  fetchHubs: hubs.fetchList,
  addStats: statisticServices.dispatchers.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralStatistics)