import {createGroup} from "./functions/createGroup"
import {groupUserInfo} from "../helpers"
import { AUTHORIZATION_INVALIDATE_TOKEN } from './authorization'

export default createGroup({
  name: 'userInfo',
  resource: '/user',
  additionalHandlers: {
    ['userEmailUpdate/SUCCESS']: (state, { payload } ) => ({...state, data: groupUserInfo(payload) }),
    [AUTHORIZATION_INVALIDATE_TOKEN]: (state) => ({...state, data: {}})
  },
  generic: {
    transformData: groupUserInfo
  }
})