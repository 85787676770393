import {hubForm, hubFilterForm} from "../../forms";
import {hubs} from "../../ducks";
import connect from "react-redux/es/connect/connect";

import HubList from './HubList'
import modal from "../../ducks/modal"

const mapStateToProps = (state) => ({
  data: hubs.getList(state) && hubs.getList(state).data,
  singleData: hubs.getSingle(state),
  pagination: hubs.getList(state).pagination,
  loading: hubs.isListRequestPending(state),
  singleLoading: hubs.isSingleRequestPending(state),
  storeLoading: hubs.isStoreRequestPending(state),
  updateLoading: hubs.isUpdateRequestPending(state),
  removeLoading: hubs.isRemoveRequestPending(state),
  listErrors: hubs.getListRequestError(state),
  singleErrors: hubs.getSingleRequestError(state),
  storeErrors: hubs.getStoreRequestError(state),
  updateErrors: hubs.getUpdateRequestError(state),
  removeErrors: hubs.getRemoveRequestError(state),
  filterFormValues: hubFilterForm.getValues(state),


})
const mapDispatchToProps = {
  fetchList: hubs.fetchList,
  fetchSinge: hubs.fetchSingle,
  storeItem: hubs.store,
  updateItem: hubs.update,
  removeItem: hubs.remove,
  changeEditFormValues: hubForm.edit.changeValues,
  resetCreationFormValues: hubForm.create.resetValues,
  resetEditionFormValues: hubForm.edit.resetValues,

  openModal: modal.openModal,
  closeModal: modal.closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(HubList)
