import {recipientServices} from "../../ducks";
import {recipientsFilterForm} from "../../forms";
import connect from "react-redux/es/connect/connect";

import RecipientList from './RecipientList'


const mapStateToProps = (state) => ({
  data: recipientServices.list.getData(state).data,
  pagination: recipientServices.list.getData(state).pagination,
  filterFormValues: recipientsFilterForm.getValues(state),

})
const mapDispatchToProps = {
  fetchList: recipientServices.list.fetch,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientList)