import { handleActions } from 'redux-actions'

const MODAL_OPEN = 'MODAL/OPEN'
const MODAL_CLOSE = 'MODAL/CLOSE'

const openModal = (payload) => ({ type: MODAL_OPEN, payload })
const closeModal = () => ({ type: MODAL_CLOSE })

export default ({
  MODAL_OPEN,
  MODAL_CLOSE,

  openModal,
  closeModal,

  reduce: handleActions({
    [MODAL_OPEN]: (state, { payload }) => ({...state, ...payload, isOpen: true }),
    [MODAL_CLOSE]: (state) => ({ small: state.small, isOpen: false }),
  }, {
    isOpen: false,
    small: true,
  })
})