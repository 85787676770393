import React from 'react'
import ReactTable from 'react-table'
import ListComponent from "../../ListComponent"
import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import CardBody from '../../../components/Card/CardBody'
import Card from '../../../components/Card/Card'
import Modal from '../../../components/Modal/Modal'
import ConfirmSweetAlert from '../../../components/SweetAlert/SweetAlert'
import SnackBar from '../../../components/Snackbar/Snackbar'
import AddAlert from "@material-ui/icons/AddAlert"
import SortsManagerFilter from "../SortsManagerFilter";
import CheckboxField from "../../../components/fields/CheckboxField";
import Check from "@material-ui/icons/Check";
import Button from "../../../components/CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import {TextField} from "../../../components/fields";
// import MissingIcon from "../../../assets/img/missing.png";
// import MissingGreenIcon from "../../../assets/img/missing_green.png";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import SortsManagerModal from './SortsManagerModal';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import qs from "query-string";


const statuses = [
    'pending',
    'manager_confirmed',
    // 'manager_missed',
    'courier_picked',
    // 'courier_missed',
    'missing',
    'rejected',
];

class SortsManagerDashboard extends ListComponent {
    state = {
        alert: {
            open: false,
        },
        snackBar: {openPos: false, message: ''},
        pageIndex: 0,
        checkedItems: new Set(),
        checkedAll: false,

        expanded: {},
        statusSelected: null,
        modalOpen: false,
        selectedIndex: 0,
    }

    getConfigStatuses = () => ({ 
        'pending': {
            name: "processing",
            action: this.onPending,
        },
        'courier_picked': {
            name: "courier picked",
            action: this.onReadOnly,
            // action: this.onCourierPicked,
        },
        // 'courier_missed': {
        //     name: "courier missed",
        //     action: this.onCourierMissed,
        // },
        'manager_confirmed': {
            name: "manager confirmed",
            action: this.onReadOnly,
            // action: this.onManagerConfirmed,
        },
        // 'manager_missed': {
        //     name: "manager missed",
        //     action: this.onManagerMissed,
        // },
        'missing': {
            name: "missing",
            action: this.onMissing,
        },
        'rejected': {
            name: "rejected",
            action: this.onNo,
        },
    })
    

    toggleSelection = () => {
        const {data} = this.props;
        const {checkedAll} = this.state
        let newCheckedItems = new Set();

        if (!checkedAll) {
            data.forEach(el => newCheckedItems.add(el.id))
        }

        this.setState({
            checkedItems: newCheckedItems,
            checkedAll: !checkedAll
        })
    }

    makeActions = (item) => {
        const {translate} = this.props;
        /* button disabled Action type */
        let packageStatusType = item.package.status;
        /* button disabled check status */
        let btnActionType = packageStatusType !== 'DELIVERED';
        /* technical Problem button disabled  */
        let technicalProblem = item.package.technicalProblem === true;
        
        const configStatuses = this.getConfigStatuses();
        
        return (
            (item.status !== 'pending') || (item.status !== 'missing') ? 
                <div className="actions-right">
                    <Button
                        justIcon
                        disabled={btnActionType || technicalProblem}
                        round
                        simple
                        onClick={() => this.onYes(item.id)}
                        color="warning"
                        className={"accept", !btnActionType ? "btn bubleButton" : technicalProblem ? "technicalProblem" : "" }          
                  >
                        <Check/>
                    </Button>

                    <FormControl className={"actionStatus"}>
                        <Select
                        value={item.status}
                        className={!btnActionType ? "selectSyle" : technicalProblem ? "technicalProblem" : "selectSyleDisabled" }
                        disabled={btnActionType || technicalProblem }
                        onChange={(e) => {
                            const itemConfig = configStatuses[e.target.value];
                           
                            if(itemConfig.name !== "missing" && itemConfig.name !== "rejected" ){
                           
                                itemConfig.action(item.id, itemConfig.name)
                                setTimeout(() => {  this.setState({ reason: null }) }, 400)
                           
                            } else {
                                this.showAlert({
                                    title: translate('noAction', {item: 'რომელიღაც შეკვეთა'}),
                                    confirmText: translate('sayNo'),
                                    cancelText: translate('close'),
                                    hideAlert: this.ClosehideAlert,// this.hideAlert,
                                    confirmFun: () => { 
                                       itemConfig.action(item.id, this.state.reason)
                                        setTimeout(() => {
                                            this.setState({ reason: null })
                                        }, 400)
                                    },
                                    warning: true,
                                    message: () => <TextField
                                        name={'reason'}
                                        label={translate('reason')}
                                        component={TextField}
                                        onChange={this.reasonChanged}
                                        value={this.state.reason}
                                        inputProps={{
                                            multiline: true,
                                            rows: 3
                                        }}
                                        errors={[]}
                                        required={true}
                                    />
                                })

                            }
                        }}
                        >
                            {statuses.map((status => {
                                const itemConfig = configStatuses[status];
                                
                                return (
                                    <MenuItem 
                                        key={status}
                                        value={status}
                                        color={item.status === "picked_up" ? "danger" : "black"}
                                    >
                                        {itemConfig.name}
                                    </MenuItem>
                            )}))}
                        </Select>
                    </FormControl>
                </div> : null
        )
    }

    reason;

    ClosehideAlert = () => {
        this.setState({ reason: null })
        setTimeout(() => {
            this.hideAlert()
        }, 400)
    }

    reasonChanged = (reason) => {
        this.reason = reason;
        this.setState({ reason })
    }

    componentDidMount() {
         /*  audit data  */
        const {fetchList,filterFormValues, findAuditLineItemsData, history } = this.props
        const page = Number(qs.parse(history.location.search).page)

        let filterValue = qs.parse(history.location.search)
        
        this.setState( { pageIndex: page || 0 })
        fetchList({
            query: {filterValue, page }
        })

    }

    pageChanged = (pageIndex) =>  {
        const { history, fetchList, filterFormValues} = this.props;

        const page = Number(qs.parse(history.location.search).page)
        
        fetchList({
            query: {filterFormValues, page: pageIndex + 1 }
        })
        this.setState({pageIndex})
        this.props.history.push({search: window.location.search.replace(/&page=[0-9+]|page=[0-9+]/i, '') + '&page=' + (pageIndex + 1)})
    }

    isChecked = (item) => this.state.checkedItems.has(item.id)

    toggleCheck = (item) => {
        const {checkedItems} = this.state;
        let newCheckedItems = checkedItems;

        if (this.isChecked(item)) {
            newCheckedItems.delete(item.id)
        } else {
            newCheckedItems.add(item.id)
        }

        this.setState({
            checkedItems: newCheckedItems
        })
    }

    renderChecked(item) {
        return (
            <div>
                <CheckboxField
                    name={`item-${item.id}`}
                    value={this.isChecked(item)}
                    onChange={() => this.toggleCheck(item)}
                />
            </div>
        )
    }

    hasOtherItems = (row) => row.package && (row.package.itemsCount > 1)

    orderIdStyle = (row) => this.hasOtherItems(row) && row.package.hasPendingReserveItems === true
    ? "blueOrderId"
    : this.hasOtherItems(row) && "yellowOrderId";


    packageItemDisabled = (row) => this.hasOtherItems(row) ? false : true;
    

    onOrderIdClicked = (row) => () => {
        const { findLineItemsInvalidate, findLineItems } = this.props;
        findLineItemsInvalidate()
        findLineItems({params: {path: `v3/line-items/${row.id}`}})
        this.setState({ reason: null })
    }

    // updateLineItem = (id, status, comment) => {
    //     const {updateLineItems} = this.props;
    //     updateLineItems({params: {path: `v3/line-items/${id}`}}, {status, comment})
    // }

    // onYes = (id) => this.updateLineItem(id, 'yes');
    // onNo = (id, comment) => comment && this.updateLineItem(id, 'no', comment);
    // onMissing = (id, comment) => comment && this.updateLineItem(id, 'missing', comment);
    
    /*  Change Status & Note modal [ manager missed ] */
    updateLineItem = (id, status, note) => {
        // console.log(" updateLineItem = ", status);

        const {updateLineItems} = this.props;
        updateLineItems({params: {path: `v3/line-items/${id}`}}, {status, note})
    }
    /*  Change Quantity & Note modal  */
    updateLineItemsQuantity = (id, note, quantity) => {
        const {updateLineItems} = this.props;
        updateLineItems({params: {path: `v3/line-items/${id}`}}, { note, quantity})
    }

    onYes = (id) => this.updateLineItem(id, 'yes');
    onNo = (id, note) => {
        note && this.updateLineItem(id, 'no', note);

        this.setState({
            expanded: {},
        })
    }
    onMissing = (id, note) => note && this.updateLineItem(id, 'missing', note);
    onPending = (id, note) => note && this.updateLineItem(id, 'pending', note);
    // onCourierPicked = (id, note) => note && this.updateLineItem(id, 'courier_picked', note);
    // onCourierMissed = (id, note) => note && this.updateLineItem(id, 'courier_missed', note);
    // onManagerConfirmed = (id, note) => note && this.updateLineItem(id, 'manager_confirmed', note);
    // onManagerMissed = (id, note) => note && this.updateLineItem(id, 'manager_missed', note);

    /*  Read Only status  */
    onReadOnly = () => {};


    disableActionButtons = () => {
        const {data} = this.props;
        if(!Array.isArray(data) || this.state.checkedItems.size === 0) {
            return true;
        }
        for(let i = 0; i < data.length; i++) {
            if(this.state.checkedItems.has(data[i].id) && data[i].package.status !== "DELIVERED") {
                return true;
            }
        }
        return false;
    }

    yesSelection = () => {
        this.state.checkedItems.forEach(this.onYes)
        this.setState({
            checkedItems: new Set()
        })
    }

    noSelection = () => {
        if (this.state.reason) {
            this.state.checkedItems.forEach((id) => this.onNo(id, this.state.reason));
            this.setState({
                checkedItems: new Set()
            })
        }
    }

    componentWillUnmount() {
        this.props.invalidate();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {updateLineItemsPending, history, fetchList, filterFormValues} = this.props;

        if (updateLineItemsPending
            && !nextProps.updateLineItemsPending
            && !nextProps.updateLineItemsError) {
                const page = Number(qs.parse(history.location.search).page)
                
                fetchList({
                    query: {filterFormValues, page }
                })
                // fetchList(filterFormValues);
                this.hideAlert();
        }
    }

    
    /* Open modal */
    modalOpen = (selectedIndex, item) => {
        /*  audit data  */
        const { findAuditLineItems } = this.props;
        findAuditLineItems({params: {path: `v3/line-items/${item.id}/audit`}})

        this.setState({
            selectedIndex,
            modalOpen: true,
        });
      };
    /* Close modal */
    modalClose = () => { 
        this.setState({ modalOpen: false }) 
    };
    

    columns = () => {
        const {translate} = this.props;
        const {checkedAll} = this.state;
        return [
            {
                Header: <CheckboxField onChange={this.toggleSelection} name={'all'} value={checkedAll}/>,
                accessor: 'checked',
                width: 40,
                sortable: false,
            },
            {
                Header: <div style={{whiteSpace: 'pre-wrap', lineHeight: 1}}>{translate('arrivalTime')}</div>,
                // accessor: 'package.receivedAt',
                width: 140,
                style: {
                    whiteSpace: 'pre-wrap'
                },
                Cell: row => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {row.original.package && row.original.package.receivedAt}
                        <br/>
                       <b>{row.original.package && row.original.package.courierName}</b>
                    </div>
                ),
                sortable: false,
            },
            {
                Header: translate('orderId'),
                accessor: "package.code",
                expander: true,
                width: 80,
                defaultExpanded: true,
                expanded: this.state.expanded,
                Expander: row => (
                    <button
                        disabled={ this.packageItemDisabled(row.original) ? true : false }
                        className={ this.packageItemDisabled(row.original) ? "packageItemBlock disabled" : "packageItemBlock"}
                    > 
                        {row.original.package.technicalProblem && <PriorityHighIcon className="packageItemError" />  } 
                        <div 
                            className={this.orderIdStyle(row.original)}
                            onClick={this.onOrderIdClicked(row.original)}
                        >
                            {row.original.package.code}
                        </div>
                    </button>
                ),
                sortable: false,
            },
            {
                Header: translate('merchant'),
                minWidth: 100,
                width: 120,
                sortable: false,
                Cell: row => (
                    <div style={{whiteSpace: 'pre-wrap'}}>
                        <b className="provider_color">{row.original.provider.name}</b> <br/>
                        {row.original.package.merchant}

                        <br/>
                        <span class="branch_color"><b>{row.original.package.code}</b></span>
                    </div>
                ),
            },
            {
                Header: translate('item'),
                minWidth: 240,
                Cell: row => (
                    <div style={{whiteSpace: 'pre-wrap'}}>
                        {row.original.url ? 
                        <>
                            <a 
                                href={row.original.url} 
                                target="_blank" 
                                className="productName Link"
                            >
                                {row.original.title}
                            </a> 
                            {row.original.sku && <span class="branch_color"> / <b>SKU - {row.original.sku}</b> </span> }
                            
                        </>
                            :
                        <>
                            <span className="productName">
                                {row.original.title}
                            </span>
                            {row.original.sku && <span class="branch_color"> / <b>SKU - {row.original.sku}</b> </span> }
                        </>    
                        }
                        <br/>
                        {row.original.price} ₾
                        <br/>
                        {/* {row.original.quantity} */}
                        {row.original.changedQuantity === null ? row.original.quantity : row.original.changedQuantity}
                    </div>
                ),
                sortable: false,
            },
            {
                Header: translate('receiver'),
                minWidth: 180,
                Cell: row => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {row.original.package && row.original.package.recipientName}
                        <br/>
                        {row.original.package && row.original.package.recipientPhoneNumber}
                        <br/>
                        {row.original.package && row.original.package.recipientAddress}
                    </div>
                ),
                sortable: false,
            },
            {
                Header: translate('history'),
                accessor: "modalAction",
                width: 83,
                sortable: false,
            },
            {
                Header: translate('status'),
                accessor: "actions",
                width: 230,
                sortable: false,
            }
        ]
    }

    renderSubTable = (row) => {
        const {findLineItemsData, findAuditLineItemsData, translate, findLineItemsPending} = this.props;

        const data = (findLineItemsData || []).filter(el => el.id !== row.id && el.orderId == row.orderId)
        

        console.log("data __ ",findLineItemsData,);
        
        return (
            <ReactTable
                data={data && data.map((item, index) => ({
                    ...item,
                    actions: this.makeActions(item),
                    modalAction: (
                        <button
                            className={(item.package.status == "PROCESSING" || item.status == "pending_reserve") ? "tbn_check-modal" : "tbn_check-modal active"}
                            onClick={() =>  this.modalOpen(index, item) }
                            disabled={item.package.status == "PROCESSING" || item.status == "pending_reserve"}
                        >
                            <PlaylistAddCheck />
                        </button>
                    ),
                }))}
                noDataText={translate('itemsNotFound')}
                loading={findLineItemsPending}
                defaultPageSize={data.length || 4}
                pageSize={data.length || 4}
                manual
                columns={[
                    // {
                    //     Header: translate('orderId'),
                    //     accessor: "package.id",
                    //     width: 70,
                    //     sortable: false,
                    // },
                    {
                        Header: translate('item'),
                        Cell: row => (
                            <div>
                                {row.original.title}
                                <br/>
                                {row.original.price} ₾
                                <br/>
                                {/* {row.original.quantity} */}
                                {row.original.changedQuantity === null ? row.original.quantity : row.original.changedQuantity}
                            </div>
                        ),
                        sortable: false,
                    },
                    {
                        Header: translate('courier'),
                        accessor: 'package.courierName',
                        sortable: false,
                    },
                    {
                        Header: translate('status'),
                        accessor: "status",
                        sortable: false,
                        width: 130,
                    },
                    {
                        Header: translate('history'),
                        accessor: "modalAction",
                        width: 100,
                        sortable: false,
                    },
                    {
                        Header: translate('actions'),
                        accessor: "actions",
                        width: 230,
                        sortable: false,
                    }
                ]}
                showPagination={false}
                className="-striped -highlight"
            />
        )
    }

    render() {
        const {translate, history, data, pagination, loading, fetchList, findAuditLineItemsData} = this.props
        const {modal, snackBar} = this.state
        const auditData = findAuditLineItemsData;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <SortsManagerFilter showPackageStatus={true} history={history} translate={translate} fetchList={fetchList}/>

                {this.state.modalOpen && auditData.length !== 0 && <SortsManagerModal
                    isOpen={this.state.modalOpen} 
                    onClose={this.modalClose} 
                    updateLineItem={this.updateLineItem}
                    styleModal="modalStyle"
                    auditData={auditData}
                    data={data && data[this.state.selectedIndex]}    
                />}

                <GridContainer>
                    <Card>
                        <CardBody>
                            <Button
                                justIcon
                                round
                                disabled={this.disableActionButtons()}
                                simple
                                onClick={this.yesSelection}
                                color="warning"
                                className="accept"
                            >
                                <Check/>
                            </Button>

                            <Button
                                justIcon
                                round
                                disabled={this.disableActionButtons()}
                                simple
                                onClick={() => this.showAlert({
                                    title: translate('noAction', {item: 'რომელიღაც შეკვეთა'}),
                                    confirmText: translate('sayNo'),
                                    cancelText: translate('close'),
                                    hideAlert: this.ClosehideAlert,// this.hideAlert,
                                    confirmFun: () => { 
                                        this.noSelection()
                                        setTimeout(() => {
                                            this.setState({ reason: null })
                                        }, 400)
                                    },
                                    warning: true,
                                    message: () => <TextField
                                        name={'reason'}
                                        label={translate('reason')}
                                        component={TextField}
                                        onChange={this.reasonChanged}
                                        value={this.state.reason}
                                        inputProps={{
                                            multiline: true,
                                            rows: 3
                                        }}
                                        errors={[]}
                                        required={true}
                                    />
                                })}
                                color="danger"
                                className="no"
                            >
                                <Close/>
                            </Button>
                            {
                                <ReactTable
                                    data={data && data.map((item, index)=> ({
                                        ...item,
                                        checked: this.renderChecked(item),
                                        actions: this.makeActions(item),
                                        modalAction: (
                                            <button
                                                className={(item.package.status == "PROCESSING" || item.status == "pending_reserve") ? "tbn_check-modal" : "tbn_check-modal active"}
                                                onClick={() =>  this.modalOpen(index, item) }
                                                disabled={item.package.status == "PROCESSING" || item.status == "pending_reserve"}
                                            >
                                                <PlaylistAddCheck />
                                            </button>
                                        ),
                                    }))}

                                    loading={loading}
                                    pages={pagination && pagination.totalPages}
                                    manual
                                    page={this.state.pageIndex}
                                    onPageChange={this.pageChanged}
                                    defaultPageSize={(pagination && pagination.perPage) || 5}
                                    pageSize={pagination && pagination.perPage}

                                    onSortedChange={this.onSortedChange}

                                    SubComponent={row => {
                                        return (
                                            <div style={{padding: "20px"}}>
                                                {this.renderSubTable(row.original)}
                                            </div>
                                        );
                                    }}

                                    columns={this.columns()}
                                    previousText={translate('previousText')}
                                    nextText={translate('nextText')}
                                    loadingText={translate('loadingText')}
                                    pageText={translate('pageText')}
                                    ofText={translate('ofText')}
                                    showPaginationBottom
                                    showPageSizeOptions={false}
                                    className="-striped -highlight"
                                    expanded={this.state.expanded}
                                    onExpandedChange={(_, t) => {
                                        const { expanded } = this.state;
                                        const elementIndex = t[0];

                                        if (this.state.expanded)
                                        this.setState({
                                            expanded: {
                                                [elementIndex]: !expanded[elementIndex]
                                            }
                                        })
                                    }}
                                />

                            }
                        </CardBody>
                    </Card>

                </GridContainer>
                <Modal
                    loading={false}
                    modalLoading={false}
                    {...modal}
                />
                {this.state.alert.open && <ConfirmSweetAlert {...this.state.alert} />}
                <SnackBar
                    place={'br'}
                    color="info"
                    icon={AddAlert}
                    message={snackBar.message}
                    open={snackBar.openPos}
                    closeNot={this.closeSnackBar}
                />
            </GridItem>

        )
    }
}

export default SortsManagerDashboard

