
import React from 'react'
import { providers } from '../../ducks'
import { statisticServices } from '../../ducks/services'
import { connect } from 'react-redux'

import GeneralStatistics from './GeneralStatistics'

const remove = (payload) => statisticServices.own.removeStat( statisticServices.own.REMOVE_STATISTIC, payload)

const mapStateToProps = (state) => ({
  items: providers.getList(state).data,
  statistics: statisticServices.own.getData(state),
  statisticsLoading: statisticServices.own.getRequestPending(state),
  name: 'own',
})
const mapDispatchToProps = {
  addStats: statisticServices.own.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralStatistics)