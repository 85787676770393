import {dispatcherPackageForm, providerPackageForm} from "../../forms"
import {packages, packageServices, orderHours} from "../../ducks"
import packageFilterForm from "../../forms/filterForms/packageFilterForm"
import connect from "react-redux/es/connect/connect"
import PackageList from './PackageList'
import modal from "../../ducks/modal"


const packageForms = {
  dispatcher: dispatcherPackageForm,
  provider: providerPackageForm
}

const mapStateToProps = (state) => ({
  data: packages.getList(state).data,
  singleData: packages.getSingle(state),
  pagination: packages.getList(state).pagination,
  loading: packages.isListRequestPending(state),
  singleLoading: packages.isSingleRequestPending(state),
  storeLoading: packages.isStoreRequestPending(state),
  updateLoading: packages.isUpdateRequestPending(state),
  removeLoading: packages.isRemoveRequestPending(state),
  listErrors: packages.getListRequestError(state),
  singleErrors: packages.getSingleRequestError(state),
  storeErrors: packages.getStoreRequestError(state),
  updateErrors: packages.getUpdateRequestError(state),
  removeErrors: packages.getRemoveRequestError(state),
  filterFormValues: packageFilterForm.getValues(state),

  canCreateOrder: orderHours.getData(state).can,

  bulkDeletePending: packageServices.bulkDelete.getRequestPending(state),
  bulkDeleteError: packageServices.bulkDelete.getRequestError(state),
})
const mapDispatchToProps = (dispatch, {userInfo}) => ({
  fetchList: (query) => dispatch(packages.fetchList({
    query,
    params: {
      path: `/packages`
    }
  })),
  fetchSingle: (id) => dispatch(packages.fetchSingle(id, {
    params: {
      path: `/packages`
    }
  })),
  storeItem: (formValues) => dispatch(packages.store(formValues, {
    params: {
      path: `/packages`
    }
  })),
  updateItem: (id, data) => dispatch(packages.update(id, data, {
    params: {
      path: `/packages`
    }
  })),
  removeItem: (id) => dispatch(packages.remove(id, {
    params: {
      path: `/packages`
    }
  })),

  openModal: (payload) => dispatch(modal.openModal(payload)),
  closeModal: () => dispatch(modal.closeModal()),

  changeEditFormValues: (...props) => dispatch(packageForms[userInfo.role === 'provider' ? 'provider' : 'dispatcher'].edit.changeValues(...props)),
  resetCreationFormValues: (...props) => dispatch(packageForms[userInfo.role === 'provider' ? 'provider' : 'dispatcher'].create.resetValues(...props)),
  resetEditionFormValues: (...props) => dispatch(packageForms[userInfo.role === 'provider' ? 'provider' : 'dispatcher'].edit.resetValues(...props)),
  bulkDelete: (...props) => dispatch(packageServices.bulkDelete.run(...props)),
  getPackageHistory : (...props) => dispatch(packageServices.history.run(...props))
})


export default connect(mapStateToProps, mapDispatchToProps)((PackageList))
