import React from 'react'
import qs from "query-string";

class ListComponent extends React.Component {

  state = {
    alert: {
      open: false,
    },
    snackBar: { openPos: false, message: ''},
    pageIndex: 0
  }

  submitUpdate = (item) => (formValues) => this.props.updateItem(item.id, {...item, ...formValues})

  // shouldComponentUpdate(nextProps, nextState){
  //   return (JSON.stringify(this.props) !== JSON.stringify(nextProps)) || (JSON.stringify(this.state) !== JSON.stringify(nextState))
  // }

  closeSnackBar = () => this.setState(state => ({ snackBar: { openPos: false, message: '', color: state.snackBar.color }}))

  pageChanged = (pageIndex) =>  {
    this.fetchList({ query: {...this.props.filterFormValues, page: pageIndex + 1} })

    this.props.history.push({search: window.location.search.replace(/&page=[0-9+]|page=[0-9+]/i, '') + '&page=' + (pageIndex + 1)})
  }

  fetchList = (request) => this.props.fetchList({...request, params: {...this.state.params}})

  dataObject = item => ({
    ...item,
    actions: this.makeActions(item)
  })

  onSortedChange = (newSorted) => {}
    // this.props.fetchList({ query: { sort: newSorted[newSorted.length - 1].id, order: newSorted[newSorted.length - 1].desc === true ? 'desc' : 'asc' } })

  componentWillMount(){
    const { history } = this.props
    const page = Number(qs.parse(history.location.search).page)
    this.setState( { pageIndex: page || 0 })
  }

  componentWillUpdate(nextProps) {
    const {
      storeLoading,
      resetCreationFormValues,
      resetEditionFormValues,
      updateLoading,
      removeLoading,
      singleLoading,
      changeEditFormValues,
      translate,
      loading,
      history
    } = this.props


    const {pagination, singleErrors, storeErrors, updateErrors, removeErrors} = nextProps

    if ((storeLoading && !nextProps.storeLoading)) {
      if (!storeErrors) {
        this.props.closeModal()
        resetCreationFormValues()
        this.fetchList({query: {page: 1, isActive: 1}})
        this.setState({pageIndex: 0})
      }
    }

    if (updateLoading && !nextProps.updateLoading && !updateErrors) {

      this.props.closeModal()
      resetEditionFormValues()
      this.fetchList({query: {page: this.state.pageIndex + 1, isActive: 1}})
    } else

    if (removeLoading && !nextProps.removeLoading && !removeErrors) {
      if ((this.state.pageIndex + 1 >= pagination.totalPages) && (pagination.total % pagination.perPage === 1)) {
        this.fetchList({query: {page: this.state.pageIndex, isActive: 1}})
        this.setState({pageIndex: this.state.pageIndex - 1})
      } else {
        this.fetchList({query: {page: this.state.pageIndex + 1, isActive: 1}})
      }

      this.setState({
        alert: {open: false},
        snackBar: {openPos: 'bl', message: translate('deleted')}
      })
    }

    if (singleLoading && !nextProps.singleLoading && !singleErrors) {
      nextProps.singleData && changeEditFormValues(nextProps.singleData)
    }

    if (loading && !nextProps.loading && nextProps.pagination) {

      this.setState({
        pageIndex: nextProps.pagination.currentPage - 1
      })
    }

    const queryPageIndex = Number(qs.parse(history.location.search).page)
    const page = queryPageIndex ? queryPageIndex - 1 : 0

    if( (page  !== this.state.pageIndex) ){
      this.setState({ pageIndex: page})
    }

  }

  showAlert = (params) => this.setState({
    alert: {
      open: true,
      ...params
    }
  })

  hideAlert = () => this.setState({
    alert: {open: false}
  })


  openFormModal = ({title = '', duck, submitText = '', form, Component, componentProps, noSubmit, submit, propName, additionalButtons = [], small = true}) => this.props.openModal({
    title,
    Component,
    componentProps,
    noSubmit,
    submitText,
    duck,
    submit,
    small,
    form,
    loading: false,
    modalLoading: false
  })
}


export default ListComponent
