import {createServiceGroup} from "../functions/createGroup"
import {createResource} from "../../helpers"

export const groupPackageData = d => ({
    ...d,
    receiveTime: {
        receivedAt: d && d.receivedAt,
        code: d && d.code
    },
    typeAndPrice: {
        type: d && d.paymentType,
        price: d && d.packagePrice,
        status: d && d.status,
        deliveryFee: d && d.deliveryFee
    },
    recipient: {name: d && d.recipientName, address: d && d.recipientAddress, phone: d && d.recipientPhone},
    provider: {
        name: d.provider && d.provider.name,
        phoneNumber: d.branch && d.branch.phoneNumber,
        address: d.branch && d.branch.address
    },
    estimatedTime: {
        pickupTime: d && d.approxPickupTime,
        deliveryTime: d && d.approxDeliveryTime,
        status: d && d.status
    },
})

export const transformPackResponse = (response) =>
    response.body.data.map(data => groupPackageData(data))

export default createServiceGroup({
    name: 'packageServices',
    items: {
        changeStatus: {
            name: 'packageChangeStatus',
            resource: '/packages',
            createResource,
        },
        bulkDelete: {
            // requestName: 'delete',
            name: 'bulkDeletePackages',
            resource: '/packages/bulk/delete',
        },
        history: {
            name: 'packageHistory',
            initialData: [],
            createResource,
            type: 'get',
            transformData: transformPackResponse
        },
        upload: {
            name: 'upload',
            createResource,
            transformData: res => res,
            type: 'post',
        }
    }
    ,
    generic: {
        type: 'post'
    }
})
