
import createLocalizationReducer from "redux-l10n";
import { persistReducer } from 'redux-persist'
import {en, ka, ru} from "../l10n";
import {defaultLocale} from "../defaultConfigs";
import storage from 'redux-persist/lib/storage'

const localization = createLocalizationReducer({
  en,
  ka,
  ru
}, defaultLocale)

export default persistReducer({
  key: 'localization.defaultLocale',
  storage,
  whitelist: ['defaultLocale']
}, localization)