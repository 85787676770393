import {createForm} from "ductus"
import { AUTHORIZATION_RECEIVE_ERROR } from "../ducks/authorization"

export default createForm({
  container: 'forms',
  name: 'authForm',
  errorActionTypes: [AUTHORIZATION_RECEIVE_ERROR],
  // transformError: transformErrorToFieldsErrors,
  fields: {
    email: {
      // validate: validateExistingEmail
    },
    password: {}
  },
})
