import React, {Fragment} from 'react'
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles"
import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Close from "@material-ui/icons/Close"
import Button from "../CustomButtons/Button.jsx"
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle.jsx"
import singleLoading from '../../assets/singleLoading.svg'
function Transition(props) {
  return <Slide direction="down" {...props} />
}

const Modal = ({classes, title, isOpen = false, buttons = [], buttonsContainerStyles, content, small = false, close, loading, modalLoading = false}) => (
  <Dialog
    // ref={ (divElement) => this.divElement = divElement}
    style={{overflow: 'hidden', display: 'block'}}
    classes={{
      root: classes.center,
      paper: small ? classes.smallerModal + ' ' : '' + classes.modal + ' ' + classes.sss
    }}
    open={isOpen}
    transition={Transition}
    keepMounted
    onClose={close}
    aria-labelledby="modal-slide-title"
    aria-describedby="modal-slide-description">
    {modalLoading &&
    <div style={{
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255, 0.7)',
      width: '100%',
      height: '100%',
      zIndex: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img src={singleLoading} style={{ width: 50}} alt={'loading'} />
    </div>
    }
    <div style={{ overflowY: 'auto' }}>
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={close}
        >
          <Close className={classes.modalClose}/>
        </Button>
        <h4 className={classes.modalTitle}>
          {title}
        </h4>
      </DialogTitle>

      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        {content}
        {loading && 'Loading...'}
      </DialogContent>

    </div>
    <DialogActions
      className={classes.modalFooter + " " + classes.modalFooterCenter}
      style={buttonsContainerStyles}
    >
      {buttons.map((button, key) =>
        <Button
          key={key}
          {...button.props}
          onClick={() => {
            button.onClick && button.onClick()
            close(!button.onClick)
          }
          }
        >
          {button.text}
        </Button>
      )}

    </DialogActions>
  </Dialog>
)


export default withStyles(modalStyle)(Modal)
