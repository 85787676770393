
import { createServiceGroup} from "../functions/createGroup"
import {createResource } from "../../helpers"

export default createServiceGroup({
  name: 'districtServices',
  items: {
    getAllTariff: {
      name: 'getDistrictAllTariff',
      createResource,
      type: 'get',
      transformData: response => response.body.data,
      initialData: []
    },
    getTariffs: {
      name: 'getDistrictTariffs',
      createResource,
      type: 'get',
    },
    storeTariffs: {
      name: 'storeTariffs',
      createResource,
    },
    updateTariffs: {
      name: 'updateTariffs',
      createResource,
      type: 'put'
    },
    getDistrictByProvider: {
      name: 'getDistrictByProvider',
      createResource,
      type: 'get',
    },
    updateDistrictByProvider: {
      name: 'updateDistrictByProvider',
      createResource,
      type: 'post',
    },
    getProviderDistricts: {
      name: 'getProviderDistricts',
      createResource,
      type: 'get',
    },
  },
  generic: {
    type: 'post'
  }
})
