
import React from 'react'
import { hubs } from '../../ducks'
import { financeServices } from '../../ducks/services'
import { connect } from 'react-redux'

import GeneralFinances from './GeneralFinances'

const remove = (payload) => financeServices.hubs.removeFinance( financeServices.hubs.REMOVE_FINANCE, payload)

const mapStateToProps = (state) => ({
  items: hubs.getList(state).data,
  finances: financeServices.hubs.getData(state),
  financesLoading: financeServices.hubs.getRequestPending(state),
  name: 'hubs',
})
const mapDispatchToProps = {
  fetchItems: hubs.fetchList,
  addFinances: financeServices.hubs.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralFinances)