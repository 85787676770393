
import { combineReducers } from 'redux'

const serviceReduceFunction = (serviceObject) => (reduction, service) => ({
  ...reduction,
  [service]: serviceObject[service].reduce
})

export const createServiceReducer =
  (serviceObject) =>
    combineReducers(Object.keys(serviceObject).reduce( serviceReduceFunction(serviceObject), {}))

export const createServicesReducer = (services) => Object.keys(services)
  .reduce(
    (reduction, service) => ({ ...reduction,  [service]: createServiceReducer(services[service]) }),
    {})