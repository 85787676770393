import {createResource } from "../helpers"
import {createGroup} from "./functions/createGroup"

const USER_HUB_TOGGLE = 'USER_HUB/TOGGLE'
const toggleHubSelect = (hubId) => ({ type: USER_HUB_TOGGLE, payload: hubId })

export default createGroup({
  statePath: `userHubs`,
  name: 'userHubs',
  generic: {
    initialData: [],
    initiallyPending: true,
    transformData: (response) => response.body.data
  },
  additionalActionTypes: {
    USER_HUB_TOGGLE
  },
  additionalActionCreators: {
    toggleHubSelect
  },
  additionalHandlers: {
    [USER_HUB_TOGGLE]: (state, { payload }) => ({...state, data: state.data.map(d => d.id === payload ? {...d, selected: !d.selected} : d )})
  },

  createResource: createResource
})