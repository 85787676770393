// ##############################
// // // ExtendedForms view styles
// #############################

import { cardTitle } from "../../../../assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const extendedFormsStyle = (theme) => ({
  // ...customCheckboxRadioSwitch(theme),
  ...customSelectStyle(theme),
  // cardTitle,
  // cardIconTitle: {
  //   ...cardTitle,
  //   marginTop: "15px",
  //   marginBottom: "0px"
  // },
  // label: {
  //   cursor: "pointer",
  //   paddingLeft: "0",
  //   color: "rgba(0, 0, 0, 0.26)",
  //   fontSize: "14px",
  //   lineHeight: "1.428571429",
  //   fontWeight: "400",
  //   display: "inline-flex"
  // }
  notFoundData: {
    color: '#c7c7c7',
    padding: '15px 25px 25px 25px'
  },
  headerPanel: {
    width: '100%',
    minHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardButtonBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  BtnAttachPostDisabled: {
    margin: '5px 10px',
    outline: 'none',
    fontSize: '14px',
    transition: '.4s',
    padding: '8px 14px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #adadad',
    color: '#212121',
    boxShadow: '0 0 7px 0px rgb(153 150 153)',
  },
  BtnAttachPostActive: {
    color: '#fff',
    margin: '5px 10px',
    outline: 'none',
    fontSize: '14px',
    transition: '.4s',
    padding: '8px 14px',
    borderRadius: '8px',
    textTransform: 'none',
    border: '1px solid #4caf50',
    backgroundColor: '#4caf50',
    boxShadow: '0 0 7px 0px rgb(153 150 153)',
    "&:hover": {
      backgroundColor: '#3e8e41',
      boxShadow: '0 0 0px 0px rgb(153 150 153)',
    }
  },
  
  BtnAttachCourierDisabled: {
    margin: '5px 10px',
    outline: 'none',
    fontSize: '14px',
    transition: '.4s',
    padding: '8px 14px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #adadad',
    color: '#212121',
    boxShadow: '0 0 7px 0px rgb(153 150 153)',
  },
  BtnAttachCourierActive: {
    color: '#fff',
    margin: '5px 10px',
    outline: 'none',
    fontSize: '14px',
    transition: '.4s',
    padding: '8px 14px',
    borderRadius: '8px',
    textTransform: 'none',
    border: '1px solid #f44336',
    backgroundColor: '#f44336',
    boxShadow: '0 0 7px 0px rgb(153 150 153)',
    "&:hover": {
      backgroundColor: '#f21c0d',
      boxShadow: '0 0 0px 0px rgb(153 150 153)',
    }
  },
  BtnDeliveredDisabled:{
    margin: '5px 10px',
    outline: 'none',
    fontSize: '14px',
    transition: '.4s',
    padding: '8px 14px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #adadad',
    color: '#212121',
    boxShadow: '0 0 7px 0px rgb(153 150 153)',
  },
  BtnDeliveredActive: {
    color: '#fff',
    margin: '5px 10px',
    outline: 'none',
    fontSize: '14px',
    transition: '.4s',
    padding: '8px 14px',
    borderRadius: '8px',
    textTransform: 'none',
    border: '1px solid #4caf50',
    backgroundColor: '#4caf50',
    boxShadow: '0 0 7px 0px rgb(153 150 153)',
    "&:hover": {
      backgroundColor: '#3e8e41',
      boxShadow: '0 0 0px 0px rgb(153 150 153)',
    }
  },

  BtnRejectedDisabled:{
    margin: '5px 10px',
    outline: 'none',
    fontSize: '14px',
    transition: '.4s',
    padding: '8px 14px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #adadad',
    color: '#212121',
    boxShadow: '0 0 7px 0px rgb(153 150 153)',
  },
  BtnRejectedActive:{
    color: '#fff',
    margin: '5px 10px',
    outline: 'none',
    fontSize: '14px',
    transition: '.4s',
    padding: '8px 14px',
    borderRadius: '8px',
    textTransform: 'none',
    border: '1px solid #f44336',
    backgroundColor: '#f44336',
    boxShadow: '0 0 7px 0px rgb(153 150 153)',
    "&:hover": {
      backgroundColor: '#f21c0d',
      boxShadow: '0 0 0px 0px rgb(153 150 153)',
    }
  },
    

})


export default extendedFormsStyle;
