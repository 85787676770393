import {providerFilterForm, providerForm} from "../../../forms";
import {providers} from "../../../ducks";
import connect from "react-redux/es/connect/connect";

import ProviderList from './ProviderList'

const mapStateToProps = (state) => ({
  creationFormValues: providerForm.create.getValues(state),
  editionFormValues: providerForm.edit.getValues(state),
  data: providers.getList(state) && providers.getList(state).data,
  singleData: providers.getSingle(state).data,
  pagination: providers.getList(state).pagination,
  loading: providers.isListRequestPending(state),
  singleLoading: providers.isSingleRequestPending(state),
  storeLoading: providers.isStoreRequestPending(state),
  updateLoading: providers.isUpdateRequestPending(state),
  removeLoading: providers.isRemoveRequestPending(state),
  listErrors: providers.getListRequestError(state),
  singleErrors: providers.getSingleRequestError(state),
  storeErrors: providers.getStoreRequestError(state),
  updateErrors: providers.getUpdateRequestError(state),
  removeErrors: providers.getRemoveRequestError(state),
  filterFormValues: providerFilterForm.getValues(state),

})
const mapDispatchToProps = {
  fetchList: providers.fetchList,
  fetchSinge: providers.fetchSingle,
  storeItem: providers.store,
  updateItem: providers.update,
  removeItem: providers.remove,
  changeEditFormValues: providerForm.edit.changeValues,
  resetCreationFormValues: providerForm.create.resetValues,
  resetEditionFormValues: providerForm.edit.resetValues,

}


export default connect(mapStateToProps, mapDispatchToProps)(ProviderList)

