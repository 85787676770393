import {Field, withForm} from "ductus";
import {Email, LockOutline} from "@material-ui/icons";
import {TextField} from "../../components/fields";
import CardBody from "../../components/Card/CardBody";
import React from "react";
import {authForm} from "../../forms";

const LoginForm = ({translate}) => (
  <CardBody>
    <Field
      name={'email'}
      label={translate('email')}
      icon={Email}
      component={TextField}
    />
    <Field
      name={'password'}
      type={'password'}
      label={translate('password')}
      icon={LockOutline}
      component={TextField}
      showSuccess={false}
    />

  </CardBody>
)

export default withForm(authForm)(LoginForm)