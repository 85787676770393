import { compose } from 'redux-net'

const floatingNumberReg = /^-?\d*\.?\d*$/
const numberRegExp = /[+-]?([0-9]*[.])?[0-9]+/
const arrInputRegExp = /^[0-9]+(,[0-9]+)*$/g


export const transformValueLengthExact = (length) => (value, prevValue) => value && value.length > length
  ? prevValue
  : value

export const transformToFloatingNumber = (value, prevValue) => !floatingNumberReg.test(value)
  ? value === '' ? value : prevValue
  : value

export const transformToNumber = (value, prevValue) => !numberRegExp.test(value)
  ? value === '' ? value : prevValue
  : value

export const transformToMinNumber = min => (value, prevValue) => Number(value) < min
  ? value === '' ? value : prevValue
  : value

export const transformToMaxNumber = max => (value, prevValue) => Number(value) > max
  ? value === '' ? value : prevValue
  : value

export const transformToArrInput = (value, prevValue) => !arrInputRegExp.test(value)
  ? prevValue
  : value

export const transformToPositiveNumbers = (value, prevValue) => !arrInputRegExp.test(value)
? value === '' ? value : prevValue
: value

export const transformNumber = (length) => compose(
  transformValueLengthExact(length),
  transformToNumber
)