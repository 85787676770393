import {authorize, isAuthorizationRequestPending} from "../../ducks/authorization";
import {authForm} from "../../forms";
import {push} from "react-router-redux";

import { connect } from 'react-redux'

import LoginPage from './LoginPage'
import {getTranslator} from "redux-l10n/dist/selectors";

const mapStateToProps = state => ({
  loading: isAuthorizationRequestPending(state),
  formValues: authForm.getValues(state),
  // getWhereToRedirect: getWhereToRedirect(state)

})

const mapDispatchToProps = {authorize,   resetValues: authForm.resetValues }

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)