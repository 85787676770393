import {combineValidators} from "ductus/build/index"

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const numberRegExp = /^[0-9]\d*(\.\d+)?$/
const floatingNumberRegExp = /^-?\d*\.?\d*$/
const arrInputRegExp = /^[0-9]+(,[0-9]+)*$/

export const validateEmail =
  value => (!value || value.length === '') ||
  emailRegExp.test(value)
    ? []
    : [{code: 'ელფოსტის მისამართი არასწორია'}] //email_wrong

export const validateNumber = (value) => (!value || value.length === '') || numberRegExp.test(value)
  ? []
  : [{code: 'არა რიცხვები'}] // not_number

export const validateFloatingNumber = value => (!value || value.length === '') || floatingNumberRegExp.test(value)
  ? []
  : [{code: 'არა რიცხვები'}] // not_number

export const validateExistence = value => !(value === null || value === undefined)
  ? []
  : [{code: 'სავალდებულო ველი'}] // required_field

export const exactLength = (length) => (value) => (!value || value.length === '') || value && value.length === length
  ? []
  : [{code: 'რაოდენობა უნდა იყოს ' + length}] // length_must_be_

export const minLength = (length) => (value) => (!value || value.length === '') || value.length >= length
  ? []
  : [{code: 'რაოდენობა ძალიან მოკლეა'}] // length_too_short

export const maxLength = (value, length) => (!value || value.length === '') || value.length <= length
  ? []
  : [{code: 'რაოდენობა ძალიან გრძელია'}] // length_too_long


export const minNumber = min => (value) => (!value || value.length === '') || value >= min
  ? []
  : [{code: 'მინიმალური ზომა უნდა იყოს ' + min}] // min_must_be_

export const maxNumber = max => (value) => (!value || value.length === '') || value <= max
  ? []
  : [{code: 'აქსიმალური ზომა უნდა იყოს ' + max}] // max_must_be_


export const validateArrayInput = (value) => (!value || value.length === 0) || arrInputRegExp.test(value)
  ? []
  : [{code: 'არასწორი მონაცემებია შეყვანილი'}] // invalid_array_input

export const validatePassword = (value) => (!value || value.length === 0) || value.length > 5
  ? []
  : [{code: 'პაროლი ძალიან მოკლეა'}]  // password_too_short

export const existingNumber = combineValidators(
  validateNumber,
  validateExistence
)

export const numberRange = (min, max) => combineValidators(
  validateNumber,
  minNumber(min),
  maxNumber(max),
)

export const validateExistingEmail = combineValidators(
  validateExistence,
  validateEmail,
)

export const validatePhoneNumber = combineValidators(
  validateNumber,
  exactLength(9)
)

export const validateIdentityNumber = () => []
//   combineValidators(
//   validateExistence,
//   validateNumber,
//   exactLength(11),
// )
