import React from "react"
import cx from "classnames"
import {Switch, Route, Redirect} from "react-router-dom"
import "perfect-scrollbar/css/perfect-scrollbar.css"

import withStyles from "@material-ui/core/styles/withStyles"

import Header from "../components/Header/Header.jsx"

import Sidebar from "../components/Sidebar/Sidebar.jsx"

import dashboardRoutes from "../routes/dashboard.jsx"
import warehouseManagerRoutes from "../routes/warehouseManager.jsx"
import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx"
import logo from "../assets/img/logo-white.svg"
import {connect} from 'react-redux'
import {snackBar, dashboard, orderHours, userHubs, userInfo } from '../ducks'
import {SnackBar} from '../components'
import {invalidateToken} from '../ducks/authorization'
import Voice from '../components/Voice/Voice'
import {voice, theme} from '../ducks'
import beep from '../assets/voices/beep.wav'
import horse from '../assets/voices/horse.mp3'

const voices = {
  beep,
  horse,
}

class Dashboard extends React.Component {
  interval = null

  state = {
    mobileOpen: false,
  }
  handleDrawerToggle = () => {
    this.setState({mobileOpen: !this.state.mobileOpen})
  }

  componentWillMount() {
    const {translate, userInfo, fetchProcessing, fetchCurrent, possibleOrder, getUserHubs } = this.props
    const {permissions, role} = userInfo

    // fetchProcessing({params: {path: `/packages/processing`},  query: { 'with-pagination': 1 }})
    // fetchCurrent({params: {path: '/packages/current'},  query: { 'with-pagination': 1 }})
    getUserHubs({ params: { path: '/user/hubs' }})
    role === 'provider' && possibleOrder()

    let RoutProvider = role === "warehouse_manager" ? warehouseManagerRoutes : dashboardRoutes;

    this.switchRoutes = (
      <Switch>
        {RoutProvider.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.pathTo} key={key}/>
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              if (!prop.perm || permissions[prop.perm])
                return (
                  <Route exact path={prop.path}
                         render={(props) =>
                           <prop.component permissions={permissions} userInfo={userInfo}
                                           translate={translate} {...props} />} key={key}
                  />
                )
            })
          if (!prop.perm || permissions[prop.perm]) {
            return (
              <Route exact path={prop.path}
                     render={(props) =>
                       <prop.component permissions={permissions} userInfo={userInfo}
                                       translate={translate} {...props}/>} key={key}
              />
            )
          }
        })}
      </Switch>
    )
  }

  sidebarMinimize() {
    const {changeSidebarMini, sidebarConfigs: {miniActive}} = this.props
    changeSidebarMini(!miniActive)
  }

  componentWillUpdate({processing, botherUser, userInfo: {role}}) {

    if (role !== 'provider') {
      if (!this.props.processing.length && processing.length) {
        this.interval = setInterval(this.props.botherUser, 20000)
      }
      else if (this.props.processing.length && !processing.length) {
        clearInterval(this.interval)
      }
    }

  }

  render() {
    const {
      classes,
      snackBar,
      currentVoice,
      sidebarConfigs: {sidebarBgColor, sidebarColor, miniActive, logoText},
      ...rest
    } = this.props

    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: miniActive,
      })

    let RoutProvider = this.props.userInfo.role === "warehouse_manager" ? warehouseManagerRoutes : dashboardRoutes;

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={RoutProvider}
          logoText={logoText}
          logo={logo}
          // image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={sidebarColor}
          bgColor={sidebarBgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={miniActive}
            routes={RoutProvider}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />

          <div className={classes.map}>{this.switchRoutes}</div>

        </div>


        <SnackBar
          {...snackBar}
        />

        <Voice voice={voices[currentVoice]}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  snackBar: snackBar.getSnackBarConfigs(state),
  processing: dashboard.processing.getData(state),
  currentVoice: voice.getCurrentVoice(state),
  sidebarConfigs: theme.getThemeSidebar(state)
})

const botherUser = (message) => ({
  type: 'EVENT/OCCURRED',
  payload: {type: 'Processing table is waiting for you!', payload: {}, notify: true}
})

export default connect(
  mapStateToProps,
  {
    fetchProcessing: dashboard.processing.run,
    fetchCurrent: dashboard.current.run,
    // fetchStats: dashboard.stats.run,
    fetchPreOrder: dashboard.preOrder.run,
    possibleOrder: orderHours.run,
    getUserHubs: userHubs.run,
    changeSidebarMini: theme.changeSidebarMini,
    invalidateToken,
    botherUser: botherUser,
  }
)(withStyles(appStyle)(Dashboard))
