import {Image, GolfCourse, Map, Store, Motorcycle, ShoppingCart, DeviceHub, Business, RecordVoiceOver, AttachMoney, PieChart, LocalBar} from '@material-ui/icons'
import DashboardIcon from "@material-ui/icons/Dashboard"
import {ProviderWizard, ProviderEditForm, ProvidersList} from '../views/Providers'
import BranchList from '../views/Branches'
import ProviderBranches from '../views/BranchesProvider'
import CouriersList from '../views/Couriers'
import PackageList from '../views/Packages'
import DistrictList from "../views/Districts"
import HubList from "../views/Hubs"
import ManagersList from "../views/Managers"
import WarehouseManagersList from "../views/WarehouseManager"
import DispatchersList from "../views/Dispatchers"
import Dashboard from '../views/Dashboard'
import Profile from '../views/Profile'
import Settings from '../views/Settings'
import DistrictPrices from '../views/DistrictTariffs'
import ProviderDistricts from '../views/Districts/ProviderDistricts'
import SizeList from '../views/Sizes'
import CouriersMap from "../views/Map";

import {
  districtPermissions,
  providerPermissions,
  providerPermissionsHistory,
  hubPermissions,
  managerPermissions,
  courierPermissions,
  packagePermissions,
  branchPermissions,
  recipientPermissions,
  financePermissions,
  statisticPermissions,
  locationPermissions, paymentPermissions, dispatcherPermissions
} from '../permissions'
import RecipientList from "../views/Recipients";

import { ProviderFinances, HubFinances, CourierFinances, OwnFinances } from '../views/Finances'
import { ProviderStatistics, CourierStatistics, DispatcherStatistics, OwnStatistics} from '../views/Statistics'
import { ProviderPayments, CourierPayments } from '../views/Payment'
import ContentPaste from "@material-ui/icons/ContentPaste";
import {SortsManagerDashboard, SortsManagerHistory, WarehouseDashboard, WarehouseHistory} from "../views/SortsManager";
    
let dashRoutes = [
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    hide: true,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    hide: true,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    icon: DashboardIcon,
    component: Dashboard
  },
  {
    path: "/sortDashboard",
    name: "sortManagerDashboard",
    icon: Store,
    component: SortsManagerDashboard,
    perm: providerPermissions.panel,
  },
  {
    path: "/sortHistory",
    name: "sortHistory",
    icon: Store,
    component: SortsManagerHistory,
    perm: providerPermissionsHistory.panel,
    // perm: providerPermissions.panel,
  },
  {
    path: "/warehouseDashboard",
    name: "warehouseDashboard",
    icon: Business,
    component: WarehouseDashboard,
    perm: providerPermissions.panel,
  },
  {
    path: "/warehouseHistory",
    name: "warehouseHistory",
    icon: Business,
    component: WarehouseHistory,
    perm: providerPermissions.panel,
  },
  {
    path: "/providers",
    name: "providers",
    icon: Store,
    component: ProvidersList,
    perm: providerPermissions.panel,
  },
  {
    path: "/providers/add",
    name: "providerAdd",
    icon: DashboardIcon,
    component: ProviderWizard,
    hide: true,
    perm: providerPermissions.create,
  },
  {
    path: "/providers/:providerId/edit",
    name: "providerEdit",
    icon: DashboardIcon,
    component: ProviderEditForm,
    hide: true,
    perm: providerPermissions.edit
  },
  {
    path: "/providers/:providerId/branches",
    name: "branches",
    icon: DashboardIcon,
    component: BranchList,
    hide: true,
    perm: branchPermissions.read
  },

  {
    path: "/providers/:providerId/tariffs",
    name: "DistrictPrices",
    component: DistrictPrices,
    hide: true,
  },
  {
    path: "/providers/:providerId/district",
    name: "ProviderDistricts",
    component: ProviderDistricts,
    hide: true,
  },
  {
    path: "/providers/:providerId/item-metrics",
    name: "SizeList",
    component: SizeList,
    hide: true,
  },
  {
    path: "/branches",
    name: "providerBranches",
    icon: DashboardIcon,
    component: ProviderBranches,
    perm: branchPermissions.ownPanel
  },
  {
    path: '/couriers',
    name: 'couriers',
    icon: Motorcycle,
    component: CouriersList,
    perm: courierPermissions.panel
  },

  {
    path: "/packages",
    name: "packages",
    icon: ShoppingCart,
    component: PackageList,
    perm: packagePermissions.panel
  },
  {
    path: "/districts",
    name: "districts",
    icon: GolfCourse,
    component: DistrictList,
    perm: districtPermissions.panel
  },

  {
    path: "/hubs",
    name: "hubs",
    icon: DeviceHub,
    component: HubList,
    perm: hubPermissions.panel
  },

  {
    path: "/dispatchers",
    name: "dispatchers",
    icon: RecordVoiceOver,
    component: DispatchersList,
    perm: dispatcherPermissions.panel
  },

  {
    path: "/managers",
    name: "managers",
    icon: RecordVoiceOver,
    component: ManagersList,
    perm: managerPermissions.panel
  },
  {
    path: "/warehouseManagers",
    name: "warehouseManager",
    icon: RecordVoiceOver,
    component: WarehouseManagersList,
    perm: managerPermissions.panel
  },
  {
    path: "/recipients",
    name: "recipients",
    icon: GolfCourse,
    component: RecipientList,
    perm: recipientPermissions.panel
  },
  {
    collapse: true,
    path: "/finances",
    name: "finances",
    state: "openFinances",
    icon: AttachMoney,
    perm: financePermissions.panel,
    views: [
      {
        path: "/finances/provider",
        name: "providers",
        mini: "P",
        component: ProviderFinances
      },
      {
        path: "/finances/hub",
        name: "hubs",
        mini: "H",
        component: HubFinances
      },
      {
        path: "/finances/courier",
        name: "couriers",
        mini: "C",
        component: CourierFinances
      }
    ]
  },
  {
    path: "/statistics/own",
    name: "ownStatistics",
    icon: PieChart,
    component: OwnStatistics,
    perm: statisticPermissions.ownPanel
  },
  {
    path: "/finances/own",
    name: "ownFinances",
    icon: GolfCourse,
    component: OwnFinances,
    perm: financePermissions.ownPanel
  },
  {
    collapse: true,
    path: "/statistics",
    name: "statistics",
    state: "openStatistics",
    icon: ContentPaste,
    perm: statisticPermissions.panel,
    views: [
      {
        path: "/statistics/provider",
        name: "providers",
        mini: "P",
        component: ProviderStatistics
      },
      {
        path: "/statistics/courier",
        name: "couriers",
        mini: "C",
        component: CourierStatistics
      },
      {
        path: "/statistics/dispatcher",
        name: "dispatchers",
        mini: "D",
        component: DispatcherStatistics
      }
    ]
  },
  {
    collapse: true,
    path: "/payments",
    name: "payments",
    state: "openPayments",
    icon: LocalBar,
    perm: paymentPermissions.panel,
    views: [
      {
        path: "/payments/provider",
        name: "providers",
        mini: "P",
        component: ProviderPayments
      },
      {
        path: "/payments/courier",
        name: "couriers",
        mini: "C",
        component: CourierPayments
      }
    ]
  },
  {
    path: '/map',
    name: 'map',
    icon: Map,
    component: CouriersMap,
    perm: locationPermissions.panel
  },

  {redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard"}
]

export default dashRoutes
