import React, {Fragment} from 'react'

import {withForm, Field} from 'ductus'
import {providerPackageForm} from '../../forms'

import ComboField from '../../components/fields/ComboField'
import TextField from '../../components/fields/TextField'
import RadioGroupField from '../../components/fields/RadioGroupField'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'

import CreditCard from '@material-ui/icons/CreditCard'
import AttachMoney from '@material-ui/icons/AttachMoney'
import Face from '@material-ui/icons/Face'
import Flag from '@material-ui/icons/Flag'
import Done from '@material-ui/icons/Done'

import {connect} from 'react-redux'
import {providers, districts, couriers, branches} from '../../ducks'
import {DateTimeField} from "../../components"
import ReactTable from 'react-table'

import {
  BrowserView,
  MobileView,
} from "react-device-detect";

import MobileDate from '../../components/fields/MobileDateTimeField'

const blurred = {
  position: 'absolute',
  backgroundColor: 'rgba(255,255,255, 0.35)',
  width: 'calc(100% - 30px)',
  height: 'calc(100% - 60px)',
  zIndex: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

class DispatcherCreatePackage extends React.Component {

  state = {

    modal: {
      isOpen: false,
      title: '',
      content: null,
      buttons: [],
      buttonContainerStyles: {}
    },
    disabled: {
      provider: true,
      receiver: true,
      pay: true,
    }
  }

  componentDidMount() {
    const {fetchDistricts, fetchBranches, providerId, changeFormValue, deliveryFee} = this.props
    // todo: districts without pagination
    fetchDistricts({query: {'without-pagination': 1}})
    fetchBranches({params: {path: `/providers/${providerId}/branches`}, query: {'without-pagination': 1}})

    changeFormValue('deliveryFee', deliveryFee || '')

  }

  onButtonClick = (name) => this.setState({disabled: {...this.state.disabled, [name]: false}})

  branchSelectionChange = (val) => {
    const branch = this.props.branches.filter(b => b.id === val)[0]
    const {changeFormValues} = this.props
    changeFormValues({
      pickupDistrictId: branch.districtId,
      pickupAddress: branch.address
    })
  }



  render() {
    const {districts, translate, branches, permissions, values } = this.props
    const activeTabe = this.props.activeTabe;

    return (
      <GridContainer>
        <div style={blurred}/>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Field
            name={'status'}
            label={'status'}
            component={TextField}
            inputProps={{
              disabled: true
            }}
            icon={Face}
            iconPosition={'start'}
          />

          <Field
            name={'code'}
            label={'code'}
            component={TextField}
            inputProps={{
              disabled: true
            }}
            icon={Face}
            iconPosition={'start'}
          />

          <Field
            name={'receivedAt'}
            label={'receivedAt'}
            component={TextField}
            inputProps={{
              disabled: true
            }}
            icon={Face}
            iconPosition={'start'}
          />

          <Field
            name={'takenAt'}
            label={'takenAt'}
            component={TextField}
            inputProps={{
              disabled: true
            }}
            icon={Flag}
            iconPosition={'start'}
          />

          <Field
            name={'deliveredAt'}
            label={'deliveredAt'}
            component={TextField}
            inputProps={{
              disabled: true
            }}
            icon={Done}
            iconPosition={'start'}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6} lg={6}>
          <legend> {translate('payment')}</legend>
          <Fragment>
            <GridContainer style={{alignItems: 'center', marginBottom: 9, marginTop: 13}}>
              {/*<GridItem xs={12}>*/}
                {/*<small style={{color: '#AAAAAA', fontSize: 15}}> {translate('paymentType/money')} </small>*/}
              {/*</GridItem>*/}

              <GridItem xs={12}>
                <BrowserView>
                  <Field
                    name={'visibleAfter'}
                    label={translate('preOrder')}
                    timeFormat="HH:mm"
                    dateFormat="DD-MM-YYYY"
                    component={DateTimeField}
                  />
                </BrowserView>

                <MobileView>
                  <Field
                    name={'visibleAfter'}
                    label={translate('preOrder')}
                    component={MobileDate}
                    timeFormat="HH:mm"
                    dateFormat="DD-MM-YYYY"
                    confirmText={translate('set')}
                    cancelText={translate('cancel')}
                  />
                </MobileView>
              </GridItem>

              <GridItem xs={12} style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                <Field
                  name={'paymentType'}
                  label={translate('paymentType')}
                  component={RadioGroupField}
                  items={[
                    {id: 'CASH', name: <AttachMoney/>},
                    {id: 'CREDIT', name: <CreditCard/>},
                  ]}
                  containerStyles={{
                    maxWidth: 150
                  }}
                />

                <Field
                  name={'packagePrice'}
                  label={translate('money')}
                  component={TextField}
                  formControlProps={{
                    style: { minWidth: 60, flex: 1 }
                  }}
                />
              </GridItem>

              <GridItem xs={6}>
                <Field
                  name={'packagePrice'}
                  label={translate('money')}
                  component={TextField}
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name={'deliveryFee'}
                  label={translate('deliveryFee')}
                  component={TextField}
                  // inputProps={{
                  //   disabled: true
                  // }}
                />
              </GridItem>
            </GridContainer>
          </Fragment>
        </GridItem>


        <GridItem xs={12} sm={12} md={6} lg={6}>
          <legend> {translate('provider')}</legend>

          <Fragment>

            <Field
              name={'branchId'}
              label={translate('branch')}
              component={ComboField}
              items={branches || []}
              selectionChange={this.branchSelectionChange}
            />

            {
              permissions.packages_select_pickup_district &&
              <Field
                name={'pickupDistrictId'}
                label={translate('pickupDistrict')}
                component={ComboField}
                items={districts || []}
                disabled={true}
              />
            }

            <Field
              name={'pickupAddress'}
              label={translate('pickupAddress')}
              component={TextField}
              inputProps={{
                disabled: true
              }}
            />

          </Fragment>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} lg={6}>
          <legend> {translate('recipient')}</legend>

          <Fragment>
            
            {/* {
              permissions.packages_select_delivery_district && */}
              <Field
                name={'deliveryDistrictId'}
                label={translate('deliveryDistrict')}
                component={ComboField}
                items={districts}
              />
            {/* } */}

            <Field
              name={'recipientName'}
              label={translate('recipientName')}
              component={TextField}
            />

            <Field
              name={'recipientPhoneNumber'}
              label={translate('recipientPhone')}
              component={TextField}
            />

            <Field
              name={'recipientAddress'}
              label={translate('recipientAddress')}
              component={TextField}
            />

          </Fragment>

        </GridItem>

        <GridItem xs={12}>
          {/* <Field
            name={'comment'}
            label={translate('comment')}
            component={TextField}
            inputProps={{
              multiline: true,
              rows: 3
            }}
          /> */}
        </GridItem>

        <GridItem xs={12}>
        <ReactTable
            data={values.items && values.items.map(item => ({
                ...item,
                // actions: this.makeActions(item)
            }))}
            noDataText={translate('itemsNotFound')}
            loading={false}
            defaultPageSize={(values.items || []).length || 4}
            pageSize={(values.items || []).length || 4}
            manual
            columns={[
                // {
                //     Header: translate('orderId'),
                //     accessor: "package.id",
                //     width: 70,
                //     sortable: false,
                // },
                {
                    Header: translate('itemName'),
                    accessor: 'title',
                    sortable: false,
                    width: 120,
                },
                {
                    Header: translate('price'),
                    accessor: 'price',
                    sortable: false,
                    width: 120,
                },

                {
                  Header: activeTabe === 1 && translate('itemMetrics'),
                  id: 'itemMetrics',
                  sortable: false,
                  width: activeTabe === 1 ? 180 : 0,
                  accessor: (item) => {
                    /* item.provider.id - 1 aris 'extra.ge' */
                    return <> { item.provider.id !== 1 && activeTabe === 1 && 
                        <div className="mu_select item">
                          <p className="col_text_note">{item.metric && item.metric.name}</p>
                          <p className="col_text_time">
                              <span className="col_text_role">{item.metric && item.metric.price}</span>
                          </p>
                        </div>
                      }</>
                  }
                },
                {
                    Header: translate('quantity'),
                    accessor: "quantity",
                    sortable: false,
                    width: 120,
                },
                {
                    Header: translate('orderId'),
                    accessor: 'orderId',
                    sortable: false,
                    width: 80,
                },
                // {
                //     Header: translate('status'),
                //     accessor: 'status',
                //     sortable: false,
                //     width: 150,
                // },
                {
                    Header: translate('comment'),
                    accessor: 'comment.note',
                    sortable: false,
                    width: 150,
                },
                // {
                //     Header: translate('actions'),
                //     accessor: "actions",
                //     width: 80,
                //     sortable: false,
                // }
            ]}
            showPagination={false}
            className="-striped -highlight"
        />
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  providers: providers.getList(state).data,
  districts: districts.getList(state).data,
  branches: branches.getList(state).data,
  couriers: couriers.getList(state).data,
  couriersLoading: couriers.isListRequestPending(state),
  providersLoading: providers.isListRequestPending(state),
  branchesLoading: branches.isListRequestPending(state)
})

export default connect(
  mapStateToProps,
  {
    fetchBranches: branches.fetchList,
    fetchProviders: providers.fetchList,
    fetchDistricts: districts.fetchList,

    changeFormValue: providerPackageForm.edit.changeValue,
  }
)(withForm(providerPackageForm.edit)(DispatcherCreatePackage))
