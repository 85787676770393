import { get } from "redux-net";
import { AUTHORIZATION_RECEIVE_TOKEN } from "../authorization";
import {
  SORTS_DASHBOARD_ERROR,
  SORTS_DASHBOARD_REQUEST,
  SORTS_DASHBOARD_RESPONSE,
} from "./actionTypes";

let status_params = ["missing", "status[]=pending"];
var arrStr = encodeURIComponent(JSON.stringify(status_params));

export const getLineItems = (withStatus = false) => (params = {}) =>
  get("v3/line-items", {
    begin: SORTS_DASHBOARD_REQUEST,
    success: SORTS_DASHBOARD_RESPONSE,
    error: SORTS_DASHBOARD_ERROR,
    query: {
      ...params,
      status: withStatus ? params.status : ["missing", "pending",'courier_picked', 'courier_missed', 'manager_confirmed', 'manager_missed'].join(","),
      order: 'asc'
    },
    headers: {
      "X-Api-Key": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsI",
    },
  });
