
import {createForm} from "ductus"

import { validateExistingEmail, validatePassword} from './validators'

export default createForm({
  container: 'forms',
  name: 'passwordChangeForm',
  // errorActionTypes: [AUTHORIZATION_RECEIVE_ERROR],
  // transformError: transformErrorToFieldsErrors,
  fields: {
    oldPassword: {},
    password: {
      validate: validatePassword
    },
    passwordConfirm: {
      validate: validatePassword
    },
  },
})
