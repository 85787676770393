import React from 'react'
import ReactTable from "react-table"

import AccessTime from '@material-ui/icons/AccessTime'
import AttachMoney from '@material-ui/icons/AttachMoney'
import CreditCard from '@material-ui/icons/CreditCard'

import moment from "moment"
import {Tooltip} from "@material-ui/core"

const currentColumns = (translate) => [
  {
    Header: <AccessTime/>,
    accessor: "updatedAt",
    Cell: row => (
      <div>
        {moment(row.value).format('DD MMM')} <br/>
        {moment(row.value).format('hh:mm')}
      </div>),
    width: 80,
    order: 1,
  },
  {
    Header: translate('provider'),
    Cell: row => (
      <Tooltip title={`${row.value.phoneNumber}`}>
        <div>
          {row.value.name} <br/>
          {row.value.address}
        </div>
      </Tooltip>

    ),
    accessor: 'provider',
    width: 175,
    order: 2,
  },
  {
    Header: translate('receiver'),
    accessor: 'recipient',
    sortable: false,
    Cell: row => (
      <Tooltip title={`${row.value && row.value.name} - ${row.value && row.value.phone}`}>
        <div>
          {row.value && row.value.address} <br/>
        </div>
      </Tooltip>
    ),
    order: 3,
    minWidth: 170

  },
  {
    Header: translate('status'),
    accessor: 'typeAndPrice',
    maxWidth: 120,
    Cell: row => (
      <div
      >
        <span> {row.value && row.value.status} </span> <br/>
        {row.value && row.value.type === 'CARD' ? <CreditCard style={{width: 16, height: 16}}/> :
          <AttachMoney style={{width: 16, height: 16}}/>}
        <span> {row.value && row.value.price}/{row.value && row.value.deliveryFee}  </span>
      </div>
    ),
    order: 4,

  },
  {
    Header: translate('courier'),
    accessor: "courier",
    Cell: row => (
      <div>
        {row.value && row.value.name}
      </div>
    ),
    width: 200,
    order: 5,

  },
  {
    Header: translate('user'),
    accessor: 'user'
  },
]

class History extends React.Component {

  state = {
    columns: {
      checkDelete: 1,
      receiveTime: 1,
      provider: 1,
      recipient: 1,
      typeAndPrice: 1,
      courier: 1,
      user: 1,
      updatedAt: 1,
    }
  }

  componentDidMount() {
    const {getPackageHistory, packageId} = this.props

    getPackageHistory({params: {path: `packages/${packageId}/history`}})


  }

  componentWillReceiveProps({packageId}) {

    if (packageId !== this.props.packageId) {
      this.props.getPackageHistory({params: {path: `packages/${packageId}/history`}})
    }
  }


  render() {
    const {translate, packageHistoryLoading, packageHistory } = this.props
    return (
      <ReactTable
          style={{ with: '100%'}}
        resizable={false}
        sortable={false}
        showPagination={false}
        data={packageHistory}
        loading={packageHistoryLoading}
        pageSize={packageHistory.length || 4}
        columns={
          currentColumns(translate)
        }

        showPageSizeOptions={false}
        className="-striped -highlight modal"
      />

    )
  }
}

export default History
