import React from 'react'
import {Field, withForm} from "ductus"

import {Face} from '@material-ui/icons'

import {
  GridContainer,
  GridItem,
  TextField, ComboField, CheckboxField
} from '../../components'

import {dispatcherForm} from "../../forms"


const ManagerForm = ({hubs, translate, permissions}) => (
  <GridContainer justify="center">
    <GridItem xs={12}>
      <Field
        name={'name'}
        label={translate('name')}
        icon={Face}
        component={TextField}
      />

      <Field
        name={'email'}
        label={translate('email')}
        icon={Face}
        component={TextField}
      />

      <Field
        name={'address'}
        label={translate('address')}
        icon={Face}
        component={TextField}
      />

      <Field
        name={'identityNumber'}
        label={translate('identityNumber')}
        icon={Face}
        component={TextField}
      />

      <Field
        name={'phoneNumber'}
        label={translate('phoneNumber', {})}
        icon={Face}
        component={TextField}
      />

      <Field
        name={'altPhoneNumber'}
        label={translate('phoneNumber', {number: 2})}
        icon={Face}
        component={TextField}
      />

      <Field
        name={'hubs'}
        label={translate('hubs')}
        searchText={translate('search')}
        icon={Face}
        component={ComboField}
        multiple={true}
        items={hubs}
      />

      {
        permissions.locations_give_permission &&
        <Field
          name={'locations_panel'}
          label={translate('locations_panel')}
          component={CheckboxField}
        />
      }

      <Field
        name={'couriers_panel'}
        label={translate('couriers_panel')}
        component={CheckboxField}
      />

      <Field
        name={'couriers_read'}
        label={translate('couriers_read')}
        component={CheckboxField}
      />

      <Field
        name={'couriers_create'}
        label={translate('couriers_create')}
        component={CheckboxField}
      />

      <Field
        name={'couriers_update'}
        label={translate('couriers_update')}
        component={CheckboxField}
      />

      <Field
          name={'packages_upload'}
          label={translate('packages_upload')}
          component={CheckboxField}
      />

      <Field
          name={'packages_courier_bulk_assign'}
          label={translate('packages_courier_bulk_assign')}
          component={CheckboxField}
      />

      <Field
        name={'isActive'}
        label={translate('active')}
        component={CheckboxField}
      />

      <Field
        name={'password'}
        type={'password'}
        label={translate('password')}
        icon={Face}
        component={TextField}
      />

      <Field
        name={'passwordConfirm'}
        type={'password'}
        label={translate('passwordConfirm')}
        icon={Face}
        component={TextField}
      />

    </GridItem>
  </GridContainer>
)


export default withForm(dispatcherForm.edit)(ManagerForm)
