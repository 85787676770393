import {recipientsFilterForm} from "../../../forms";
import connect from "react-redux/es/connect/connect";
import {recipientServices, providers, hubs} from "../../../ducks";

import RecipientsFilterForm from './RecipientsFilterForm'

const mapStateToProps = state => ({
  formValues: recipientsFilterForm.getValues(state),
  providers: providers.getList(state).data,
  hubs: hubs.getList(state).data,
  token: state.authorization.auth.token
})
const mapDispatchToProps = {
  changeValues: recipientsFilterForm.changeValues,
  filterItems: recipientServices.list.fetch,
  fetchProviders: providers.fetchList,
  fetchHubs: hubs.fetchList,

}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientsFilterForm)