import React from "react";
import {withStyles} from "@material-ui/core";
import {
  GridItem,
  GridContainer,
  Card,
  CardHeader,
  CardFooter,
  Button
} from '../../components/index'
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import LoginForm from './LoginForm'

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({cardAnimaton: ""});
      }.bind(this),
      700
    );
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // const { loading, getWhereToRedirect } = this.props
    // if(loading && !nextProps.loading){
    //   this.props.push( getWhereToRedirect ? getWhereToRedirect :'/dashboard')
    // }
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.props.authorize(this.props.formValues)
  }

  componentWillUnmount(){
    this.props.resetValues()
  }

  render() {
    const {classes, translate} = this.props
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form onSubmit={this.onSubmit}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <h4 className={classes.cardTitle}> {translate('logIn')} </h4>
                  </CardHeader>

                  <LoginForm translate={translate} />

                  <CardFooter className={classes.justifyContentCenter}>
                    <Button color="primary" size="lg" type={'submit'}>
                      {translate('logIn')}
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default  withStyles(loginPageStyle)(LoginPage)
