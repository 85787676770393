import {trim, trimEnd} from 'redux-net'
import {createCrudModule} from "crud-factory"

const createModules = ({statePathBase = '', resourceBase = '', createResource, generic, modules}) => {
  const spb = statePathBase ? trim(statePathBase, '.') + '.' : ''
  const rb = trimEnd(resourceBase, '/')

  return Array.isArray(modules)
    ? modules.reduce((reduction, module) => ({
      ...reduction,
      [module]: createCrudModule({
        ...generic,
        name: module.toUpperCase(),
        statePath: spb + module,
        resource: rb + '/' + module,
      }),
    }), {})
    : Object.keys(modules).reduce((reduction, module) => ({
      ...reduction,
      [module]: createCrudModule({
        ...generic,
        name: module.toUpperCase(),
        statePath: spb + module,
        resource: rb + '/' + module,
        ...modules[module],
      }),
    }), {})
}

export const createModulesWithResourceFunction = ({statePathBase = '', resourceBase = '', generic, modules, createResource}) => {
  const spb = statePathBase ? trim(statePathBase, '.') + '.' : ''
  const rb = trimEnd(resourceBase, '/')

  return Array.isArray(modules)
    ? modules.reduce((reduction, module) => ({
      ...reduction,
      [module]: createCrudModule({
        ...generic,
        name: module.toUpperCase(),
        statePath: spb + module,
        resource: rb + '/' + module,
        createResource: (params) => createResource(params),
      }),
    }), {})
    : Object.keys(modules).reduce((reduction, module) => ({
      ...reduction,
      [module]: createCrudModule({
        ...generic,
        name: module.toUpperCase(),
        statePath: spb + module,
        resource: rb + '/' + module,
        createResource: (params) => createResource(params),
        ...modules[module],
      }),
    }), {})
}

export const createSingle = ({statePathBase = '', resourceBase = '', generic, module, createResource}) => {
  const spb = statePathBase ? trim(statePathBase, '.') + '.' : ''
  const rb = trimEnd(resourceBase, '/')

  return createCrudModule({
    ...generic,
    name: module.toUpperCase(),
    statePath: spb + module,
    resource: rb + '/' + module,
    createResource: createResource
  })
}

export default createModules
