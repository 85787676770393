import React from 'react'
import {Field, withForm} from "ductus"

// import Face from '@material-ui/icons/Face'
import { 
  Face, 
  MailOutline, 
  PermIdentity, 
  Phone, 
  Lock, 
  Message 
} from '@material-ui/icons';

import Rowing from '@material-ui/icons/Rowing'
import Flight from '@material-ui/icons/Flight'
import DirectionsBoat from '@material-ui/icons/DirectionsBoat'
import DirectionsRailway from '@material-ui/icons/DirectionsRailway'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import DirectionsBike from '@material-ui/icons/DirectionsBike'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Motorcycle from '@material-ui/icons/Motorcycle'

import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'

import {courierForm} from "../../forms"

import TextField from "../../components/fields/TextField"
import ComboField from "../../components/fields/ComboField"
import RadioGroupField from "../../components/fields/RadioGroupField"
import CheckboxField from '../../components/fields/CheckboxField'


const EditCourierForm = ({hubs, translate, handleSubmit, values }) => (
  <GridContainer justify="center">
    <form onSubmit={(e) => {e.preventDefault(); handleSubmit(values)}} className={'good-form'}>

      <GridItem xs={12}>
        <Field
          name={'name'}
          label={translate('name')}
          icon={Face}
          component={TextField}
        />

        <Field
          name={'email'}
          label={translate('email')}
          icon={MailOutline}
          component={TextField}
        />

        <Field
          name={'identityNumber'}
          label={translate('identityNumber')}
          icon={PermIdentity}
          component={TextField}
        />

        <Field
          name={'phoneNumber'}
          label={translate('phoneNumber', {})}
          icon={Phone}
          component={TextField}
        />

        <Field
          name={'altPhoneNumber'}
          label={translate('phoneNumber', {number: 2})}
          icon={Phone}
          component={TextField}
        />

        <Field
          name={'hubs'}
          label={translate('hubs')}
          searchText={translate('search')}
          icon={Face}
          component={ComboField}
          multiple={true}
          items={hubs}
        />

        <Field
          name={'transportationType'}
          label={translate('transportationType')}
          component={RadioGroupField}
          items={[
            { id: 'PEDESTRIAN', name: <DirectionsRun style={{ color: '#333333'}} />},
            { id: 'BIKE', name: <DirectionsBike style={{ color: '#333333'}} />},
            { id: 'MOTORCYCLE', name: <Motorcycle style={{ color: '#333333'}}  />},
            { id: 'CAR', name: <DirectionsCar style={{ color: '#333333'}} />},
            { id: 'OTHER',
              name:
                <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                  <DirectionsRailway style={{ color: '#333333'}} />
                  <Rowing style={{ color: '#333333'}} />
                  <DirectionsBoat style={{ color: '#333333'}} />
                  <Flight style={{ color: '#333333'}} />
                  ...
                </div>
            },
          ]}
        />

        <Field
          name={'isActive'}
          label={translate('active')}
          icon={Face}
          component={CheckboxField}
        />

        <Field
          name={'isWorking'}
          label={translate('isWorking')}
          icon={Face}
          component={CheckboxField}
        />

        <Field
          name={'password'}
          type={'password'}
          label={translate('password')}
          icon={Lock}
          component={TextField}
        />

        <Field
          name={'passwordConfirm'}
          type={'password'}
          label={translate('passwordConfirm')}
          icon={Lock}
          component={TextField}
        />

        <Field
          name={'comment'}
          label={translate('comment')}
          icon={Message}
          component={TextField}
          inputProps={commentInputProps}
        />
      </GridItem>
      <button type={'submit'} style={{display: 'none'}}>b</button>
    </form>
  </GridContainer>
)

const commentInputProps = {
  multiline: true,
  rows: 3
}

export default withForm(courierForm.edit)(EditCourierForm)
