import React from 'react'
import {Button,} from "../../../components/index"

import qs from 'query-string'
import Fields from './Fields'
import {ip} from "../../../defaultConfigs";
import {bulkPackagesForm, dispatcherPackageForm, providerPackageForm} from "../../../forms";
import $ from 'jquery'
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import {ExpansionPanelActions, ExpansionPanelSummary} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FileDownload from "@material-ui/icons/FileDownload";
import Search from "@material-ui/icons/Search";
import YoutubeSearchedFor from "@material-ui/icons/YoutubeSearchedFor";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import accordionStyle from "../../../assets/jss/material-dashboard-pro-react/components/accordionStyle";

const response = {
    "status": 200,
    "success": true,
    "data": {
        "success": false,
        "fieldMap": {
            "recipient_name": "მიმღები",
            "recipient_address": "მისამართი",
            "recipient_phone_number": "მობ.ნომერი",
            "comment": "კომენტარი",
            "payment_type": "ონლაინ გადახდა",
            "package_price": "ფასი"
        },
        "errors": [
            {
                "sheet": "Sheet1",
                "errors": [
                    {
                        "index": 2,
                        "row": {
                            "provider_id": null,
                            "branch_id": "1",
                            "courier_id": null,
                            "recipient_name": "ერეკლე",
                            "recipient_address": "ჯიმშერიანის 9",
                            "recipient_phone_number": "123132",
                            "comment": "ასევე კომენტარი",
                            "payment_type": 0,
                            "package_price": 1.1
                        },
                        "errors": {
                            "recipient_phone_number": [
                                "The recipient phone must be 9 characters.",
                                'egegege'
                            ]
                        }
                    },
                    {
                        "index": 3,
                        "row": {
                            "provider_id": null,
                            "branch_id": "1",
                            "courier_id": null,
                            "recipient_name": "ერეკლე",
                            "recipient_address": "ჯიმშერიანის 9",
                            "recipient_phone_number": "123132",
                            "comment": "ასევე კომენტარი",
                            "payment_type": 0,
                            "package_price": 1.1
                        },
                        "errors": {
                            "recipient_phone_number": [
                                "The recipient phone must be 9 characters.",
                                'egegege'
                            ]
                        }
                    },
                ]
            },
            {
                "sheet": "Sheet2",
                "errors": [
                    {
                        "index": 2,
                        "row": {
                            "provider_id": null,
                            "branch_id": "1",
                            "courier_id": null,
                            "recipient_name": "ერეკლე",
                            "recipient_address": "ჯიმშერიანის 9",
                            "recipient_phone_number": "123132",
                            "comment": "ასევე კომენტარი",
                            "payment_type": 0,
                            "package_price": 1.1
                        },
                        "errors": {
                            "recipient_phone_number": [
                                "The recipient phone must be 9 characters."
                            ]
                        }
                    }
                ]
            }
        ]
    }
}

const style = (theme) => ({
    ...accordionStyle(theme),
    accordionContent: {
        alignItems: 'center'
    },
})

class PackagesFilterForm extends React.Component {

    state = {
        branches: [],
        branchesDisabled: true,
        value: 20
    }

    componentWillUnmount() {
        this.props.resetForm()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.providers && this.props.providers) {
            this.providerSelectionChange(this.props.userInfo.providerId);
        }
    }

    componentWillMount() {
        const {formValues, history, fetchProviders, fetchCouriers, changeFormValues} = this.props
        const query = {...formValues, ...qs.parse(history.location.search)}

        changeFormValues(query)
        fetchProviders({query: {'without-pagination': 1}});
        fetchCouriers({query: {'without-pagination': 1}})
    }

    providerSelectionChange = (val) => {
        const provider = this.props.providers.find(p => p.id === val)
        if (provider) {
            const {branches} = provider
            // branches.length && this.props.changeFormValue('branchId', branches[0].id)
            this.setState({
                branches,
                branchDisabled: false
            })
        }
    }

    onFilterClick = () => {
        const {filterItems, formValues, history} = this.props
        filterItems({query: formValues, params: {path: '/packages'}})
        history.push({search: qs.stringify(formValues)})
    }

    onClear = () => {
        const {filterItems, resetForm, history} = this.props
        resetForm()
        history.push({search: ''})
        filterItems({query: {}, params: {path: '/packages'}})
    }

    formSubmitted = (e) => {
        e.preventDefault();
        this.onFilterClick()
    }

    openFormModal = ({title = '', duck, submitText = '', form, Component, componentProps, noSubmit, submit, propName, additionalButtons = [], small = true}) => this.props.openModal({
        title,
        Component,
        componentProps,
        noSubmit,
        submitText,
        duck,
        submit,
        small,
        form,
        loading: false,
        modalLoading: false
    })

    render() {
        const {userInfo, providers, couriers, translate, formValues, exp, token, classes} = this.props
        const {hubs, role, permissions} = userInfo
        const {branches} = this.state

        return (
            <ExpansionPanel
                expanded={this.state.expanded}
                onChange={() => this.setState({
                    expanded: !this.state.expanded
                })}
                classes={{
                    root: classes.expansionPanel + ' sw-selector',
                    expanded: classes.expansionPanelExpanded
                }} 

            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore/>}
                    classes={{
                        root: classes.expansionPanelSummary,
                        expanded: classes.expansionPanelSummaryExpaned,
                        content: classes.expansionPanelSummaryContent + ' ' + classes.accordionContent,
                        expandIcon: classes.expansionPanelSummaryExpandIcon
                    }}
                    style={{
                    }}
                >
                    {translate('filter')}

                    {
                        exp ?
                            <Button
                                color={'primary'}
                                simple
                                justIcon
                                round
                                style={{
                                    position: 'absolute',
                                    right: 45,
                                    padding: 0,
                                    margin: 0
                                }}

                                href={`http://${ip}/download/packages/export?${qs.stringify(formValues)}&token=${token}`}
                            >
                                <FileDownload/>
                            </Button>
                            : ''
                    } 
                </ExpansionPanelSummary>

                <ExpansionPanelDetails
                    className={classes.expansionPanelDetails}
                >
                    <form className={'filter-flex-container'} onSubmit={this.formSubmitted}>
                        <Fields
                            translate={translate}
                            role={role}
                            hubs={hubs}
                            branches={branches}
                            providers={providers}
                            couriers={couriers}
                            providerSelectionChange={this.providerSelectionChange}
                        />
                    </form>
                </ExpansionPanelDetails>
                <ExpansionPanelActions
                    style={{
                        paddingTop: 0
                    }}
                >
                    {/* {
                        permissions['packages_upload'] &&
                        <Button
                            color={'rose'}
                            disabled={!providers}
                            onClick={() => {
                                if (providers) {
                                    this.openFormModal({
                                        title: translate('uploadExcel'),
                                        submitText: 'add',
                                        submit: this.handleSubmit,
                                        // duck: 'packages',
                                        form: bulkPackagesForm,
                                        Component: 'BulkPackages',
                                        componentProps: {
                                            translate,
                                            providers,
                                            couriers,
                                            branches,
                                            isProvider: role === 'provider'
                                        }
                                    })
                                }

                            }
                            }
                        >
                            {translate('bulkUpload')}
                        </Button>
                    } */}
                    <Button
                        justIcon
                        title={translate('clear')}
                        color={'rose'}
                        onClick={this.onClear}
                    >
                        <i className="fa fa-eraser" aria-hidden="true"></i>
                        {/*<YoutubeSearchedFor/>*/}
                    </Button>
                    <Button
                        type="submit"
                        justIcon
                        title={translate('search')}
                        color={'primary'}
                        onClick={this.onFilterClick}
                    >
                        <Search/>
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        )
    }

    handleSubmit = values => {
        const {openSnackBar, closeSnackBar, translate} = this.props
        if (values.branchId && values.file) {
            let formData = new FormData()
            formData.append('file', values.file)
            formData.append('providerId', values.providerId)
            formData.append('branchId', values.branchId)
            if (values.courierId) {
                formData.append('courierId', values.courierId)

            }
            formData.append('token', this.props.token)

            const openFormModal = this.openFormModal
            const {uploadRes, closeModal, providers, couriers, userInfo, startLoading, endLoading, fetchPackages} = this.props
            const {branches} = this.state
            const handleSubmit = this.handleSubmit;

            startLoading();

            $.ajax({
                url: `http://${ip}/api/upload/packages`,
                data: formData,
                type: 'POST',
                dataType: "JSON",
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('accept', 'application/json');
                    xhr.setRequestHeader('authorization', 'Bearer ' + formData.get('token'));
                },
                success: function ({data}) {
                    endLoading();
                    if (data.success) {
                        closeModal()
                        openSnackBar(translate(data.message))
                        setTimeout(() => {
                            closeSnackBar()
                        }, 3000)
                    } else {
                        openSnackBar(translate('uploadError'), 'danger')
                        setTimeout(() => {
                            closeSnackBar()
                        }, 3000)

                        openFormModal({
                            title: translate('uploadErrors'),
                            submitText: 'backToUpload',
                            small: false,
                            submit: () => {
                                openFormModal({
                                    submitText: 'add',
                                    submit: handleSubmit,
                                    form: bulkPackagesForm,
                                    Component: 'BulkPackages',
                                    componentProps: {
                                        translate,
                                        providers,
                                        couriers,
                                        branches,
                                        isProvider: userInfo.role === 'provider'
                                    }
                                })
                            },
                            Component: 'ExcelErrors',
                            componentProps: {
                                data
                            }
                        })
                    }
                    uploadRes(data)
                }
            });
        } else {
            openSnackBar(translate('branchIdAndFileIsRequired'), 'danger')
            setTimeout(() => {
                closeSnackBar()
            }, 3000)
        }
    }
}


export default withStyles(style)(PackagesFilterForm)
