import LoginPage from "../views/Login";
// import RegisterPage from "../views/DashboardViews/Pages/RegisterPage.jsx";

// import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
const pagesRoutes = [
  // {
  //   path: "/register",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   icon: PersonAdd,
  //   component: RegisterPage
  // },
  {
    path: "/login",
    name: "Login Page",
    short: "Login",
    mini: "L",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/login'
  }
];

export default pagesRoutes;
