import React from 'react'

import {
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'

import {Search} from '@material-ui/icons'

import CustomInput from '../CustomInput/CustomInput'
import {hasMatch} from '../../helpers'
// import extendedFormStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle'
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";


class ComboBox extends React.Component {

  state = {
    selected: 0,
    receivedItems: [],
    items: [],
    searchText: '',
    open: false
  }

  componentDidMount() {
    this.setState({
      items: this.props.items,
      receivedItems: this.props.items
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      items: nextProps.items,
      receivedItems: nextProps.items
    })
  }

  filterList = (e) => {

    const receivedItems = this.state.receivedItems

    const searchText = e.target.value

    const final =
      e.target.value !== ''
        ? receivedItems
          .map((item) =>
            hasMatch(typeof item.name === 'string' ? item.name : item.n, searchText)
              ? item
              : {...item, hidden: true}
          )
        : receivedItems


    this.setState({items: final, searchText})

  }

  handleChange = (e) => {
    const { onChange, selectionChange, multiple } = this.props

    // const value = multiple ? (e.target.value !== this.state.selected) ? e.target.value : '' : e.target.value
    const value = (e.target.value !== this.state.selected) ? e.target.value : ''


    onChange(value)
    selectionChange && selectionChange(value)
    this.setState({
      selected: value,
      searchText: '',
      items: this.state.receivedItems,
      open: multiple || !value,
    })
  }

  onOpen = () => this.setState({open: true, items: this.state.receivedItems})

  onClose = () => this.setState({open: false, searchText: ''})

  render() {

    const {classes, label, multiple, searchText, value, name, disabled} = this.props
    const {open} = this.state
    const changedValue = multiple ? value.map(val => (typeof val === 'object') ? val.id : val) : value


    return (
      <FormControl
        fullWidth
        className={classes.selectFormControl}
        disabled={disabled}
      >
        {
          label &&
          <InputLabel

            htmlFor="multiple-select"
            className={classes.selectLabel}
          >
            {label}
          </InputLabel>
        }
        <Select
          multiple={multiple}
          value={changedValue}
          onChange={this.handleChange}
          MenuProps={{className: classes.selectMenu}}
          classes={{select: classes.select}}
          disabled={disabled}
          inputProps={{
            name
          }}

          open={open}

          onOpen={this.onOpen}

          onClose={this.onClose}
        >
          <CustomInput
            formControlProps={{
              fullWidth: true,
              style: {
                padding: '8px 15px 5px',
                position: 'fixed',
                top: 0,
                backgroundColor: 'white',
                zIndex: 100
              }
            }}

            inputProps={{
              value: this.state.searchText,
              placeholder: searchText,
              onChange: this.filterList,

              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Search className={classes.inputAdornmentIcon}/>
                </InputAdornment>
              ),
              autoFocus: true,
              style: {paddingLeft: 8, fontSize: '15px'}
            }}

          />

          {
            this.state.items.map((item, key) =>
              <MenuItem
                key={key}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={item.id}

                style={
                  item.hidden
                    ? {height: 0, padding: 0}
                    : {}
                }
              >
                {item.name}
              </MenuItem>
            )
          }
        </Select>
      </FormControl>

    )
  }
}

export default withStyles(customSelectStyle)(ComboBox)