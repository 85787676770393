import React from "react";

import Map from './GoogleFull'

const FullScreenMap = ({ children, ...props}) => (
  <Map
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCT28dtW22CdgMeuNaH46UKVfu_QLKEM7Y"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `${window.innerHeight - 64}px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    {...props}
  >
    {children}
  </Map>
)

export default FullScreenMap;
