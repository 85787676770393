import {packagesFilterForm} from "../../../forms";
import {couriers, packages, packageServices, providers} from "../../../ducks";
import modal from '../../../ducks/modal'
import connect from "react-redux/es/connect/connect";

import PackagesFilterForm from './PackagesFilterForm'

const mapStateToProps = state => ({
  formValues: packagesFilterForm.getValues(state),
  providers: providers.getList(state).data,
  couriers: couriers.getList(state).data,
  token: state.authorization.auth.token,
  uploadLoading: packageServices.upload.getRequestPending(state),
  uploadError: packageServices.upload.getRequestError(state),
  uploadResponse: packageServices.upload.getData(state),
});

const mapDispatchToProps = {
  filterItems: packages.fetchList,
  changeFormValues: packagesFilterForm.changeValues,
  resetForm: packagesFilterForm.resetValues,
  fetchProviders: providers.fetchList,
  fetchCouriers: couriers.fetchList,
  changeFormValue: packagesFilterForm.changeValue,
  openModal: modal.openModal,
  closeModal: modal.closeModal,
  upload: packageServices.upload.run,
  uploadRequest: () => ({ type: "upload/REQUEST", }),
  uploadRes: (res) => ({ type: "upload/SUCCESS", res }),
  fetchPackages: packages.fetchList,
  startLoading: () => ({ type: 'LOADING.START'}),
  endLoading: () => ({ type: 'LOADING.END'}),
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesFilterForm)
