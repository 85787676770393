import {managerForm, managersFilterForm} from "../../forms";
import {managers} from "../../ducks";
import connect from "react-redux/es/connect/connect";

import ManagersList from './ManagersList'
import modal from "../../ducks/modal"

const mapStateToProps = (state) => ({
  data: managers.getList(state) && managers.getList(state).data,
  singleData: managers.getSingle(state),
  pagination: managers.getList(state).pagination,
  loading: managers.isListRequestPending(state),
  singleLoading: managers.isSingleRequestPending(state),
  storeLoading: managers.isStoreRequestPending(state),
  updateLoading: managers.isUpdateRequestPending(state),
  removeLoading: managers.isRemoveRequestPending(state),
  listErrors: managers.getListRequestError(state),
  singleErrors: managers.getSingleRequestError(state),
  storeErrors: managers.getStoreRequestError(state),
  updateErrors: managers.getUpdateRequestError(state),
  removeErrors: managers.getRemoveRequestError(state),
  filterFormValues: managersFilterForm.getValues(state),

})
const mapDispatchToProps = {
  fetchList: managers.fetchList,
  fetchSinge: managers.fetchSingle,
  storeItem: managers.store,
  updateItem: managers.update,
  removeItem: managers.remove,
  changeEditFormValues: managerForm.edit.changeValues,
  resetCreationFormValues: managerForm.create.resetValues,
  resetEditionFormValues: managerForm.edit.resetValues,

  openModal: modal.openModal,
  closeModal: modal.closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagersList)
