
import { createServiceGroup} from "../functions/createGroup";
import {createResource} from "../../helpers";

export default createServiceGroup({
  name: 'recipientServices',
  items: {
    list: {
      requestName: 'fetch',
      name: 'fetchRecipients',
      resource: '/recipients',
      initialData: { data: [] },
      // createResource,
      transformData: (response) => ({
        data: response.body.data,
        pagination: response.body.pagination
      })
    },
  },
  generic: {
    // type: 'post'
  }
})
