import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"


import CheckboxField from "../../../../components/fields/CheckboxField"

import {Field} from 'ductus'

import GridItem from '../../../../components/Grid/GridItem'
import GridContainer from '../../../../components/Grid/GridContainer'

class Rights extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false
    }
  }

  sendState() {
    return this.state
  }

  // handleSimple = event => {
  //   this.setState({ [event.target.name]: event.target.value });
  // };
  //
  isValidated = () => true

  render() {

    const { getTranslator} = this.props

    return (
      <GridContainer justify="center">

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <legend> {getTranslator('rights')}</legend>
          {/* <Field
            name={'finances_read_packages_price'}
            label={getTranslator('financeCirculation')}
            component={CheckboxField}
          />

          <Field
            name={'packages_preoder'}
            label={getTranslator('preOrder')}
            component={CheckboxField}
          /> */}
          
          <Field
            name={'sorting_history_panel'}
            label={getTranslator('sortHistory')}
            component={CheckboxField}
          />

          <Field
            name={'packages_show_courier'}
            label={getTranslator('viewCourier')}
            component={CheckboxField}
          />

          <Field
            name={'courier_arrival_times'}
            label={getTranslator('viewCourierTime')}
            component={CheckboxField}
          />

          {/* <Field
            name={'packages_select_pickup_district'}
            label={getTranslator('choosePickUpDistrict')}
            component={CheckboxField}
          />

          <Field
            name={'packages_select_delivery_district'}
            label={getTranslator('chooseDeliveryDistrict')}
            component={CheckboxField}
          /> */}

          <Field
            name={'branches_own_panel'}
            label={getTranslator('branches_own_panel')}
            component={CheckboxField}
          />

          <Field
            name={'courier_accept_notification'}
            label={getTranslator('courier_accept_notification')}
            component={CheckboxField}
          />

          <Field
              name={'packages_upload'}
              label={getTranslator('packages_upload')}
              component={CheckboxField}
          />

          <Field
              name={'packages_export'}
              label={getTranslator('packages_export')}
              component={CheckboxField}
          />

          {/* <Field
              name={'locations_panel'}
              label={getTranslator('locations_panel')}
              component={CheckboxField}
          /> */}

        </GridItem>

      </GridContainer>
    )
  }
}

export default withStyles({})(Rights)
