import React from 'react'
import ReactTable from 'react-table'
// import {packagePermissions} from "../../permissions"
// import {Button} from '../../components/index'
import {Dvr, Close, CreditCard, AttachMoney} from '@material-ui/icons'
import moment from "moment/moment"
import {connect} from 'react-redux'
import {dashboard, packages} from '../../ducks/index'
import Button from '../../components/CustomButtons/Button'
import modal from '../../ducks/modal'
import {dispatcherPackageForm, managerForm, packageForm, providerPackageForm} from '../../forms'
import {managerPermissions} from '../../permissions'

class PreOrders extends React.Component {


    componentWillMount() {
        this.props.fetchPreOrder({
            params: {
                path: `/packages/pre-order`
            },
            query: {'with-pagination': 1}
        })
    }

    pageChanged(pageIndex){
        this.props.fetchPreOrder({ params: {path: `/packages/pre-order`}, query: { 'with-pagination': 1, page: pageIndex + 1} })

        this.props.history.push({search: window.location.search.replace(/&preOrderPage=[0-9+]|preOrderPage=[0-9+]/i, '') + '&preOrderPage=' + (pageIndex + 1)})

    }

    openFormModal = ({title = '', duck, submitText = '', form, Component, componentProps, noSubmit, submit, propName, additionalButtons = [], small = false}) => this.props.openModal({
        title,
        Component,
        componentProps,
        noSubmit,
        submitText,
        duck,
        submit,
        small,
        form,
        loading: false,
        modalLoading: false
    })

    render() {
        const {
            preOrder,
            preOrderLoading,
            translate,
            userInfo: {role, hubs, permissions},
            resetEditionFormValues, resetEditionFormValues1,
            fetchSingle,
            preOrderPaging,
        } = this.props
        return (
            <ReactTable
                data={preOrder && preOrder}
                loading={preOrderLoading}
                manual
                columns={[
                    {
                        Header: translate('appearanceTime'),
                        accessor: "receiveTime",
                        sortable: false,
                        Cell: row => (
                            <div
                            >
                                {moment(row.value.receivedAt).format('MMM DD hh:mm')} <br/>
                                {row.value.code}
                            </div>)
                    },
                    {
                        Header: translate('visibleAfter'),
                        accessor: "visibleAfter",
                        sortable: false,
                        Cell: row => (
                            <div
                            >
                                {moment(row.value).format('hh:mm DD MMM')}
                            </div>)
                    },
                    {
                        Header: translate('payment'),
                        accessor: 'typeAndPrice',
                        sortable: false,
                        Cell: row => (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                {row.value.type === 'CARD' ? <CreditCard style={{width: 16, height: 16}}/> :
                                    <AttachMoney style={{width: 16, height: 16}}/>}
                                <span> {row.value.price} ₾ </span>
                            </div>
                        )
                    },
                    {
                        Header: translate('provider'),
                        accessor: 'provider',
                        Cell: row => (
                            <div>
                                {row.value.name} <br/>
                                {row.value.phoneNumber} <br/>
                                {row.value.address}
                            </div>
                        )
                    },
                    {
                        Header: translate('receiver'),
                        accessor: 'recipient',
                        sortable: false,
                        Cell: row => (
                            <div>
                                {row.value.name} <br/>
                                {row.value.address} <br/>
                                {row.value.phone}
                            </div>
                        )
                    },
                    {
                        Header: translate('courier'),
                        accessor: "courier",
                        sortable: false,
                        Cell: row => (
                            <div>
                                {row.value && row.value.name}
                            </div>
                        )
                    },
                    {
                        Header: translate('actions'),
                        accessor: "actions",
                        sortable: false,
                        maxWidth: 100,
                        Cell: row => {

                            return (
                                <div className="actions-right">
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                            resetEditionFormValues()
                                            resetEditionFormValues1()
                                            fetchSingle(row.original.id)
                                            this.openFormModal({
                                                submitText: 'edit',

                                                noSubmit: role === 'provider',
                                                Component: role === 'provider' ? 'EditPackage' : 'DispatcherEditPackage',
                                                form: role === 'provider' ? providerPackageForm.edit : dispatcherPackageForm.edit,
                                                duck: 'packages',
                                                submit: this.handleUpdate(row.original.id),
                                                componentProps: {
                                                    hubs,
                                                    permissions
                                                },
                                            })
                                            // || fetchSinge(item.id)
                                        }}
                                        color="warning"
                                        className="edit"
                                    >
                                        <Dvr/>
                                    </Button>
                                </div>
                            )
                        }
                    }
                ]}
                showPagination={true}
                showPaginationBottom={true}
                showPaginationTop={false}
                showPageSizeOptions={false}
                pages={preOrderPaging && preOrderPaging.totalPages}
                page={preOrderPaging && (preOrderPaging.currentPage - 1)}
                onPageChange={this.pageChanged}
                defaultPageSize={(preOrderPaging && preOrder.length) || 5}
                pageSize={preOrderPaging && preOrder.length}

                previousText={translate('previousText')}
                nextText={translate('nextText')}
                loadingText={translate('loadingText')}
                pageText={translate('pageText')}
                ofText={translate('ofText')}
            />
        )
    }

    handleUpdate = (id) => (values) => this.props.updateItem(id, values, {params: {path: `/packages`}})
}

const mapStateToProps = (state) => {
    const preOrderData = dashboard.preOrder.getData(state)
    return {
        userInfo: state.userInfo,
        preOrder: preOrderData.data,
        preOrderPaging: preOrderData.pagination,
        preOrderLoading: dashboard.preOrder.getRequestPending(state),
    }
}

export default connect(
    mapStateToProps,
    {
        fetchPreOrder: dashboard.preOrder.run,
        openModal: modal.openModal,
        closeModal: modal.closeModal,
        fetchSingle: packages.fetchSingle,
        resetEditionFormValues: dispatcherPackageForm.edit.resetValues,
        resetEditionFormValues1: providerPackageForm.edit.resetValues,
        updateItem: packages.update
    }
)(PreOrders)
