import React from 'react'

import {Dvr, Close} from '@material-ui/icons'
import ReactTable from 'react-table'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'
import ConfirmSweetAlert from '../../components/SweetAlert/SweetAlert'
import SnackBar from '../../components/Snackbar/Snackbar'
import { AddButton } from '../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"
import { branchForm } from "../../forms"
import CreateBranchForm from './CreateBranchForm'
import EditBranchForm from './EditBranchForm'

import {branchPermissions} from '../../permissions'
import ListComponent from "../ListComponent"
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import BranchesProviderFilterForm from "./BranchesProviderFilterForm"

class ProviderBranchList extends ListComponent {

  makeActions = (item) => {
    const {removeItem, districts, translate,userInfo, resetEditionFormValues, fetchSinge, updateItem, permissions, match} = this.props
    const path = `providers/${userInfo.providerId}/branches`
    return (
      <div className="actions-right">
        {/* use this button to add a like kind of action */}
        {
          permissions[branchPermissions.update] &&
          <Button
            justIcon
            round
            simple
            onClick={() => {
              resetEditionFormValues()
              fetchSinge(item.id, {params: {path}})

              this.openFormModal({
                submitText: 'edit',
                form: branchForm.edit,
                duck: 'branches',
                submit: (formValues) => updateItem(item.id, {...item, ...formValues}, {params: {path}}),
                Component: 'EditBranchForm',
                componentProps: {
                  districts
                }
              })

            }
            }
            color="warning"
            className="edit"
          >
            <Dvr/>
          </Button>
        }
        {
          permissions[branchPermissions.delete] &&
          <Button
            justIcon
            round
            simple
            onClick={() => this.showAlert({
              title: translate('toDelete', {item: item.name}),
              confirmText: translate('delete'),
              cancelText: translate('close'),
              hideAlert: this.hideAlert,
              confirmFun: () => removeItem(item.id, {params: {path}}),
              warning: true
            })}
            color="danger"
            className="remove"
          >
            <Close/>
          </Button>
        }


      </div>
    )
  }

  componentWillMount(){
    this.setState({
      params: {
        path: `providers/${this.props.userInfo.providerId}/branches`
      }
    })
  }

  componentDidMount() {
    this.props.fetchList({
      params: {path: this.state.params.path},
      query: {isActive: 1}
    })
    this.props.fetchDistricts({query: {'without-pagination': 1}})
  }

  pageChanged = (pageIndex) => {
      this.props.fetchList({
          query: {page: pageIndex + 1},
          params: {path: this.state.params.path},
      })
      this.props.history.push({search: window.location.search.replace(/&page=[0-9+]/i, '') + '&page=' + (pageIndex + 1)})
  }

  submitAction = (values) => this.props.storeItem(values, {params: {path: `/providers/${this.props.userInfo.providerId}/branches`}})


  render() {
    const {data, loading, pagination, translate, districts, permissions, history, userInfo } = this.props
    const {modal, alert, snackBar} = this.state
    const { hubs } = userInfo
    


    return (
      <>
      <div onClick={() => window.history.back()} className="goBack_btn_link creat"> 
        <KeyboardArrowLeftIcon  className="goBack_btn"/> 
        <span>უკან დაბრუნება</span>
      </div>
      <br/>
      <br/>
      <br/>

      <GridItem xs={12} sm={12} md={12}>
        {/* <Card>
            <CardBody>
              <BranchesProviderFilterForm history={history} translate={translate} userHubs={hubs} />
            </CardBody>
        </Card> */}

        <AddButton
          hidden={!permissions[branchPermissions.create]}
          onClick={() => this.openFormModal({
            submitText: 'add',
            form: branchForm.create,
            submit: this.submitAction,
            Component: 'CreateBranchForm',
            componentProps: {
              districts
            }
          })}
        >
        </AddButton>


        <GridContainer>
          <Card>
            <CardBody>

                <ReactTable
                  data={data.map(this.dataObject)}
                  loading={loading}
                  page={this.state.pageIndex}
                  pages={pagination && pagination.totalPages}
                  manual
                  onPageChange={this.pageChanged}
                  defaultPageSize={(pagination && pagination.perPage)  || 5}
                  pageSize={(pagination && pagination.perPage) || 5}

                  onSortedChange={this.onSortedChange}

                  columns={[
                    {
                      Header: translate('name'),
                      accessor: "name"
                    },
                    {
                      Header: translate('actions'),
                      accessor: "actions"
                    }
                  ]}
                  previousText={translate('previousText')}
                  nextText={translate('nextText')}
                  loadingText={translate('loadingText')}
                  pageText={translate('pageText')}
                  ofText={translate('ofText')}
                  showPaginationBottom
                  showPageSizeOptions={false}
                  className="-striped -highlight"
                />

            </CardBody>
          </Card>
        </GridContainer>
        {alert.open && <ConfirmSweetAlert {...this.state.alert} />}
        <SnackBar
          place={'br'}
          color="info"
          icon={AddAlert}
          message={snackBar.message}
          open={snackBar.openPos}
          closeNot={this.closeSnackBar}
          close
        />
      </GridItem>
      </>
    )
  }
}

export default ProviderBranchList
