import {courierForm, couriersFilterForm} from "../../forms"
import {couriers, courierServices} from "../../ducks"
import connect from "react-redux/es/connect/connect"
import CouriersList from './CouriersList'
import modal from "../../ducks/modal"

const mapStateToProps = (state) => ({
  data: couriers.getList(state) && couriers.getList(state).data,
  singleData: couriers.getSingle(state),
  pagination: couriers.getList(state).pagination,
  loading: couriers.isListRequestPending(state),
  singleLoading: couriers.isSingleRequestPending(state),
  storeLoading: couriers.isStoreRequestPending(state),
  updateLoading: couriers.isUpdateRequestPending(state),
  removeLoading: couriers.isRemoveRequestPending(state),
  listErrors: couriers.getListRequestError(state),
  singleErrors: couriers.getSingleRequestError(state),
  storeErrors: couriers.getStoreRequestError(state),
  updateErrors: couriers.getUpdateRequestError(state),
  removeErrors: couriers.getRemoveRequestError(state),
  filterFormValues: couriersFilterForm.getValues(state),

})
const mapDispatchToProps = {
  fetchList: couriers.fetchList,
  fetchSinge: couriers.fetchSingle,
  storeItem: couriers.store,
  updateItem: couriers.update,
  removeItem: couriers.remove,
  changeEditFormValues: courierForm.edit.changeValues,
  resetCreationFormValues: courierForm.create.resetValues,
  resetEditionFormValues: courierForm.edit.resetValues,
  endWork: courierServices.endWork.run,

  openModal: modal.openModal,
  closeModal: modal.closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CouriersList)

