import React from 'react'

import Timer from '@material-ui/icons/Timer'
import CreditCard from '@material-ui/icons/CreditCard'
import AttachMoney from '@material-ui/icons/AttachMoney'
import AccessTime from '@material-ui/icons/AccessTime'
import Dvr from '@material-ui/icons/Dvr'
import Timelapse from '@material-ui/icons/Timelapse'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core'
import AttachCourier from '../../components/AttachCourier/AttachCourier'
import SupplierPost from '../../components/SupplierPost/SupplierPost'


import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import CardHeader from '../../components/Card/CardHeader'
import Card from '../../components/Card/Card'
import ConfirmSweetAlert from '../../components/SweetAlert/SweetAlert'
import SnackBar from '../../components/Snackbar/Snackbar'
import { AddButton } from '../../components/CustomButtons/AddButton'
import AddAlert from '@material-ui/icons/AddAlert'
import moment from 'moment'
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { courierPermissions, districtPermissions } from '../../permissions'
import EditPackage from '../Packages/EditPackage'
import DispatcherEditPackage from '../Packages/DispatcherEditPackage'
import Countdown from '../../components/Countdown/Countdown'
import TimePassed from '../../components/Countdown/TimePassed'
import { timeTillEvent } from '../../helpers'

import ProviderCreatePackage from '../Packages/ProviderCreatePackage'
import HistoryModal from '../Packages/History'
import MobileTable from './MobileTable'

import AllOut from '@material-ui/icons/AllOut'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import DirectionsBike from '@material-ui/icons/DirectionsBike'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Motorcycle from '@material-ui/icons/Motorcycle'
import { providerPackageForm, dispatcherPackageForm } from '../../forms'
import CheckboxField from "../../components/fields/CheckboxField";
import DashTable from './DashTable';
import ComboField from "../../components/fields/ComboField";
import { Field, withForm } from "ductus";

import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Typography } from "@material-ui/core";

import {ip} from "../../defaultConfigs";
import $ from 'jquery'
import qs from "query-string";

let counter = 0
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const chooseTime = (obj) => obj.pickupTime

const courierIcons = {
    PEDESTRIAN: <DirectionsRun style={{ height: 16, width: 16 }} />,
    BIKE: <DirectionsBike style={{ height: 16, width: 16 }} />,
    MOTORCYCLE: <Motorcycle style={{ height: 16, width: 16 }} />,
    CAR: <DirectionsCar style={{ height: 16, width: 16 }} />,
    OTHER: <AllOut style={{ height: 16, width: 16 }} />
}

const statusArrays = [
    ['PROCESSING', 'ACCEPTED', 'AT_PROVIDER', 'PICKED_UP', 'AT_RECIPIENT'],
    ['ACCEPTED', 'AT_PROVIDER', 'PICKED_UP', 'AT_RECIPIENT', 'PROCESSING'],
    ['AT_PROVIDER', 'PICKED_UP', 'AT_RECIPIENT', 'PROCESSING', 'ACCEPTED'],
    ['PICKED_UP', 'AT_RECIPIENT', 'PROCESSING', 'ACCEPTED', 'AT_PROVIDER'],
    ['AT_RECIPIENT', 'PROCESSING', 'ACCEPTED', 'AT_PROVIDER', 'PICKED_UP'],
]

const columnsMap = {
    current: new Map([
        [
            'receiveTime',
            (n, key) => (
                <TableCell className="receiveTime time_item max_value max_item" key={key} component="th" scope="row" padding="none">
                    <span onClick={(e) => {
                        e.stopPropagation()
                        n.openFormModal({
                            submitText: 'edit',
                            noSubmit: true,
                            Component: 'HistoryModal',
                            componentProps: {
                                packageId: n.id
                            }
                        })
                    }}
                    >
                        {moment(n.receivedAt).format('HH:mm')}
                    </span> <br />
                    {n.code}
                </TableCell>
            )
        ],
        [
            'provider',
            (n, key) => (
                <TableCell padding="none" className="provider max_value max_item" key={key} align="right">
                    <div title={n.pickupAddress}>
                        <b className="provider_color">{n.provider && n.provider.name}</b>
                        <b className="branch_color"> - {n.branch && n.branch.name}</b> <br />
                        {n.pickupAddress ? <span>{n.pickupAddress} </span> : ""} <br />
                        {n.branch && n.branch.phoneNumber} <br />
                    </div>
                </TableCell>
            )
        ],
        [
            'recipient',
            (n, key) => (
                <TableCell
                    padding="none"
                    className="recipient max_value max_item"
                    key={key}
                    align="right"
                    title={`${n.recipient && n.recipient.name} - ${n.recipient && n.recipient.phone}`}
                >
                   {n.recipientAddress} 

                   {n.itemMetricLabel && <b class="branch_color"> - {n.itemMetricLabel}</b>}
                </TableCell>
            )
        ],
        [
            'typeAndPrice',
            (n, key) => (
                <TableCell padding="none" className="typeAndPrice max_value max_item" key={key} align="right">
                    <span> {n.status} </span> <br />
                    {n.typeAndPrice.type === 'CASH'
                        ? <AttachMoney style={{ width: 16, height: 16 }} />
                        : <CreditCard style={{ width: 16, height: 16 }} />}
                    <span> {n.typeAndPrice && n.typeAndPrice.price}/{n.typeAndPrice && n.typeAndPrice.deliveryFee}  </span>
                </TableCell>
            )
        ],
        [
            'courier',
            (n, key) => (
                <TableCell padding="none" className="courier max_value max_item" key={key} align="right">
                    <span> {courierIcons[n.courier && n.courier.transportationType]} </span>
                    {n.courier && n.courier.name}
                </TableCell>
            )
        ],
        [
            'estimatedTime',
            (n, key) => (
                <TableCell padding="none" className="estimatedTime max_value max_item" key={key} align="right">
                    {n.status === 'ACCEPTED' ?
                        <Countdown eventTime={n.approxPickupTime} translate={n.translate} /> : '---'}
                </TableCell>
            )
        ],
        [
            'acceptedAt',
            (n, key) => (
                <TableCell padding="none" className="estimatedTime max_value max_item" key={key} align="right">
                    <TimePassed eventTime={n.acceptedAt} translate={n.translate} />
                </TableCell>
            )
        ],
        [
            'actions',
            (n, key) => (
                <TableCell padding="none" className="actions" key={key} component="th" scope="row" padding="none">
                    {n.actions}
                </TableCell>
            )
        ],
    ]),
    processing: new Map([
        [
            'receiveTime',
            (n, key) => (
                <TableCell className="receiveTime time_item max_value max_item" key={key} component="th" scope="row" padding="none">
                    <div>
                        {moment(n.receivedAt).format('HH:mm')} <br />
                        {n.code}
                    </div>
                </TableCell>
            )
        ],
        [
            'provider',
            (n, key) => (
                <TableCell className="provider max_value max_item" key={key} align="right">
                    <div title={n.pickupAddress}>
                        <b className="provider_color">{n.provider && n.provider.name}</b>
                        <b className="branch_color"> - {n.branch && n.branch.name}</b> <br />
                        {n.pickupAddress ? <span>{n.pickupAddress} </span> : ""} <br />
                        {n.branch && n.branch.phoneNumber} <br />
                    </div>
                </TableCell>
            )
        ],
        [
            'recipient',
            (n, key) => (
                <TableCell className="recipient max_value max_item" key={key} align="right"
                    title={`${n.recipient && n.recipient.name} - ${n.recipient && n.recipient.phone}`}>
                    {n.recipientAddress}

                   {n.itemMetricLabel && <b class="branch_color"> - {n.itemMetricLabel}</b>}

                </TableCell>
            )
        ],
        [
            'postProvider',
            (n, key) => (
                <TableCell className="postProvider max_value max_item" key={key} align="right">
                    {n.postProvider ? 
                    <span className="postProvider lable">
                        {n.postProvider.title}
                    </span>  : "" }
                </TableCell>
            )
        ],
        [
            'typeAndPrice',
            (n, key) => (
                <TableCell className="typeAndPrice max_value max_item" key={key} align="right">
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {n.typeAndPrice.type === 'CREDIT'
                            ? <CreditCard style={{ width: 16, height: 16 }} />
                            : <AttachMoney style={{ width: 16, height: 16 }} />}
                        <span> {n.typeAndPrice && n.typeAndPrice.price}/{n.typeAndPrice && n.typeAndPrice.deliveryFee}  </span>
                    </div>
                </TableCell>
            )
        ],
        [
            'actions',
            (n, key) => (
                <TableCell className="actions" key={key} component="th" scope="row" padding="none">
                    {n.actions}
                </TableCell>
            )
        ],
    ]),
    post: new Map([
        [
            'receiveTime',
            (n, key) => (
                <TableCell className="receiveTime time_item max_value max_item" key={key} component="th" scope="row" padding="none">
                    <div>
                        {moment(n.receivedAt).format('HH:mm')} <br />
                        {n.code}
                    </div>
                </TableCell>
            )
        ],
        [
            'provider',
            (n, key) => (
                <TableCell className="provider max_value max_item" key={key} align="right">
                    <div title={n.pickupAddress}>
                        <b className="provider_color">{n.provider && n.provider.name}</b>
                        <b className="branch_color"> - {n.branch && n.branch.name}</b> <br />
                        {n.pickupAddress ? <span>{n.pickupAddress} </span> : ""} <br />
                        {n.branch && n.branch.phoneNumber} <br />
                    </div>
                </TableCell>
            )
        ],
        [
            'recipient',
            (n, key) => (
                <TableCell className="recipient max_value max_item" key={key} align="right"
                    title={`${n.recipientName} - ${n.recipientPhone}`}>
                    {n.recipientAddress}
                </TableCell>
            )
        ],
        [
            'postProvider',
            (n, key) => (
                <TableCell className="postProvider max_value max_item" key={key} align="right">
                    {n.postProvider ? 
                    <span className="postProvider lable">
                        {n.postProvider && n.postProvider.title}
                    </span>  : "" }
                </TableCell>
            )
        ],
        [
            'courier',
            (n, key) => (
                <TableCell padding="none" className="courier max_value max_item" key={key} align="right">
                    <span> {courierIcons[n.courier && n.courier.transportationType]} </span>
                    {n.courier && n.courier.name}
                </TableCell>
            )
        ],
        [
            'deliveredToPostAt',
            (n, key) => (
                <TableCell padding="none" className="estimatedTime max_value max_item" key={key} align="right">
                    {n.status === 'DELIVERED_TO_POST' ? n.deliveredToPostAt : '---'}
                </TableCell>
            )
        ],
        [
            'actions',
            (n, key) => (
                <TableCell className="actions" key={key} component="th" scope="row" padding="none">
                    {n.actions}
                </TableCell>
            )],
    ])
}

const columns = {
    current: [
        { id: 'receiveTime', disablePadding: false, label: <div className="receiveTime time_item"><AccessTime /></div> },
        { id: 'provider', disablePadding: false, label: 'providerInfo' },
        { id: 'recipient', disablePadding: false, label: 'receiver' },
        { id: 'typeAndPrice', disablePadding: false, label: 'price' },
        { id: 'courier', disablePadding: false, label: 'courier' },
        { id: 'estimatedTime', disablePadding: false, label: <Timelapse /> },
        { id: 'acceptedAt', disablePadding: false, label: <Timer /> },
        { id: 'actions', disablePadding: false, label: '' },
    ],
    processing: [
        { id: 'receiveTime', disablePadding: true, label: <div className="receiveTime time_item"><AccessTime /></div> },
        { id: 'provider', disablePadding: false, label: 'providerInfo' },
        { id: 'recipient', disablePadding: false, label: 'receiver' },
        { id: 'postProvider', disablePadding: false, label: <div className="postProvider item">პროვაიდერი ფოსტა</div> },
        { id: 'typeAndPrice', disablePadding: false, label: 'price' },
        { id: 'actions', disablePadding: false, label: '' },
    ],
    post: [
        { id: 'receiveTime', disablePadding: true, label: <div className="receiveTime time_item"><AccessTime /></div> },
        { id: 'provider', disablePadding: false, label: 'provider' },
        { id: 'recipient', disablePadding: false, label: 'recipient' },
        { id: 'postProvider', disablePadding: false, label: 'postProvider' },
        { id: 'courier', disablePadding: false, label: 'courier' },
        { id: 'deliveredToPostAt', disablePadding: false, label: <Timelapse /> },
        { id: 'actions', disablePadding: false, label: '' },
    ]
}


const sortMapper = {
    receiveTime: 'received_at',
    // typeAndPrice: 'status',
    provider: 'provider_id',
    courier: 'courier_id',
    acceptedAt: 'accepted_at',
}

class Dashboard extends React.Component {

    width = window.innerWidth;

    state = {
        chooseCitySort: null,
        packageType: 'none',
        statusSortIndex: 0,
        allProcessingSelected: false,
        selectedProcessingPackages: new Set(),
        selectedPostPackages: new Set(),
        statusSortChangeCount: 0,
        height: 0,
        modal: {
            isOpen: false,
            title: '',
            content: null,
            buttons: [],
            buttonContainerStyles: {}
        },
        alert: {
            open: false,
        },
        snackBar: { openPos: false, message: '' },
        pageIndex: 0,

        processingColumns: {
            checkPackage: 1,
            receiveTime: 1,
            typeAndPrice: 1,
            provider: 1,
            recipient: 1,
            courier: 1,
            actions: 1,
        },
        postColumns: {
            checkPackage: 1,
            receiveTime: 1,
            typeAndPrice: 1,
            provider: 1,
            recipient: 1,
            courier: 1,
            actions: 1,
        },
        pColumns: new Set([
            'checkPackage',
            'receiveTime',
            'typeAndPrice',
            'provider',
            'provider',
            'recipient',
            'courier',
            'postProvider',
            'actions'
        ]),
        cColumns: new Set([
            'receiveTime',
            'typeAndPrice',
            'provider',
            'recipient',
            'courier',
            'estimatedTime',
            'actions',
            'acceptedAt',
        ]),
        postColumns: new Set([
            'checkPackage',
            'receiveTime',
            'provider',
            'recipient',
            'postProvider',
            'courier',
            'deliveredToPostAt',
            'actions'
        ]),

        changed: {},
        allowSubmitClick: true,
        sorted: {},
        tabValue: parseInt(localStorage.getItem("tabValue") || 0),
        allowClose: false
    }

    currentSort = (order, orderBy) => {

        
        const sort = sortMapper[orderBy];
        
        if (orderBy === 'typeAndPrice') {
            this.setState({ statusSortIndex: (this.state.statusSortIndex === statusArrays.length - 1) ? 0 : (this.state.statusSortIndex + 1) })
        }

        if (sort) {
            this.props.fetchCurrent({
                params: { path: 'packages/current' }, query: {
                    'with-pagination': 1,
                    sort, order,
                    type: this.state.tabValue === 1 ? "deliver" : "collect"
                    // type: this.state.packageType === 'none' ? '' : this.state.packageType
                }
            })
        }
    }


    toggleTableColumns = (tableName, columnName) => this.setState({
        [tableName]: {
            ...this.state[tableName],
            [columnName]:
                !this.state[tableName][columnName]
        }
    })

    showAlert = (params) => this.setState({
        alert: {
            open: true,
            ...params
        }
    })

    hideAlert = () => this.setState({
        alert: { open: false }
    })

    openModal = (modal) =>
        this.setState({
            modal: {
                isOpen: true,
                ...modal
            }
        })

    closeModal = (justClosed = true) => justClosed && this.setState({
        modal: {
            ...this.state.modal,
            isOpen: false
        }
    })

    makeActions = (item) => {
        const { userInfo, updateItem, fetchSingle, fetchPostdata, permissions } = this.props
        const path = `/packages`
        const isProvider = userInfo.role === 'provider'
        const activeTabe = this.state.tabValue
        const allowSubmitClick = this.state.allowSubmitClick
        return (
            <div className="actions-right">
                <Button
                    justIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        fetchSingle(item.id, { params: { path } })
                        this.openFormModal({
                            submitText: 'edit',
                            submit: (values ) => {
                                const { fetchCurrent, fetchProcessing,currentPaging, processingPaging ,fetchSingle, fetchPostdata} = this.props;
                                // this.setState({allowSubmitClick: false})

                                this.setState({
                                    allowClose: true, 
                                    allowSubmitClick: false,
                                    selectedPostPackages: new Set(),
                                    selectedProcessingPackages: new Set(),
                                })

                                values.packagePrice = values.packagePrice.replace(',', '');
                                updateItem(item.id, values, { params: { path } }).then(() => {

                                    fetchCurrent({params: {path: `/packages/current`}, query: { 'with-pagination': 1, page: currentPaging.currentPage, type: this.state.tabValue === 1 ? "deliver" : "collect" }})
                                    fetchProcessing({params: {path: `/packages/processing`}, query: { 'with-pagination': 1, page: processingPaging.currentPage, type: this.state.tabValue === 1 ? "deliver" : "collect" }})

                                    if(activeTabe === 0) {
                                        fetchSingle(item.id, { params: { path } })
                                        fetchProcessing({params: {path: `/packages/processing`}, query: { 'with-pagination': 1, page: processingPaging.currentPage, type: this.state.tabValue === 1 ? "deliver" : "collect" }})
                                    }  
                                
                                    this.setState({allowSubmitClick: true})
                                });
                            },
                            Component: isProvider ? 'EditPackage' : 'DispatcherEditPackage',
                            allowSubmitClick,
                            componentProps: {
                                permissions,
                                activeTabe,
                                allowSubmitClick,
                                providerId: isProvider ? userInfo.entityId : item.providerId,
                                handleSubmit: (values) => updateItem(item.id, values, { params: { path } })
                            },
                            noSubmit: isProvider,
                            form: isProvider ? providerPackageForm.edit : dispatcherPackageForm.edit,
                            duck: 'packages',     
                        })
                    }
                    }
                    color="warning"
                    className="edit"
                >
                    <Dvr />
                </Button>{' '}
            </div>
        )
    }

    removeInBoth = (which) => {
        this.setState({
            processingColumns: {
                ...this.state.processingColumns,
                [which]: 0
            },
            currentColumns: {
                ...this.state.currentColumns,
                [which]: 0
            },
            postColumns:{
                ...this.state.postColumns,
                [which]: 0
            }
        })
    }
  
    fetchData(type){

        // if(this.state.tabValue === 2){
            this.props.fetchPost({ params: { path: `/packages/delivered-to-post` }, query: { 'with-pagination': 1, } })
        // }else{
            this.props.fetchCurrent({ params: { path: 'packages/current' }, query: { 'with-pagination': 1, type: type } })
            this.props.fetchProcessing({ params: { path: `/packages/processing` }, query: { 'with-pagination': 1, type: type } })
        // }
    }

    componentDidMount() {
        const { userInfo, fetchCurrent, fetchProcessing, fetchDistricts, fetchPost } = this.props
        const { permissions, role } = userInfo

        this.props.fetchDistricts({query: {'without-pagination': 1}})

        // fetchCurrent({ params: { path: 'packages/current' }, query: { 'with-pagination': 1, type: 'collect' } })
        // fetchProcessing({ params: { path: `/packages/processing` }, query: { 'with-pagination': 1, type: 'collect' } })
        // console.log("selectedTab ",  this.props.selectedTab);

        this.fetchData(this.state.tabValue === 1 ? "deliver" : "collect");

        this.props.history.push({ search: '' })

        let newCols = {
            processingColumns: this.state.pColumns,
            currentColumns: this.state.cColumns,
            postColumns: this.state.postColumns
        }

        if (!permissions['packages_courier_bulk_assign']) {
            newCols.processingColumns.delete('checkPackage')
            newCols.postColumns.delete('checkPackage')

        }

        if (role === 'provider') {
            if (!permissions.packages_show_courier) {
                newCols.processingColumns.delete('courier')
                newCols.currentColumns.delete('courier')
                newCols.postColumns.delete('courier')
            }

            if (!permissions.courier_arrival_times) {
                newCols.processingColumns.delete('estimatedTime')
                newCols.currentColumns.delete('estimatedTime')
                newCols.postColumns.delete('estimatedTime')
            }
        }
        this.setState({
            ...newCols
        })
    }

    attachCourier = (packageId) => (courierId) => this.props.assignCourier({}, {
        courierId,
        packageId
    })

    bulkAttachCourier = () => {
        this.props.bulkAttachCourier({}, {
            courierId: this.state.courierId,
            packages: this.getSelectedProcessingPackages()
        })

        this.setState({ selectedProcessingPackages: new Set() })
    }
    supplierPost = () => {
        this.props.supplierPost({}, {
            postProviderId: this.state.postProviderId,
            packages: this.getSelectedProcessingPackages()
        })
        let delivery_district_id = this.state.chooseCitySort ? this.state.chooseCitySort : undefined;
        
        const page = Number(qs.parse(this.props.history.location.search).processingPage)
                
        this.props.fetchProcessing({params: { path: `/packages/processing` }, query: { 'with-pagination': 1, page, type: "deliver", delivery_district_id } })

    }
    
    getSelectedProcessingPackages = () => Array.from(this.state.selectedProcessingPackages)

    getSelectedPostPackages = () => Array.from(this.state.selectedPostPackages)

    setCourierId = (courierId) => this.setState({
        courierId
    });
    setPostProviderId = (postProviderId) => this.setState({
        postProviderId
    });

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState.courierId === this.state.courierId
    }

    getTdProps = (state, rowInfo, column) => {
        const { userInfo } = this.props
        const can = userInfo.permissions[courierPermissions.read]

        return {
            onClick: () => {
                if (
                    (column.id !== 'actions') &&
                    (column.id !== 'receiveTime') &&
                    can &&
                    rowInfo
                ) {
                    const { id, courier } = rowInfo.original

                    this.openFormModal({
                        title: 'chooseCourier',
                        Component: 'AttachCourier',
                        componentProps: {
                            courier,
                            packageId: id,
                            setCourierId: this.setCourierId,
                            attach: this.attachCourier(id)
                        },
                        submitText: 'attach',
                        submit: () => this.attachCourier(id)(this.state.courierId),
                        small: true
                    })
                }
            },
            style: { padding: '0 3px', whiteSpace: 'normal', cursor: can ? 'pointer' : 'default' }
        }
    }

    onProcessingRowClick = (data) => {
        const { userInfo } = this.props
        const can = userInfo.permissions[courierPermissions.read]
        if (can) {
            const { id, courier } = data

            this.openFormModal({
                title: 'chooseCourier',
                Component: 'AttachCourier',
                componentProps: {
                    courier,
                    packageId: id,
                    setCourierId: this.setCourierId,
                    attach: this.attachCourier(id)
                },
                submitText: 'attach',
                submit: () => this.attachCourier(id)(this.state.courierId),
                small: true
            })
        }
    }
    onPostRowClick = (data) => {
        const { userInfo } = this.props
        const can = userInfo.permissions[courierPermissions.read]
        // if (can) {
        //     const { id, courier } = data

            // this.openFormModal({
            //     title: 'chooseCourier',
            //     Component: 'AttachCourier',
            //     componentProps: {
            //         courier,
            //         packageId: id,
            //         setCourierId: this.setCourierId,
            //         attach: this.attachCourier(id)
            //     },
            //     submitText: 'attach',
            //     submit: () => this.attachCourier(id)(this.state.courierId),
            //     small: true
            // })
        // }
    }

    renderCheckPackage = (item) => {
        const { selectedProcessingPackages } = this.state

        return (
            <div>
                <CheckboxField
                    name={`package-${item.id}`}
                    value={selectedProcessingPackages.has(item.id)}
                    onChange={(a, e) => {
                        e.stopPropagation()
                        this.toggleCheck(item)
                    }}
                />
            </div>
        )
    }

    toggleCheck = (item) => {
        const selected = new Set(this.state.selectedProcessingPackages);
        if (selected.has(item.id)) {
            selected.delete(item.id)
        } else {
            selected.add(item.id)

        }

        this.setState({
            selectedProcessingPackages: selected
        })
    }

    toggleSelectAll = () => {
        const { processing } = this.props;
        const selected = new Set();
        if (processing.length > this.state.selectedProcessingPackages.size) {
            processing.forEach(p => {
                selected.add(p.id)
            })
        }
        this.setState({
            selectedProcessingPackages: selected
        })
    }

    postToggleCheck = (item) => {
        const selected = new Set(this.state.selectedPostPackages);
        if (selected.has(item.id)) {
            selected.delete(item.id)
        } else {
            selected.add(item.id)

        }

        this.setState({
            selectedPostPackages: selected
        })
    }

    postToggleSelectAll = () => {
        const { post } = this.props;
        const selectedPost = new Set();
        if (post.length > this.state.selectedPostPackages.size) {
            post.forEach(p => {
                selectedPost.add(p.id)
            })
        }
        this.setState({
            selectedPostPackages: selectedPost
        })
    } 

    getDelivered = () => {
        this.props.upatedPostStatus({}, {
            status: "DELIVERED",
            package_ids: this.getSelectedPostPackages()
        })

        this.setState({ selectedPostPackages: new Set() })
    }                 
    getRejected = () => {
        this.props.upatedPostStatus({}, {
            status: "REJECTED",
            package_ids: this.getSelectedPostPackages()
        })

        this.setState({ selectedPostPackages: new Set() })
    }  

    // selectAllPackage = () => {
    //     const {allProcessingSelected} = this.state
    //     const {processing} = this.props
    //     let newPackages = new Set()
    //     processing.forEach(pack => {
    //         if(allProcessingSelected){
    //             newPackages.delete(pack.id)
    //         } else {
    //             newPackages.set(pack.id)
    //         }
    //     })
    //     this.setState({selectedProcessingPackages: newPackages, allProcessingSelected: Number(!allProcessingSelected)})
    // }

    // packageTypeChanged = type => {
    //     console.log('change: ', type)
    //     this.props.fetchCurrent({
    //         params: { path: 'packages/current' }, query: {
    //             'with-pagination': 1,
    //             type: type === 'none' ? '' : type,
    //             ...this.state.sorted,
    //             status: this.props.filterStatus || ''

    //         }
    //     })
    //     this.setState({ packageType: type })
    // }
    chooseCitySort = type => {
        this.props.fetchProcessing({
            params: { path: `/packages/processing` }, query: {
                'with-pagination': 1,
                // type: type === 'none' ? '' : type,
                type: 'deliver',
                delivery_district_id: type,

                // ...this.state.sorted,
                // status: this.props.filterStatus || '',  
            }
        })
        this.setState({ chooseCitySort: type })
    }

    componentWillUpdate(nextProps) {
        const {
            resetEditionFormValues,
            updateLoading,
            singleLoading,
            attachCourierPending,
            supplierPostPending,
            storeLoading,
            resetCreationFormValues,
            resetCreationFormValuesDispatcher,
            current,
            currentPending,
            changedCurrent,
            bulkAttachCourierLoading,
            supplierPostLoading,
            translate,
            filterStatus,
            updateErrors
        } = this.props

        const { userInfo } = nextProps;

        if (updateErrors !== nextProps.updateErrors && nextProps.updateErrors instanceof TypeError) {
            alert("დაფიქსირდა შეცდომა. გთხოვთ ხელახლა სცადოთ");
        }

        if (filterStatus !== nextProps.filterStatus) {
            if (nextProps.filterStatus) {

            }
            const query = nextProps.filterStatus ? {
                ...this.state.sorted,
                'with-pagination': 1,
                status: nextProps.filterStatus,
                type: this.state.packageType === 'none' ? '' : this.state.packageType
            } : {
                    ...this.state.sorted,
                    'with-pagination': 1,
                    type: this.state.packageType === 'none' ? '' : this.state.packageType
                }

            this.props.fetchCurrent({
                params: { path: `/packages/current` },
                query
            })
        }

        if (bulkAttachCourierLoading && !nextProps.bulkAttachCourierLoading) {
            this.props.closeModal()
            if (nextProps.bulkAttachCourierError) {
                this.setState({
                    snackBar: {
                        openPos: 'bl',
                        message: translate('bulkAttachCourierError'),
                        color: 'danger'
                    }
                })
                setTimeout(() => this.closeSnackBar(), 3000)
            }
        }
        if (supplierPostLoading && !nextProps.supplierPostLoading) {
            this.props.closeModal()
            if (nextProps.supplierPostError) {
                this.setState({
                    snackBar: {
                        openPos: 'bl',
                        message: translate('supplierPostError'),
                        color: 'danger'
                    }
                })
                setTimeout(() => this.closeSnackBar(), 3000)
            }
        }
        
        // if (updateLoading && !nextProps.updateLoading && !updateErrors) {

        //     this.props.closeModal()
        //     resetEditionFormValues()
        // }

        if (updateLoading && !nextProps.updateLoading && !nextProps.updateErrors) {
            resetEditionFormValues()
        }
                
        if (updateLoading && !nextProps.updateLoading && !updateErrors && this.state.allowClose === true) {
            this.setState({allowClose: false})

        // if(!updateLoading && !nextProps.updateErrors && nextProps.current !== current && 
        //     currentPending !== false && this.props.snackBar.message !== "PACKAGE_CREATED") {
            
            this.props.closeModal()
        }

        if (attachCourierPending && !nextProps.attachCourierPending) {
            this.props.closeModal()
        }
        
        if (supplierPostPending && !nextProps.supplierPostPending) {
            this.props.closeModal()
        }

        if (singleLoading && !nextProps.singleLoading && !nextProps.singleErrors) {
            this.props[`changeEditFormValues${userInfo.role === 'provider' ? 'provider' : 'dispatcher'}`](nextProps.singleData)
        }

        if (storeLoading && !nextProps.storeLoading && !nextProps.storeErrors) {
            this.props.closeModal()
            resetCreationFormValues()
            resetCreationFormValuesDispatcher()
        }

        if (changedCurrent !== nextProps.changedCurrent) {
            current.map(curr => {
                const nextCurr = nextProps.current.find(c => c.id === curr.id)
                if (nextCurr && nextCurr.changed) {
                    this.setState({
                        changed: { ...this.state.changed, [nextCurr.id]: true }
                    })
                    setTimeout(() => this.setState({
                        changed: { ...this.state.changed, [nextCurr.id]: false }
                    }), 800)
                    // else if(curr.changed && !nextCurr.changed){
                    //   let newChanged = {...this.state.changed}
                    //   delete newChanged[nextCurr.id]
                    //   this.setState({
                    //     changed: newChanged
                    //   })
                    // }
                }
            })
        }

    }

    getTrProps = (state, rowInfo) => {
        const { infoColor } = this.props.themeColors
        const changed = this.state.changed[rowInfo && rowInfo.row._original.id]
        return {
            style: {
                backgroundColor: changed ? infoColor : 'white',
                color: changed ? 'white' : 'black'
            }
        }
    }

    dataObject = item => ({
        ...item,
        actions: this.makeActions(item),
        openFormModal: this.openFormModal,
        translate: this.props.translate

    })

    closeSnackBar = () =>
        this.setState({
            snackBar:
                { openPos: false, message: '', color: this.state.snackBar.color }
        })

    compareForMobile = (a, b) => statusArrays[0].indexOf(a.status) - statusArrays[0].indexOf(b.status)

    openFormModal = ({ title = '', duck, submitText = '', form, Component, componentProps, noSubmit, submit, propName, additionalButtons = [], small = false, ...rest }) => this.props.openModal({
        title,
        Component,
        componentProps,
        noSubmit,
        submitText,
        submit,
        small,
        form,
        duck,
        ...rest
    })

    filterColumns = table => col => this.state[table].has(col.accessor)

    addItem = (values) => {
        // this.props.storeItem(values)
        const formData = new FormData();
        for(const valueKey in values) {
            formData.append(valueKey, values[valueKey])
        }
        
        $.ajax({
            url: `http://${ip}/api/packages`,
            data: formData,
            type: 'POST',
            dataType: "JSON",
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: (xhr) => {
                xhr.setRequestHeader('accept', 'application/json');
                xhr.setRequestHeader('Authorization', 'Bearer ' + this.props.token);
            },
            success: (data) => {
                const { userInfo, storeItem, permissions } = this.props

                const successStatus = data.success;

                this.openFormModal({
                    submitText: 'add',
                    submit: this.addItem,
                    form: providerPackageForm.create,
                    duck: 'packages',
                    // Component: 'ProviderCreatePackage',
                    Component: userInfo.role === 'provider' ? 'ProviderCreatePackage' : 'DispatcherCreatePackage',
                    componentProps: {
                        submitted: counter++,
                        successStatus,
                        providerId: userInfo.entityId,
                        deliveryFee: userInfo.deliveryFee,
                        handleSubmit: storeItem,
                        permissions
                    }
                })   
            },
            error: (errors) => {
                const { userInfo, storeItem, permissions } = this.props
                // handle error
                const formSubmitErrors = Object.values(errors.responseJSON.errors);
                const formSubmitBlankErrors = errors.responseJSON.errors.message;
                const errorStatus = errors.status;
                
                this.openFormModal({
                    submitText: 'add',
                    submit: this.addItem,
                    form: providerPackageForm.create,
                    duck: 'packages',
                    // Component: 'ProviderCreatePackage',
                    Component: userInfo.role === 'provider' ? 'ProviderCreatePackage' : 'DispatcherCreatePackage',
                    componentProps: {
                        submitted: counter++,
                        formSubmitErrors,
                        formSubmitBlankErrors,
                        errorStatus,
                        providerId: userInfo.entityId,
                        deliveryFee: userInfo.deliveryFee,
                        handleSubmit: storeItem,
                        permissions
                    }
                })
            },
        });
    }

    pageChanged = (some) => (pageIndex) => {
        let delivery_district_id = this.state.chooseCitySort ? this.state.chooseCitySort : undefined;

        if (some === 'processing') {
            this.props.fetchProcessing({
                params: { path: `/packages/processing` },
                query: { 
                    'with-pagination': 1, 
                    page: pageIndex + 1, 
                    type: this.state.tabValue === 1 ? "deliver" : "collect", 
                    delivery_district_id 
                }
            })

            this.props.history.push({ search: window.location.search.replace(/&processingPage=[0-9+]|processingPage=[0-9+]/i, '') + '&processingPage=' + (pageIndex + 1) })
        } else if (some === 'post') {
            this.props.fetchPost({
                params: { path: `/packages/delivered-to-post` },
                query: { 'with-pagination': 1, page: pageIndex + 1 }
            })

            this.props.history.push({ search: window.location.search.replace(/&postPage=[0-9+]|postPage=[0-9+]/i, '') + '&postPage=' + (pageIndex + 1) })
        }
         else {
            const query = this.props.filterStatus ? {
                ...this.state.sorted,
                'with-pagination': 1,
                page: pageIndex + 1,
                status: this.props.filterStatus,
                // type: this.state.packageType === 'none' ? '' : this.state.packageType
                type: this.state.tabValue === 1 ? "deliver" : "collect"
            } : {
                    ...this.state.sorted,
                    'with-pagination': 1,
                    page: pageIndex + 1,
                    // type: this.state.packageType === 'none' ? '' : this.state.packageType
                    type: this.state.tabValue === 1 ? "deliver" : "collect"
                }

            this.props.fetchCurrent({
                params: { path: `/packages/current` },
                query
            })

            this.props.history.push({ search: window.location.search.replace(/&currentPage=[0-9+]|currentPage=[0-9+]/i, '') + '&currentPage=' + (pageIndex + 1) })
        }
    };

    TabHandleChange = (event, tabValue) => {
        
        localStorage.setItem('tabValue', tabValue); 
        this.setState({ tabValue, selectedProcessingPackages: new Set(), chooseCitySort: null });
        this.props.selectTab(tabValue === 0 ? 'collect' : "deliver" )
        this.fetchData(tabValue === 0 ? 'collect' : "deliver" )
    
    }; 


    render() {
        const { classes } = this.props;
        const {
            processing,
            processingLoading,
            current,
            currentLoading,
            translate,
            userInfo,
            storeItem,
            permissions,
            canCreateOrder,
            currentPaging,
            processingPaging,
            postPaging,
            postLoading,
            // post
        } = this.props
        
        const { snackBar, tabValue } = this.state
        const chooseCity = this.props.districts;
        const post = this.props.post;

        const copyMakeAction = this.props.updateMakeActionError;

        return (
            <GridContainer>
                
                {
                    canCreateOrder &&
                    <AddButton
                        // hidden={!permissions[packagePermissions.create]}
                        onClick={() => this.openFormModal({
                            submitText: 'add',
                            submit: this.addItem,
                            form: userInfo.role === 'provider' ? providerPackageForm.create : dispatcherPackageForm.create,
                            duck: 'packages',
                            Component: userInfo.role === 'provider' ? 'ProviderCreatePackage' : 'DispatcherCreatePackage',
                            componentProps: {
                                formSubmitErrors: [],
                                formSubmitBlankErrors: null,
                                errorStatus: null,
                                successStatus: false,
                                providerId: userInfo.entityId,
                                deliveryFee: userInfo.deliveryFee,
                                handleSubmit: storeItem,
                                permissions
                            }
                        })}
                    >
                    </AddButton>
                }


                <div style={{ flexGrow: 1 }}>
                    <AppBar position="static" className="tab_nav bg_transparent"  style={{ background: "transkarent" }}>
                        <Tabs 
                            value={tabValue} 
                            onChange={this.TabHandleChange} 
                            TabIndicatorProps={{style: {backgroundColor: 'transparent'}}}
                            >
                            <Tab 
                                label="ასაღები" 
                                classes={{
                                    root: "Tab_btn Btn_sort",
                                    selected: "Tab_selected"
                                }} 
                            />
                            <Tab 
                                label="მისატანი" 
                                classes={{
                                    root: "Tab_btn Btn_sort",
                                    selected: "Tab_selected"
                                }} 
                            />
                            { userInfo.role !== "provider" && 
                                <Tab 
                                    label="ფოსტაშია" 
                                    classes={{
                                        root: "Tab_btn Btn_sort",
                                        selected: "Tab_selected"
                                    }} 
                                />
                            }
                        </Tabs>
                    </AppBar>
                    {tabValue === 0 && <TabContainer>

                        <GridItem xs={12}>
                            <Card>
                                <CardHeader className={classes.headerPanel}>
                                    <h4 style={{ display: 'inline-block' }}
                                        className={classes.cardIconTitle}> {translate('processing')} </h4>
                                    { userInfo.role !== "provider" &&
                                        // (processingLoading || !permissions['packages_courier_bulk_assign']) ? null : processing.length ?
                                        // processingLoading ? null : processing.length ?
                                       
                                           <div className={classes.cardButtonBlock}>
                                            <Button
                                            // color="primary"
                                                className={!this.getSelectedProcessingPackages().length ? classes.BtnAttachCourierDisabled : classes.BtnAttachCourierActive }
                                                disabled={!this.getSelectedProcessingPackages().length}
                                                size="sm"
                                                onClick={() => this.openFormModal({
                                                    title: 'აირჩიე კურიერი',
                                                    Component: 'AttachCourier',
                                                    componentProps: {
                                                        setCourierId: this.setCourierId,
                                                        attach: this.bulkAttachCourier
                                                    },
                                                    submitText: 'attach',
                                                    submit: this.bulkAttachCourier,
                                                    small: true

                                                })}
                                                >
                                               {translate('AttachCourier')}
                                               {/* {translate('bulkAttachCourier')} */}
                                            </Button>
                                        </div>
                                        // : null
                                    }
                                </CardHeader>
                                {
                                    processingLoading ? null : !processing.length ?
                                        <span className={classes.notFoundData}>
                                            {translate('recordsNotFound')}
                                        </span> : null
                                }
                                {
                                    processing.length > 0 &&
                                    <CardBody>
                                        {
                                            // !processingLoading && !processing.length ? null :
                                            this.width > 920 &&
                                            <DashTable
                                                className={this.state.tabValue === 0 ? "collect" : "deliver"}
                                                translate={translate}
                                                data={processing.map(this.dataObject)}
                                                pagination={processingPaging}
                                                loading={processingLoading}
                                                onPageChange={this.pageChanged('processing')}
                                                showColumns={this.state.pColumns}
                                                openFormModal={this.openFormModal}
                                                onRowClick={this.onProcessingRowClick}
                                                // withCheck={permissions['packages_courier_bulk_assign']}
                                                withCheck={true}
                                                selected={this.state.selectedProcessingPackages}
                                                toggleSelected={this.toggleCheck}
                                                toggleSelectAll={this.toggleSelectAll}
                                                allSelected={this.state.selectedProcessingPackages.size === processing.length}
                                                columns={columns.processing}
                                                columnsMap={columnsMap.processing}
                                            />
                                        }
                                        {
                                            this.width <= 920 &&
                                            <MobileTable
                                                thClick={(item) => {
                                                    const path = '/packages'
                                                    const isProvider = userInfo.role === 'provider'
                                                    const { fetchSingle, updateItem } = this.props

                                                    fetchSingle(item.id, { params: { path } })
                                                    this.openFormModal({
                                                        submitText: 'edit',
                                                        submit: (values) => {
                                                            const { fetchCurrent, fetchProcessing } = this.props;
                                                            updateItem(item.id, values, { params: { path } });
                                                            setTimeout(() => {
                                                                fetchCurrent({ params: { path: 'packages/current' }, query: { 'with-pagination': 1 } })
                                                                fetchProcessing({ params: { path: `/packages/processing` }, query: { 'with-pagination': 1 } })
                                                            }, 400)
                                                        },
                                                        Component: isProvider ? 'EditPackage' : 'DispatcherEditPackage',
                                                        componentProps: {
                                                            permissions,
                                                            providerId: isProvider ? userInfo.entityId : item.providerId,
                                                            handleSubmit: (values) => updateItem(item.id, values, { params: { path } })
                                                        },
                                                        noSubmit: isProvider,
                                                        form: isProvider ? providerPackageForm.edit : dispatcherPackageForm.edit,
                                                        duck: 'packages'
                                                    })
                                                }}
                                                trClick={permissions[courierPermissions.read]
                                                    ? ((courier, id) => {
                                                        this.openFormModal({
                                                            title: 'chooseCourier',
                                                            Component: 'AttachCourier',
                                                            componentProps: {
                                                                courier,
                                                                packageId: id,
                                                                setCourierId: this.setCourierId,
                                                                attach: this.attachCourier(id)
                                                            },
                                                            submitText: 'attach',
                                                            submit: () => this.attachCourier(id)(this.state.courierId),
                                                            small: true
                                                        })
                                                    })
                                                    : (() => {
                                                    })
                                                }
                                                translate={translate}
                                                col6={userInfo.role === 'provider'}
                                                data={processing && processing
                                                    .map(this.dataObject)}

                                            />
                                        }
                                    </CardBody>
                                }
                            </Card>
                        </GridItem>

                        <GridItem xs={12}>
                            <Card>
                                <CardHeader style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                    <h4 style={{ display: 'inline-block' }}
                                        className={classes.cardIconTitle}> {translate('current')} </h4>

                                    {/* <GridItem xs={3} sm={6} md={3}>
                                        <ComboField
                                            label={translate('chooseType')}
                                            items={[{ id: 'none', name: 'ყველა' }, { id: 'collect', name: 'ასაღები' }, { id: 'deliver', name: 'მისატანი' }]}
                                            value={this.state.packageType}
                                            onChange={this.packageTypeChanged}
                                            multiple={false}
                                        />
                                    </GridItem> */}

                                </CardHeader>


                                {
                                    current.length > 0 &&
                                    <CardBody>
                                        {
                                            // !currentLoading && !current.length ? null :
                                            this.width > 920 &&
                                            <DashTable
                                                translate={translate}
                                                data={current.map(this.dataObject)
                                                    .sort((a, b) => {
                                                        const arr = statusArrays[this.state.statusSortIndex];
                                                        return arr.indexOf(a.status) - arr.indexOf(b.status)
                                                    })
                                                }
                                                pagination={currentPaging}
                                                loading={currentLoading}
                                                onPageChange={this.pageChanged('current')}
                                                showColumns={this.state.cColumns}
                                                openFormModal={this.openFormModal}
                                                onRowClick={this.onProcessingRowClick}
                                                withCheck={false}
                                                columns={columns.current}
                                                columnsMap={columnsMap.current}
                                                onSortChange={this.currentSort}
                                                sortable={true}
                                            />
                                        }
                                        {
                                            this.width <= 920 &&
                                            <MobileTable
                                                thClick={(item) => {
                                                    const path = '/packages'
                                                    const isProvider = userInfo.role === 'provider'
                                                    const { fetchSingle, updateItem } = this.props

                                                    fetchSingle(item.id, { params: { path } })
                                                    this.openFormModal({
                                                        submitText: 'edit',
                                                        submit: (values) => {
                                                            const { fetchCurrent, fetchProcessing } = this.props;
                                                            updateItem(item.id, values, { params: { path } })
                                                            setTimeout(() => {
                                                                fetchCurrent({ params: { path: 'packages/current' }, query: { 'with-pagination': 1 } })
                                                                fetchProcessing({ params: { path: `/packages/processing` }, query: { 'with-pagination': 1 } })
                                                            }, 400)
                                                        },
                                                        Component: isProvider ? 'EditPackage' : 'DispatcherEditPackage',
                                                        componentProps: {
                                                            permissions,
                                                            providerId: isProvider ? userInfo.entityId : item.providerId,
                                                            handleSubmit: (values) => updateItem(item.id, values, { params: { path } })
                                                        },
                                                        noSubmit: isProvider,
                                                        form: isProvider ? providerPackageForm.edit : dispatcherPackageForm.edit,
                                                        duck: 'packages'
                                                    })
                                                }}
                                                trClick={permissions[courierPermissions.read]
                                                    ? ((courier, id) => {
                                                        this.openFormModal({
                                                            title: 'chooseCourier',
                                                            Component: 'AttachCourier',
                                                            componentProps: {
                                                                courier,
                                                                packageId: id,
                                                                setCourierId: this.setCourierId,
                                                                attach: this.attachCourier(id)
                                                            },
                                                            submitText: 'attach',
                                                            submit: () => this.attachCourier(id)(this.state.courierId),
                                                            small: true
                                                        })
                                                    })
                                                    : (() => {
                                                    })
                                                }
                                                col6={userInfo.role === 'provider'}
                                                translate={translate}
                                                data={current && current
                                                    .map(this.dataObject)
                                                    .sort(this.compareForMobile)
                                                }

                                            />
                                        }
                                    </CardBody>
                                }
                            </Card>
                        </GridItem>


                    </TabContainer>}
                    {tabValue === 1 && <TabContainer>
                        
                        <GridItem xs={12}>
                            <Card>
                                <CardHeader className={classes.headerPanel}> 
                                    <h4 style={{ display: 'inline-block' }}
                                        className={classes.cardIconTitle}> {translate('processing')} </h4>
                                    
                                    {userInfo.role !== "provider" &&
                                        // (processingLoading || !permissions['packages_courier_bulk_assign']) ? null : processing.length ?
                                        // processingLoading ? null : processing.length ?
                                       
                                        <div className={classes.cardButtonBlock}>
                                            <div>
                                                
                                                <Button
                                                    className={!this.getSelectedProcessingPackages().length ? classes.BtnAttachPostDisabled : classes.BtnAttachPostActive }
                                                    disabled={!this.getSelectedProcessingPackages().length}
                                                    size="sm"
                                                    onClick={() => this.openFormModal({
                                                        title: 'მონიშნე პროვაიდერი ფოსტა',
                                                        Component: 'SupplierPost',
                                                        componentProps: {
                                                            setPostProviderId: this.setPostProviderId,
                                                            attach: this.supplierPost
                                                        },
                                                        submitText: 'attach',
                                                        submit: this.supplierPost,
                                                        small: true

                                                    })}>
                                                        {translate('supplierPost')}
                                                </Button>
                                            </div>

                                            <GridItem xs={3} sm={6} md={3}>
                                                <ComboField
                                                    label={translate('chooseCity')}
                                                    items={chooseCity.slice(0).reverse().map((item) => ({ id: item.id, name: item.name }) ) }
                                                    value={this.state.chooseCitySort}
                                                    onChange={this.chooseCitySort}
                                                    multiple={false}
                                                />
                                            </GridItem>
                                            
                                            <Button
                                                // color="primary"
                                                className={!this.getSelectedProcessingPackages().length ? classes.BtnAttachCourierDisabled : classes.BtnAttachCourierActive }
                                                disabled={!this.getSelectedProcessingPackages().length}
                                                size="sm"
                                                onClick={() => this.openFormModal({
                                                    title: 'აირჩიე კურიერი',
                                                    Component: 'AttachCourier',
                                                    componentProps: {
                                                        setCourierId: this.setCourierId,
                                                        attach: this.bulkAttachCourier
                                                    },
                                                    submitText: 'attach',
                                                    submit: this.bulkAttachCourier,
                                                    small: true

                                                })}>
                                                    {translate('AttachCourier')}
                                                {/* {translate('bulkAttachCourier')} */}
                                            </Button>
                                            </div>
                                            // : null
                                    }
                                </CardHeader>
                                {
                                    processingLoading ? null : !processing.length ?
                                        <span className={classes.notFoundData}>
                                            {translate('recordsNotFound')}
                                        </span> : null
                                }
                                {
                                    processing.length > 0 &&
                                    <CardBody>
                                        {
                                            // !processingLoading && !processing.length ? null :
                                            this.width > 920 &&
                                            <DashTable
                                                translate={translate}
                                                data={processing.map(this.dataObject)}
                                                pagination={processingPaging}
                                                loading={processingLoading}
                                                onPageChange={this.pageChanged('processing')}
                                                showColumns={this.state.pColumns}
                                                openFormModal={this.openFormModal}
                                                onRowClick={this.onProcessingRowClick}
                                                // withCheck={permissions['packages_courier_bulk_assign']}
                                                withCheck={true}
                                                selected={this.state.selectedProcessingPackages}
                                                toggleSelected={this.toggleCheck}
                                                toggleSelectAll={this.toggleSelectAll}
                                                allSelected={this.state.selectedProcessingPackages.size === processing.length}
                                                columns={columns.processing}
                                                columnsMap={columnsMap.processing}
                                            />
                                        }
                                        {
                                            this.width <= 920 &&
                                            <MobileTable
                                                thClick={(item) => {
                                                    const path = '/packages'
                                                    const isProvider = userInfo.role === 'provider'
                                                    const { fetchSingle, updateItem } = this.props

                                                    fetchSingle(item.id, { params: { path } })
                                                    this.openFormModal({
                                                        submitText: 'edit',
                                                        submit: (values) => {
                                                            const { fetchCurrent, fetchProcessing } = this.props;
                                                            updateItem(item.id, values, { params: { path } });
                                                            setTimeout(() => {
                                                                fetchCurrent({ params: { path: 'packages/current' }, query: { 'with-pagination': 1 } })
                                                                fetchProcessing({ params: { path: `/packages/processing` }, query: { 'with-pagination': 1 } })
                                                            }, 400)
                                                        },
                                                        Component: isProvider ? 'EditPackage' : 'DispatcherEditPackage',
                                                        componentProps: {
                                                            permissions,
                                                            providerId: isProvider ? userInfo.entityId : item.providerId,
                                                            handleSubmit: (values) => updateItem(item.id, values, { params: { path } })
                                                        },
                                                        noSubmit: isProvider,
                                                        form: isProvider ? providerPackageForm.edit : dispatcherPackageForm.edit,
                                                        duck: 'packages'
                                                    })
                                                }}
                                                trClick={permissions[courierPermissions.read]
                                                    ? ((courier, id) => {
                                                        this.openFormModal({
                                                            title: 'chooseCourier',
                                                            Component: 'AttachCourier',
                                                            componentProps: {
                                                                courier,
                                                                packageId: id,
                                                                setCourierId: this.setCourierId,
                                                                attach: this.attachCourier(id)
                                                            },
                                                            submitText: 'attach',
                                                            submit: () => this.attachCourier(id)(this.state.courierId),
                                                            small: true
                                                        })
                                                    })
                                                    : (() => {
                                                    })
                                                }
                                                translate={translate}
                                                col6={userInfo.role === 'provider'}
                                                data={processing && processing
                                                    .map(this.dataObject)}

                                            />
                                        }
                                    </CardBody>
                                }
                            </Card>
                        </GridItem>

                        <GridItem xs={12}>
                            <Card>
                                <CardHeader style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                    <h4 style={{ display: 'inline-block' }}
                                        className={classes.cardIconTitle}> {translate('current')} </h4>

                                    {/* <GridItem xs={3} sm={6} md={3}>
                                        <ComboField
                                            label={translate('chooseType')}
                                            items={[{ id: 'none', name: 'ყველა' }, { id: 'collect', name: 'ასაღები' }, { id: 'deliver', name: 'მისატანი' }]}
                                            value={this.state.packageType}
                                            onChange={this.packageTypeChanged}
                                            multiple={false}
                                        />
                                    </GridItem> */}

                                </CardHeader>


                                {
                                    current.length > 0 &&
                                    <CardBody>
                                        {
                                            // !currentLoading && !current.length ? null :
                                            this.width > 920 &&
                                            <DashTable
                                                translate={translate} 
                                                data={current.map(this.dataObject)
                                                    .sort((a, b) => {
                                                        const arr = statusArrays[this.state.statusSortIndex];
                                                        return arr.indexOf(a.status) - arr.indexOf(b.status)
                                                    })
                                                }
                                                pagination={currentPaging}
                                                loading={currentLoading}
                                                onPageChange={this.pageChanged('current')}
                                                showColumns={this.state.cColumns}
                                                openFormModal={this.openFormModal}
                                                onRowClick={this.onProcessingRowClick}
                                                withCheck={false}
                                                columns={columns.current}
                                                columnsMap={columnsMap.current}
                                                onSortChange={this.currentSort}
                                                sortable={true}
                                            />
                                        }
                                        {
                                            this.width <= 920 &&
                                            <MobileTable
                                                thClick={(item) => {
                                                    const path = '/packages'
                                                    const isProvider = userInfo.role === 'provider'
                                                    const { fetchSingle, updateItem } = this.props

                                                    fetchSingle(item.id, { params: { path } })
                                                    this.openFormModal({
                                                        submitText: 'edit',
                                                        submit: (values) => {
                                                            const { fetchCurrent, fetchProcessing } = this.props;
                                                            updateItem(item.id, values, { params: { path } })
                                                            setTimeout(() => {
                                                                fetchCurrent({ params: { path: 'packages/current' }, query: { 'with-pagination': 1 } })
                                                                fetchProcessing({ params: { path: `/packages/processing` }, query: { 'with-pagination': 1 } })
                                                            }, 400)
                                                        },
                                                        Component: isProvider ? 'EditPackage' : 'DispatcherEditPackage',
                                                        componentProps: {
                                                            permissions,
                                                            providerId: isProvider ? userInfo.entityId : item.providerId,
                                                            handleSubmit: (values) => updateItem(item.id, values, { params: { path } })
                                                        },
                                                        noSubmit: isProvider,
                                                        form: isProvider ? providerPackageForm.edit : dispatcherPackageForm.edit,
                                                        duck: 'packages'
                                                    })
                                                }}
                                                trClick={permissions[courierPermissions.read]
                                                    ? ((courier, id) => {
                                                        this.openFormModal({
                                                            title: 'chooseCourier',
                                                            Component: 'AttachCourier',
                                                            componentProps: {
                                                                courier,
                                                                packageId: id,
                                                                setCourierId: this.setCourierId,
                                                                attach: this.attachCourier(id)
                                                            },
                                                            submitText: 'attach',
                                                            submit: () => this.attachCourier(id)(this.state.courierId),
                                                            small: true
                                                        })
                                                    })
                                                    : (() => {
                                                    })
                                                }
                                                col6={userInfo.role === 'provider'}
                                                translate={translate}
                                                data={current && current
                                                    .map(this.dataObject)
                                                    .sort(this.compareForMobile)
                                                }

                                            />
                                        }
                                    </CardBody>
                                }
                            </Card>
                        </GridItem>

                        
                    </TabContainer>}
                    {tabValue === 2 &&  userInfo.role !== "provider" && <TabContainer>
                        
                        <GridItem xs={12}>
                            <Card>
                                <CardHeader className={classes.headerPanel} >
                                    <h4 style={{ display: 'inline-block' }}
                                        className={classes.cardIconTitle}> {translate('post')} </h4>
                                    {
                                        // (processingLoading || !permissions['packages_courier_bulk_assign']) ? null : processing.length ?
                                        // processingLoading ? null : processing.length ?
                                       
                                        <div className={classes.cardButtonBlock}>
                                            <Button
                                                className={!this.getSelectedPostPackages().length ? classes.BtnDeliveredDisabled : classes.BtnDeliveredActive }
                                                disabled={!this.getSelectedPostPackages().length}
                                                size="sm"
                                                onClick={() => this.getDelivered() }
                                                >
                                              Delivered
                                            </Button>
                                            <Button
                                                className={!this.getSelectedPostPackages().length ? classes.BtnRejectedDisabled : classes.BtnRejectedActive }
                                                disabled={!this.getSelectedPostPackages().length}
                                                size="sm"
                                                onClick={() => this.getRejected() }
                                                >
                                                Rejected
                                            </Button>
                                        </div>
                                        // : null
                                    }
                                </CardHeader>
                                {
                                    postLoading ? null : post && post.length > 0 ? null : (
                                        <span className={classes.notFoundData}>
                                            {translate('recordsNotFound')}
                                        </span>
                                    )
                                }
                                {
                                    post && post.length > 0 &&
                                    <CardBody>
                                        {
                                            // !processingLoading && !processing.length ? null :
                                            this.width > 920 &&
                                            <DashTable
                                                translate={translate}
                                                data={post.map(this.dataObject)}
                                                pagination={postPaging}
                                                loading={postLoading}
                                                onPageChange={this.pageChanged('post')}
                                                showColumns={this.state.postColumns}
                                                openFormModal={this.openFormModal}
                                                onRowClick={this.onPostRowClick}
                                                // withCheck={permissions['packages_courier_bulk_assign']}
                                                withCheck={true}
                                                selected={this.state.selectedPostPackages}
                                                toggleSelected={this.postToggleCheck}
                                                toggleSelectAll={this.postToggleSelectAll}
                                                allSelected={this.state.selectedPostPackages.size === post.length}
                                                columns={columns.post}
                                                columnsMap={columnsMap.post}
                                            />
                                        }
                                        {
                                            this.width <= 920 &&
                                            <MobileTable
                                                thClick={(item) => {
                                                    const path = '/packages'
                                                    const isProvider = userInfo.role === 'provider'
                                                    const { fetchSingle, updateItem } = this.props
                    
                                                    fetchSingle(item.id, { params: { path } })
                                                    this.openFormModal({
                                                        submitText: 'edit',
                                                        submit: (values) => {
                                                            const { fetchCurrent, fetchProcessing } = this.props;
                                                            updateItem(item.id, values, { params: { path } });
                                                            setTimeout(() => {
                                                                fetchCurrent({ params: { path: 'packages/current' }, query: { 'with-pagination': 1 } })
                                                                fetchProcessing({ params: { path: `/packages/processing` }, query: { 'with-pagination': 1 } })
                                                            }, 400)
                                                        },
                                                        Component: isProvider ? 'EditPackage' : 'DispatcherEditPackage',
                                                        componentProps: {
                                                            permissions,
                                                            providerId: isProvider ? userInfo.entityId : item.providerId,
                                                            handleSubmit: (values) => updateItem(item.id, values, { params: { path } })
                                                        },
                                                        noSubmit: isProvider,
                                                        form: isProvider ? providerPackageForm.edit : dispatcherPackageForm.edit,
                                                        duck: 'packages'
                                                    })
                                                }}
                                                trClick={permissions[courierPermissions.read]
                                                    ? ((courier, id) => {
                                                        this.openFormModal({
                                                            title: 'chooseCourier',
                                                            Component: 'AttachCourier',
                                                            componentProps: {
                                                                courier,
                                                                packageId: id,
                                                                setCourierId: this.setCourierId,
                                                                attach: this.attachCourier(id)
                                                            },
                                                            submitText: 'attach',
                                                            submit: () => this.attachCourier(id)(this.state.courierId),
                                                            small: true
                                                        })
                                                    })
                                                    : (() => {
                                                    })
                                                }
                                                translate={translate}
                                                col6={userInfo.role === 'provider'}
                                                data={processing && processing
                                                    .map(this.dataObject)}
                    
                                            />
                                        }
                                    </CardBody>
                                }
                            </Card>
                        </GridItem>
                       
                    </TabContainer>}
                </div>


                {this.state.alert.open && <ConfirmSweetAlert {...this.state.alert} />}
                <SnackBar
                    place={'br'}
                    color={snackBar.color || "info"}
                    icon={AddAlert}
                    message={snackBar.message}
                    open={snackBar.openPos}
                    closeNot={this.closeSnackBar}
                    close
                />
            </GridContainer>
        )
    }
}

// const TableBody = ({children, style}) => {
//   const child = children.filter(c => c.length)[0]
//   return (
//     <FlipMove style={style}>
//       {child.map((c, key) => <div key={key}> {c} </div>)}
//     </FlipMove>
//   )
// }

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(extendedFormsStyle)(Dashboard)
