import {createGroup} from "./functions/createGroup"

export default createGroup({
  statePath: `orderHours`,
  name: 'orderHours',
  generic: {
    initialData: { can: true },
    transformData: (response) => response.body.data
  },
  resource: 'provider/can-create-order',
  additionalHandlers: {
    ['WORKING_HOURS_START']: (state) => ({...state, data: { can: true }}),
    ['WORKING_HOURS_END']: (state) => ({...state, data: { can: false }})
  }
})