import {combineReducers} from "redux";
import filter from "../filterForms";

import {
  authForm,
  emailChangeForm,
  bulkPackagesForm,
  passwordChangeForm,
  tariffsForm,
} from "../../forms";
import couriers from './couriers'
import districts from './districts'
import providers from './providers'
import branches from './branches'
import sizes from './sizes'
import hubs from './hubs'
import managers from './managers'
import warehouse_managers from './warehouseManagers'
import dispatchers from './dispatchers'
import {dispatcherPackages, providerPackages, packages } from './packages'
import * as sortsDashForm from "ductus";

export default combineReducers({
  filter, // ფილტრის ფორმები
  tariffsForm: tariffsForm.reduce,
  authForm: authForm.reduce,
  emailChangeForm: emailChangeForm.reduce,
  bulkPackagesForm: bulkPackagesForm.reduce,
  passwordChangeForm: passwordChangeForm.reduce,
  couriers,
  districts,
  providers,
  branches,
  sizes,
  packages,
  dispatcherPackages,
  providerPackages,
  hubs,
  managers,
  warehouse_managers,
  dispatchers,
})
