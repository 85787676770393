import storage from "redux-persist/lib/storage"

const api_url = process.env.REACT_APP_API_URL;
export const ip = localStorage.getItem('ip') || api_url || 'extra:8000'
export const port = localStorage.getItem('port') || ''
export const broadcastPort = localStorage.getItem('broadcastPort') || 6001

export const defaultLocale = localStorage.getItem('defaultLocale') || 'ka'

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['preferences', 'localization.translations']
}

export const echoOptions = {
  broadcaster: 'socket.io',
  host: `http://${ip}:${broadcastPort}`
}

export const miniActive = localStorage.getItem('miniActive')

export const voiceConfigs = {
  voice: true,
  toggle: false,
  selectedVoice: 'beep'
}

export const theme = {

  sidebar: {
    sidebarBgColor: 'black',
    sidebarColor: 'red',
    miniActive: false,
    logoText: 'EXTRA',
  },

  button: {
    primary: 'primary',
    secondary: 'google'
  },

  Mui: {
    colors: {
      primaryColor: "#9c27b0",
      secondaryColor: "#e91e63",
      warningColor: "#ff9800",
      dangerColor: "#f44336",
      successColor: "#4caf50",
      infoColor: "#00acc1",
      grayColor: "#999999",
    },
    // rgb: {
    //   primaryColor: {
    //     156, 39, 176,
    //     r: 156,
    //     g: 39,
    //     b: 176,
    //   },
    //   secondaryColor: "#e91e63",
    //   warningColor: "#ff9800",
    //   dangerColor: "#f44336",
    //   successColor: "#4caf50",
    //   infoColor: "#00acc1",
    //   grayColor: "#999999",
    // }
  }
}
