
import React from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const FinanceTable = ({ data, translate, cols, classes }) =>
  <Paper className={classes && classes.root} style={{ overflowX: 'auto'}}>
    <Table className={classes && classes.table}>
      <TableHead>
        <TableRow>
          {
            cols && cols.map( col =>
              <TableCell  > {translate(col)}</TableCell>
            )
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {data && data.map( (row, key) => (
          <TableRow key={key}>
            {
              cols && cols.map( col =>
                <TableCell numeric={true}> {row[col]} </TableCell>
              )
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Paper>

export default FinanceTable