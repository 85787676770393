import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import DateTime from 'react-datetime'

import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import moment from 'moment'

const joinFormats = (dateFormat, timeFormat) => {
  let arr = []
  dateFormat && arr.push('YYYY-MM-DD')
  timeFormat && arr.push('HH:mm:ss')

  return arr.join(' ')
}

const DateTimeField = ({name, value, error, onChange, dateFormat, timeFormat, id = undefined, label = ''}) => (
  <DateTime
    name={name}
    value={moment(value, joinFormats(dateFormat, timeFormat))}
    onChange={e => typeof e === 'object' ? onChange(e.format(joinFormats(dateFormat, timeFormat))) : onChange(e)}
    // onBlur={val => !moment(val).isValid() && onChange('')}
    dateFormat={dateFormat}
    timeFormat={timeFormat}
    inputProps={{placeholder: label }}
    // strictParsing={true}
  />
)


export default withStyles(customCheckboxRadioSwitch)(DateTimeField)
