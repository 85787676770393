import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import {courierServices} from "../../ducks/services"

import {
  Marker,
  InfoWindow
} from "react-google-maps";

import AllOut from '@material-ui/icons/AllOut'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import DirectionsBike from '@material-ui/icons/DirectionsBike'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Motorcycle from '@material-ui/icons/Motorcycle'

const courierIcons = {
  PEDESTRIAN: <DirectionsRun className={'courierIcon'} />,
  BIKE: <DirectionsBike className={'courierIcon'} />,
  MOTORCYCLE: <Motorcycle className={'courierIcon'} />,
  CAR: <DirectionsCar className={'courierIcon'} />,
  OTHER: <AllOut className={'courierIcon'} />
}

class Locations extends React.Component {

  state = {
    open: {}
  }

  componentDidMount(){
    this.props.getLocations()
  }

  toggleInfoWindow = (key) => this.setState({ open: {...this.state.open, [key]: !this.state.open[key] }})
  closeInfoWindow = (key) => this.setState({ open: {...this.state.open, [key]: false }})

  render(){
    const { courierLocations } = this.props

    const locations = courierLocations.filter(courier => courier.locations.length )

    return (
      <Fragment>
        {
          locations.map((loc, key) =>
            <Marker key={key} onClick={() => this.toggleInfoWindow(key) } position={{ lat: loc.locations[0].lat , lng: loc.locations[0].lng }} >
              {
                this.state.open[key] &&
                <InfoWindow onCloseClick={() => this.closeInfoWindow(key)}>
                  <div>
                    <span> {loc.name} </span> <br />
                    <span> {loc.phoneNumber} </span> <br />
                    <span> {courierIcons[loc.transportationType]} </span> <br />
                    <span> {loc.locations[0].createdAt}</span>
                  </div>
                </InfoWindow>
              }
            </Marker>
          )
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  courierLocations: courierServices.locations.getData(state)
})
const mapDispatchToProps = {
  getLocations: courierServices.locations.run,
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations)