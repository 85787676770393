
import React from 'react'
import { hubs } from '../../ducks'
import { financeServices } from '../../ducks/services'
import { connect } from 'react-redux'

import GeneralPayments from './GeneralPayments'

const remove = (payload) => financeServices.paymentProviders.removeFinance( financeServices.paymentProviders.REMOVE_PAYMENT, payload)

const mapStateToProps = (state) => ({
  hubs: hubs.getList(state).data,
  payments: financeServices.paymentProviders.getData(state),
  paymentsLoading: financeServices.paymentProviders.getRequestPending(state),
  token: state.authorization.auth.token,
  name: 'providers',
})
const mapDispatchToProps = {
  fetchHubs: hubs.fetchList,
  addPayment: financeServices.paymentProviders.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralPayments)