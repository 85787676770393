import React from 'react'
import {withForm} from 'ductus'
import {providerFilterForm, sortsDashForm} from "../../forms"
import {Field} from 'ductus'

import {ComboField, CheckboxField, TextField, DateTimeField} from "../../components"

import {connect} from 'react-redux'
import {providers} from '../../ducks'
import qs from 'query-string'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {ExpansionPanelActions, ExpansionPanelSummary} from "@material-ui/core";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import withStyles from "@material-ui/core/styles/withStyles";
import accordionStyle from "../../assets/jss/material-dashboard-pro-react/components/accordionStyle";
import Search from "@material-ui/icons/Search";
import { couriers } from '../../ducks'
import {ip} from "../../defaultConfigs";
import {withRouter} from 'react-router-dom';
import FileDownload from "@material-ui/icons/FileDownload";

const style = (theme) => ({
    ...accordionStyle(theme),
    accordionContent: {
        alignItems: 'center'
    },
})

class WarehouseManagerFilter extends React.Component {

    state = {
        branches: [],
        branchesDisabled: true,
        expanded: false
    }
    
    componentDidMount() {
        /* Manager & History pages reset filter data */
        // this.props.fetchList()
    }

    componentWillUnmount() {
        this.props.resetForm()
    }
    onClear = () => {
        const {filterItems, resetForm, history} = this.props
        resetForm()
        history.push({search: ''})
        this.props.fetchList()
    }

    onFilterClick = () => {
        const {filterItems, formValues, history} = this.props
        filterItems({query: formValues, params: {path: '/packages'}})
        history.push({search: qs.stringify(formValues)})
    }

    providerSelectionChange = (val) => {
        const provider = this.props.providers.find(p => p.id === val)
        if (provider) {
            const {branches} = provider
            // branches.length && this.props.changeFormValue('branchId', branches[0].id)
            this.setState({
                branches,
                branchDisabled: false
            })
        }
    }

    componentWillMount() {
        const { formValues, history, fetchList, fetchCouriers, fetchProviders } = this.props
        fetchCouriers({query: {'without-pagination': 1}})
        const query = {...formValues, ...qs.parse(history.location.search), order: 'asc'};
        // const query = {...formValues, ...qs.parse(history.location.search)};
        
        if(query.date){
            query.date = parseInt(query.date)
        }
        if(query.merchantId){
            query.merchantId = parseInt(query.merchantId)
        }
        if(query.providerId){
            query.providerId = parseInt(query.providerId)
        }
        if(query.courier_id){
            query.courier_id = parseInt(query.courier_id)
        }

        this.props.changeFormValues(query)
        fetchList(query)
        // this.props.history.push({ search: qs.stringify(query) })
        fetchProviders({query: {'without-pagination': 1}});
    }

    onSearch = () => {
        const { history, fetchList, formValues } = this.props;
        history.push({search: qs.stringify(formValues)})
        fetchList(formValues);
    }

    render() {
        const { translate, classes, showYesNo, couriers, showPackageStatus, providers} = this.props;
        let minFilter = this.state.expanded !== true ?  "min_filter" : "min_filter_hidden";

        return (
        <div>
            <ExpansionPanel
                expanded={this.state.expanded}
                onChange={() => this.setState({
                    expanded: !this.state.expanded
                })}
                classes={{
                    root: classes.expansionPanel + ' sw-selector',
                    expanded: classes.expansionPanelExpanded
                }}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore/>}
                    classes={{
                        root: classes.expansionPanelSummary,
                        expanded: classes.expansionPanelSummaryExpaned,
                        content: classes.expansionPanelSummaryContent + ' ' + classes.accordionContent,
                        expandIcon: classes.expansionPanelSummaryExpandIcon
                    }}
                    style={{}}
                >
                    {translate('filter')}
                    {
                        this.props.showDownload ?
                            <Button
                                color={'primary'}
                                simple
                                justIcon
                                round
                                style={{
                                    position: 'absolute',
                                    right: 45,
                                    padding: 0,
                                    margin: 0
                                }}

                                href={`http://${ip}/download/line-items/export?${qs.stringify(this.props.formValues)}&token=${this.props.token}`}
                            >
                                <FileDownload/>
                            </Button>
                            : ''
                    }
                </ExpansionPanelSummary>
                {this.state.expanded === true && <div>
                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <GridContainer style={{alignItems: 'center'}}>
                            <GridItem xs={12} sm={6} md={4} lg={3}>
                                <GridContainer style={{alignItems: 'center'}}>
                                    <GridItem xs={12}>
                                        <small style={{color: '#AAAAAA', fontSize: 15}}> {translate('dateRange')} </small>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Field
                                            name={'date[start_date]'}
                                            component={DateTimeField}
                                            dateFormat={'YYYY-MM-DD'}
                                            timeFormat={false}
                                        />
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Field
                                            name={'date[end_date]'}
                                            component={DateTimeField}
                                            dateFormat={'YYYY-MM-DD'}
                                            timeFormat={false}
                                        />
                                    </GridItem>

                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={3}>
                                <Field
                                    name={'order_id'}
                                    label={translate('orderId')}
                                    component={TextField}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={3}>
                                <Field
                                    name={'recipient_name'}
                                    label={translate('clientName')}
                                    component={TextField}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={3}>
                                <Field
                                    name={'recipient_phone_number'}
                                    label={translate('clientNumber')}
                                    component={TextField}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={3}>
                                <Field
                                    name={'providerId'}
                                    label={translate('provider')}
                                    items={providers || []}
                                    component={ComboField}
                                    selectionChange={this.providerSelectionChange}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={3}>
                                <Field
                                    name={'merchantId'}
                                    label={translate('branches')}
                                    component={ComboField}
                                    items={this.state.branches}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={3}>
                                <Field
                                    name={'courier_id'}
                                    label={translate('courier')}
                                    component={ComboField}
                                    items={couriers}
                                />
                            </GridItem>

                            {
                                showYesNo &&
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <Field
                                        name={'status'}
                                        label={translate('action')}
                                        component={ComboField}
                                        items={[
                                            // {
                                            //     id: null,
                                            //     name: 'აირჩიეთ'
                                            // },
                                            {
                                                id: 'missing',
                                                name: 'missing'
                                            },
                                            {
                                                id: 'pending',
                                                name: 'pending'
                                            },
                                            {
                                                id: 'yes',
                                                name: 'yes'
                                            },
                                            {
                                                id: 'no',
                                                name: 'no'
                                            },
                                            {
                                                id: 'courier_picked',
                                                name: 'courier picked'
                                            },
                                            {
                                                id: 'courier_missed',
                                                name: 'courier missed'
                                            }
                                        ]}
                                    />
                                </GridItem>
                            }
                            {
                                showPackageStatus &&
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <Field
                                    name={'package_status'}
                                    label={'სტატუსი'}
                                    component={ComboField}
                                    items={[
                                        //   {
                                        //       id: null,
                                        //       name: 'აირჩიეთ'
                                        //   },
                                        {
                                            id: 'DELIVERED',
                                            name: 'მიტანილი'
                                        },
                                        {
                                            id: 'NOT_DELIVERED',
                                            name: 'არ არის მიტანილი'
                                        }
                                    ]}
                                    />
                                </GridItem>
                            }
                        </GridContainer>
                    </ExpansionPanelDetails>
                    <ExpansionPanelActions
                        style={{
                            paddingTop: 0
                        }}
                    >
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Field
                                name={'search'}
                                label={translate('item')}
                                component={TextField}
                            />
                        </GridItem>
                        
                        <Button
                            justIcon
                            title={translate('clear')}
                            color={'rose'}
                            onClick={this.onClear}
                        >
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                        </Button>
                        <Button
                            justIcon
                            title={translate('search')}
                            color={'primary'}
                            onClick={this.onSearch}
                        >
                            <Search/>
                        </Button>
                    </ExpansionPanelActions>
                </div>}
            </ExpansionPanel>        
            {/* </div> */}
            <div className={minFilter}>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Field
                        name={'providerId'}
                        label={translate('provider')}
                        items={providers || []}
                        component={ComboField}
                        selectionChange={this.providerSelectionChange}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Field
                        name={'merchantId'}
                        label={translate('branches')}
                        component={ComboField}
                        items={this.state.branches}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                    <Field
                        name={'courier_id'}
                        label={translate('courier')}
                        component={ComboField}
                        items={couriers}
                    />
                </GridItem>
                <Button
                    justIcon
                    title={translate('clear')}
                    color={'rose'}
                    onClick={this.onClear}
                >
                    <i className="fa fa-eraser" aria-hidden="true"></i>
                </Button>
                <Button
                    justIcon
                    title={translate('search')}
                    color={'primary'}
                    onClick={this.onSearch}
                >
                    <Search/>
                </Button>
            </div>

        </div>
        )
    }
}

const mapStateToProps = state => ({
    formValues: sortsDashForm.getValues(state),
    providers : providers.getList(state).data,
    couriers: couriers.getList(state).data,
    token: state.authorization.auth.token,
})

export default connect(
    mapStateToProps,
    {
        fetchCouriers: couriers.fetchList,
        filterItems: providers.fetchList,
        changeFormValues: sortsDashForm.changeValues,
        fetchProviders: providers.fetchList,
        resetForm: sortsDashForm.resetValues,
    }
)(withForm(withRouter(sortsDashForm))(withStyles(style)(WarehouseManagerFilter)))
