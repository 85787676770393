import React from 'react'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'
import ConfirmSweetAlert from '../../components/SweetAlert/SweetAlert'
import SnackBar from '../../components/Snackbar/Snackbar'
import { AddButton } from '../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"
import { dispatcherForm } from "../../forms"
import {Dvr, Close} from '@material-ui/icons'
import ReactTable from 'react-table'
import CreateDispatcherForm from './CreateDispatcherForm'
// import EditManagerForm from './EditDispatcherForm'
import DispatcherFilterForm from './DispatchersFilterForm'
import {dispatcherPermissions} from "../../permissions"
import qs from "query-string"
import ListComponent from "../ListComponent";

class DispatchersList extends ListComponent {

  submitUpdate = (item) => (formValues) => this.props.updateItem(item.id, {...item, ...formValues})

  makeActions = (item) => {
    const {resetEditionFormValues, fetchSinge, userInfo, removeItem, translate, permissions} = this.props
    const { hubs } = userInfo

    return (
      <div className="actions-right">
        {
          permissions[dispatcherPermissions.update] &&
          <Button
            justIcon
            round
            simple
            onClick={() => {
              resetEditionFormValues() ||
              this.openFormModal({
                submitText: 'edit',
                duck: 'dispatchers',
                form: dispatcherForm.edit,
                submit: this.submitUpdate(item),
                Component: 'EditDispatcherForm',
                componentProps: {
                  hubs,
                  permissions
                }
              })
              || fetchSinge(item.id)
            }}
            color="warning"
            className="edit"
          >
            <Dvr/>
          </Button>
        }
        {
          permissions[dispatcherPermissions.delete] &&
          <Button
            justIcon
            round
            simple
            onClick={() => this.showAlert({
              title: translate('toDelete', {item: item.name}),
              confirmText: translate('delete'),
              cancelText: translate('close'),
              hideAlert: this.hideAlert,
              confirmFun: () => removeItem(item.id),
              warning: true
            })}
            color="danger"
            className="remove"
          >
            <Close/>
          </Button>
        }
      </div>
    )
  }

  componentDidMount() {
    const {filterFormValues, fetchList, history} = this.props
    const {search} = history.location
    const query = {...filterFormValues, ...qs.parse(search)}
    fetchList({query})
    this.props.history.push({search: qs.stringify(query)})
  }

  render() {

    const {data, loading,storeItem, pagination, userInfo, translate, permissions, history} = this.props
    const { alert, snackBar} = this.state
    const { hubs } = userInfo

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <DispatcherFilterForm hubs={hubs} translate={translate} history={history}/>
          </CardBody>
        </Card>

        <AddButton

          hidden={!permissions[dispatcherPermissions.create]}
          onClick={() => this.openFormModal({
            submitText: 'add',
            form: dispatcherForm.create,
            submit: storeItem,
            Component: 'CreateDispatcherForm',
            componentProps: {
              hubs,
              permissions
            }
          })}
        >
        </AddButton>

        <GridContainer>
          <Card>
            <CardBody>
              <ReactTable
                data={data && data.map(this.dataObject)}
                loading={loading}
                pages={pagination && pagination.totalPages}
                manual
                page={this.state.pageIndex}
                onPageChange={this.pageChanged}
                defaultPageSize={(pagination && pagination.perPage) || 5 }
                pageSize={pagination && pagination.perPage}

                onSortedChange={this.onSortedChange}

                columns={[
                  {
                    Header: translate('name'),
                    accessor: "name"
                  },
                  {
                    Header: translate('phoneNumber', {}),
                    accessor: "phoneNumber",
                    sortable: false,
                  },
                  {
                    Header: translate('phoneNumber', {number: 2}),
                    accessor: "altPhoneNumber"
                  },
                  {
                    Header: translate('actions'),
                    accessor: "actions",
                    sortable: false,
                  }
                ]}
                previousText={translate('previousText')}
                nextText={translate('nextText')}
                loadingText={translate('loadingText')}
                pageText={translate('pageText')}
                ofText={translate('ofText')}
                showPaginationBottom
                showPageSizeOptions={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>

        </GridContainer>
        {alert.open && <ConfirmSweetAlert {...alert} />}
        <SnackBar
          place={'br'}
          color="info"
          icon={AddAlert}
          message={snackBar.message}
          open={snackBar.openPos}
          closeNot={this.closeSnackBar}
          close
        />
      </GridItem>
    )
  }
}


export default DispatchersList
