import React from "react";

import {
  GoogleMap
} from "react-google-maps"

class MyGoogleMap extends React.Component {

  state = {
    open: {}
  }

  toggleInfoWindow = (key) => this.setState({ open: {...this.state.open, [key]: !this.state.open[key] }})
  closeInfoWindow = (key) => this.setState({ open: {...this.state.open, [key]: false }})

  render(){
    return (
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: 41.720519, lng: 44.767149 }}
        defaultOptions={{
          zoomControl: true,
        }}
      >
        {this.props.children}
      </GoogleMap>
    )
  }
}

export default MyGoogleMap