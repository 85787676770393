import React from "react";
import Button from '@material-ui/core/Button';
import Modal from '../../../components/Modal/Modal'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class WarehouseModal extends React.PureComponent {

    render() {
        const {isOpen, onClose, styleModal, auditData } = this.props;
        
        return (
            <Modal
                isOpen={isOpen}
                close={onClose}
                style={styleModal}
                className="modalStyle"
                // small={true}
                content={
                    <div className="modalContainter">
                        <div className="modalRow">
                            <h1 className="modalTitle">ისტორია</h1>
                        </div>
                        <div className="modal_cols dalRow">
                            <Table className="modal_tbl" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>როლი</TableCell>
                                        <TableCell align="right">სტატუსი</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {auditData.statusAudit.map((item, key) => (
                                    <TableRow key={key}>
                                        <TableCell component="th" scope="row">
                                            <p className="col_text_note">{item.user && item.user.name}</p>
                                            <p className="col_text_time">
                                                <span className="col_text_role">{item.user && item.user.roles}</span>
                                                <span> | </span>
                                                <span className="col_text_time">{item.createdAt}</span>
                                            </p>
                                        </TableCell>
                                        <TableCell align="right">
                                            <p className="col_text_note">{item.status.new}</p>
                                            <p className="col_text_time">{item.createdAt}</p>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            <Table className="modal_tbl" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>როლი</TableCell>
                                        <TableCell align="right">კომენტარი</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {auditData.noteAudit.map((item, key) => (
                                    <TableRow key={key}>
                                        <TableCell align="right">
                                            <p className="col_text_note">{item.user && item.user.name}</p>
                                            <p className="col_text_time">
                                                <span className="col_text_role">{item.user && item.user.roles}</span>
                                                <span> | </span>
                                                <span className="col_text_time">{item.createdAt}</span>
                                            </p>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="col_text">
                                                <p className="col_text_note">{item.note}</p>
                                                <p className="col_text_time">{item.createdAt}</p>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>


                        </div>
                        <div className="rowButtons center">
                            <Button className="closeBtn" onClick={onClose}>დახურვა</Button>
                        </div>

                    </div>
                } 
            />
        )
    };
}


export default WarehouseModal;


