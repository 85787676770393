
import React from 'react'
import { Card, CardBody} from '../../components'
import ChartGraph from "react-chartist";

let delays2 = 80,
  durations2 = 500;

const multipleBarsChart = {

  options: {
    seriesBarDistance: 10,
    axisX: {
      showGrid: false
    },
    height: "300px"
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function(value) {
            return value[0];
          }
        }
      }
    ]
  ],
  animation: {
    draw: function(data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

const StatsChart = ({ data: {intervals} }) =>
  <Card style={{ flex: 1 }}>
    <CardBody>
      <ChartGraph
        data={{
          labels: intervals.map( int => int.interval ),
          series: [intervals.map( int => int.value )]
        }}
        type="Bar"
        options={multipleBarsChart.options}
        listener={multipleBarsChart.animation}
      />
    </CardBody>
  </Card>

export default StatsChart