
import React from 'react'
import beep from "../../assets/voices/beep.wav";
import {connect } from 'react-redux'
import { voice } from "../../ducks";

class Voice extends React.Component{

  componentWillReceiveProps(nextProps){
    if((this.props.toggle !== nextProps.toggle) && nextProps.hasVoice ){
      this.audio.play()
      // document.getElementById('beep').play()
    }
  }

  render(){
    return (
      <audio ref={audio => this.audio = audio } src={this.props.voice}></audio>
    )
  }
}

const mapStateToProps = (state) => ({
  toggle: voice.getToggle(state),
  hasVoice: voice.getVoice(state)
})

export default connect(mapStateToProps, null)(Voice)