import {combineEpics, ofType} from 'redux-observable'
import {delay, map} from 'rxjs/operators'
import { snackBar } from './ducks'

// export const handleEvent = action$ => action$.pipe(
//   filter(action => (action.type === 'EVENT/OCCURRED' || action.type === 'EVENT/OCCURRED_SILENT')),
//   filter(action => action.payload.type.includes('PACKAGE') ),
//   map( ({ payload, type }) =>
//     // ({...payload, type: `EVENT/${payload.type}`})
//     ({ type: 'SHOULD_UPDATE', payload: { type } })
//   ),
// )


// export const snackBarEpic = action$ => action$.pipe(
//   ofType('EVENT/OCCURRED'),
//   map( ({ payload }) => snackBar.openSnackBar({ message: payload.type }) ),
// )

export const snackBarCloseEpic = action$ => action$.pipe(
  ofType('EVENT/OCCURRED'),
  delay(3000),
  map( () => snackBar.closeSnackBar() ),
)

export default combineEpics(snackBarCloseEpic)