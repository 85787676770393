
import { connect } from 'react-redux'
import {setDefaultLocale} from 'redux-l10n/dist/actions'

import Settings from './Settings'
import {theme, voice} from "../../ducks"
import { emailChangeForm, passwordChangeForm } from "../../forms"
import { userServices } from '../../ducks/services'

const mapStateToProps = (state) => ({
  emailFormValues: emailChangeForm.getValues(state),
  passwordFormValues: passwordChangeForm.getValues(state),
  getCurrentVoice: voice.getCurrentVoice(state),
  Mui: theme.getThemeMui(state),
  sidebarConfigs: theme.getThemeSidebar(state)
})
const mapDispatchToProps = {
  changeEmailFormValue: emailChangeForm.changeValue,
  updatePassword: userServices.passwordUpdate.update,
  updateEmail: userServices.emailUpdate.update,
  setDefaultLocale,
  changeVoice: voice.changeVoice,
  changeColor: theme.changeColor,
  changeSidebarConfig: theme.changeSidebarConfig,
  setDefault: theme.setDefault,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)