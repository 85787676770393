import { handleActions } from 'redux-actions'
import {PACKAGE_TAB_UPDATE} from '../../eventTypes';


const selectTab = (payload) => ({ type: PACKAGE_TAB_UPDATE, payload })

export default ({
    PACKAGE_TAB_UPDATE,

    selectTab,
    getSelectedTab: (state) => {
        return state.dashboard.selectedTab.selectedTab
    },

  reduce: handleActions({
    [PACKAGE_TAB_UPDATE]: (state, { payload }) => ({...state, selectedTab: payload }),
  }, {
    selectedTab: localStorage.getItem('tabValue') === '1' ? 'deliver' : "collect",
  })
})