import SortsManagerDashboard from "./SortsManagerDashboard";
import connect from "react-redux/es/connect/connect";
import {getLineItems, SORTS_DASHBOARD_INVALIDATE} from "../../../ducks/sortsManager";
import {sortsDashForm} from "../../../forms";
import sortsManagerServices from "../../../ducks/services/sortsManager";

const mapStateToProps = (state) => ({
    pending: state.sortsManager.dash.pending,
    data: state.sortsManager.dash.data.data,
    pagination: state.sortsManager.dash.data.pagination,
    loading: state.sortsManager.dash.pending,
    filterFormValues: sortsDashForm.getValues(state),
    updateLineItemsPending: sortsManagerServices.updateLineItems.getRequestPending(state),
    updateLineItemsError: sortsManagerServices.updateLineItems.getRequestError(state),
    findLineItemsData: sortsManagerServices.findLineItems.getData(state).data,
    findLineItemsPending: sortsManagerServices.findLineItems.getRequestPending(state),
    findLineItemsError: sortsManagerServices.findLineItems.getRequestError(state),
     
    findAuditLineItemsData: sortsManagerServices.findAuditLineItems.getData(state).data,
    findAuditLineItemsPending: sortsManagerServices.findAuditLineItems.getRequestPending(state),
    findAuditLineItemsError: sortsManagerServices.findAuditLineItems.getRequestError(state),
   
});

export default connect(mapStateToProps, {
    fetchList: getLineItems(),
    findAuditLineItems: sortsManagerServices.findAuditLineItems.run,
    updateLineItems: sortsManagerServices.updateLineItems.run,
    findLineItems: sortsManagerServices.findLineItems.run,
    findLineItemsInvalidate: sortsManagerServices.findLineItems.invalidate,
    invalidate: () => ({ type: SORTS_DASHBOARD_INVALIDATE })
})(SortsManagerDashboard)
