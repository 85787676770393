import { Business } from '@material-ui/icons'
import Profile from '../views/Profile'
import Settings from '../views/Settings'
import { warehousePanel } from '../permissions'
import { WarehouseDashboard, WarehouseHistory} from "../views/SortsManager";

let warehouseManagerRoutes = [
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      hide: true,
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      hide: true,
    },
    {
      path: "/warehouseDashboard",
      name: "warehouseDashboard",
      icon: Business,
      component: WarehouseDashboard,
      perm: warehousePanel.panel,
    },
    {
      path: "/warehouseHistory",
      name: "warehouseHistory",
      icon: Business,
      component: WarehouseHistory,
      perm: warehousePanel.panel,
    },
  
    {redirect: true, path: "/", pathTo: "/warehouseDashboard", name: "warehouseDashboard"}
  ]


export default warehouseManagerRoutes
