import React, {Fragment} from "react"
import AttachMoney from '@material-ui/icons/AttachMoney'
import CreditCard from '@material-ui/icons/CreditCard'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

import AllOut from "@material-ui/icons/AllOut"
import DirectionsRun from "@material-ui/icons/DirectionsRun"
import DirectionsBike from "@material-ui/icons/DirectionsBike"
import DirectionsCar from "@material-ui/icons/DirectionsCar"
import Motorcycle from "@material-ui/icons/Motorcycle"
import moment from "moment/moment"
import Countdown from '../../components/Countdown/Countdown'
import Timelapse from '@material-ui/icons/Timelapse'
import AccessTime from '@material-ui/icons/AccessTime'

const courierIcons = {
  PEDESTRIAN: <DirectionsRun style={{height: 16, width: 16}}/>,
  BIKE: <DirectionsBike style={{height: 16, width: 16}}/>,
  MOTORCYCLE: <Motorcycle style={{height: 16, width: 16}}/>,
  CAR: <DirectionsCar style={{height: 16, width: 16}}/>,
  OTHER: <AllOut style={{height: 16, width: 16}}/>
}

const MobileTable = ({data, translate, classes, trClick, thClick, col6 }) =>
  <Paper className={classes && classes.root} style={{overflowX: "auto"}}>
    <Table className={classes && classes.table}>
      {
        data.map((d, i) =>
          <Fragment key={i}>
            {/*<TableHead onClick={() => thClick(d)}>*/}
              {/*<TableRow>*/}
                {/*<TableCell>{translate("provider")} </TableCell>*/}
                {/*<TableCell>{translate("receiver")} </TableCell>*/}
              {/*</TableRow>*/}
            {/*</TableHead>*/}
            <TableBody onClick={() => thClick(d)}>
              <TableRow>
                <TableCell>
                  <div>
                    {d.provider.name} <br/>
                    {d.provider.phoneNumber} <br/>
                    {d.provider.address}
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    {d.recipient.name} <br/>
                    {d.recipient.address} <br/>
                    {d.recipient.phone}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>

            {/*<TableHead onClick={() => thClick(d)}>*/}
              {/*<TableRow>*/}
                {/*<TableCell>{translate("status")} </TableCell>*/}
                {/*<TableCell>{translate("courier")} </TableCell>*/}
              {/*</TableRow>*/}
            {/*</TableHead>*/}

            <TableBody onClick={() => trClick(d.courier, d.id)}>
              <TableRow>
                <TableCell>
                  <div>
                    <span> {d.typeAndPrice.status} </span> <br/>
                    {d.typeAndPrice.type === "CASH"
                      ? <AttachMoney style={{width: 16, height: 16}}/>
                      : <CreditCard style={{width: 16, height: 16}}/>}
                    <span> {d.typeAndPrice.price}/{d.typeAndPrice.deliveryFee}  </span>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    <span> {courierIcons[d.courier && d.courier.transportationType]} </span>
                    {d.courier && d.courier.name}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>

            {
              col6 &&
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center' }}>
                      {<AccessTime/>} <span>{moment(d.receiveTime.receivedAt).format("hh:mm")}</span>
                      <span className={'divider-slash'} > / </span>
                      {<Timelapse/>}
                      {d.estimatedTime && d.estimatedTime.status === "ACCEPTED"
                        ? <Countdown eventTime={d.estimatedTime.pickupTime} translate={translate} />
                        : <span> --- </span>
                      }
                    </div>
                  </TableCell>
                  <TableCell>
                    {d.comment}
                  </TableCell>
                </TableRow>
              </TableBody>
            }

            <TableBody>
              <TableRow>
                <TableCell style={{ padding: 0 }}>
                  <hr className={'dividerHr'} />
                </TableCell>
                <TableCell style={{ padding: 0 }}>
                  <hr className={'dividerHr'} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Fragment>
        )
      }
    </Table>
  </Paper>

export default MobileTable