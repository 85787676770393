import {combineReducers} from "redux";
import {reduceAuthorization, verifyAuth} from "../ducks";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";

export default persistReducer({
  key: 'authorization.auth',
  storage,
  whitelist: ['auth']
}, combineReducers({
  auth: reduceAuthorization,
  verify: verifyAuth
}))