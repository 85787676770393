import React from 'react'
import {GridContainer, GridItem} from "../Grid";
import {Field, withForm} from "ductus";
import {bulkPackagesForm} from "../../forms";
import {ComboField} from "../fields";

class BulkPackages extends React.Component {

    render(){
        const { translate, providers, couriers, values, changeValue, isProvider, branches } = this.props;
        return(
            <GridContainer>
                <GridItem xs={12}>
                    {
                        !isProvider &&
                        <Field
                            name="providerId"
                            label={translate("provider")}
                            component={ComboField}
                            items={providers}
                        />
                    }
                    <Field
                        name="branchId"
                        label={translate('branch')}
                        component={ComboField}
                        items={isProvider ? branches : values.providerId ? providers.find(pr => pr.id === values.providerId).branches : [] }
                    />
                    {
                        !isProvider && <Field
                            name="courierId"
                            label={translate('courier')}
                            component={ComboField}
                            items={couriers}
                        />
                    }

                    <input type="file" name="file" onChange={(e) => {
                        changeValue('file', e.target.files[0])
                    }} />
                </GridItem>
            </GridContainer>
        )
    }
}

export default withForm(bulkPackagesForm)(BulkPackages)
