import React from 'react'
import FinanceFilter from './FinanceFilter'

import Accordion from '../../components/Accordion/Accordion'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'

// import columns from './columns'
import FinanceTable from './FinanceTable'

const GeneralFinances = ({translate, items, fetchItems, hubs, fetchHubs, addFinances, finances, financesLoading, name, remove, userInfo: { role } }) => {

  // const cols = columns(translate)[name]

  const financesKeys = Object.keys(finances)
  const financesMap = financesKeys.map((finKey, k) => ({
    title: finances[finKey].info.name,
    subTitle: `${finances[finKey].info.from} - ${finances[finKey].info.to}`,
    expanded: k === (financesKeys.length - 1),
    key: finKey,
    remove: () => remove(finKey),
    content:
      <Card style={{margin: 0}}>
        <CardBody>
          <FinanceTable
            data={finances[finKey].stats}
            cols={finances[finKey].headers}
            translate={translate}
          />
        </CardBody>
      </Card>
  }))
  return (
    <GridItem xs={12}>
      <FinanceFilter
        translate={translate}
        fetchHubs={fetchHubs}
        hubs={hubs}
        items={items}
        fetchItems={fetchItems}
        addFinances={addFinances}
        itemLabel={name}
        hub={(name === 'providers') || (name === 'couriers')}
        own={name === 'own'}
      />

      <GridContainer>
        <Accordion
          collapses={financesMap}
        />

      </GridContainer>
    </GridItem>
  )
}

export default GeneralFinances