import React, { Fragment } from "react";
import { withForm, Field } from "ductus";
import { dispatcherPackageForm } from "../../forms";
import {
  GridContainer,
  GridItem,
  ComboField,
  TextField,
  RadioGroupField,
  Button,
  DateTimeField,
} from "../../components";
import ReactTable from "react-table";
import {
  FiberManualRecord,
  CreditCard,
  AttachMoney,
  LockOpen,
  Face,
  Flag,
  Done,
  Lock,
} from "@material-ui/icons";
import { connect } from "react-redux";
import { districtServices } from "../../ducks/services";
import {
  providers,
  districts,
  branches,
  couriers,
  postServices,
  editPackageServices,
} from "../../ducks";

import { BrowserView, MobileView } from "react-device-detect";

import MobileDate from "../../components/fields/MobileDateTimeField";
import Checkbox from "@material-ui/core/Checkbox";
import branch from "../../forms/fields/branch";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const blurred = {
  position: "absolute",
  backgroundColor: "rgba(255,255,255, 0.35)",
  width: "calc(100% - 30px)",
  height: "calc(100% - 60px)",
  zIndex: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
// let getPostData = []
class DispatcherCreatePackage extends React.Component {
  state = {
    branches: [],
    branchDisabled: true,
    disabled: {
      provider: true,
      receiver: true,
      pay: true,
      listOfItems: true,
    },
    selectedItems: [],
    itemMetrics: [],
    orderItemId: "",
    itemMetricId: [],
    getProviderDistricts: [],
  };
  dataUpdated = false;
  itemsDataUpdated = false;

  componentWillUnmount() {
    this.props.changeFormValues({
      postProviderId: null,
      approxDeliveryTime: null,
      approxPickupTime: null,
      comment: null,
      courier: null,
      courierFee: null,
      courierId: null,
      deliveredAt: null,
      deliveryDistrictId: null,
      deliveryFee: null,
      extraDeliveryFee: null,
      extraFee: null,
      packagePrice: null,
      pickupAddress: null,
      pickupDistrictId: null,
      postProvider: null,
      providerId: null,
      receivedAt: null,
      recipientAddress: null,
      recipientCash: null,
      recipientName: null,
      recipientPhoneNumber: null,
      rejectedAt: null,
      statusReason: null,
      takenAt: null,
      visibleAfter: null,
    });
    this.setState({
      branches: [],
      branchDisabled: true,
      disabled: {
        provider: true,
        receiver: true,
        pay: true,
        listOfItems: true,
      },
      selectedItems: [],
      itemMetrics: [],
      orderItemId: "",
      itemMetricId: [],
      getProviderDistricts: [],
    });
  }

  componentDidMount() {
    const {
      fetchProviders,
      fetchDistricts,
      fetchCouriers,
      fetchPostdata,
      values,
      changeFormValues,
    } = this.props;
    fetchProviders({ query: { "without-pagination": 1 } });
    fetchDistricts({ query: { "without-pagination": 1 } });
    fetchCouriers({ query: { "without-pagination": 1 } });
    fetchPostdata({ query: { "without-pagination": 1 } });

    if (this.props.providers && values.providerId) {
      this.providerSelectionChange(values.providerId);
    }
  }

  // (key === 'Enter') && this.props.handleSubmit(this.props.values)
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { values, providers } = this.props;

    if (
      (!this.dataUpdated && values.providerId && providers) ||
      (providers && prevProps.values.providerId !== values.providerId)
    ) {
      this.dataUpdated = true;
      this.providerSelectionChange(values.providerId);
    }

    if (
      values &&
      values.items &&
      values.items !== prevProps.values.items &&
      !this.itemsDataUpdated
    ) {
      const selectedItems = [];
      this.itemsDataUpdated = true;
      values.items.forEach((v) => {
        selectedItems.push({
          id: v.id,
          note: v.comment ? v.comment.note : "",
          quantity: v.changedQuantity,
          selected: v.status === "courier_picked",
          status: v.status,
        });
        // selectedItems.push({id: v.id, note: v.comment ? v.comment.note : "", quantity: v.changedQuantity || v.quantity, selected: v.status === "courier_picked", status: v.status})
      });
      this.setState({ selectedItems });
    }

    if (values && values.items && values.items !== prevProps.values.items) {
      const itemMetrics = {};

      values.items.forEach((v) => {
        itemMetrics[v.id] = v.metric && v.metric.id;
      });

      this.setState({ itemMetrics });

      this.props.getProviderDistricts({
        params: { path: `districts` },
        query: {
          "without-pagination": 1,
          provider_id: this.props.values.providerId,
        },
      });
    }
  }

  /*componentWillReceiveProps(nextProps) {
    const {providersLoading, branchesLoading, fetchBranches} = this.props
    if (providersLoading && !nextProps.providersLoading) {
      fetchBranches({
        params: {path: `/providers/${nextProps.values.providerId}/branches`},
        query: {'without-pagination': 1}
      })
    }

    if (branchesLoading && !nextProps.branchesLoading) {
      this.setState({
        branches: nextProps.branches,
        branchesDisabled: false
      })
    }
  }*/

  onButtonClick = (name) =>
    this.setState({
      disabled: { ...this.state.disabled, [name]: !this.state.disabled[name] },
    });

  providerSelectionChange = (val) => {
    const provider = this.props.providers.filter((p) => p.id === val)[0];
    const { branches, deliveryFee } = provider;

    const { changeFormValue } = this.props;

    changeFormValue("deliveryFee", deliveryFee || "");

    if (branches.length) {
      const defaultSelectedId = this.props.values.branchId
        ? this.props.values.branchId
        : branches[0].id;
      //const defaultSelectedId = provider.defaultBranch ? branches[provider.defaultBranch].id : branches[0].id
      changeFormValue("branchId", defaultSelectedId);
      this.branchSelectionChange(defaultSelectedId, branches);
    } else {
      this.branchSelectionChange(null, branches);
    }
    this.setState({
      branches,
      branchDisabled: false,
    });
  };

  branchSelectionChange = (val, passedBranches, comboField = false) => {
    const branches = passedBranches || this.state.branches;
    const branch = branches.filter((b) => b.id === val)[0];
    const { changeFormValues } = this.props;

    if (branch && comboField) {
      const newValues = {};
      if (branch.districtId) {
        newValues.pickupDistrictId = branch.districtId;
      }
      /*if(branch.address) {
        newValues.pickupAddress = branch.address;
      }*/
      changeFormValues(newValues);
    }
  };

  selectedStatusChange = (event, id) => {
    let selectedItems = [...this.state.selectedItems];
    const it = selectedItems.find((item) => id === item.id);
    if (it) {
      it.selected = !it.selected;
      // selectedItems = selectedItems.map(item => {
      //   return {...item, selected: !item.selected,  }
      // })
    }
    this.setState({ selectedItems: selectedItems });
  };

  saveItems = (e) => {
    this.state.selectedItems.map((item) => {
      this.props.updateLineItems(
        { params: { path: `v3/line-items/${item.id}` } },
        {
          status: item.selected ? "courier_picked" : "courier_missed",
        }
      );

      this.props.updateLineItems(
        { params: { path: `v3/line-items/${item.id}` } },
        {
          // selectedItems: this.state.selectedItems,
          // status: item.selected ? "courier_picked" : "courier_missed",
          quantity: item.quantity,
          note: item.note,
        }
      );
    });

    setTimeout(() => {
      this.itemsDataUpdated = false;
    }, 1000);
  };

  handleChange = (event, itemId) => {
    const itemMetrics = { ...this.state.itemMetrics };
    itemMetrics[itemId] = event.target.value;

    this.setState({
      itemMetrics,
      orderItemId: itemId,
      // itemMetricIndex: event.target.value
    });
    const newItemMetricId = {
      itemId: itemId,
      itemMetricId: event.target.value,
    };

    this.setState({
      itemMetricId: [...this.state.itemMetricId, newItemMetricId],
    });
  };

  saveItemMetrics = () => {
    const { updateLineItems } = this.props;
    // const { updateLineItems, values } = this.props;
    // let itemId = this.state.orderItemId;
    // let itemId = values && values.items.map( item => item.id );
    /*
        let metricsId = this.state.itemMetrics;    
        const itemMetricResult = Object.keys(metricsId).map((key) => metricsId[key]);
        let itemMetricsId = itemMetricResult;
    */
    // let itemMetricsId = this.state.itemMetricIndex;

    this.state.itemMetricId.map((item) =>
      updateLineItems({
        params: {
          path: `/v3/line-items/${item.itemId}/item-metrics/${item.itemMetricId}`,
        },
      })
    );
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.props.handleSubmit(this.props.values);

    this.setState({
      disabled: {
        provider: true,
        receiver: true,
        pay: true,
        listOfItems: true,
        selectedItems: [],
      },
    });
  };

  render() {
    const {
      providers,
      districts,
      getProviderDistricts,
      translate,
      couriers,
      couriersLoading,
      values,
    } = this.props;
    const { branches, disabled } = this.state;
    const activeTabe = this.props.activeTabe;

    let cou = couriers.map((c) => ({
      ...c,
      name: c.isWorking ? (
        <span className={"flexSpan"}>
          <FiberManualRecord
            style={{ color: "green", width: 12, height: 12, marginRight: 5 }}
          />{" "}
          {c.name}
        </span>
      ) : (
        <span className={"flexSpan"}>
          <FiberManualRecord
            style={{ opacity: 0, width: 12, height: 12, marginRight: 5 }}
          />{" "}
          {c.name}
        </span>
      ),
      n: c.name,
    }));

    cou =
      !cou.find((c) => c.id === values.courierId) && values.courierId
        ? [
            {
              ...values.courier,
              name: (
                <span className={"flexSpan"}>
                  <FiberManualRecord
                    style={{
                      ...(values.courier.isWorking
                        ? { color: "green" }
                        : { opacity: 0 }),
                      width: 12,
                      height: 12,
                      marginRight: 5,
                    }}
                  />
                  {values.courier.name}
                </span>
              ),
            },
          ]
        : cou;

    let columns = [
      {
        Header: translate("itemName"),
        accessor: "title",
        sortable: false,
        width: 300,
      },
      {
        Header: translate("price"),
        accessor: "price",
        sortable: false,
        width: 80,
      },
      {
        Header: activeTabe === 1 && translate("itemMetrics"),
        id: "itemMetrics",
        sortable: false,
        width: activeTabe === 1 ? 180 : 0,
        accessor: (v) => {
          /* v.provider.id - 1 aris 'extra.ge' */
          return (
            <>
              {" "}
              {v.provider.id !== 1 && activeTabe === 1 && (
                <Select
                  className=" mu_select style mg0"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.itemMetrics[v.id]}
                  onChange={(e) => this.handleChange(e, v.id)}
                >
                  {v.provider.itemMetrics &&
                    v.provider.itemMetrics.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        className="mu_select item"
                      >
                        <p className="col_text_note">{item.name}</p>
                        <p className="col_text_time">
                          <span className="col_text_role">{item.price}</span>
                        </p>
                      </MenuItem>
                    ))}
                </Select>
              )}
            </>
          );
        },
      },
      {
        Header: translate("quantity"),
        sortable: false,
        width: 120,
        id: "quantity",
        accessor: (v) => {
          const selectedItem = this.state.selectedItems.find(
            (item) => v.id === item.id
          );
          const firstQuantity = v.quantity; // v.changedQuantity || v.quantity
          let quantity = v.quantity;

          if (selectedItem) {
            quantity = selectedItem.quantity;
          }
          let lastQuantity =
            activeTabe == 0 ? (
              <input
                type="number"
                onChange={(e) => {
                  const selectedItems = [...this.state.selectedItems];
                  let selectedItemIndex = selectedItems.findIndex(
                    (item) => item.id === v.id
                  );
                  if (selectedItems[selectedItemIndex]) {
                    selectedItems[selectedItemIndex].quantity = Math.min(
                      parseInt(e.target.value),
                      firstQuantity
                    );
                  }
                  this.setState({ selectedItems });
                }}
                min={0}
                value={quantity}
                max={firstQuantity}
                className="quantity_input"
              />
            ) : (
              <span>
                {v.changedQuantity !== null ? v.changedQuantity : v.quantity}
              </span>
            );

          return <div>{lastQuantity}</div>;
        },
      },
      {
        Header: translate("Order ID"),
        accessor: "orderId",
        sortable: false,
        width: 80,
      },
      {
        Header: translate("comment"),
        sortable: false,
        width: 150,
        id: "comment",
        accessor: (v) => {
          const selectedItem = this.state.selectedItems.find(
            (item) => v.id === item.id
          );
          let note = v.comment !== null ? v.comment.note : " ";
          if (selectedItem) {
            note = selectedItem.note;
          }
          let lastNote =
            activeTabe == 0 ? (
              <textarea
                name="note"
                onChange={(e) => {
                  const selectedItems = [...this.state.selectedItems];
                  let selectedItemIndex = selectedItems.findIndex(
                    (item) => item.id === v.id
                  );
                  if (selectedItems[selectedItemIndex]) {
                    selectedItems[selectedItemIndex].note = e.target.value;
                  }
                  this.setState({ selectedItems });
                }}
                min={0}
                value={note}
                rows="3"
                cols="20"
                className="note_Style"
              ></textarea>
            ) : (
              <span>{note}</span>
            );

          return <div>{lastNote}</div>;
        },
      },
      // {
      //   Header: translate('actions'),
      //   accessor: "actions",
      //   width: 80,
      //   sortable: false,
      // }
    ];

    if (activeTabe === 0) {
      columns.unshift({
        Header: translate(""),
        id: "selected",
        sortable: false,
        width: 50,
        accessor: (v) => {
          return (
            <Checkbox
              // checked={v.status === "courier_picked"}
              checked={
                !!this.state.selectedItems.find(
                  (item) => v.id === item.id && item.selected
                )
              }
              onChange={(event) => this.selectedStatusChange(event, v.id)}
              inputProps={{ "aria-label": "disabled checked checkbox" }}
            />
          );
        },
      });
    }

    return (
      <GridContainer>
        <form onSubmit={this.handleSubmitForm} className={"good-form"}>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Fragment>
              <Field
                name={"code"}
                label={"code"}
                component={TextField}
                inputProps={{
                  disabled: true,
                }}
                icon={Face}
                iconPosition={"start"}
              />

              <Field
                name={"receivedAt"}
                label={"receivedAt"}
                component={TextField}
                inputProps={{
                  disabled: true,
                }}
                icon={Face}
                iconPosition={"start"}
              />

              <Field
                name={"takenAt"}
                label={"takenAt"}
                component={TextField}
                inputProps={{
                  disabled: true,
                }}
                icon={Flag}
                iconPosition={"start"}
              />

              <Field
                name={"deliveredAt"}
                label={"deliveredAt"}
                component={TextField}
                inputProps={{
                  disabled: true,
                }}
                icon={Done}
                iconPosition={"start"}
              />
            </Fragment>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <legend>
              {" "}
              {translate("payment")}
              <Button
                simple
                size={"sm"}
                justIcon
                round
                color={"primary"}
                onClick={() => this.onButtonClick("pay")}
              >
                {" "}
                {disabled.pay ? <Lock /> : <LockOpen />}{" "}
              </Button>
            </legend>
            <Fragment>
              {disabled.pay && <div style={blurred}></div>}
              <GridContainer
                style={{ alignItems: "center", marginBottom: 9, marginTop: 13 }}
              >
                {/*<GridItem xs={4}>*/}
                {/*<small style={{color: '#AAAAAA'}}> {translate('paymentType/money')} </small>*/}
                {/*</GridItem>*/}

                <GridItem xs={12}>
                  <BrowserView>
                    <Field
                      name={"visibleAfter"}
                      label={translate("preOrder")}
                      timeFormat="HH:mm"
                      dateFormat="DD-MM-YYYY"
                      component={DateTimeField}
                    />
                  </BrowserView>

                  <MobileView>
                    <Field
                      name={"visibleAfter"}
                      label={translate("preOrder")}
                      component={MobileDate}
                      timeFormat="HH:mm"
                      dateFormat="DD-MM-YYYY"
                      confirmText={translate("set")}
                      cancelText={translate("cancel")}
                    />
                  </MobileView>
                </GridItem>

                <GridItem
                  xs={12}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Field
                    name={"paymentType"}
                    label={translate("paymentType")}
                    component={RadioGroupField}
                    items={[
                      { id: "CASH", name: <AttachMoney /> },
                      { id: "CREDIT", name: <CreditCard /> },
                    ]}
                    containerStyles={{
                      maxWidth: 150,
                    }}
                  />

                  <Field
                    name={"packagePrice"}
                    label={translate("money")}
                    component={TextField}
                    formControlProps={{
                      style: { minWidth: 60, flex: 1 },
                    }}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    name={"deliveryFee"}
                    label={translate("deliveryFee")}
                    component={TextField}
                    // inputProps={{
                    //   disabled: true
                    // }}
                  />
                </GridItem>
                {/* <GridItem xs={6}>
                  <Field
                    name={'extraFee'}
                    label={translate('extra')}
                    component={TextField}
                    disabled={false}
                  />
                </GridItem> */}

                {/* <GridItem xs={6}>
                  <Field
                    name={'extraDeliveryFee'}
                    label={translate('extraDelivery')}
                    component={TextField}
                    disabled={false}
                  />
                </GridItem> */}
              </GridContainer>
            </Fragment>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <legend>
              {" "}
              {translate("provider")}
              <Button
                simple
                size={"sm"}
                justIcon
                round
                color={"primary"}
                onClick={() => this.onButtonClick("provider")}
              >
                {" "}
                {disabled.provider ? <Lock /> : <LockOpen />}{" "}
              </Button>
            </legend>
            <Fragment>
              {disabled.provider && <div style={blurred}></div>}
              <Field
                name={"providerId"}
                label={translate("provider")}
                component={ComboField}
                items={providers || []}
                selectionChange={this.providerSelectionChange}
              />

              <Field
                name={"branchId"}
                label={translate("branch")}
                component={ComboField}
                items={branches || []}
                selectionChange={(val, passedBranches) =>
                  this.branchSelectionChange(val, passedBranches, true)
                }
              />

              {values.providerId &&
                providers &&
                providers.find((p) => values.providerId === p.id)[
                  "packages_select_pickup_district"
                ] && (
                  <Field
                    name={"pickupDistrictId"}
                    label={translate("pickupDistrict")}
                    component={ComboField}
                    items={districts || []}
                  />
                )}

              <Field
                name={"pickupAddress"}
                label={translate("pickupAddress")}
                component={TextField}
              />
            </Fragment>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <legend>
              {translate("receiver")}
              <Button
                simple
                size={"sm"}
                justIcon
                round
                color={"primary"}
                onClick={() => this.onButtonClick("receiver")}
              >
                {" "}
                {disabled.receiver ? <Lock /> : <LockOpen />}
              </Button>
            </legend>

            <Fragment>
              {disabled.receiver && <div style={blurred}></div>}

              {/* {
                values.providerId && providers && providers.find(p => values.providerId === p.id)['packages_select_delivery_district'] && */}
              <Field
                name={"deliveryDistrictId"}
                label={translate("deliveryDistrict")}
                component={ComboField}
                // items={districts}
                items={
                  values.providerId !== 1
                    ? this.props.getProviderDistrictsData
                    : districts
                }
              />
              {/* } */}
              <Field
                name={"recipientName"}
                label={translate("recipientName")}
                component={TextField}
              />

              <Field
                name={"recipientPhoneNumber"}
                label={translate("recipientPhone")}
                component={TextField}
              />

              <Field
                name={"recipientAddress"}
                label={translate("recipientAddress")}
                component={TextField}
              />
            </Fragment>
          </GridItem>

          {activeTabe === 1 && (
            <GridItem xs={12}>
              <Field
                name={"customerComment"}
                label={translate("customerComment")}
                component={TextField}
                inputProps={{
                  multiline: true,
                  rows: 3,
                }}
              />
            </GridItem>
          )}

          <GridItem xs={12}>
            <Field
              name={"comment"}
              label={translate("comment")}
              component={TextField}
              inputProps={{
                multiline: true,
                rows: 3,
              }}
            />
          </GridItem>
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <GridContainer>
              <GridItem xs={activeTabe === 1 ? 4 : 6}>
                <Field
                  name={"status"}
                  label={translate("status")}
                  component={ComboField}
                  items={[
                    { id: "PROCESSING", name: "PROCESSING" },
                    { id: "ACCEPTED", name: "ACCEPTED" },
                    { id: "AT_PROVIDER", name: "AT_PROVIDER" },
                    { id: "PICKED_UP", name: "PICKED_UP" },
                    { id: "AT_RECIPIENT", name: "AT_RECIPIENT" },
                    { id: "DELIVERED", name: "DELIVERED" },
                    { id: "REJECTED", name: "REJECTED" },
                  ]}
                />
              </GridItem>
              <GridItem xs={activeTabe === 1 ? 4 : 6}>
                <Field
                  name={"courierId"}
                  label={translate("courier")}
                  component={ComboField}
                  items={cou}
                />
              </GridItem>

              {activeTabe === 1 && (
                <GridItem xs={4}>
                  <Field
                    name={"postProviderId"}
                    label={translate("postProvider")}
                    component={ComboField}
                    items={this.props.postData.map((item) => ({
                      id: item.id,
                      name: item.title,
                    }))}
                  />
                </GridItem>
              )}
            </GridContainer>

            {values.status === "REJECTED" && (
              <Field
                name={"statusReason"}
                label={"statusReason"}
                component={TextField}
              />
            )}
          </GridItem>
          <GridItem xs={12}>
            {activeTabe === 0 && (
              <legend>
                {" "}
                {translate("listOfItems")}
                <Button
                  simple
                  size={"sm"}
                  justIcon
                  round
                  color={"primary"}
                  disabled={values.status === "yes"}
                  onClick={() => this.onButtonClick("listOfItems")}
                >
                  {disabled.listOfItems ? <Lock /> : <LockOpen />}
                </Button>
              </legend>
            )}
            {activeTabe === 0 && disabled.listOfItems && (
              <div style={blurred}></div>
            )}

            <ReactTable
              // data={values.items && values.items.map(item => ({
              //   ...item,
              //   // actions: this.makeActions(item)
              // }))}
              data={values && values.items}
              noDataText={translate("itemsNotFound")}
              loading={false}
              defaultPageSize={(values.items || []).length || 4}
              pageSize={(values.items || []).length || 4}
              manual
              columns={columns}
              showPagination={false}
              className="-striped -highlight"
            />

            {activeTabe === 0 && (
              <div className="save__BTN">
                <Button onClick={() => this.saveItems()} color={"primary"}>
                  ცვლილებების შენახვა
                </Button>
              </div>
            )}
            {activeTabe === 1 && (
              <div className="save__BTN">
                <Button
                  onClick={() => this.saveItemMetrics()}
                  color={"primary"}
                >
                  ცვლილებების შენახვა
                </Button>
              </div>
            )}
          </GridItem>
          <button type={"submit"} style={{ display: "none" }}>
            b
          </button>
        </form>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  providers: providers.getList(state).data,
  districts: districts.getList(state).data,
  branches: branches.getList(state).data,
  couriersLoading: couriers.isListRequestPending(state),
  couriers: couriers.getList(state).data,
  providersLoading: providers.isListRequestPending(state),
  branchesLoading: branches.isListRequestPending(state),

  postData: postServices.postProviders.getData(state),

  updateLineItemsPending:
    editPackageServices.updateLineItems.getRequestPending(state),
  updateLineItemsError:
    editPackageServices.updateLineItems.getRequestError(state),
  getProviderDistrictsData:
    districtServices.getProviderDistricts.getData(state).data,
});

export default connect(mapStateToProps, {
  fetchBranches: branches.fetchList,
  fetchProviders: providers.fetchList,
  fetchDistricts: districts.fetchList,
  fetchCouriers: couriers.fetchList,
  changeFormValue: dispatcherPackageForm.edit.changeValue,
  changeFormValues: dispatcherPackageForm.edit.changeValues,
  fetchPostdata: postServices.postProviders.run,
  updateLineItems: editPackageServices.updateLineItems.run,
  getProviderDistricts: districtServices.getProviderDistricts.run,
})(withForm(dispatcherPackageForm.edit)(DispatcherCreatePackage));
