import {createGroup} from "./createGroup";
import {transformPackResponse, createResource } from "../../helpers";

export default (name, additionalHandlers = {} ) => createGroup({
  statePath: `dashboard.${name}`,
  name: name,
  generic: {
    initialData: {
      data: [],
    },
    initiallyPending: true,
    transformData: (response) => transformPackResponse(response, true)
  },
  additionalHandlers,
  createResource: createResource
})
