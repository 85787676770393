
import {withGoogleMap, withScriptjs} from "react-google-maps";
import React from "react";
import MyGoogleMap from './MyGoogleMap'

export default withScriptjs(
  withGoogleMap(({children, ...props}) => (
    <MyGoogleMap {...props} >
      {children}
    </MyGoogleMap>
  ))
);