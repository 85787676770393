
import React from 'react'
import {Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core'


const FinanceTable = ({ data, translate, cols, classes }) =>
  <Paper className={classes && classes.root} style={{ overflowX: 'auto' }}>
    <Table className={classes && classes.table}>
      <TableHead>
        <TableRow>
          {
            cols.map( col =>
              <TableCell > {translate(col)}</TableCell>
            )
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map( (row, key) => (
          <TableRow key={key}>
            {
              cols.map( col =>
                <TableCell numeric={true}> {row[col]} </TableCell>
              )
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Paper>

export default FinanceTable