import {createForm} from "ductus"
import {transformErrorToFieldsErrors} from "../helpers"
import {validateNumber} from './validators'
import {transformToFloatingNumber, transformToNumber} from './transformers'

export default createForm({
  name: 'tariffsForm',
  statePath: `forms.tariffsForm`,
  errorActionTypes: ["storeTariffs/ERROR"],
  fields: {
    deliveryFee: {
      validate: validateNumber,
      transform: transformToFloatingNumber
    },
    courierFee: {
      validate: validateNumber,
      transform: transformToFloatingNumber
    },
    approxTime: {
      validate: validateNumber,
      transform: transformToNumber
    },
    fromDistrictId: {},
    toDistrictId: {}
  },
  transformError: transformErrorToFieldsErrors,
})