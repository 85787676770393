import React from 'react'
import {Field, withForm} from 'ductus'
import { emailChangeForm } from '../../forms'
import {GridItem, TextField, Button} from '../../components/index'

const EmailChange = ({translate, handleSubmit, disabled, isValid}) => (
  <form onSubmit={(e) => {
    e.preventDefault()
    handleSubmit()
  }} autoComplete="off"
  >
    <input type={'text'} name={'email'}
           style={{height: 0, overflow: 'hidden', padding: 0, margin: 0, border: 'none'}}/>
    <input type={'password'} name={'password'}
           style={{height: 0, overflow: 'hidden', padding: 0, margin: 0, border: 'none'}}/>

    <GridItem xs={12}>
      <Field
        name={'email'}
        label={translate('email')}
        component={TextField}
      />
      <Field
        name={'password'}
        label={translate('password')}
        component={TextField}
        type={'password'}
        showSuccess={false}
      />

      <Button
        color={'primary'}
        type={'submit'}
        disabled={disabled || !isValid}
      >
        {translate('update')}
      </Button>
    </GridItem>

  </form>
)

export default withForm(emailChangeForm)(EmailChange)
