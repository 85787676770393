import {validateExistence, validateNumber, validatePhoneNumber} from "../validators"
import {combineValidators} from 'ductus'
import {transformNumber, transformToNumber, transformValueLengthExact} from "../transformers"
import {compose} from "ductus/build/index"
export default ({
  providerId: {
    validate: validateExistence
  },
  branchId: {
    validate: validateExistence
  },
  courierId: {},
  pickupDistrictId: {
    validate: validateExistence
  },
  pickupAddress: {
    validate: validateExistence
  },
  deliveryDistrictId: {},
  recipientName: {
    validate: validateExistence
  },
  recipient_name: {
    validate: validateExistence
  },
  recipientPhoneNumber: {
    transform: compose(
      transformToNumber,
      transformValueLengthExact(9)
    ),
    validate: combineValidators(
      validatePhoneNumber,
      validateExistence
    ),
  },
  recipient_phone_number: {
    transform: compose(
      transformToNumber,
      transformValueLengthExact(9)
    ),
    validate: combineValidators(
      validatePhoneNumber,
      validateExistence
    ),
  },
  recipientAddress: {
    validate: validateExistence
  },
  recipient_address: {
    validate: validateExistence
  },
  city: {},
  title: {},
  quantity: {},
  price: {
    validate: combineValidators(
      validateNumber,
      validateExistence
    )
  },

  recipientCash: {
    transform: transformNumber
  },
  comment: {},
  paymentType: {
    initialValue: 'CASH'
  },
  packagePrice: {
    validate: combineValidators(
      validateNumber,
      validateExistence
    )
  },
  visibleAfter: {},
})