import {
  AUTHORIZATION_INVALIDATE_TOKEN,
  AUTHORIZATION_RECEIVE_ERROR,
  AUTHORIZATION_RECEIVE_TOKEN,
  AUTHORIZATION_REQUEST,

  AUTHORIZATION_VERIFY,
  AUTHORIZATION_VERIFIED,
  AUTHORIZATION_NOT_VERIFIED
} from "./actionTypes"
import {createRequestReducer} from "crud-factory"

export const verifyAuth = createRequestReducer({
  begin: AUTHORIZATION_VERIFY,
  success: AUTHORIZATION_VERIFIED,
  error: AUTHORIZATION_NOT_VERIFIED,
  // invalidate: AUTHORIZATION_INVALIDATE_TOKEN,
  dataKey: 'verified',
  transformData: (response) => response.body.data.token,
  transformError: (error) => error.code
})

export default createRequestReducer({
  begin: AUTHORIZATION_REQUEST,
  success: AUTHORIZATION_RECEIVE_TOKEN,
  error: AUTHORIZATION_RECEIVE_ERROR,
  invalidate: AUTHORIZATION_INVALIDATE_TOKEN,
  dataKey: 'token',
  transformData: (response) => response.body.data.token,
  transformError: (error) => error
})

