import {createRequestReducer, createDotSelector} from 'crud-factory'
import * as rNet from 'redux-net'

export const createGroup = ({
                                name,
                                resource,
                                initialData,
                                transformData,
                                requestName,
                                postRequest,
                                type = 'get',
                                statePath = name,
                                generic = {},
                                createResource = () => resource,
                                additionalActionTypes,
                                additionalActionCreators,
                                additionalSelectors,
                                additionalHandlers,
                                headers = {}
                            }) => {
    const select = createDotSelector(statePath)

    const REQUEST = generic.begin || name + '/REQUEST'
    const SUCCESS = generic.success || name + '/SUCCESS'
    const ERROR = generic.error || name + '/ERROR'
    const INVALIDATE = generic.invalidate || name + '/INVALIDATE'

    return {

        REQUEST: REQUEST,
        SUCCESS: SUCCESS,
        ERROR: ERROR,
        ...additionalActionTypes,

        [requestName || 'run']: (request = {
            params: {},
            query: {}
        }, body) => rNet[type](createResource(request.params), {
            begin: REQUEST,
            success: SUCCESS,
            error: ERROR,
            body,
            query: request.query,
            headers
        }),
        [postRequest || 'post']: (request = {
            params: {},
            query: {}
        }, body) => rNet['post'](createResource(request.params), {
            begin: REQUEST,
            success: SUCCESS,
            error: ERROR,
            body,
            query: request.query,
        }),

        invalidate: () => ({ type: INVALIDATE }),

        ...additionalActionCreators,

        getData: (state) => select(state).data,
        getRequestPending: (state) => select(state).pending,
        getRequestError: (state) => select(state).error,
        ...additionalSelectors,

        reduce: createRequestReducer({
            begin: REQUEST,
            success: SUCCESS,
            error: ERROR,
            invalidate: INVALIDATE,
            transformData: transformData || generic.transformData,
            initialData: initialData || generic.initialData,
            initiallyPending: generic.initiallyPending,
            additionalHandlers
        })
    }
}

export const createGroups = (division, generic) =>
    Object.keys(division).reduce((reduction, group) => ({
        ...reduction,
        [group]: createGroup({
            ...generic,
            ...division[group]
        })
    }), {})


export const createServiceGroup = ({items, generic, name}) => Object.keys(items).reduce((reduction, service) => ({
    ...reduction,
    [service]: createGroup({
        ...generic,
        statePath: `${name}.${service}`,
        ...items[service]
    })
}), {})
