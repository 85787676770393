import React, { Fragment } from "react"
import CustomInput from "../CustomInput/CustomInput"
import {withStyles, InputAdornment} from "@material-ui/core"

import customInputStyle from '../../assets/jss/material-dashboard-pro-react/components/customInputStyle'

const style = {
  ...customInputStyle,
  errorClass: {
    color: 'red',
    transition: 'all .2s ease-in'
  }
}


const TextField = ({name, value, errors, onChange, required, showSuccess = true, type = 'text', id = '', label = '', icon: Icon, iconPosition = 'end', formControlProps = { fullWidth: true}, inputProps = {}, classes}) => (
  <Fragment>
    <CustomInput
      success={showSuccess && value && !errors.length}
      error={!!errors.length}
      labelText={label ? (
        <span>
          {label}
        </span>
      ): undefined}
      formControlProps={{...formControlProps, required}}
      inputProps={{
        type,
        name,
        autoComplete: 'off',
        value: value || '',
        onChange: e => onChange(e.target.value, e.target.name),
        [iconPosition + 'Adornment']: Icon && (
          <InputAdornment
            position={iconPosition}
            className={classes.inputAdornment}
          >
            <Icon className={classes.inputAdornmentIcon}/>
          </InputAdornment>
        ),
        ...inputProps,
      }}
    />
    <small className={classes.errorClass} style={{ opacity: errors.length ? 20  : 0}}> {errors.map( err =>  err.code + ', ')}</small>
  </Fragment>
)

export default withStyles(style)(TextField)
