
import React from 'react'
import { financeServices } from '../../ducks/services'
import { connect } from 'react-redux'

import GeneralFinances from './GeneralFinances'

const remove = (payload) => financeServices.own.removeFinance( financeServices.own.REMOVE_FINANCE, payload)

const mapStateToProps = (state) => ({
  finances: financeServices.own.getData(state),
  fiancesLoading: financeServices.own.getRequestPending(state),
  name: 'own',
})
const mapDispatchToProps = {
  addFinances : financeServices.own.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralFinances)