

import React from 'react'
import {couriers, hubs} from '../../ducks'
import financeServices  from '../../ducks/services/finances'
import { connect } from 'react-redux'

import GeneralFinances from './GeneralFinances'

const remove = (payload) => financeServices.couriers.removeFinance( financeServices.couriers.REMOVE_FINANCE, payload)


const mapStateToProps = (state) => ({
  items: couriers.getList(state).data,
  hubs: hubs.getList(state).data,
  finances: financeServices.couriers.getData(state),
  financesLoading: financeServices.couriers.getRequestPending(state),
  name: 'couriers',
})
const mapDispatchToProps = {
  fetchItems: couriers.fetchList,
  fetchHubs: hubs.fetchList,
  addFinances: financeServices.couriers.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralFinances)