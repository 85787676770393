import React from 'react'
import {GridContainer, GridItem} from "../Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    cell: {
        // backgroundColor: theme.palette.error.main
    },
});

class ExcelErrors extends React.Component {

    render(){
        const { data, classes } = this.props

        return (
          <Paper className={classes.root}>
              <Table className={classes.table}>
                  <TableHead>
                      <TableRow>
                          <TableCell numeric component="th" TableCellscope="row">პოზიცია</TableCell>
                          {
                              Object.keys(data.fieldMap).map(fieldKey => (
                                <TableCell component="th" TableCellscope="row">{data.fieldMap[fieldKey]}</TableCell>
                              ))
                          }
                      </TableRow>
                  </TableHead>

                  <TableBody>
                      {
                          data.errors.map(error => {
                              const sheetRow = (
                                  <TableRow>
                                      <TableCell colSpan={7}>
                                          {error['sheet']}
                                      </TableCell>
                                  </TableRow>
                              )

                              return (
                                  <React.Fragment>
                                      {sheetRow}
                                      {error.errors.map(er => {
                                          const row = er.row
                                          const errors = er.errors
                                          return (
                                              (
                                                  <TableRow className={classes.row}>
                                                      <TableCell numeric>{er.index}</TableCell>
                                                      {
                                                          errors.recipient_name
                                                              ? (
                                                                  <Tooltip title={errors.recipient_name.join(', ')}>
                                                                      <TableCell className="error-cell">
                                                                          {row.recipient_name}
                                                                      </TableCell>
                                                                  </Tooltip>
                                                              )
                                                              : (
                                                                  <TableCell>
                                                                      {row.recipient_name}
                                                                  </TableCell>
                                                              )
                                                      }
                                                      {
                                                          errors.recipient_address
                                                              ? (
                                                                  <Tooltip title={errors.recipient_address.join(', ')}>
                                                                      <TableCell className="error-cell">
                                                                          {row.recipient_address}
                                                                      </TableCell>
                                                                  </Tooltip>
                                                              )
                                                              : (
                                                                  <TableCell>
                                                                      {row.recipient_address}
                                                                  </TableCell>
                                                              )
                                                      }
                                                      {
                                                          errors.recipient_phone_number
                                                              ? (
                                                                  <Tooltip title={errors.recipient_phone_number.join(', ')}>
                                                                      <TableCell className="error-cell">
                                                                          {row.recipient_phone_number}
                                                                      </TableCell>
                                                                  </Tooltip>
                                                              )
                                                              : (
                                                                  <TableCell>
                                                                      {row.recipient_phone_number}
                                                                  </TableCell>
                                                              )
                                                      }
                                                      {
                                                          errors.comment
                                                              ? (
                                                                  <Tooltip title={errors.comment.join(', ')}>
                                                                      <TableCell className="error-cell">
                                                                          {row.comment}
                                                                      </TableCell>
                                                                  </Tooltip>
                                                              )
                                                              : (
                                                                  <TableCell>
                                                                      {row.comment}
                                                                  </TableCell>
                                                              )
                                                      }
                                                      {
                                                          errors.payment_type
                                                              ? (
                                                                  <Tooltip title={errors.payment_type.join(', ')}>
                                                                      <TableCell className="error-cell">
                                                                          {row.payment_type}
                                                                      </TableCell>
                                                                  </Tooltip>
                                                              )
                                                              : (
                                                                  <TableCell>
                                                                      {row.payment_type}
                                                                  </TableCell>
                                                              )
                                                      }
                                                      {
                                                          errors.package_price
                                                              ? (
                                                                  <Tooltip title={errors.package_price.join(', ')}>
                                                                      <TableCell className="error-cell">
                                                                          {row.package_price}
                                                                      </TableCell>
                                                                  </Tooltip>
                                                              )
                                                              : (
                                                                  <TableCell>
                                                                      {row.package_price}
                                                                  </TableCell>
                                                              )
                                                      }

                                                  </TableRow>
                                              )
                                          )
                                      })}
                                  </React.Fragment>
                              )

                          })
                      }
                  </TableBody>
              </Table>
          </Paper>

        )
    }
}

export default withStyles(styles)(ExcelErrors)
