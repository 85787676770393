import React from 'react'
import {courierServices} from '../../ducks'
import {
  withStyles,
  InputAdornment, FormControlLabel, Radio,
} from '@material-ui/core'

import {FiberManualRecord, Search} from '@material-ui/icons'
import {CustomInput, GridContainer, GridItem} from '../../components'
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"
import {connect} from 'react-redux'
import loading from '../../assets/singleLoading.svg'
import {hasMatch, courierNumbers} from '../../helpers'

import AllOut from '@material-ui/icons/AllOut'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import DirectionsBike from '@material-ui/icons/DirectionsBike'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Motorcycle from '@material-ui/icons/Motorcycle'

const courierIcons = {
  PEDESTRIAN: <DirectionsRun style={{ height: 16, width: 16}} />,
  BIKE: <DirectionsBike style={{ height: 16, width: 16}} />,
  MOTORCYCLE: <Motorcycle style={{ height: 16, width: 16}} />,
  CAR: <DirectionsCar style={{ height: 16, width: 16}} />,
  OTHER: <AllOut style={{ height: 16, width: 16}} />
}

class AttachCourier extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      received: [],
      couriers: [],
      searchText: '',
      value: props.courier && props.courier.id,
    }
  }

  componentWillMount() {
    const couriers = courierNumbers(this.props.couriers)

    this.setState({
      couriers,
      received: couriers
    })
  }

  componentWillReceiveProps(nextProps) {

    if(this.props.couriers.length !== nextProps.couriers.length){
      const couriers = courierNumbers(nextProps.couriers)
      this.setState({
        received: couriers,
        couriers: nextProps.couriers.filter(({name}) => hasMatch(name, this.state.searchText )),
        value: (nextProps.courier && nextProps.courier.id) || this.state.value
      })
    } else if(nextProps.courier &&  nextProps.courier.id){
      this.setState({
        value:  nextProps.courier.id
      })
    }
  }

  onChange = (e) => this.setState({
    searchText: e.target.value,
    couriers: this.state.received.filter(({name}) => hasMatch(name, e.target.value))
  })

  onChangeId = (itemId) => {
    this.props.setCourierId(itemId)
    this.setState({
      value: itemId
    })
  }

  render() {
    const {classes, translate, attachCourierPending} = this.props;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <CustomInput
            labelText={
              <span>
                {translate('search')}
              </span>
            }
            // id="name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: this.onChange,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Search className={classes.inputAdornmentIcon}/>
                </InputAdornment>
              )
            }}
          />

          {
            this.state.couriers.map((item, key) =>
              <div
                key={key}
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  onDoubleClick={() => this.props.attach(item.id)}
                  control={
                    <Radio
                      checked={this.state.value === item.id}
                      onChange={() => this.onChangeId(item.id)}
                      value={item.id}
                      name={item.name}
                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord
                          className={classes.radioChecked}
                        />
                      }
                      classes={{
                        checked: classes.radio
                      }}
                    />
                  }
                  style={{
                    userSelect: 'none'
                  }}

                  classes={{
                    label: classes.label
                  }}
                  label={
                    <span style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                      {courierIcons[item.transportationType]} {item.name}
                    </span>}
                />
              </div>
            )
          }

          {attachCourierPending && <img src={loading} alt={'loading'} width={30} height={30}/>}
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  attachCourierPending: courierServices.assign.getRequestPending(state),
  couriers: courierServices.available.getData(state),
})

export default connect(mapStateToProps, {})(withStyles(customCheckboxRadioSwitch)(AttachCourier))
