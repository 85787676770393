import {validateExistence} from '../validators'

export default ({
  name: {
    validate: validateExistence
  },
  opensAt: {},
  closesAt: {},
  isClosed: {
    initialValue: false
  },
  closeReason: {},
  closeStartDate: {},
  closeEndDate: {},
  isActive: {
    initialValue: 1
  },
  daily: {
    initialValue: 0
  }
})