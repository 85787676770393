import React from 'react'

import {Dvr, Close} from '@material-ui/icons'
import ReactTable from 'react-table'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'
import ConfirmSweetAlert from '../../components/SweetAlert/SweetAlert'
import SnackBar from '../../components/Snackbar/Snackbar'
import { AddButton } from '../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"
import { hubForm } from "../../forms"
import CreateHubForm from './CreateHubForm'
import EditHubForm from './EditHubForm'
import {hubPermissions} from "../../permissions"

import ListComponent from '../ListComponent'
import HubFilterForm from './HubFilterForm'

class HubList extends ListComponent {

  makeActions = (item) => {
    const {resetEditionFormValues, fetchSinge, removeItem, translate, permissions} = this.props
    return (
      <div className="actions-right">
        {
          permissions[hubPermissions.update] &&
          <Button
            justIcon
            round
            simple
            onClick={() => {
              resetEditionFormValues() ||
              this.openFormModal({
                submitText: 'edit',
                form: hubForm.edit,
                submit: this.submitUpdate(item),
                Component: 'EditHubForm',
              })
              || fetchSinge(item.id)
            }}
            color="warning"
            className="edit"
          >
            <Dvr/>
          </Button>
        }
        {
          permissions[hubPermissions.delete] &&
          <Button
            justIcon
            round
            simple
            onClick={() => this.showAlert({
              title: translate('toDelete', {item: item.name}),
              confirmText: translate('delete'),
              cancelText: translate('close'),
              hideAlert: this.hideAlert,
              confirmFun: () => removeItem(item.id),
              warning: true
            })}
            color="danger"
            className="remove"
          >
            <Close/>
          </Button>
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.fetchList({query: {isActive: 1}})
  }

  render() {
    const {data, loading, storeItem, pagination, userInfo, history, translate, permissions} = this.props
    const {modal, alert, snackBar} = this.state
    const { hubs } = userInfo

    return (
      <GridItem xs={12} sm={12} md={12}>

        <AddButton
          hidden={!permissions[hubPermissions.create]}
          onClick={() => this.openFormModal({
            form: hubForm.create,
            submitText: 'add',
            submit: storeItem,
            Component: 'CreateHubForm',
          })}
        >
        </AddButton>

        <Card>
          <CardBody>
            <HubFilterForm history={history} translate={translate} userHubs={hubs} />
          </CardBody>
        </Card>

        <GridContainer>
          <Card>
            <CardBody>
              {

                <ReactTable
                  data={data && data.map(this.dataObject)}
                  loading={loading}
                  pages={pagination && pagination.totalPages}
                  manual
                  onPageChange={this.pageChanged}
                  defaultPageSize={ (pagination && pagination.perPage) || 5}
                  pageSize={pagination && pagination.perPage}

                  onSortedChange={this.onSortedChange}
                  page={this.state.pageIndex}
                  columns={[
                    {
                      Header: translate('name'),
                      accessor: "name"
                    },
                    {
                      Header: translate('actions'),
                      accessor: "actions"
                    }
                  ]}
                  previousText={translate('previousText')}
                  nextText={translate('nextText')}
                  loadingText={translate('loadingText')}
                  pageText={translate('pageText')}
                  ofText={translate('ofText')}
                  showPaginationBottom
                  showPageSizeOptions={false}
                  className="-striped -highlight"

                />
              }
            </CardBody>
          </Card>

        </GridContainer>
        {alert.open && <ConfirmSweetAlert {...alert} />}
        <SnackBar
          place={'br'}
          color="info"
          icon={AddAlert}
          message={snackBar.message}
          open={snackBar.openPos}
          closeNot={this.closeSnackBar}
          close
        />
      </GridItem>

    )
  }
}

export default HubList