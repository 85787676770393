import { combineReducers } from 'redux'
import forms from './forms/index'
import ducks from './ducks'
import localization from './localization'
import preferences from './preferences'
import authorization from "./authorization";
import sortsManager from "./sortsManager";
import sortsManagerHistory from "./sortsManagerHistory";
import modal from '../ducks/modal'
import {handleActions} from "redux-actions";
export default combineReducers({
  modal: modal.reduce,
  forms,
  ...ducks,
  localization,
  preferences,
  authorization,
  sortsManager,
  sortsManagerHistory,
  uploadLoading: handleActions({
    'LOADING.START': () => ({ loading: true }),
    'LOADING.END': () => ({ loading: false })
  }, {
    loading: false
  })
})
