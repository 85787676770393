
import {createForm} from "ductus"

import { validateExistingEmail, validatePassword} from './validators'

export default createForm({
  container: 'forms',
  name: 'bulkPackagesForm',
  // errorActionTypes: [AUTHORIZATION_RECEIVE_ERROR],
  // transformError: transformErrorToFieldsErrors,
  fields: {
    providerId: {},
    branchId: {},
    courierId: {},
    file: {}
  },
})
