import {combineReducers} from "redux";
import {dashboard} from "../ducks";

export default combineReducers({
  processing: dashboard.processing.reduce,
  current: dashboard.current.reduce,
  preOrder: dashboard.preOrder.reduce,
  stats: dashboard.stats.reduce,
  selectedTab: dashboard.selectedTab.reduce,
  post: dashboard.post.reduce
})