import React, {Fragment} from "react"
import ReactDOM from "react-dom"
import AuthCheck from "routes/index.jsx"
import {PersistGate} from "redux-persist/integration/react"
import "assets/scss/material-dashboard-pro-react.css?v=1.2.0"
import {Provider} from "react-redux"
import {store, persistor, history} from "./store"
import loading from "./assets/loading.svg"
import Modal from "./components/Modal/Modal1"
import Mui from "./Mui"
import {connect} from "react-redux"
import "./TempApp.css";

// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update')
//   whyDidYouUpdate(React)
// }


let translate
const getTranslator = function (state) {
  translate = function (key, params, fallback, locale) {
    if (fallback === void 0) { fallback = key; }
    if (locale === void 0) { locale = null; }
    let translations = state.localization.translations[locale || state.localization.defaultLocale];
    return translations && translations[key]
      ? formatTranslation(translations[key], params)
      : fallback;
  };
  return translate;
};
let formatTranslation = function (translation, params) { return typeof translation !== 'function'
  ? translation
  : translation(params); };


const mapStateToProps = state => {
  !translate && getTranslator(state)
  return ({ translate })
}

const Root =
  connect(mapStateToProps, null)
  (({ translate }) =>
  <Fragment>
    <AuthCheck translate={translate} history={history}/>
    <Modal translate={translate} />
  </Fragment>)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={loading} persistor={persistor}>
      <Mui>
        <Root/>
      </Mui>
    </PersistGate>

  </Provider>,
  document.getElementById("root")
)
