import processing from './processing'
import current from './current'
import preOrder from './preOrder'
import stats from '../stats'
import selectedTab from '../dashboard/selectedTab'
import post from '../dashboard/post'

export default {
  current,
  processing,
  preOrder,
  stats,
  selectedTab,
  post
}
