import {createForm} from "ductus"
import {transformToNumber} from "../transformers"

export default createForm({
  statePath: 'forms.filter.couriersFilterForm',
  name: 'couriersFilterForm',
  fields: {
    name: {},
    phoneNumber: {
      transform: transformToNumber
    },
    hubId: {},
    isActive: {
      initialValue: 1
    }
  },
})
