import React from "react"
import CustomInput from "../CustomInput/CustomInput"
import {withStyles, FormControlLabel, Radio} from "@material-ui/core"
import {FiberManualRecord} from '@material-ui/icons'

import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";


const RadioGroupField = ({name, value, items, error, disabled, onChange, containerStyles = {}, id = undefined, label = '', classes}) =>
  (
    <div style={{...containerStyles, display: 'flex'}}>
      {
        items.map((item, key) =>
          <div
            key={key}
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={value === item.id}
                  onChange={() => onChange(item.id)}
                  value={item.id}
                  name={item.name}
                  disabled={disabled}
                  // aria-label="B"
                  icon={
                    <FiberManualRecord
                      className={classes.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={classes.radioChecked}
                    />
                  }
                  classes={{
                    checked: classes.radio
                  }}
                />
              }
              classes={{
                label: classes.label
              }}
              label={item.name}
            />
          </div>
        )
      }
    </div>
  )

export default withStyles(customCheckboxRadioSwitch)(RadioGroupField)
