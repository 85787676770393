import {handleActions, createAction} from 'redux-actions'

const SNACK_BAR_OPEN = 'SNACK_BAR/OPEN'
const SNACK_BAR_CLOSE = 'SNACK_BAR/CLOSE'

const openSnackBar = createAction(SNACK_BAR_OPEN)
const closeSnackBar = createAction(SNACK_BAR_CLOSE)

const getSnackBarConfigs = (state) => state.snackBar

const initialState = {
  open: false,
  place: 'bl',
  color: 'info',
  message: '',
}

export default ({
  SNACK_BAR_OPEN,
  SNACK_BAR_CLOSE,

  openSnackBar,
  closeSnackBar,

  getSnackBarConfigs,

  reduce: handleActions({
    [SNACK_BAR_OPEN]: (state, {payload}) => ({
      ...state,
      ...payload,
      open: true,
    }),
    ['EVENT/OCCURRED']: (state, {payload: { type }}) => ({
      ...state,
      message: type,
      open: true,
    }),
    [SNACK_BAR_CLOSE]: () => initialState
  },
    initialState)
})