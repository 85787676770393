
import connect from "react-redux/es/connect/connect"
import {providers} from '../../../ducks'
import {providerForm} from '../../../forms'

import CreateProviderWizard from './Wizard'

const mapStateToProps = (state) => ({
  createFormValues: providerForm.create.getValues(state),
  storeLoading: providers.isStoreRequestPending(state),
  storeErrors: providers.getStoreRequestError(state),
  isValid: providerForm.create.isValid(state),

  singleLoading: providers.isSingleRequestPending(state),
  singleData: providers.getSingle(state),
  singleError: providers.getStoreRequestError(state),
})
const mapDispatchToProps = {
  fetchSingle: providers.fetchSingle,
  createItem: providers.store,
  resetCreateFormValues: providerForm.create.resetValues,
  changeCreateFormValues: providerForm.create.changeValues,
  resetForm: providerForm.create.resetValues,

}


export default connect(mapStateToProps, mapDispatchToProps)(CreateProviderWizard)
