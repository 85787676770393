import moment from "moment";

import _ from 'lodash'

const difference = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export const isDeepEqual = (a,b) => _.isEmpty(difference(a,b))


export const mapErrorToValidationError = (error) => ({
  code: error,
  message: error,
})

export const transformErrorToFieldsErrors = (fieldNames, errors) => errors && typeof errors === 'object' && Object.keys(errors).reduce((reduction, fieldName) => {
  try {
    return {
      ...reduction,
      [fieldName]: errors[fieldName].map(mapErrorToValidationError)
    }
  }catch(e) {
    return {
      ...reduction,
      [fieldName]: [{
        code: errors[fieldName],
        message: errors.message,
      }]
    }
  }
}, {})

export const createResource = (params) => params && params.path

const mapHubToId = h => h.id
export const hubIds = response => {
  const data = response.body.data;
//  console.log("data", data); 
  return ({
    ...data,
    postProviderId: data.postProvider ? data.postProvider.id : undefined,
    // post_provider_id: data.postProvider ? data.postProvider.id : undefined,
    hubs: data.hubs ? data.hubs.map(mapHubToId) : undefined,
  });
}

export const groupUserInfo = (response) => ({
  ...response.body.data,
  role: response.body.data.roles[0],
  permissions: response.body.data.permission
    .reduce((ac, curr) => ({...ac, [curr]: true}), {})
})

export const groupPackageData = d => ({
  ...d,
  receiveTime: {
    receivedAt: d.receivedAt,
    code: d.code,
    id: d.id,
    hasCourier: !!d.courier
  },
  typeAndPrice: {type: d.paymentType, price: d.packagePrice, status: d.status, deliveryFee: d.deliveryFee},
  recipient: {name: d.recipientName, address: d.recipientAddress, phone: d.recipientPhone},
  provider: {name: d.provider.name, phoneNumber: d.branch.phoneNumber, address: d.branch.address},
  estimatedTime: {pickupTime: d.approxPickupTime, deliveryTime: d.approxDeliveryTime, status: d.status},
})

export const transformPackResponse = (response, withPagination = true) => withPagination ? ({
  data: response.body.data.map(
    data =>
      groupPackageData(data)),
  pagination: response.body.pagination
}) : response.body.data.map(data => groupPackageData(data))


// export const transformPackEventRes = (response, withPagination = true) => withPagination ? ({
//   data: response.body.data.map(
//     data =>
//       groupPackageData(data)),
//   pagination: response.body.pagination
// }) : response.body.data.map(data => groupPackageData(data))
//


export const hasMatch = (item, searchText) => item.toLowerCase().search(searchText.toLowerCase()) !== -1

export const courierNumbers = (couriers) => couriers.map(c => ({
  ...c,
  name: `${c.name} - ${c.currentOrdersCount} - ${c.deliveredOrdersCount}`
}))

// export const postDataNumbers = (postData) => postData.map(c => ({
//   ...c,
//   name: `${c.name}`

//   // name: `${c.name} - ${c.currentOrdersCount} - ${c.deliveredOrdersCount}`
// }))

export const timeTillEvent =
  (eventTime) =>
    Math.floor((moment(eventTime).unix() - moment().unix()) / 60)

export const fromNow =
  (time) =>
    Math.floor(moment().diff(moment(time)) / 60000)

export const toNumber = v => Number(v)