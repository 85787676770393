import React from 'react'
import {Field, withForm} from "ductus"

import { Face, Home, Phone } from '@material-ui/icons';

import {
  CheckboxField,
  GridContainer,
  GridItem,
} from '../../components'

import {branchForm} from "../../forms"

import {TextField, ComboField} from "../../components"


const BranchForm = ({districts, translate, handleSubmit, values}) => (
  <GridContainer justify="center">
    <form onSubmit={(e) => {e.preventDefault(); handleSubmit(values)}} className={'good-form'}>
      <GridItem xs={12}>
        <Field
          name={'name'}
          label={translate('name')}
          icon={Face}
          component={TextField}
        />

        <Field
          name={'address'}
          label={translate('address')}
          icon={Home}
          component={TextField}
        />

        <Field
          name={'phoneNumber'}
          label={translate('phoneNumber', {})}
          icon={Phone}
          component={TextField}
        />

        <Field
          name={'districtId'}
          label={translate('district')}
          icon={Face}
          component={ComboField}
          items={districts}
        />

        {/* <Field
          name={'isActive'}
          label={translate('active')}
          icon={Face}
          component={CheckboxField}
        /> */}

      </GridItem>
      <button type={'submit'} style={{display: 'none'}}>b</button>
    </form>
  </GridContainer>
)


export default withForm(branchForm.edit)(BranchForm)
