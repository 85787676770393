import {createForm} from "ductus";
import {transformToNumber} from "../transformers";

export default createForm({
    statePath: 'forms.filter.sortsDashForm',
    name: 'sortsDashForm',
    fields: {
        'start_date': {},
        'end_date': {},
        'order_id': {},
        'recipient_name': {},
        'recipient_phone_number': {
            transform: transformToNumber
        },
        'courier_id': {},
        'status': {}
    },
})
