import React from "react"
import PropTypes from "prop-types"

import withStyles from "@material-ui/core/styles/withStyles";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import ExpandMore from "@material-ui/icons/ExpandMore"
import Clear from "@material-ui/icons/Clear"
import FileDownload from "@material-ui/icons/FileDownload"


import accordionStyle from "../../assets/jss/material-dashboard-pro-react/components/accordionStyle.jsx"
import Button from '../../components/CustomButtons/Button'

const style = (theme) => ({
  ...accordionStyle(theme),
  accordionContent: {
    alignItems: 'center'
  },
})

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    const actives = props.actives ? props.actives : []
    this.state = {
      actives: [...actives],
      ac: {}
    };
  }

  handleChange = panel => (event, expanded) => this.setState({
    ac:  {...this.state.ac, [panel]: expanded }
  })

  componentWillReceiveProps(nextProps) {
    const {collapses} = nextProps
    if (this.props.collapses.length < collapses.length) {
      this.setState({
        ac: {...this.state.ac, [collapses[collapses.length - 1].key]: true }
      })
    }
  }

  render() {
    const {classes, collapses} = this.props;
    return (
      <div className={classes.root}>
        {collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded={this.state.ac[prop.key]}
              onChange={this.handleChange(prop.key)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded
              }}
              style={{
                margin: '15px 0',
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore/>}
                classes={{
                  root: classes.expansionPanelSummary,
                  expanded: classes.expansionPanelSummaryExpaned,
                  content: classes.expansionPanelSummaryContent + ' ' + classes.accordionContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon
                }}
                style={{
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <h4 style={{margin: 0}}> {prop.title} </h4> <span
                style={{marginLeft: 30, opacity: 0.7}}> {prop.subTitle} </span>

                {
                  prop.export &&
                  <Button
                    color={'primary'}
                    simple
                    justIcon
                    round
                    style={{
                      position: 'absolute',
                      right: 100,
                      padding: 0,
                      margin: 0
                    }}

                    href={prop.export}
                  >
                    <FileDownload/>
                  </Button>
                }
                {
                  prop.remove &&
                  <Button
                    simple
                    justIcon
                    color={'primary'}
                    round
                    style={{
                      position: 'absolute',
                      right: 50,
                      padding: 0,
                      margin: 0
                    }}
                    onClick={prop.remove}
                  >
                    <Clear/>
                  </Button>
                }
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                {prop.content}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

Accordion.defaultProps = {
  active: -1
};

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  // index.jsx of the default active collapse
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.object,
      content: PropTypes.node
    })
  ).isRequired
};

export default withStyles(style)(Accordion);
