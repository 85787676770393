import React from 'react'
import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'
import ReactTable from 'react-table'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'
import qs from "query-string";

import ConfirmSweetAlert from '../../components/SweetAlert/SweetAlert'
import SnackBar from '../../components/Snackbar/Snackbar'
import { AddButton } from '../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"

import CreateDistrictForm from './CreateDistrictForm'
import EditDistrictForm from './EditDistrictForm'

import { districtPermissions } from '../../permissions'
import ListComponent from "../ListComponent";
import { districtForm } from '../../forms'
import { connect } from 'react-redux'
import { districts } from "../../ducks";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { districtServices } from '../../ducks/services';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom'
class DistrictList extends ListComponent {
  state = {
    selected: new Map(),
    updateSuccess: false
  }

  onChange = (e, item) => {
    const {selected} = this.state;
    const selectionStatus = selected.get(item.id);
    selected.set(item.id, selectionStatus !== undefined ? !selectionStatus : !item.selected);      

    this.setState({ selected })

    /* success notification */
    this.setState({updateSuccess: false})
    
  }

  saveDistricts = (e) => {
    
    this.props.updateDistrictByProvider({ params: {path: `providers/${this.props.match.params.providerId}/districts`}},
      Array.from(
        this.state.selected.entries())
          .map(([district_id, selected]) => ({district_id, selected})
        )
    )
    /* success notification */
    if(this.props.updateDistrictsStatus.success == true) {
      this.setState({updateSuccess: true})
      setTimeout(() => {
        this.setState({updateSuccess: false})
      }, 5000 );
    }

  }


  makeActions = (item) => {
    const selectionStatus = this.state.selected.get(item.id);

    return (
      <div className="actions-right">
          <Checkbox
            checked={selectionStatus !== undefined ? selectionStatus : item.selected}
            onChange={e => this.onChange(e, item) }
            inputProps={{ 'aria-label': 'Checkbox' }}
            className="provider_district_selected"
          />

      </div>
    )
  }

  componentDidMount() {
    const { history } = this.props
    const page = Number(qs.parse(history.location.search).page)
    this.setState( { pageIndex: page || 0 })
    this.props.fetchList({ query: { isActive: 1 } })
    this.props.getDistrictByProvider({
      params: {path: `providers/${this.props.match.params.providerId}/districts`},
      query: {page, order: 'asc'}
    })
  }

  pageChanged = (pageIndex) =>  {
    this.props.getDistrictByProvider({
      params: {path: `providers/${this.props.match.params.providerId}/districts`},
      query: {page: pageIndex + 1,  order: 'asc'}
    })
    this.setState({pageIndex})
    this.props.history.push({search: window.location.search.replace(/&page=[0-9+]|page=[0-9+]/i, '') + '&page=' + (pageIndex + 1)})
  }

  componentWillUpdate(nextProps) {
    const {
      storeLoading,
      updateLoading,
      removeLoading,
      singleLoading,
      changeEditFormValues,
      translate,
      loading,
      resetCreationFormValues,
      resetEditionFormValues,
    } = this.props

    const { pagination, singleErrors, storeErrors, updateErrors, removeErrors } = nextProps

    if ((storeLoading && !nextProps.storeLoading) && !storeErrors) {
      this.props.closeModal()

      resetCreationFormValues()
      this.fetchList({ query: { page: 1, isActive: 1 } })
      this.setState({ pageIndex: 0 })

    } else if (updateLoading && !nextProps.updateLoading && !updateErrors) {

      this.props.closeModal()
      resetEditionFormValues()
      this.fetchList({ query: { page: this.state.pageIndex + 1, isActive: 1 } })
    }

    if (removeLoading && !nextProps.removeLoading && !removeErrors) {
      if ((this.state.pageIndex + 1 >= pagination.totalPages) && (pagination.total % pagination.perPage === 1)) {
        this.fetchList({ query: { page: this.state.pageIndex, isActive: 1 } })
        this.setState({ pageIndex: this.state.pageIndex - 1 })
      } else {
        this.fetchList({ query: { page: this.state.pageIndex + 1, isActive: 1 } })
      }

      this.setState({
        alert: { open: false },
        snackBar: { openPos: 'bl', message: translate('deleted') }
      })
    }

    if (singleLoading && !nextProps.singleLoading && !singleErrors) {
      nextProps.singleData && changeEditFormValues(nextProps.singleData)
    }

    if (loading && !nextProps.loading && pagination) {
      this.setState({
        pageIndex: nextProps.pagination.currentPage - 1
      })
    }
  }

  render() {
    const { data, loading, districts, storeItem, pagination, translate, permissions } = this.props
    const { snackBar, alert } = this.state

    return (
      <>
        <Link to={`/providers`} className="goBack_btn_link creat">
          <KeyboardArrowLeftIcon className="goBack_btn" />
          <span>უკან დაბრუნება</span>
        </Link>
        <br />
        <br />
        <br />
        <GridItem xs={12}>

          {/* <AddButton
          hidden={!permissions[districtPermissions.create]}
          onClick={() => this.openFormModal({
            submitText: 'add',
            submit: storeItem,
            Component: 'CreateDistrictForm',
            componentProps: {},
            form: districtForm.create
          })}
        >
        </AddButton> */}

          <GridContainer className="mb-5">
            <Card>
              <CardBody>
                {

                  <ReactTable
                    data={districts && districts.map(item => ({
                      ...item,
                      actions: this.makeActions(item)
                    }))}
                    loading={loading}
                    pages={pagination && pagination.totalPages}
                    manual
                    onPageChange={this.pageChanged}
                    defaultPageSize={(pagination && pagination.perPage) || 5}
                    pageSize={pagination && pagination.perPage}

                    onSortedChange={this.onSortedChange}
                    page={this.state.pageIndex}
                    columns={[
                      {
                        Header: translate('name'),
                        accessor: "name"
                      },
                      {
                        Header: translate('actions'),
                        accessor: "actions"
                      }
                    ]}
                    previousText={translate('previousText')}
                    nextText={translate('nextText')}
                    loadingText={translate('loadingText')}
                    pageText={translate('pageText')}
                    ofText={translate('ofText')}
                    showPaginationBottom
                    showPageSizeOptions={false}
                    className="-striped -highlight"

                  />

                }


                <div className="save__BTN district_save">
                  <Button onClick={() => this.saveDistricts()} color={'primary'} >ცვლილებების შენახვა</Button>
                </div>
              </CardBody>
            </Card>
            {this.state.updateSuccess && 
              <GridItem xs={12} className="upload_successStatus success_block z_100_fixed">
                <h4>წარმატებით აიტვირთა მონაცემები</h4>
              </GridItem>
            }
          </GridContainer>

          {/* {alert.open && <ConfirmSweetAlert {...alert} />}
          <SnackBar
            place={'br'}
            color="info"
            icon={AddAlert}
            message={snackBar.message}
            open={snackBar.openPos}
            closeNot={this.closeSnackBar}
            close
          /> */}
        </GridItem>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {    
    districts: districtServices.getDistrictByProvider.getData(state).data,
    pagination: districtServices.getDistrictByProvider.getData(state).pagination,
   
    updateDistricts: districtServices.updateDistrictByProvider.getData(state),
    updateDistrictsStatus: districtServices.updateDistrictByProvider.getData(state),

    singleData: districts.getSingle(state),
    loading: districts.isListRequestPending(state),
    singleLoading: districts.isSingleRequestPending(state),
    storeLoading: districts.isStoreRequestPending(state),
    updateLoading: districts.isUpdateRequestPending(state),
    removeLoading: districts.isRemoveRequestPending(state),
    listErrors: districts.getListRequestError(state),
    singleErrors: districts.getSingleRequestError(state),
    storeErrors: districts.getStoreRequestError(state),
    updateErrors: districts.getUpdateRequestError(state),
    removeErrors: districts.getRemoveRequestError(state),
  }
}

const mapDispatchToProps = {
  fetchList: districts.fetchList,
  getDistrictByProvider: districtServices.getDistrictByProvider.run,
  updateDistrictByProvider: districtServices.updateDistrictByProvider.run,

  storeItem: districts.store,
  updateItem: districts.update,
  removeItem: districts.remove,
  changeEditFormValues: districtForm.edit.changeValues,
  resetCreationFormValues: districtForm.create.resetValues,
  resetEditionFormValues: districtForm.edit.resetValues,
}
export default connect(mapStateToProps, mapDispatchToProps)(DistrictList)
