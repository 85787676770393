import WarehouseDashboard from "./WarehouseDashboard";
import connect from "react-redux/es/connect/connect";
import {getLineItems, SORTS_DASHBOARD_INVALIDATE} from "../../../ducks/warehouseManager";
import {sortsDashForm} from "../../../forms";
import sortsManagerServices from "../../../ducks/services/sortsManager";

const mapStateToProps = (state) => ({
    pending: state.sortsManager.dash.pending,
    data: state.sortsManager.dash.data.data,
    pagination: state.sortsManager.dash.data.pagination,
    loading: state.sortsManager.dash.pending,
    filterFormValues: sortsDashForm.getValues(state),
    updateLineItemsPending: sortsManagerServices.updateLineItems.getRequestPending(state),
    updateLineItemsError: sortsManagerServices.updateLineItems.getRequestError(state),
    findLineItemsData: sortsManagerServices.findLineItems.getData(state).data,
    findLineItemsPending: sortsManagerServices.findLineItems.getRequestPending(state),
    findLineItemsError: sortsManagerServices.findLineItems.getRequestError(state),
    
    findWarehouseAuditLineItemsData: sortsManagerServices.findWarehouseAuditLineItems.getData(state).data,
    findWarehouseAuditLineItemsPending: sortsManagerServices.findWarehouseAuditLineItems.getRequestPending(state),
    findWarehouseAuditLineItemsError: sortsManagerServices.findWarehouseAuditLineItems.getRequestError(state),
    
});

export default connect(mapStateToProps, {
    fetchList: getLineItems(),
    findWarehouseAuditLineItems: sortsManagerServices.findWarehouseAuditLineItems.run,
    updateLineItems: sortsManagerServices.updateLineItems.run,
    findLineItems: sortsManagerServices.findLineItems.run,
    findLineItemsInvalidate: sortsManagerServices.findLineItems.invalidate,
    invalidate: () => ({ type: SORTS_DASHBOARD_INVALIDATE })
})(WarehouseDashboard)
