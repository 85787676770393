import { createServiceGroup} from "../functions/createGroup";
import { COURIER_STATS_UPDATE, COURIER_WORK_END, COURIER_WORK_START, COURIERS_LOCATIONS_UPDATE} from '../../eventTypes'
import { createResource } from '../../helpers'
const removeMarker = (state, { payload }) => ({...state, data: state.data.filter(item => item.id !== payload.id )})

const addAvailable = (state, { payload }) => ({...state, data: [ payload, ...state.data.filter(item => item.id !== payload.id ) ] })

const addMarker = addAvailable

const removeAvailable = (state, { payload }) => ({ ...state, data: state.data.filter(item => item.id !== payload.id )})

const updateAvailable = (state, { payload }) => ({...state, data: state.data.map( d => d.id === payload.id ? payload : d ) })

const updateMarkers = (state, { payload }) => ({...state, data: payload  })

export default createServiceGroup({
  name: 'postServices',
  items: {
    assign: {
      name: 'assignCourier',
      resource: '/package/assign-courier',
      type: 'post'
    },
    postProviders: {
      name: 'postProviders',
      resource: '/post-providers',
      generic: {transformData: (response) => response.body.data, initialData: []},
      // additionalHandlers: {
      //   [COURIER_WORK_START]: addAvailable,
      //   [COURIER_WORK_END]: removeAvailable,
      //   [COURIER_STATS_UPDATE]: updateAvailable,
      // }
    },
    // endWork: {
    //   type: 'put',
    //   name: 'upatePostProviders',
    //   resource: '/post-providers',
    //   resource: 'couriers/work-end',
    //   createResource,
    // },
    // locations: {
    //   name: 'postProviders',
    //   resource: '/post-providers',
    //   generic: {transformData: (response) => response.body.data, initialData: []},
    //   additionalHandlers: {
    //     // ['COURIER_WORK_START']: addMarker,
    //     [COURIER_WORK_START]: addMarker,
    //     [COURIER_WORK_END]: removeMarker,
    //     [COURIERS_LOCATIONS_UPDATE]: updateMarkers,
    //   }
    // },
    supplierPost: {
      name: 'upatePostProviders',
      resource: `/package/bulk-assign-package-provider`,
      type: 'post',
    }
  }
})
