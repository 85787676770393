import {createStore, applyMiddleware} from 'redux'
import {compose} from 'redux-net'
import reduce from "./reduce"
import {persistStore, persistReducer} from 'redux-persist'
import {routerMiddleware} from './middlewares'
import {createBrowserHistory} from "history";
import {createEpicMiddleware} from 'redux-observable'
import requestHandlerMiddleware from './netConfigs'
import { persistConfig } from './defaultConfigs'
import rootEpic from './epics'


window.io = require('socket.io-client');

const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedReducer = persistReducer(persistConfig, reduce)

const store = createStore(persistedReducer, composeEnhancers(
  applyMiddleware(
    requestHandlerMiddleware,
    routerMiddleware(history),
    epicMiddleware
  )
))

epicMiddleware.run(rootEpic)

// echo$.subscribe(action => store.dispatch(action))


const persistor = persistStore(store)

export { history, store, persistor}