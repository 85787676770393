
import {
  branches,
  couriers,
  dispatchers,
  districts,
  hubs,
  managers,
  warehouse_managers,
  packages,
  packageStatuses,
  sizes,
  providers,
  snackBar,
  userInfo,
  orderHours,
  userHubs,
} from "../ducks"
import dashSorter from '../ducks/dashSorter'

import dashboard from './dashboard'
import services from './serviceGroups'
export default {
  dashboard,
  orderHours: orderHours.reduce,
  ...services,
  dashSorter,
  userInfo: userInfo.reduce,
  userHubs: userHubs.reduce,
  districts: districts.reduce,
  couriers: couriers.reduce,
  providers: providers.reduce,
  sizes: sizes.reduce,
  branches: branches.reduce,
  packages: packages.reduce,
  hubs: hubs.reduce,
  managers: managers.reduce,
  warehouse_managers: warehouse_managers.reduce,
  dispatchers: dispatchers.reduce,
  packageStatuses: packageStatuses.reduce,
  // recipients: recipients.reduce,

  snackBar: snackBar.reduce,
}