import React, { Fragment } from 'react'
import {Field, withForm} from "ductus"
import {packagesFilterForm} from "../../../forms"
import {ComboField, DateTimeField, TextField} from "../../../components/fields"

import GridContainer from "../../../components/Grid/GridContainer"
import GridItem from "../../../components/Grid/GridItem"

const Fields = ({ translate, role, branches, providers, couriers, hubs, providerSelectionChange }) => (
  <Fragment>
    <div style={{ width: 100 }}>
      <Field
        name={'code'}
        label={translate('orderId')}
        component={TextField}
      />
    </div>
    <div style={{ width: 100 }}>
      <Field
        name={'status'}
        label={translate('status')}
        component={ComboField}
        items={[
          {id: 'PROCESSING', name: 'PROCESSING'},
          {id: 'ACCEPTED', name: 'ACCEPTED'},
          {id: 'AT_PROVIDER', name: 'AT_PROVIDER'},
          {id: 'PICKED_UP', name: 'PICKED_UP'},
          {id: 'AT_RECIPIENT', name: 'AT_RECIPIENT'},
          {id: 'DELIVERED', name: 'DELIVERED'},
          {id: 'REJECTED', name: 'REJECTED'},
        ]}
      />
    </div>

    <div style={{ display: 'flex', width: 200 }}>
      <GridContainer style={{alignItems: 'center'}}>
        <GridItem xs={12}>
          <small style={{color: '#AAAAAA', fontSize: 15}}> {translate('priceRange')} </small>
        </GridItem>

        <GridItem xs={6}>
          <Field
            name={'minPrice'}
            // label={translate('minPrice')}
            component={TextField}
            showSuccess={false}
          />
        </GridItem>

        <GridItem xs={6}>
          <Field
            name={'maxPrice'}
            // label={translate('maxPrice')}
            component={TextField}
            showSuccess={false}
          />
        </GridItem>

      </GridContainer>
    </div>

    <div style={{ display: 'flex', width: 200}}>
        <GridContainer style={{alignItems: 'center'}}>
            <GridItem xs={12}>
                <small style={{color: '#AAAAAA', fontSize: 15}}> {translate('dateRange')} </small>
            </GridItem>

            <GridItem xs={6}>
                <Field
                    name={'startDate'}
                    component={DateTimeField}
                    dateFormat={'DD-MM-YYYY'}
                    timeFormat={false}
                />
            </GridItem>

            <GridItem xs={6}>
                <Field
                    name={'endDate'}
                    component={DateTimeField}
                    dateFormat={'DD-MM-YYYY'}
                    timeFormat={false}
                />
            </GridItem>

        </GridContainer>
    </div>

    <div style={{ flex: 1, minWidth: 200 }}>
      <Field
        name={'recipientPhoneNumber'}
        label={translate('recipientPhoneNumber')}
        component={TextField}
      />
    </div>

    <div style={{ flex: 1, minWidth: 200 }}> 
      {hubs.length > 1 ? 
        <Field
          name={'hubId'}
          label={translate('hub')}
          component={ComboField}
          items={hubs}
        />
        : ( hubs.length === 1 ?
          <div className="label__block">
            <span className="label__name">{translate('hub')}</span>
            <p>{ hubs.map((item) => item.name ) }</p>
          </div>
        : " " )
      }
    </div>

    {
      role !== 'provider' &&
      <div style={{ flex: 1, minWidth: 200, maxWidth: 300 }}>
        <Field
          name={'providerId'}
          label={translate('provider')}
          component={ComboField}
          items={providers || []}
          selectionChange={providerSelectionChange}
        />
      </div>
    }



    <div style={{ flex: 1, minWidth: 200, maxWidth: 300 }}>
      <Field
        name={'branchId'}
        label={translate('branch')}
        component={ComboField}
        items={branches || []}
      />
    </div>

    <div style={{ flex: 1, minWidth: 150, maxWidth: 200 }}>
      <Field
        name={'courierId'}
        label={translate('courier')}
        component={ComboField}
        items={couriers || []}
      />
    </div>

    {/* <div style={{ width: 150 }}>
      <Field
        name={'paymentType'}
        label={translate('paymentType')}
        component={ComboField}
        items={[
          {id: 'CASH', name: translate('cash')},
          {id: 'CREDIT', name: translate('credit')}
        ]}
      />
    </div> */}

    <div style={{ width: 150 }}>
      <Field
        name={'type'}
        label={translate('type')}
        component={ComboField}
        items={[
          {id: '', name: translate('all')},
          {id: 'collect', name: translate('collect')},
          {id: 'deliver', name: translate('deliver')}
        ]}
      />
    </div>
    <GridItem xs={12} sm={12} md={12} lg={12}>
        <Field
            name={'search'}
            label={translate('item')}
            component={TextField}
        />
    </GridItem>
  </Fragment>
)

export default withForm(packagesFilterForm)(Fields)
