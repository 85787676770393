import React from 'react'
import {withForm} from 'ductus'
import {couriersFilterForm} from "../../forms"
import {Field} from 'ductus'
import {ComboField, GridContainer, GridItem, Button, CheckboxField} from "../../components"
import {TextField} from "../../components/fields"
import {connect} from 'react-redux'
import {couriers} from '../../ducks'
import qs from 'query-string'

import DirectionsRun from '@material-ui/icons/DirectionsRun'
import DirectionsBike from '@material-ui/icons/DirectionsBike'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Motorcycle from '@material-ui/icons/Motorcycle'

class CouriersFilterForm extends React.Component {

  componentWillUnmount() {
    this.props.resetForm()
  }

  componentWillMount() {
    const {formValues, history} = this.props
    this.props.changeFormValues({...formValues, ...qs.parse(history.location.search)})
  }

  handleSubmitForm = (e) => {
    const {filterItems, formValues, history} = this.props
    e.preventDefault()
    filterItems({query: formValues})
    history.push({search: qs.stringify(formValues)})
  }

  render() {
    const {hubs, translate,} = this.props
    return (
      <GridContainer style={{alignItems: 'center'}}>
        <form onSubmit={this.handleSubmitForm} className={'good-form'}>
          <GridItem xs={12} sm={12} md={3}>
            <Field
              name={'name'}
              label={translate('name')}
              component={TextField}
              showSuccess={false}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <Field
              name={'phoneNumber'}
              label={translate('phoneNumber', {})}
              component={TextField}
              showSuccess={false}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>

            <Field
              name={'hubId'}
              label={translate('chooseHub')}
              component={ComboField}
              searchText={translate('search')}
              items={hubs}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>

            <Field
              name={'transportationType'}
              label={translate('transportationType')}
              component={ComboField}
              searchText={translate('search')}
              items={[
                { id: 'PEDESTRIAN', name: <DirectionsRun/>},
                { id: 'BIKE', name: <DirectionsBike/>},
                { id: 'MOTORCYCLE', name: <Motorcycle />},
                { id: 'CAR', name: <DirectionsCar/>},
                { id: 'OTHER', name: translate('other') }
              ]}
            />
          </GridItem>


          <GridItem xs={12} sm={6} md={3}>
            <Field
              name={'isActive'}
              label={translate('active')}
              component={CheckboxField}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <Button color={'primary'} type={'submit'}> {translate('search')} </Button>
          </GridItem>
        </form>
      </GridContainer>
    )
  }
}

const mapStateToProps = state => ({
  formValues: couriersFilterForm.getValues(state)
})

export default connect(
  mapStateToProps,
  {
    filterItems: couriers.fetchList,
    changeFormValues: couriersFilterForm.changeValues,
    resetForm: couriersFilterForm.resetValues,
  }
)(withForm(couriersFilterForm)(CouriersFilterForm))