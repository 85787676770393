const actions = {
  'add': 'დამატება',
  'store': 'შენახვა',
  'edit': 'ჩასწორება',
  'update': 'განახლება',
  'delete': 'წაშლა',
  'attach': 'მიმაგრება',
}

const divisions = {
  'courier': 'კურიერის',
  'provider': 'პროვაიდერის',
  'dispatcher': 'დისპეჩერის',
  'branch': 'ფილიალის',
  'district': 'უბნის',
  'hub': 'ჰაბის',
  'manager': 'მენეჯერის',
  'package': 'შეკვეთის',
}

export default ({
  'hello': 'გამარჯობა',
  'name': 'სახელი',
  'email': 'ელ. ფოსტა',
  'identityNumber': 'საიდენტიფიკაციო კოდი',
  'phoneNumber': ({number}) => `ტელეფონის ნომერი ${number ? number : ''}`,
  'address': 'მისამართი',
  'credit': 'კრედიტი',
  'comment': 'კომენტარი',
  'customerComment': 'მომხმარებლის კომენტარი',
  'active': 'აქტიური',
  'hubs': 'ჰაბები',
  'hub': 'ჰაბი',
  'chooseHubs': 'აირჩიეთ ჰაბები',
  'chooseHub': 'აირჩიეთ ჰაბი',
  'providers': 'პროვაიდერები',
  'provider': 'პროვაიდერი',
  'providerBranches': 'აღების მისამართი',
  'branches': 'ფილიალები',
  'branch': 'ფილიალი',
  'district': 'ქალაქი',
  'districts': 'ქალაქები',
  'dispatchers': 'დისპეჩერები',
  'dispatches': 'დისპეჩერი',
  'couriers': 'კურიერები',
  'courier': 'კურიერი',
  'packages': 'შეკვეთები',
  'package': 'შეკვეთა',
  'list': 'სია',
  'single': 'ერთი',
  'cancel': 'გათიშვა',
  'close': 'დახურვა',
  'deleted': 'წაშლილია',
  ...actions,
  'whichAction': ({type, which}) => `${divisions[which]} ${actions[type]}`,
  'toDelete': ({item}) => `გნებავთ წაშალოთ ${item}?`,

  'search': 'ძებნა',
  'filter': 'ფილტრი',
  'actions': 'მოქმედებები',
  'previousText': 'წინა',
  'back': 'უკან',
  'finish': 'დასრულება',
  'nextText': 'მომდევნო',
  'next': 'შემდეგი',
  'loadingText': 'დაელოდეთ...',
  'pageText': 'გვერდი',
  'ofText': ' სულ ',

  'systemData': 'სისტემური მონაცემები',
  'legalData': 'იურიდიული მონაცემები',
  'accountNumber': 'ანგარიშის ნომერი',
  'contactPerson': 'საკონტაქტო პირი',
  'general': 'ზოგადი',
  'rights': 'უფლებები',
  'right': 'უფლება',
  'financial': 'ფინანსური',
  'financialConfiguration': 'ფინანსური კონფიგურაცია',
  'financeCirculation': 'ბრუნვის ხედვა',
  'preOrder': 'წინასწარი შეკვეთა',
  'preOrders': 'წინასწარი შეკვეთები',
  'viewCourier': 'შეკვეთაში კურიერის ხედვა',
  'viewCourierTime': 'კურიერის ფილიალში მისვლის დრო',
  'choosePickUpDistrict': 'აღების უბნის არჩევა',
  'withoutDistrict': 'უბნის გარეშე',
  'monthlyFee': 'ფიქსირებული',
  'deliveryFee': 'მიტანის ფასი',
  'serviceFee': 'მომსახურების ფასი',
  'percentage': 'პროცენტული',
  'hasDistrictFee': 'უბნიდან უბანზე ნამატი',
  'invoiceDay': 'ინვოისის რიცხვი',
  'invoiceTime': 'გაგზავნის საათი',
  'courierFee': 'კურიერზე გასაცემი',
  'shutdownDate': 'გათიშვის თარიღი',
  'opens': 'იხსნება',
  'closes': 'იხურება',
  'closed': 'დაკეტილია',
  'closeReason': 'დაკეტვის მიზეზი',
  'closeStartDate': 'დახურვის დაწყების დრო',
  'closeEndDate': 'დახურვის დასრულების დრო',
  'payment': 'გადახდა',
  'pickupDistrict': 'ასაღები უბანი',
  'pickupAddress': 'ასაღები მისამართი',
  'deliveryDistrict': 'დანიშნულების უბანი',
  'recipientName': 'მიმღების სახელი',
  'recipientPhone': 'მიმღების ტელეფონი',
  'recipientAddress': 'მიმღების მისამართი',
  'paymentType/money': 'გადახდის ტიპი/თანხა',
  'paymentType': 'გადახდის ტიპი',
  'quantity': 'რაოდენობა',

  'status': 'სტატუსი',
  'time': 'დრო',
  'arrivalTime': 'შემოსვლის დრო',
  'receiver': 'მიმღები',
  'received': 'აღებული',
  'current': 'მიმდინარე',
  'pre': 'წინასწარი',
  'processing': 'მუშავდება',

  'myProfile': 'პროფილი',
  'settings': 'პარამეტრები',
  'exit': 'გასვლა',
  'dashboard': 'დეშბორდი',
  'managers': 'მენეჯერები',
  'manager': 'მენეჯერი',
  'extra': 'რესტორნის +',
  'accepted': 'მიღებულია',
  'pickedUp': 'აღებულია',
  'atProvider': 'პროვაიდერთან',
  'all': 'ყველა',
  'activeCouriers': 'აქტიური კურიერები',
  'recordsNotFound': 'ჩანაწერები არ მოიძებნა',
  'money': 'თანხა',
  'logIn': 'შესვლა',
  'atRecipient': 'მიმღებთან',
  'password': 'პაროლი',
  'passwordConfirm': 'პაროლის დადასტურება',
  'daily': 'ყოველდღიური',
  'appearanceTime': 'გამოჩენის დრო',
  'chooseDeliveryDistrict': 'მიტანის უბნის არჩევა',
  'min': 'წთ',
  'minPrice': 'მინიმალური ფასი',
  'maxPrice': 'მაქსიმალური ფასი',
  'recipients': 'მიმღები',
  'recipient': 'მიმღები',
  'cash': 'ქეში',
  'creditCard': 'ბარათი',
  'card': 'ბარათი',
  'georgian': 'ქართული',
  'english': 'ინგლისური',
  'languages': 'ენები',
  'language': 'ენა',
  'notificationVoices': 'ნოტიფიკაციის ხმები',
  'deleteSelectedPackagesMessage': 'გინდათ წაშალოთ მონიშნული შეკვეთები?',
  'estimatedTimes': 'სავარაუდო დროები',
  'statistics': 'ტექ. სტატისტიკა',
  'finances': 'ფინ. სტატისტიკა',
  'map': 'რუკა',
  'export': 'ექსპორტი',
  'currentPassword': 'მიმდინარე პაროლი',
  'newPassword': 'ახალი პაროლი',
  'confirmPassword': 'პაროლის დადასტურება',
  'changeEmail': 'ელ.ფოსტის ცვლილება',
  'changePassword': 'პაროლის ცვლილება',
  'branches_own_panel': 'საკუთარი ფილიალების ხედვა',
  'ownStatistics': 'სტატისტიკა',
  'ownFinances': 'ფინანსები',
  'weekFinances': 'კვირის ფინანსები',
  'packageCount': 'რაოდენობა',
  'packagePrice': 'ღირებულება',
  'payments': 'ფინ. ანგარისწორება',
  'chooseCourier': 'კურიერის არჩევა',
  'average': 'საშუალო',
  'packageCounted': 'დათვლილია' ,
  'hoursWorked': 'საათები',
  'ordersWithCash': 'ნაღდი',
  'ordersWithCredit': 'უნაღდო',
  'recieved': 'აღებული',
  'recieve': 'ასაღები',
  'fee': 'გასაცემი',
  'courier_accept_notification' : 'დადასტურებაზე ნოტიფიკაცია',
  'isWorking': 'სამუშაოზეა',
  'extraDelivery' : 'კურიერის +',
  'be_payed': 'გადასახადი',
  'hours': 'საათები',
  'income': 'შემოსავალი',
  'from': 'დან',
  'to': 'მდე',
  'PROCESSING': 'PROCESSING',
  'CURRENT': 'CURRENT',
  'branchIdAndFileIsRequired': 'ფილიალის და ფაილის მითითება სავალდებულოა',
  'backToUpload': 'ატვირთვაზე დაბრუნება',
  'excelSuccessfulAdded': 'შეკვეთები წარმატებით დაემატა',
  'uploadExcel': 'შეკვეთების Excel-ით დამატება',
  'uploadError': 'შეცდომა ატვირთვისას',
  'uploadErrors': 'ატვირთვის შეცდომები',
  'bulkAttachCourierError': 'შეცდომა კურიერის მიბმისას',
  'bulkAttachCourier': 'შეკვეთების კურიერზე მიბმა',
  'AttachCourier': 'კურიერის მიბმა',
  'supplierPost': 'პროვაიდერი ფოსტა',
  'endDate': 'დასრულების თარიღი',
  'startDate': 'დაწყების თარიღი',
  'recipientPhoneNumber': 'მიმღების ტელეფონი',
  'code': 'კოდი',
  'price': 'ფასი',
  'locations_panel': 'მდებარეობები',
  'packages_upload': 'შეკვეთების ატვირთვა',
  'packages_delete': 'შეკვეთების წაშლა',
  'packages_export': 'სორტირების ისტორიის ჩამოტვირთვა (report)',
  'packages_courier_bulk_assign': 'შეკვეთების ერთჯერადი მიბმა',
  'couriers_panel': 'კურიერების პანელი',
  'couriers_read': 'კურიერების კითხვა',
  'couriers_create': 'კურიერების შექმნა',
  'couriers_update': 'კურიერების რედაქტირება',
  'priceRange': 'ფასები: მინ/მაქს.',
  'dateRange': 'თარიღები: მინ/მაქს.',
  'deleteSelection': 'მონიშნულების წაშლა',
  'packagesDeleted': 'შეკვეთები წარმატებით წაიშალა',
  'transportationType': 'ტრანს. ტიპი',
  'tariffs': 'ტარიფები',
  'clear': 'გასუფთავება',
  'history': 'ისტორია',
  'sortManagerDashboard': 'სორტირების დეშბორდი',
  'sortHistory': 'სორტირების ისტორია',
  'warehouseDashboard': 'საწყობის დეშბორდი',
  'warehouseHistory': 'საწყობის ისტორია',
  'warehouseManager': 'საწყობის მენეჯერი',
  'item': 'ნივთი',
  'clientName': 'კლიენტი',
  'clientNumber': 'კლიენტის ტელეფონი',
  'noAction': 'დაწერეთ მიზეზი',
  'sayNo': 'დადასტურება',
  'chooseType': 'ტიპი',
  'providerInfo': 'პროვაიდერი',
  'chooseCity': 'აირჩიე ქალაქი',
  'post': 'ფოსტაშია',
  'postProvider': 'პროვაიდერი ფოსტა',
  'listOfItems': 'ნივთების სია',
  'itemMetrics': 'საზომი ერთეულები',
  'itemName': 'დასახელება',
  'sizeName': 'სახელი',
  'sizePrice': 'რიცხვითი მნიშვნელობა',
  'sizeDescription': 'გთხოვთ მიუთითოთ საზომი ერთეულის სახელი და მისი რიცხვითი მნიშვნელობა'
})
