import React from 'react'
import qs from 'query-string'
import Form from './Form'

import Button from '../../../components/CustomButtons/Button'
import { ip } from "../../../defaultConfigs";

class RecipientsFilterForm extends React.Component {

  componentWillMount(){
    const {changeValues, history, fetchProviders, fetchHubs } = this.props
    changeValues(qs.parse(history.location.search))
    fetchProviders({query: {'without-pagination': 1}})
    fetchHubs({ query: { 'without-pagination': 1}})
  }

  render(){
    const { formValues, filterItems, translate, history, providers, hubs, token } = this.props

    return (
      <div className={'filter-flex-container'}>
        <Form translate={translate} providers={providers} hubs={hubs} />
        <div style={{ width: 100 }}>
          <Button
            color="primary"
            onClick={() => filterItems({ query: formValues }) && history.push({search: qs.stringify(formValues)}) }
          >
            {translate('search')}
          </Button>


          {/*<a target={'_blank'} href={`http://${ip}/download/recipients/export?${qs.stringify(formValues)}`} >*/}
            {/*{translate('export')}*/}
          {/*</a>*/}
        </div>
        <div style={{ width: 100 }}>
          <Button
            color={'gray'}
            href={`http://${ip}/download/recipients/export?${qs.stringify(formValues)}&token=${token}`}
          >
            {translate('export')}
          </Button>
        </div>
      </div>
    )
  }
}

export default RecipientsFilterForm