import React from 'react'
import {Link} from 'react-router-dom'

import ReactTable from 'react-table'
import ListComponent from "../../ListComponent"

import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import CardBody from '../../../components/Card/CardBody'
import Card from '../../../components/Card/Card'
import Modal from '../../../components/Modal/Modal'
import ConfirmSweetAlert from '../../../components/SweetAlert/SweetAlert'
import SnackBar from '../../../components/Snackbar/Snackbar'
import {AddButton} from '../../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"
import SortsManagerFilter from "../SortsManagerFilter";
import ProviderManagerFilter from "../ProviderManagerFilter";
import CheckboxField from "../../../components/fields/CheckboxField";
import moment from "moment";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Check from "@material-ui/icons/Check";
import Button from "../../../components/CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import {CardHeader} from "../../../components/Card";
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import SortsManagerHistoryModal from "./SortsManagerHistoryModal";
import { userInfo } from '../../../ducks'
import qs from "query-string";

class SortsManagerHistory extends ListComponent {

    state = {
        alert: {
            open: false,
        },
        snackBar: {openPos: false, message: ''},
        pageIndex: 0,

        checkedItems: new Set(),
        checkedAll: false,
        modalOpen: false,
    }

    toggleSelection = () => {
        const {data, checkedAll} = this.state
        let newCheckedItems = new Set();

        if (!checkedAll) {
            data.forEach(el => newCheckedItems.add(el.id))
        }

        this.setState({
            checkedItems: newCheckedItems,
            checkedAll: !checkedAll
        })
    }

    makeActions = (item) => {
        const {removeItem, history, translate, permissions} = this.props
        return (
            <div className="actions-right">
                {/*<Button*/}
                {/*    justIcon*/}
                {/*    round*/}
                {/*    simple*/}
                {/*    onClick={() => this.showAlert({*/}
                {/*        title: translate('toDelete', {item: 'რომელიღაც შეკვეთა'}),*/}
                {/*        confirmText: translate('delete'),*/}
                {/*        cancelText: translate('close'),*/}
                {/*        hideAlert: this.hideAlert,*/}
                {/*        confirmFun: () => {},*/}
                {/*        warning: true*/}
                {/*    })}*/}
                {/*    color="danger"*/}
                {/*    className="remove"*/}
                {/*>*/}
                {/*    <Close/>*/}
                {/*</Button>*/}
            </div>
        )
    }

    componentDidMount() {
        // const {filterFormValues, fetchList, history } = this.props
        // const { search } = history.location
        // const query = {...filterFormValues, ...qs.parse(search) }
        // fetchList({ query })
        // this.props.history.push({ search: qs.stringify(query) })

        /*  audit data  */
        const {fetchList, findAuditLineItemsData, history } = this.props
        const page = Number(qs.parse(history.location.search).page)
        this.setState( { pageIndex: page || 0 })
        fetchList({
            params: {path: `sortHistory`},
            query: {page }
        })

    }

    pageChanged = (pageIndex) =>  {
        this.props.fetchList({
            params: {path: `sortHistory`},
            query: {page: pageIndex + 1,}
        })
        this.setState({pageIndex})
        this.props.history.push({search: window.location.search.replace(/&page=[0-9+]|page=[0-9+]/i, '') + '&page=' + (pageIndex + 1)})
    }

    isChecked = (item) => this.state.checkedItems.has(item.id)

    toggleCheck = (item) => {
        const {checkedItems} = this.state;
        let newCheckedItems = checkedItems;

        if (this.isChecked(item)) {
            newCheckedItems.delete(item.id)
        } else {
            newCheckedItems.add(item.id)
        }

        this.setState({
            checkedItems: newCheckedItems
        })
    }

    componentWillUnmount() {
        this.props.invalidate();
    }

    /*  Open modal  */
    modalOpen = (selectedIndex, item) => {
        /*  audit data  */
        const { findAuditLineItems } = this.props;
        findAuditLineItems({params: {path: `v3/line-items/${item.id}/audit`}})

        this.setState({
            selectedIndex,
            modalOpen: true,
        });
    };
    /*  Close modal  */
    modalClose = () => { this.setState({ modalOpen: false }) };
    
    render() {
        const {translate, userInfo, history, fetchList, findAuditLineItemsData, findLineItemsData, pagination, loading, data } = this.props
        const {modal, snackBar} = this.state
        const auditData = findAuditLineItemsData;



        return (
            <GridItem xs={12} sm={12} md={12}>

            {userInfo.role === "provider" ?
                <ProviderManagerFilter
                    showYesNo={true}
                    showDownload={true}
                    history={history}
                    translate={translate}
                    fetchList={fetchList}
                    userInfo={userInfo}
                    permissions={this.props.permissions}
                /> 
                :
                <SortsManagerFilter
                    showYesNo={true}
                    showDownload={true}
                    history={history}
                    translate={translate}
                    fetchList={fetchList}
                    permissions={this.props.permissions}
                />            
            }
            
                {this.state.modalOpen && auditData.length !== 0 && <SortsManagerHistoryModal
                    isOpen={this.state.modalOpen} 
                    onClose={this.modalClose} 
                    updateLineItem={this.updateLineItem}
                    styleModal="modalStyle"
                    auditData={auditData}
                    data={data && data[this.state.selectedIndex]} 
                />}

                <GridContainer>
                    <Card>
                        <CardBody>
                            {
                                <ReactTable
                                    data={data && data.map((item, index) => ({
                                        ...item,
                                        actions: this.makeActions(item),
                                        modalAction: (
                                            <button
                                                className={(item.package.status == "PROCESSING" || item.status == "pending_reserve") ? "tbn_check-modal" : "tbn_check-modal active"}
                                                onClick={() =>  this.modalOpen(index, item) }
                                                disabled={item.package.status == "PROCESSING" || item.status == "pending_reserve"}
                                            >
                                               <PlaylistAddCheck />
                                            </button>
                                        )
                                    }))}
                                    
                                    getTrGroupProps={(state, rowInfo, column) => {
                                        return {
                                            "data-orderLineId": rowInfo && rowInfo.original.id
                                        };
                                    }}

                                    loading={loading}
                                    pages={pagination && pagination.totalPages}
                                    manual
                                    page={this.state.pageIndex}
                                    onPageChange={this.pageChanged}
                                    defaultPageSize={(pagination && pagination.perPage) || 5 }
                                    pageSize={pagination && pagination.perPage}

                                    onSortedChange={this.onSortedChange}
                                    columns={[
                                        {
                                            Header: <div style={{whiteSpace: 'pre-wrap', lineHeight: 1}}>{translate('arrivalTime')}</div>,
                                            accessor: 'package.receivedAt',
                                            width: 150,
                                            style: {
                                                whiteSpace: 'pre-wrap'
                                            },
                                            Cell: row => (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    {row.original.package && row.original.package.receivedAt}
                                                    <br/>
                                                   <b>{row.original.package && row.original.package.courierName}</b>
                                                </div>
                                            ),
                                            sortable: false,
                                        },
                                        {
                                            Header: translate('orderId'),
                                            accessor: "package.code",
                                            width: 70,
                                            sortable: false,
                                        },
                                        {
                                            Header: translate('merchant'),
                                            // accessor: "package.merchant",
                                            minWidth: 100,
                                            width: 120,
                                            sortable: false,
                                            Cell: row => (
                                                <div style={{whiteSpace: 'pre-wrap'}}>
                                                    <b className="provider_color">{row.original.provider.name}</b> <br/>
                                                    {row.original.package.merchant}
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: translate('item'),
                                            minWidth: 200,
                                            Cell: row => (
                                                <div style={{whiteSpace: 'pre-wrap'}}>
                                                   {row.original.url ? 
                                                        <a 
                                                            href={row.original.url} 
                                                            target="_blank" 
                                                            className="productName Link"
                                                        >
                                                            {row.original.title}
                                                        </a>
                                                        :
                                                        <span className="productName">
                                                            {row.original.title}
                                                        </span>
                                                    }
                                                    <br />
                                                    {row.original.price} ₾
                                                    <br/>
                                                    {row.original.quantity}
                                                </div>
                                            ),
                                            sortable: false,
                                        },
                                        {
                                            Header: translate('receiver'),
                                            Cell: row => (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    {row.original.package && row.original.package.recipientName}
                                                    <br/>
                                                    {row.original.package && row.original.package.recipientPhoneNumber}
                                                    <br/>
                                                    {row.original.package && row.original.package.recipientAddress}
                                                </div>
                                            ),
                                            sortable: false,
                                        },
                                        {
                                            Header: translate('history'),
                                            accessor: "modalAction",
                                            width: 100,
                                            sortable: false,
                                        },
                                        {
                                            Header: translate('status'),
                                            accessor: "status",
                                            sortable: false,
                                            width: 140,
                                        },
                                        {
                                            Header: translate('comment'),
                                            accessor: 'comment.note',
                                            width: 150,
                                            sortable: false,
                                        },
                                        // {
                                        //     Header: translate('actions'),
                                        //     accessor: "actions",
                                        //     width: 100,
                                        //     sortable: false,
                                        // }
                                    ]}
                                    previousText={translate('previousText')}
                                    nextText={translate('nextText')}
                                    loadingText={translate('loadingText')}
                                    pageText={translate('pageText')}
                                    ofText={translate('ofText')}
                                    showPaginationBottom
                                    showPageSizeOptions={false}
                                    className="-striped -highlight"
                                />

                            }
                        </CardBody>
                    </Card>

                </GridContainer>
                <Modal
                    loading={false}
                    modalLoading={false}
                    {...modal}
                />
                {this.state.alert.open && <ConfirmSweetAlert {...this.state.alert} />}
                <SnackBar
                    place={'br'}
                    color="info"
                    icon={AddAlert}
                    message={snackBar.message}
                    open={snackBar.openPos}
                    closeNot={this.closeSnackBar}
                />
            </GridItem>

        )
    }
}

export default SortsManagerHistory

