import React from 'react'

import GridContainer from '../../../components/Grid/GridContainer'
import Button from '../../../components/CustomButtons/Button'
import CardBody from '../../../components/Card/CardBody'
import Card from '../../../components/Card/Card'

import loading from '../../../assets/singleLoading.svg'
import { toNumber } from "../../../helpers";
import Form from './Form'

class ProviderForm extends React.Component {

  componentDidMount() {
    const {fetchSingle} = this.props
    fetchSingle(this.props.match.params.providerId)
  }

  componentWillReceiveProps(nextProps) {
    const {updateLoading, singleLoading, history, changeFormValues} = this.props
    if (updateLoading && !nextProps.updateLoading && !nextProps.updateError) {
      history.push('/providers')
    }

    if (singleLoading && !nextProps.singleLoading) {
      changeFormValues(nextProps.singleData)
    }

  }

  componentWillUnmount() {
    this.props.resetForm()
  }

  onClickUpdate = () => this.props.resetForm && this.props.update(this.props.match.params.providerId, this.props.formValues)

  render() {
    const {translate, match, formValues, userInfo} = this.props
    return (
      <Card>
        <CardBody>
          <GridContainer justify="space-around">
            {
              this.props.singleLoading &&
              <div style={{
                position: 'absolute',
                backgroundColor: 'rgba(255,255,255, 0.7)',
                width: '100%',
                height: '100%',
                zIndex: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <img src={loading} style={{width: 50}} alt={'loading'} />
              </div>
            }

            <Form translate={translate} userInfo={userInfo}/>
            <GridContainer justify="center">
              <Button
                color={'secondary'}
                onClick={() => window.history.back()}
              > {translate('cancel')} </Button>

              <Button
                color={'primary'}
                onClick={() => this.props.update(match.params.providerId, {
                  ...formValues,
                  times: formValues.times && formValues.times.split(',').map(toNumber)
                })}
              > {translate('update')} </Button>

            </GridContainer>


          </GridContainer>
        </CardBody>
      </Card>
    )
  }
}

export default ProviderForm