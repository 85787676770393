import {transformNumber, transformToFloatingNumber} from "../transformers"
import {
  validateEmail,
  validateExistence,
  validateIdentityNumber,
  validateFloatingNumber,
  validatePhoneNumber,
  minLength
} from "../validators"
import {combineValidators} from "ductus"
export default ({
  name: {
    initialValue: '',
    // validate: validateExistence
  },
  email: {
    // validate: combineValidators(
    //   validateEmail,
    //   validateExistence
    // )
  },
  identityNumber: {
    // transform: transformNumber(11),
    // validate: validateIdentityNumber,
  },
  phoneNumber: {
    // transform: transformNumber(9),
    // validate: combineValidators(
    //   validateExistence,
    //   validatePhoneNumber
    // ),
  },
  altPhoneNumber: {
    // transform: transformNumber(9),
    // validate: validatePhoneNumber
  },
  // credit: {
  //   initialValue: '0',
  //   transform: transformToFloatingNumber,
  //   validate: validateFloatingNumber,
  //
  // },
  isWorking: {},
  comment: {},
  isActive: {
    initialValue: 1
  },
  hubs: {},
  password: {
    // validate: minLength(6)
  },
  passwordConfirm: {
    // validate: minLength(6)
  },
  transportationType: {
    initialValue: 'OTHER'
  }
})