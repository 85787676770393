import React from 'react'
import PaymentFilter from './PaymentFilter'
import Accordion from '../../components/Accordion/Accordion'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'

import FinanceTable from './FinanceTable'
import {ip} from "../../defaultConfigs";

const GeneralPayments = ({translate, items, fetchItems, hubs, fetchHubs, addPayment, payments, paymentsLoading, name, remove, token}) => {

  const paymentKeys = Object.keys(payments)
  const paymentsMap = paymentKeys.map((payKey, k) => {

    const { info, stats, headers } = payments[payKey]

    return ({
      title: info.name,
      subTitle: `${info.from} - ${info.to}`,
      expanded: k === (paymentKeys.length - 1),
      key: payKey,
      remove: () => remove(payKey),
      export: `http://${ip}/download/finances/hubs/${info.id}/${name}?token=${token}&from=${info.from}&to=${info.to}`,
      content:
        <Card style={{margin: 0}}>
          <CardBody>
            <FinanceTable
              data={stats}
              cols={headers}
              translate={translate}
            />
          </CardBody>
        </Card>
    })
  })
  return (
    <GridItem xs={12}>
      <PaymentFilter
        translate={translate}
        fetchHubs={fetchHubs}
        hubs={hubs}
        addPayment={addPayment}
        name={name}
      />

      <GridContainer>
        <Accordion
          collapses={paymentsMap}
        />

      </GridContainer>
    </GridItem>
  )
}

export default GeneralPayments