

import React from 'react'
import {couriers, hubs} from '../../ducks'
import {financeServices } from '../../ducks/services'
import { connect } from 'react-redux'

import {getTranslator} from "redux-l10n/dist/selectors"
import GeneralPayments from './GeneralPayments'

const remove = (payload) => financeServices.paymentCouriers.removeFinance( financeServices.paymentCouriers.REMOVE_PAYMENT, payload)


const mapStateToProps = (state) => ({
  hubs: hubs.getList(state).data,
  payments: financeServices.paymentCouriers.getData(state),
  paymentsLoading: financeServices.paymentCouriers.getRequestPending(state),
  token: state.authorization.auth.token,
  name: 'couriers',
})
const mapDispatchToProps = {
  fetchHubs: hubs.fetchList,
  addPayment: financeServices.paymentCouriers.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralPayments)