import React from 'react'
import SweetAlert from "react-bootstrap-sweetalert"
import {withStyles} from '@material-ui/core'
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"

const ConfirmSweetAlert = ({showCancel = true, title = '', confirmText = '', confirmFun, cancelText = '', hideAlert, message = '', classes, success = false, warning = false,}) => (
  <SweetAlert
    warning={warning}
    success={success}
    style={{display: "block", marginTop: "-100px"}}
    title={title}
    onConfirm={confirmFun}
    onCancel={hideAlert}
    confirmBtnCssClass={
      classes.button + " " + classes.success
    }
    cancelBtnCssClass={
      classes.button + " " + classes.danger
    }
    confirmBtnText={confirmText}
    cancelBtnText={cancelText}
    showCancel={showCancel}
  >
    {typeof message === 'function' ? message() : message}
  </SweetAlert>
)

export default withStyles(sweetAlertStyle)(ConfirmSweetAlert)
