import React from "react"
import CustomInput from "../CustomInput/CustomInput"
import {withStyles, InputAdornment, FormControl, InputLabel, Select, MenuItem} from "@material-ui/core"

import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";


const SelectField = ({name, value, items, error, onChange, type = 'text', id = undefined, label = '', icon: Icon, iconPosition = 'end', formControlProps = {fullWidth: true}, inputProps = {}, classes}) => (
  <FormControl
    className={classes.selectFormControl}
    {...formControlProps}
  >
    {
      label &&
      <InputLabel
        htmlFor="multiple-select"
        className={classes.selectLabel}
      >
        {label}
      </InputLabel>
    }

    <Select
      value={value === null ? -1 : value}
      onChange={ e => onChange(e.target.value)}
      MenuProps={{className: classes.selectMenu}}
      classes={{select: classes.select}}
      inputProps={{
        name,
        id
      }}
    >
      {
        items.map( (item, key) =>
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={item.value}
          >
            {item.text}
          </MenuItem>
        )
      }

    </Select>
  </FormControl>
)


export default withStyles(customSelectStyle)(SelectField)
