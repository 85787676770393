import {handleActions} from 'redux-actions'
import {theme} from "../../defaultConfigs"

const SIDEBAR_BG_COLOR = 'THEME/SIDEBAR/BG_COLOR'
const SIDEBAR_COLOR = 'THEME/SIDEBAR/COLOR'
const SIDEBAR_MINI_ACTIVE = 'THEME/SIDEBAR/MINI_ACTIVE'
const SIDEBAR_LOGO_TEXT = 'THEME/SIDEBAR/LOGO_TEXT'
const BUTTON_COLOR = 'THEME/BUTTON/COLOR'
const COLOR = 'THEME/COLOR'
const SIDEBAR = 'THEME/SIDEBAR'

const DEFAULT = 'THEME/DEFAULT'

export default ({
  SIDEBAR_BG_COLOR,
  SIDEBAR_COLOR,
  SIDEBAR_MINI_ACTIVE,
  SIDEBAR_LOGO_TEXT,
  BUTTON_COLOR,
  COLOR,
  SIDEBAR,
  DEFAULT,

  changeSidebarBackgroundColor: (color) => ({type: SIDEBAR_BG_COLOR, payload: color}),
  changeSidebarColor: (color) => ({type: SIDEBAR_COLOR, payload: color}),
  changeSidebarMini: (value) => ({type: SIDEBAR_MINI_ACTIVE, payload: value}),
  changeSidebarLogo: (text) => ({type: SIDEBAR_LOGO_TEXT, payload: text}),
  changeSidebarConfig: (which, value) => ({type: SIDEBAR, payload: {which, value}}),
  changeButtonColor: (button, color) => ({type: BUTTON_COLOR, payload: {button, color}}),
  changeColor: (which, color) => ({type: COLOR, payload: {which, color}}),

  getSidebarBg: (state) => state.preferences.theme.sidebar.sidebarBgColor,
  getSidebarColor: (state) => state.preferences.theme.sidebar.sidebarColor,
  getMiniActive: (state) => state.preferences.theme.sidebar.miniActive,
  getLogoText: (state) => state.preferences.theme.sidebar.logoText,
  getButtonColor: (state, type) => state.preferences.theme.button[type],
  getColors: (state) => state.preferences.theme.Mui.colors,
  getThemeSidebar: (state) => state.preferences.theme.sidebar,
  getThemeMui: (state) => state.preferences.theme.Mui,

  setDefault: () => ({type: DEFAULT}),

  reduce: handleActions({
      [SIDEBAR_BG_COLOR]: (state, {payload}) => ({...state, sidebar: {...state.sidebar, sidebarBgColor: payload}}),
      [SIDEBAR_COLOR]: (state, {payload}) => ({...state, sidebar: {...state.sidebar, sidebarColor: payload}}),
      [SIDEBAR_MINI_ACTIVE]: (state, {payload}) => ({...state, sidebar: {...state.sidebar, miniActive: payload}}),
      [SIDEBAR_LOGO_TEXT]: (state, {payload}) => ({...state, sidebar: {...state.sidebar, logoText: payload}}),

      [BUTTON_COLOR]: (state, {payload: {button, color}}) => ({...state, button: {...state.button, [button]: color}}),
      [COLOR]: (state, {payload: {which, color}}) => ({
        ...state,
        Mui: {...state.Mui, colors: {...state.Mui.colors, [which]: color}}
      }),
      [SIDEBAR]: (state, {payload: {which, value}}) => ({
        ...state,
        sidebar: {...state.sidebar, [which]: value}
      }),

      [DEFAULT]: () => ({...theme})

    },
    theme)
})