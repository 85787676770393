import {createForm} from "ductus"
import {transformToNumber} from "../transformers"

export default createForm({
  statePath: 'forms.filter.packagesFilterForm',
  name: 'packagesFilterForm',
  fields: {
    code: {},
    hubId: {},
    status: {},
    providerId: {},
    recipientPhoneNumber: {},
    branchId: {},
    minPrice: {
      transform: transformToNumber
    },
    maxPrice: {
      transform: transformToNumber
    },
    startDate: {},
    endDate: {},
    courierId: {},
    paymentType: {},
    type: {},
  },
})
