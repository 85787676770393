import {branchesProviderFilterForm, branchForm} from "../../forms";
import {branches, districts} from "../../ducks";
import connect from "react-redux/es/connect/connect";

import ProviderBranchList from './ProviderBranchList'
import modal from "../../ducks/modal"

const mapStateToProps = (state) => ({
  creationFormValues: branchForm.create.getValues(state),
  creationFormValid: branchForm.create.isValid(state),
  editionFormValid: branchForm.edit.isValid(state),
  editionFormValues: branchForm.edit.getValues(state),
  data: branches.getList(state) && branches.getList(state).data,
  singleData: branches.getSingle(state),
  pagination: branches.getList(state).pagination,
  loading: branches.isListRequestPending(state),
  singleLoading: branches.isSingleRequestPending(state),
  storeLoading: branches.isStoreRequestPending(state),
  updateLoading: branches.isUpdateRequestPending(state),
  removeLoading: branches.isRemoveRequestPending(state),
  listErrors: branches.getListRequestError(state),
  singleErrors: branches.getSingleRequestError(state),
  storeErrors: branches.getStoreRequestError(state),
  updateErrors: branches.getUpdateRequestError(state),
  removeErrors: branches.getRemoveRequestError(state),
  districts: districts.getList(state).data,
  filterFormValues: branchesProviderFilterForm.getValues(state),
})

const mapDispatchToProps = {
  fetchList: branches.fetchList,
  fetchSinge: branches.fetchSingle,
  storeItem: branches.store,
  updateItem: branches.update,
  removeItem: branches.remove,
  changeEditFormValues: branchForm.edit.changeValues,
  resetCreationFormValues: branchForm.create.resetValues,
  resetEditionFormValues: branchForm.edit.resetValues,
  fetchDistricts: districts.fetchList,

  openModal: modal.openModal,
  closeModal: modal.closeModal
}


export default connect(mapStateToProps, mapDispatchToProps)(ProviderBranchList)

