import {createForm} from "ductus"
import {transformToNumber} from "../transformers"

export default createForm({
  container: 'forms',
  statePath: 'forms.filter.providerFilterForm',
  name: 'providerFilterForm',
  fields: {
    name: {},
    // phoneNumber: {
    //   transform: transformToNumber
    // },
    hubId: {},
    isActive: {
      initialValue: 1
    }
  },
})
