import {createServiceGroup} from "../functions/createGroup";
import {createResource} from "../../helpers";

const transformFinancesData = (response,inlineData) => {
  const {name, from, to, headers, data, id} = response.body.data
  return {
    ...inlineData,
    [`${name}-${from}-${to}`]: {
      info: {name, from, to, id},
      stats: data,
      headers
    }
  }
}

const transformFin = ({ body: { data }} ) => data

const removeFinance = (type, which) => ({ type, payload: which })

const removeFinanceHandler = (state, { payload }) => {
  let newData = {...state.data}
  delete newData[payload]
  return {
    ...state,
    data: newData
  }
}


export default createServiceGroup({
  name: 'financeServices',
  items: {
    providers: {
      name: 'providerFinances',
      transformData: transformFinancesData,
      additionalActionTypes: {
        REMOVE_FINANCE: `REMOVE_FINANCE/PROVIDER`
      },
      additionalActionCreators: {
        removeFinance: removeFinance
      },
      additionalHandlers: {
        [`REMOVE_FINANCE/PROVIDER`]: removeFinanceHandler
      }
    },
    hubs: {
      name: 'hubFinances',
      transformData: transformFinancesData,
      additionalActionTypes: {
        REMOVE_FINANCE: `REMOVE_FINANCE/HUB`
      },
      additionalActionCreators: {
        removeFinance: removeFinance
      },
      additionalHandlers: {
        [`REMOVE_FINANCE/HUB`]: removeFinanceHandler
      }
    },
    couriers: {
      name: 'courierFinances',
      transformData: transformFinancesData,
      additionalActionTypes: {
        REMOVE_FINANCE: `REMOVE_FINANCE/COURIER`
      },
      additionalActionCreators: {
        removeFinance: removeFinance
      },
      additionalHandlers: {
        [`REMOVE_FINANCE/COURIER`]: removeFinanceHandler
      }
    },
    week: {
      name: 'weekFinances',
      transformData: transformFin,
      initialData: []
    },
    month: {
      name: 'monthFinances',
      transformData: transformFin,
      initialData: []
    },
    quarter: {
      name: 'quarterFinances',
      transformData: transformFin,
      initialData: []
    },

    paymentProviders: {
      name: 'providerPayments',
      transformData: transformFinancesData,
      additionalActionTypes: {
        REMOVE_PAYMENT: `REMOVE_PAYMENT/PROVIDER`
      },
      additionalActionCreators: {
        removeFinance: removeFinance
      },
      additionalHandlers: {
        [`REMOVE_PAYMENT/PROVIDER`]: removeFinanceHandler
      }
    },
    paymentCouriers: {
      name: 'courierPayments',
      transformData: transformFinancesData,
      additionalActionTypes: {
        REMOVE_PAYMENT: `REMOVE_PAYMENT/COURIER`
      },
      additionalActionCreators: {
        removeFinance: removeFinance
      },
      additionalHandlers: {
        [`REMOVE_PAYMENT/COURIER`]: removeFinanceHandler
      }
    },

    own: {
      name: 'ownFinances',
      transformData: transformFinancesData,
      additionalActionTypes: {
        REMOVE_FINANCE: `REMOVE_FINANCE/OWN`
      },
      additionalActionCreators: {
        removeFinance: removeFinance
      },
      additionalHandlers: {
        [`REMOVE_FINANCE/OWN`]: removeFinanceHandler
      }
    },
  },
  generic: {
    type: 'get',
    createResource,
    initialData: {}
  }
})
