import React from 'react'
import { SketchPicker } from 'react-color'
import { Button } from "../../components";

class SketchExample extends React.Component {
  state = {
    displayColorPicker: false,
    color: '#ffffff',
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChangeComplete = (color) => {
    const { colorName, changeColor } = this.props
    changeColor(colorName, color.hex)
  }

  render() {
    const { color, colorName } = this.props
    return (
      <div>
        <Button color={colorName.replace('Color', '')} onClick={this.handleClick} >
          {colorName}
        </Button>
        { this.state.displayColorPicker ? <div className={'picker-popover'} >
          <div className={'picker-cover'} onClick={ this.handleClose }/>
          <SketchPicker color={ color } onChangeComplete={ this.handleChangeComplete } />
        </div> : null }

      </div>
    )
  }
}

export default SketchExample