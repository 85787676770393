
import { createServiceGroup} from "../functions/createGroup";
import {createResource} from "../../helpers";

export default createServiceGroup({
  name: 'userServices',
  items: {
    passwordUpdate: {
      requestName: 'update',
      name: 'userPasswordUpdate',
      resource: '/user/password/update',
      // initialData: { data: [] },
      // createResource,
      // transformData: (response) => ({
      //   data: response.body.data,
      // })
    },
    emailUpdate: {
      requestName: 'update',
      name: 'userEmailUpdate',
      resource: '/user/email/update',
      // initialData: { data: [] },
      // createResource,
      // transformData: (response) => ({
      //   data: response.body.data,
      // })
    },
  },
  generic: {
    type: 'post'
  }
})
