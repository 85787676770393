import React from 'react'
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles"
import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Close from "@material-ui/icons/Close"
import Button from "../CustomButtons/Button.jsx"
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle.jsx"
import singleLoading from '../../assets/singleLoading.svg'

import {connect} from 'react-redux'
import modal from '../../ducks/modal'
import PreOrder from '../../views/Dashboard/PreOrders'
import CreateBranchForm from '../../views/Branches/CreateBranchForm'
import EditBranchForm from '../../views/Branches/EditBranchForm'

import CreateSizeForm from '../../views/Sizes/CreateSizeForm'
import EditSizeForm from '../../views/Sizes/EditSizeForm'

import CreateCourierForm from '../../views/Couriers/CreateCourierForm'
import EditCourierForm from '../../views/Couriers/EditCourierForm'

import CreateDispatcherForm from '../../views/Dispatchers/CreateDispatcherForm'
import EditDispatcherForm from '../../views/Dispatchers/EditDispatcherForm'

import CreateDistrictForm from '../../views/Districts/CreateDistrictForm'
import EditDistrictForm from '../../views/Districts/EditDistrictForm'

import CreateHubForm from '../../views/Hubs/CreateHubForm'
import EditHubForm from '../../views/Hubs/EditHubForm'

import CreateManagerForm from '../../views/Managers/CreateManagerForm'
import EditManagerForm from '../../views/Managers/EditManagerForm'

import WarehouseCreateManagerForm from '../../views/WarehouseManager/WarehouseCreateManagerForm'
import WarehouseEditManagerForm from '../../views/WarehouseManager/WarehouseEditManagerForm'

import ProviderCreatePackage from '../../views/Packages/ProviderCreatePackage'
import EditPackage from '../../views/Packages/EditPackage'
import DispatcherCreatePackage from '../../views/Packages/DispatcherCreatePackage'
import DispatcherEditPackage from '../../views/Packages/DispatcherEditPackage'
import AttachCourier from '../../components/AttachCourier/AttachCourier'
import SupplierPost from '../../components/SupplierPost/SupplierPost'

import HistoryModal from '../../views/Packages/History'
import { BulkPackages } from '../BulkPackages'
import TariffsForm from '../../views/DistrictTariffs/TariffsForm'
import ExcelErrors from '../ExcelErrors'

import loading from '../../assets/singleLoading.svg'

import * as d from '../../ducks'

const ducks = Object.keys(d).reduce((reduction, duck ) => ({
  ...reduction,
  [duck]: {
    modalLoading: d[duck].isSingleRequestPending,
    loading: d[duck].isStoreRequestPending
  }
}), {})


const Components = {
  CreateBranchForm,
  EditBranchForm,
  CreateDispatcherForm,
  EditDispatcherForm,
  CreateDistrictForm,
  EditDistrictForm,
  CreateCourierForm,
  EditCourierForm,
  CreateHubForm,
  EditHubForm,
  CreateManagerForm,
  EditManagerForm,
  WarehouseCreateManagerForm,
  WarehouseEditManagerForm,
  ProviderCreatePackage,
  CreateSizeForm,
  EditSizeForm,
  EditPackage,
  DispatcherCreatePackage,
  DispatcherEditPackage,
  HistoryModal,
  AttachCourier,
  SupplierPost,
  PreOrder,
  TariffsForm,
  BulkPackages,
  ExcelErrors
}

function Transition(props) {
  return <Slide direction="down" {...props} />
}


const Modal = (
  props ) => {
    const {
      values, classes,
      close, translate,
      uploadLoading,
      modalLoading = false,
      loading = false,
      modal: {
        form,
        isOpen, Component, allowSubmitClick,
        componentProps, noSubmit, submit, submitText, closeText, title, small = false,
      }
    } = props
  const Comp = Components[Component]
  
  return (
    <Dialog
      // ref={(divElement) => this.divElement = divElement}
      style={{overflow: 'hidden', display: 'block'}}
      classes={{
        root: classes.center,
        paper: small ? classes.smallerModal + ' ' : '' + classes.modal + ' ' + classes.sss
      }}
      open={isOpen}
      transition={Transition}
      keepMounted
      // onClose={close}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description">
      {modalLoading &&
      <div className={'modalLoading'}>
        <img src={singleLoading} style={{width: 50}} alt={'loading'} />
      </div>
      }
      <div style={{overflowY: 'auto'}}>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={close}
          >
            <Close className={classes.modalClose}/>
          </Button>
          <h4 className={classes.modalTitle}>
            {title}
          </h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {Component && <Comp {...componentProps} handleSubmit={submit} translate={translate} />}
          {loading && <img src={loading} alt={'loading'} width={30} height={30}/>}
        </DialogContent>

      </div>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
        style={{textAlign: 'right', marginRight: 0}}
      >

        <Button
          color={'secondary'}
          onClick={close}
        >
          {translate(closeText || 'close')}
        </Button>
        {
          !noSubmit &&
          <Button color={'primary'} disabled={allowSubmitClick === false || componentProps === undefined || loading || uploadLoading} onClick={() => {
            submit(values)
            // close()
          }}>
            {translate(submitText)}
          </Button>
        }

      </DialogActions>
    </Dialog>
  )

}

const mapStateToProps = (state) => {
  const modal = state.modal
  const duck = modal.duck
  const form = modal.form
  return ({
    modal,
    values: form && form.getValues(state),
    modalLoading: duck && ducks[duck].modalLoading(state),
    loading: duck && ducks[duck].loading(state),
    uploadLoading: state.uploadLoading.loading
  })
}

const dispatchToProps = {
  close: modal.closeModal
}

export default connect(mapStateToProps, dispatchToProps)(withStyles(modalStyle)(Modal))
