import React from 'react'
import ReactTable from 'react-table'

import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'
import CreditCard from '@material-ui/icons/CreditCard'
import AttachMoney from '@material-ui/icons/AttachMoney'
import History from '@material-ui/icons/History'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'
import ConfirmSweetAlert from '../../components/SweetAlert/SweetAlert'
import SnackBar from '../../components/Snackbar/Snackbar'
import {AddButton} from '../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"

import {providerPackageForm, dispatcherPackageForm} from '../../forms'

import ProviderCreatePackage from './ProviderCreatePackage'
import DispatcherCreatePackage from './DispatcherCreatePackage'
import DispatcherEditPackage from './DispatcherEditPackage'
import EditPackage from './EditPackage'
import {packagePermissions} from "../../permissions"
import moment from "moment"
import PackagesFilterForm from './Filter'
import qs from "query-string"
import ListComponent from "../ListComponent";
import CheckboxField from "../../components/fields/CheckboxField";
import HistoryModal from './History'

const packageListColumns = (translate, allDelete, openFormModal) => [
    // {
    //     Header: <CheckboxField onChange={selectAllToDelete} name={'packages-all'} value={allDelete}/>,
    //     accessor: 'checkDelete',
    //     width: 50,
    //     sortable: false,
    // },
    {
        Header: translate('arrivalTime'),
        accessor: "receiveTime",
        Cell: row => (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
        <span onClick={() =>
            openFormModal({
                small: false,
                submitText: 'edit',
                noSubmit: true,
                Component: 'HistoryModal',
                componentProps: {
                    packageId: row.value.id
                }
            })}
              style={{cursor: 'pointer'}}
        >
          {moment(row.value.receivedAt).format('DD-MM-YYYY HH:mm')} <br/>
            {row.original.deliveredAt && moment(row.original.deliveredAt).format('DD-MM-YYYY HH:mm')}

        </span> <br/>
                {row.value.code}
            </div>),
        width: 140
    },
    {
        Header: translate('provider'),
        accessor: 'provider',
        Cell: row => (
            <div>
                <b className="provider_color">{row.original.provider.name}</b>
                <b className="branch_color"> - {row.original.branch.name}</b> <br/> 
                {row.value.phoneNumber} <br/>
                {row.original.pickupAddress}
            </div>
        ),
        width: 175,
    },
    {
        Header: translate('receiver'),
        accessor: 'recipient',
        sortable: false,
        Cell: row => (
            <div>
                {row.value.name} <br/>
                {row.original.recipientAddress} <br/>
                {row.value.phone}
            </div>
        )
    },
    {
        Header: translate('price'),
        accessor: 'typeAndPrice',
        sortable: false,
        Cell: row => (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    // alignItems: 'center',
                }}
            >
                <span
                    style={{color: row.value.status === 'REJECTED' ? 'red' : 'inherit'}}> {translate(row.value.status)}</span>
                <br/>
                <span>
                            {row.value.type === 'CREDIT'
                                ? <CreditCard style={{width: 16, height: 16}}/>
                                : <AttachMoney style={{width: 16, height: 16}}/>}
                    <span> {row.value.price}/{row.value.deliveryFee} </span>
                          </span>
            </div>
        ),
        maxWidth: 120,
    },
    {
        Header: translate('courier'),
        accessor: 'courier',
        Cell: row => (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                {row.value && row.value.name}
            </div>),
        width: 150,
        maxWidth: 180,
    },
    {
        Header: translate('actions'),
        accessor: "actions",
        sortable: false,
        maxWidth: 100
    }
]


class PackageList extends ListComponent {

    state = {
        alert: {
            open: false,
        },
        snackBar: {openPos: false, message: ''},
        pageIndex: 0,
        selectedPackages: [],
        packages: {},
        columns: {
            checkDelete: 1,
            receiveTime: 1,
            provider: 1,
            recipient: 1,
            typeAndPrice: 1,
            courier: 1,
            actions: 1,
        }
    }

    filterColumns = col => this.state.columns[col.accessor]

    handleUpdate = (id) => (values) => this.props.updateItem(
        id,
        {...values, packagePrice: values.packagePrice.replace(/,/g, '')},
        {params: {path: `/packages`}}
    ).then(() => {
        const {filterFormValues, history} = this.props;
        const query = {...filterFormValues, ...qs.parse(history.location.search)}
        this.fetchList(query).then(() => this.props.closeModal());
    })

    makeActions = (item) => {
        const {removeItem, permissions, translate, userInfo, resetEditionFormValues, fetchSingle} = this.props
        const isProvider = userInfo.role === 'provider'
        return (
            <div className="actions-right">
                {
                    permissions[packagePermissions.update] &&
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() =>
                            this.openFormModal({
                                noSubmit: true,
                                Component: 'HistoryModal',
                                componentProps: {
                                    packageId: item.id,
                                },
                                small: false
                            })
                        }
                        color="warning"
                        className="edit"
                    >
                        <History/>
                    </Button>
                }

                {
                    permissions[packagePermissions.read] &&
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                            resetEditionFormValues()
                            fetchSingle(item.id)
                            this.openFormModal({
                                submitText: 'edit',
                                submit: this.handleUpdate(item.id),
                                noSubmit: isProvider,
                                Component: isProvider ? 'EditPackage' : 'DispatcherEditPackage',
                                componentProps: {
                                    providerId: isProvider ? userInfo.providerId : item.providerId,
                                    deliveryFee: userInfo.deliveryFee,
                                    permissions,
                                    handleSubmit: this.handleUpdate(item.id)
                                },
                                form: isProvider ? providerPackageForm.edit : dispatcherPackageForm.edit,
                                duck: 'packages',
                                small: false
                            })
                        }
                        }
                        color="warning"
                        className="edit"
                    >
                        <Dvr/>
                    </Button>
                }

                {
                    permissions[packagePermissions.delete] &&
                    item.status === 'processing' &&
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => this.showAlert({
                            title: translate('toDelete', {item: item.code}),
                            confirmText: translate('delete'),
                            cancelText: translate('close'),
                            hideAlert: this.hideAlert,
                            confirmFun: () => removeItem(item.id),
                            warning: true
                        })}
                        color="danger"
                        className="remove"
                    >
                        <Close/>
                    </Button>
                }
            </div>
        )
    }

    fetchList = (query) => this.props.fetchList({...this.props.filterFormValues, ...query})

    toggleColumn = (colName) => this.setState({
        columns: {
            ...this.state.columns,
            [colName]: !this.state.columns[colName]
        }
    })

    componentDidMount() {
        const {filterFormValues, fetchList, history, userInfo} = this.props
        const {permissions, role} = userInfo
        const {search} = history.location
        const query = {...filterFormValues, ...qs.parse(search)}
        let newCols = {...this.state.columns}
        console.log(query, "t")
        fetchList(query)
        history.push({search: qs.stringify(query)})

        if (role === 'provider') {
            !permissions.packages_show_courier && this.toggleColumn('courier')
            !permissions.courier_arrival_times && this.toggleColumn('estimatedTime')

            if (!permissions.packages_show_courier) {
                newCols = {
                    ...newCols,
                    courier: 0
                }
                newCols = {
                    ...newCols,
                    courier: 0
                }
            }

            if (!permissions.courier_arrival_times) {
                newCols = {
                    ...newCols,
                    estimatedTime: 0
                }
                newCols = {
                    ...newCols,
                    estimatedTime: 0
                }
            }
            this.setState({
                columns: {...newCols}
            })
        }

        !permissions[packagePermissions.delete] && this.setState({
            columns: {
                ...newCols,
                checkDelete: 0
            }
        })
    }


    componentWillReceiveProps(nextProps) {
        const {
            loading,
            bulkDeletePending,
            fetchList,
            permissions,
            translate
        } = this.props

        if (permissions[packagePermissions.delete] && loading && !nextProps.loading) {
            this.setState({
                packages: nextProps.data && nextProps.data.reduce((reduction, current) => ({
                    ...reduction,
                    [current.id]: {id: current.id, toDelete: 0}
                }), {})
            })
        }

        if (bulkDeletePending && !nextProps.bulkDeletePending) {
            fetchList({query: {page: 1, isActive: 1}})

            if (!nextProps.bulkDeleteError) {
                this.setState({
                    snackBar: {
                        openPos: 'bl',
                        message: translate('packagesDeleted'),
                        color: 'info'
                    }
                })

                setTimeout(() => {
                    this.setState({
                        snackBar: {
                            openPos: '',
                        }
                    })
                }, 2000)
            }
        }
    }

    renderCheckDelete = (item) => {
        const stateItem = this.state.packages[item.id]
        return (
            stateItem &&
            // item.status === 'processing' &&
            <div>
                <CheckboxField
                    name={`package-${item.id}`}
                    value={stateItem.toDelete}
                    onChange={() => this.toggleDeleteCheck(item, stateItem)}
                />
            </div>
        )
    }

    toggleDeleteCheck = (item, stateItem) => this.setState({
        packages:
            {
                ...this.state.packages,
                [item.id]:
                    {
                        id: stateItem.id,
                        toDelete: Number(!stateItem.toDelete)
                    }
            }
    })

    deleteSelection = () => this.showAlert({
        title: this.props.translate('deleteSelectedPackagesMessage'),
        confirmText: this.props.translate('delete'),
        cancelText: this.props.translate('close'),
        hideAlert: this.hideAlert,
        confirmFun: () => {
            this.hideAlert()
            this.props.bulkDelete({}, {packageIds: Object.keys(this.state.packages).filter(pack => this.state.packages[pack].toDelete)})
        },
        warning: true
    })

    // selectAllToDelete = () => {
    //     const {packages, allDelete} = this.state
    //     let newPackages = {}
    //     Object.keys(packages).forEach(pack => newPackages[pack] = {...packages[pack], toDelete: !allDelete})
    //     this.setState({packages: newPackages, allDelete: Number(!allDelete)})
    // }

    // pageChanged = (pageIndex) => this.fetchList({page: pageIndex + 1, ...this.props.filterFormValues})

    handleSubmit = (values) => this.props.storeItem(values)

    renderSubTable = (row) => {
        const {translate} = this.props;

        const data = (row.items || [])

        return (
            <ReactTable
                data={data && data.map(item => ({
                    ...item,
                    // actions: this.makeActions(item)
                }))}
                noDataText={translate('itemsNotFound')}
                loading={false}
                defaultPageSize={data.length || 4}
                pageSize={data.length || 4}
                manual
                columns={[
                    // {
                    //     Header: translate('orderId'),
                    //     accessor: "package.id",
                    //     width: 70,
                    //     sortable: false,
                    // },
                    {
                        Header: translate('title'),
                        accessor: 'title',
                        sortable: false,
                    },
                    {
                        Header: translate('price'),
                        accessor: 'price',
                        sortable: false,
                        width: 120,
                    },
                    {
                        Header: translate('quantity'),
                        accessor: "quantity",
                        sortable: false,
                        width: 120,
                    },
                    {
                        Header: translate('orderLineId'),
                        accessor: 'orderLineId',
                        sortable: false,
                        width: 80,
                    },
                    {
                        Header: translate('status'),
                        accessor: 'status',
                        sortable: false,
                        width: 150,
                    },
                    {
                        Header: translate('comment'),
                        accessor: 'comment.note',
                        sortable: false,
                        width: 150,
                    },
                    {
                        Header: translate('actions'),
                        accessor: "actions",
                        width: 80,
                        sortable: false,
                    }
                ]}
                showPagination={false}
                className="-striped -highlight"
            />
        )
    }

    render() {
        const {
            history,
            data,
            loading,
            pagination,
            translate,
            permissions,
            userInfo,
            canCreateOrder
        } = this.props
        const {snackBar} = this.state
        const {providerId} = userInfo

        const toDeleteLength = Object.keys(this.state.packages).filter(pack => this.state.packages[pack].toDelete).length;

        return (
            <GridItem xs={12}>
                <PackagesFilterForm
                    history={history}
                    translate={translate}
                    userInfo={userInfo}
                    exp={permissions['packages_export']}
                    closeSnackBar={this.closeSnackBar}
                    openSnackBar={(message, color = 'info') => this.setState({
                        snackBar: {
                            openPos: 'bl',
                            message,
                            color
                        }
                    })}
                />

                {/* {
                    canCreateOrder &&
                    <AddButton
                        hidden={!permissions[packagePermissions.create]}
                        onClick={() => this.openFormModal({
                            submitText: 'add',
                            submit: this.handleSubmit,
                            duck: 'packages',
                            form: userInfo.role === 'provider' ? providerPackageForm.create : dispatcherPackageForm.create,
                            Component: userInfo.role === 'provider' ? 'ProviderCreatePackage' : 'DispatcherCreatePackage',
                            componentProps: {
                                providerId,
                                deliveryFee: userInfo.deliveryFee,
                                handleSubmit: this.handleSubmit,
                                permissions
                            },
                            small: false,
                            // content: userInfo.role === 'provider'
                            //   ?
                            //   <ProviderCreatePackage
                            //     translate={translate}
                            //     providerId={providerId}
                            //     deliveryFee={userInfo.deliveryFee}
                            //     handleSubmit={this.handleSubmit}
                            //     permissions={permissions}
                            //   />
                            //   :
                            //   <DispatcherCreatePackage
                            //     translate={translate}
                            //     handleSubmit={this.handleSubmit}
                            //   />
                        })}
                    >
                    </AddButton>
                }
 */}

                <GridContainer>
                    <Card>
                        <CardBody>
                        {/* {userInfo.role !== 'provider' && 
                            <Button
                                disabled={toDeleteLength === 0}
                                color={'primary'}
                                onClick={this.deleteSelection}
                            >
                                {translate('deleteSelection')}
                            </Button>
                        } */}

                            <ReactTable
                                page={this.state.pageIndex}
                                data={data && data.map(item => ({
                                    ...item,
                                    actions: this.makeActions(item),
                                    checkDelete: this.renderCheckDelete(item)
                                }))}
                                loading={loading}
                                pages={pagination && pagination.totalPages}
                                manual
                                onPageChange={this.pageChanged}
                                defaultPageSize={(pagination && pagination.perPage) || 5}
                                pageSize={pagination && pagination.perPage}
                                onSortedChange={this.onSortedChange}
                                columns={packageListColumns(translate, 
                                    // this.selectAllToDelete, 
                                    this.state.allDelete, this.openFormModal).filter(this.filterColumns)}
                                showPaginationBottom
                                showPageSizeOptions={false}
                                className="-striped -highlight"
                                SubComponent={row => {
                                    return (
                                        <div style={{padding: "20px"}}>
                                            {this.renderSubTable(row.original)}
                                        </div>
                                    );
                                }}
                            />
                        </CardBody>
                    </Card>

                </GridContainer>
                {this.state.alert.open && <ConfirmSweetAlert {...this.state.alert} />}
                <SnackBar
                    place={'br'}
                    color={snackBar.color || "info"}
                    icon={AddAlert}
                    message={snackBar.message}
                    open={snackBar.openPos}
                    closeNot={this.closeSnackBar}
                />
            </GridItem>
        )
    }
}

export default PackageList
