import React from 'react'
import { Field, withForm } from "ductus"

import { Face, Dialpad, Home, Phone } from '@material-ui/icons';

import {
  CheckboxField,
  GridContainer,
  GridItem,
} from '../../components'

import { sizeForm } from "../../forms"

import { TextField, ComboField } from "../../components"
import { number } from 'prop-types';


const SizeForm = ({ districts, translate, handleSubmit, values }) => (
  <GridContainer justify="center">
    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }} className={'good-form'}>
      <GridItem xs={12}>
        <h6>{translate('sizeDescription')}</h6>
        <Field
          name={'name'}
          label={translate('sizeName')}
          icon={Face}
          component={TextField}
          inputProps={{
            disabled: true
          }}
        />

        <Field
          type={'number'}
          name={'price'}
          label={translate('sizePrice')}
          icon={Dialpad}
          component={TextField}
        />

        {/* <Field
          name={'phoneNumber'}
          label={translate('phoneNumber', {})}
          icon={Phone}
          component={TextField}
        />

        <Field
          name={'districtId'}
          label={translate('district')}
          icon={Face}
          component={ComboField}
          items={districts}
        /> */}

        {/* <Field
          name={'isActive'}
          label={translate('active')}
          icon={Face}
          component={CheckboxField}
        /> */}

      </GridItem>
      <button type={'submit'} style={{ display: 'none' }}>b</button>
    </form>
  </GridContainer>
)


export default withForm(sizeForm.edit)(SizeForm)
