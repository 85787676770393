import React from 'react'
import Map from './FullScreenMap'
import Locations from './Locations'

const CouriersMap = () =>
  <Map>
    <Locations />
  </Map>

export default CouriersMap
