import React from "react"
import moment from 'moment'
import DatePicker from 'react-mobile-datepicker'

const joinFormats = (dateFormat, timeFormat) => {
  let arr = []
  dateFormat && arr.push('YYYY-MM-DD')
  timeFormat && arr.push('HH:mm:ss')

  return arr.join(' ')
}

const monthMap = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

class DateTime extends React.Component {

  state = {
    isOpen: false,
  }


  handleClick = () => {
    this.setState({ isOpen: true });
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
  }

  handleSelect = (time) => {
    const { dateFormat, timeFormat, onChange } = this.props
    const timeString = moment(time).format(joinFormats(dateFormat, timeFormat))
    onChange(timeString)
    this.setState({ time, isOpen: false });
  }

  render() {
    const { confirmText, cancelText, label, value, dateFormat, timeFormat } = this.props
    return (
      <div className="mobile-datetime">
        <a
          className="select-btn"
          onClick={this.handleClick}>
          {label}:  {value && moment(value).format(joinFormats(dateFormat, timeFormat))}
        </a>

        <DatePicker
          isPopup={true}
          min={new Date()}
          dateFormat={['DD', ['MM', (month) => monthMap[month]], 'hh', 'mm']}
          value={new Date(value)}
          isOpen={this.state.isOpen}
          onSelect={this.handleSelect}
          onCancel={this.handleCancel}
          confirmText={confirmText}
          cancelText={cancelText}
        />
      </div>
    );
  }
}


export default DateTime
