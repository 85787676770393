import React from 'react'
import {Field, withForm} from 'ductus'
import {passwordChangeForm} from '../../forms'
import {GridItem, TextField, Button} from '../../components/index'

const PasswordChange = ({translate, handleSubmit, disabled, isValid}) => (
  <form autoComplete="off">
    <input type={'text'} name={'email'}
           style={{height: 0, overflow: 'hidden', padding: 0, margin: 0, border: 'none'}}/>
    <input type={'password'} name={'password'}
           style={{height: 0, overflow: 'hidden', padding: 0, margin: 0, border: 'none'}}/>

    <GridItem xs={12}>
      <Field
        name={'oldPassword'}
        label={translate('oldPassword')}
        component={TextField}
        type={'password'}
        showSuccess={false}
      />
      <Field
        name={'password'}
        label={translate('password')}
        component={TextField}
        type={'password'}
      />
      <Field
        name={'passwordConfirm'}
        label={translate('passwordConfirm')}
        component={TextField}
        type={'password'}
      />

      <div>
          <Button
              color={'primary'}
              disabled={disabled || !isValid}
              onClick={handleSubmit}
          >
              {translate('update')}
          </Button>
      </div>
    </GridItem>

  </form>
)

export default withForm(passwordChangeForm)(PasswordChange)
