import { createForms } from './create'

import {packages as packDuck} from '../ducks'
const {  couriers, districts, hubs, providers, sizes, branchesProvider, branches, packages, managers,  warehouse_managers, dispatchers, dispatcherPackages, providerPackages } = createForms({
  forms: [
    'couriers',
    'districts',
    'hubs',
    'providers',
    'sizes',
    'branches',
    'branchesProvider',
    'packages',
    'managers',
    'warehouse_managers',

    'dispatchers',
    { name: 'dispatcherPackages', errorActionTypes: { create: packDuck.STORE_RECEIVE_ERROR, edit: packDuck.UPDATE_RECEIVE_ERROR }},
    { name: 'providerPackages', errorActionTypes: { create: packDuck.STORE_RECEIVE_ERROR, edit: packDuck.UPDATE_RECEIVE_ERROR }},
    ],
  formsContainer: 'forms'
})

export {
  couriers as courierForm,
  districts as districtForm,
  hubs as hubForm,
  providers as providerForm,
  sizes as sizeForm,
  branchesProvider as branchesProviderForm,
  branches as branchForm,
  packages as packageForm,
  managers as managerForm,
  warehouse_managers as warehouseManagersForm,

  dispatchers as dispatcherForm,
  dispatcherPackages as dispatcherPackageForm,
  providerPackages as providerPackageForm,
}

export {default as couriersFilterForm} from './filterForms/couriersFilterForm'
export {default as managersFilterForm} from './filterForms/managersFilterForm'
export {default as warehouseManagersFilterForm} from './filterForms/warehouseManagersFilterForm'

export {default as dispatchersFilterForm} from './filterForms/dispatchersFilterForm'
export {default as providerFilterForm } from './filterForms/providerFilterForm'
export {default as sizesFilterForm } from './filterForms/sizesFilterForm'
export {default as hubFilterForm } from './filterForms/hubFilterForm'
export {default as branchesFilterForm } from './filterForms/branchesFilterForm'
export {default as branchesProviderFilterForm } from './filterForms/branchesProviderFilterForm'
export {default as packagesFilterForm } from './filterForms/packageFilterForm'
export {default as recipientsFilterForm } from './filterForms/recipientsFilterForm'
export {default as sortsDashForm } from './filterForms/sortsManageDashFIlter'
export {default as authForm} from './authForm'
export {default as emailChangeForm } from './emailChange'
export {default as bulkPackagesForm } from './bulkPackages'
export {default as passwordChangeForm } from './passwordChange'
export {default as tariffsForm } from './tariffs'
