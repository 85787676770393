import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

import Checkbox from "@material-ui/core/Checkbox";


class EnhancedTableHead extends React.Component {

    render() {
        const { columns, order, orderBy, translate, withCheck, toggleSelectAll, allSelected, sortable = false} = this.props;

        return (
            <TableHead>
                <TableRow>
                    {
                        withCheck &&
                        <TableCell padding="checkbox" className="checkbox" style={{ paddingLeft: 5 }}>
                            <Checkbox
                                color="default"
                                checked={allSelected}
                                onClick={(e) => {
                                   e.stopPropagation();
                                    toggleSelectAll()
                                }}
                            />
                        </TableCell>
                    }

                    {columns.map(
                        column => (
                            <TableCell
                                className={column.id}
                                key={column.id}
                                padding="none"
                                sortDirection={orderBy === column.id ? order : false}
                            >
                                <TableSortLabel
                                    title="Sort"
                                    active={orderBy === column.id}
                                    direction={order}
                                    onClick={() => {
                                        if(sortable){
                                            this.props.onRequestSort(column.id);
                                        }
                                    }}
                                >
                                    {translate(column.label)}
                                </TableSortLabel>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

class EnhancedTable extends React.Component {
    state = {
        order: '',
        orderBy: '',
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.props.onSortChange(order, orderBy);
        this.setState({order, orderBy});
    };

    handleChangePage = (event, page) => {
        // this.setState({ page });
        this.props.onPageChange(page)
    };

    render() {
        const { translate, pagination = {},className, columns, columnsMap, loading, selected, toggleSelected, toggleSelectAll, allSelected, data, onRowClick, withCheck, showColumns, sortable = false} = this.props;
        const {order, orderBy} = this.state;

        return (
            <Paper>
                <div style={{ overflowY: 'auto' }} className={className}>
                    <Table aria-labelledby="tableTitle" className="dashTable">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            columns={columns.filter(col => showColumns.has(col.id))}
                            translate={translate}
                            withCheck={withCheck}
                            sortable={sortable}
                            toggleSelectAll={toggleSelectAll}
                            allSelected={allSelected}
                        />
                        <TableBody>
                            {data
                                .map(n => {
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => onRowClick(n)}
                                            style={{ cursor: 'pointer'}}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={n.id}
                                        >
                                            {
                                                withCheck &&
                                                <TableCell padding="checkbox" className="checkbox">
                                                    <Checkbox
                                                        color="default"
                                                        checked={selected && selected.has(n.id)}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            toggleSelected(n)
                                                        }
                                                        }
                                                    />
                                                </TableCell>
                                            }
                                            {columns.filter(col => showColumns.has(col.id)).map((column, key) => columnsMap.get(column.id)(n, key) )}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={pagination.total || 0}
                    rowsPerPage={pagination.perPage || 5}
                    page={pagination.currentPage - 1 || 0}
                    backIconButtonProps={{
                        'aria-label': translate('previousPage'),
                    }}
                    nextIconButtonProps={{
                        'aria-label': translate('nextPage'),
                    }}
                    onChangePage={this.handleChangePage}
                />
            </Paper>
        );

    }
}

export default EnhancedTable;
