import React from 'react'
import { connect } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { theme } from "./ducks/preferences";

const Mui = ({ Mui, children })=> (
  <MuiThemeProvider theme={createMuiTheme(Mui)}>
    {children}
  </MuiThemeProvider>
)

const mapStateToProps = (state) => ({
  Mui: theme.getThemeMui(state)
})

export default connect(mapStateToProps, null)(Mui)