import React from 'react'

import {withForm, Field} from 'ductus'
import {providerPackageForm, dispatcherPackageForm} from '../../forms'
import {
  GridContainer,
  GridItem,
  ComboField,
  TextField,
  RadioGroupField,
  DateTimeField
} from '../../components'

import CreditCard from '@material-ui/icons/CreditCard'
import AttachMoney from '@material-ui/icons/AttachMoney'
import {connect} from 'react-redux'
import {providers, districts, branches} from '../../ducks'
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

import MobileDate from '../../components/fields/MobileDateTimeField'
import { Button } from "@material-ui/core";
import FileDownload from "@material-ui/icons/FileDownload";
import {ip} from "../../defaultConfigs";
import FileUploadIcon from '@material-ui/icons/FileUpload';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DescriptionIcon from '@material-ui/icons/Description';

const initialPackage = {
  recipient_name: "", 
  recipient_phone_number: "", //5
  city: "",
  recipient_address: "",
  title: "",
  quantity: "",
  price: "",
  comment: ""
}
class ProviderCreatePackage extends React.Component {
  state = {
    deliverPackages: [
      initialPackage
    ],
    clearDeliverPackages: [
      initialPackage
    ],
    // Initially, no file is selected
    selectedFile: null,
    selectedFileName: null,
    showSelectedFile: true,
  };
  
  // On file select (from the pop up)
  onFileChange = event => {
    const {changeValue} = this.props;
    // Update the state
    this.setState({ 
      showSelectedFile: false,
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0] && event.target.files[0].name
     });
    changeValue('import', event.target.files[0])
  };

  componentDidMount() {
    const {fetchBranches, fetchDistricts, providerId, deliveryFee, changeFormValue, changeFormValues} = this.props

    fetchBranches({params: {path: `/providers/${providerId}/branches`}, query: {'without-pagination': 1}})
    fetchDistricts({query: {'without-pagination': 1}})


    changeFormValue('deliveryFee', deliveryFee || '' )
    changeFormValues({
      recipientName: 'Izibox', 
      recipientPhoneNumber: '599601040', 
      recipientAddress: 'ფარსადანასი #10',
      packagePrice: '0',
    })
  }
  
  branchSelectionChange = (val) => {
    const branch = this.props.branches.filter(b => b.id === val)[0]
    const {changeValues} = this.props
    branch &&
    changeValues({
      pickupDistrictId: branch.districtId,
      pickupAddress: branch.address
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.submitted !== this.props.submitted) {
      this.setState({
        deliverPackages: [ initialPackage ],
        selectedFile: null,
        selectedFileName: null,
        showSelectedFile: true
      })
      
      /* << bulck upload file clear >> */
      this.props.changeValue('import', '')
      
      /* << table row upload info clear >> */
      this.props.changeFormValue('deliverPackages', JSON.stringify(this.state.clearDeliverPackages))
     
    }

  }

  componentWillReceiveProps(nextProps){
    const { branches } = nextProps
    if(!this.props.branches.length && branches.length){
      const branch = branches[0]
      this.props.changeValues({
        branchId: branch.id,
        pickupDistrictId: branch.districtId,
        pickupAddress: branch.address
      })
    }
  }

  handleSubmitForm = (e) => {
    e.preventDefault()
    this.props.handleSubmit(this.props.values)
  }

  tableChangeHandler = (e, i) => {
    const key = e.target.name;
    const value = e.target.value;

    /*  phone number chack  */
    // if(key === "recipient_phone_number" && value.length === 0) {
    //   return;
    // } 
    
    this.setState(state => {
      const currentItem = {...state.deliverPackages[i]};
      currentItem[key] = value;
      state.deliverPackages[i] = currentItem
      this.props.changeFormValue('deliverPackages', JSON.stringify(state.deliverPackages))
      return {
        ...state
      }
    })

  }

  addRow = (e) => {
    e.preventDefault()
    this.setState(state => {
      state.deliverPackages.push(initialPackage)
      return {...state}
    })
  }
  removeRow = (e) => {
    e.preventDefault()
    this.setState(state => {
      state.deliverPackages.splice(-1)
      return {...state}
    })
  }


  render() {
    
    const {branches, districts, translate, permissions } = this.props;
    const fileUploadError = this.props.formSubmitErrors;
    const formSubmitBlankErrors = this.props.formSubmitBlankErrors;
    const errorStatus = this.props.errorStatus;
    const successStatus = this.props.successStatus;
    const city = this.props.districts;

    return (
      <GridContainer>
        <form onSubmit={this.handleSubmitForm} id={"dcd"} className={'good-form'}  autocomplete="off">
          <GridItem xs={12} sm={12} md={4} lg={4}>
            <legend>{translate('provider')}</legend>

            <Field
              name={'branchId'}
              label={translate('branch')}
              component={ComboField}
              items={branches}
              selectionChange={this.branchSelectionChange}
            />

            {
              permissions.packages_select_pickup_district &&
              <Field
                name={'pickupDistrictId'}
                label={translate('pickupDistrict')}
                component={ComboField}
                items={districts}
              />
            }

            <Field
              name={'pickupAddress'}
              label={translate('pickupAddress')}
              component={TextField}
            />

          </GridItem>


          <GridItem xs={12} sm={12} md={4} lg={4}>
            <legend> {translate('receiver')}</legend>

            {
              permissions.packages_select_delivery_district &&
              <Field
                name={'deliveryDistrictId'}
                label={translate('deliveryDistrict')}
                component={ComboField}
                items={districts}
              />
            }
            
            <Field
              name={'recipientName'}
              label={translate('recipientName')}
              component={TextField}
              inputProps={{
                disabled: true
              }}
            />

            <Field
              name={'recipientPhoneNumber'}
              label={translate('recipientPhone')}
              component={TextField}
              inputProps={{
                disabled: true
              }}
            />
              
            <Field
              name={'recipientAddress'}
              label={translate('recipientAddress')}
              component={TextField}
              inputProps={{
                disabled: true
              }}
            />

          </GridItem>


          <GridItem xs={12} sm={12} md={4} lg={4}>
            <legend> {translate('package')} </legend>

            {
              permissions.packages_preoder &&
              <React.Fragment>
                <BrowserView>
                  <Field
                    name={'visibleAfter'}
                    label={translate('preOrder')}
                    timeFormat="HH:mm"
                    dateFormat="DD-MM-YYYY"
                    component={DateTimeField}
                  />
                </BrowserView>

                <MobileView>
                  <Field
                    name={'visibleAfter'}
                    label={translate('preOrder')}
                    component={MobileDate}
                    timeFormat="HH:mm"
                    dateFormat="DD-MM-YYYY"
                    confirmText={translate('set')}
                    cancelText={translate('cancel')}
                  />
                </MobileView>
              </React.Fragment>
            }


            <GridItem xs={12} style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
              <Field
                name={'paymentType'}
                label={translate('paymentType')}
                component={RadioGroupField}
                items={[
                  {id: 'CASH', name: <AttachMoney/>},
                  {id: 'CREDIT', name: <CreditCard/>},
                ]}
                containerStyles={{
                  maxWidth: 150
                }}
                disabled={true}
              />

              <Field
                name={'packagePrice'}
                label={translate('money')}
                component={TextField}
                formControlProps={{
                  style: { minWidth: 60, flex: 1 }
                }}
                inputProps={{
                  disabled: true
                }}
              />
            </GridItem>


            <GridItem xs={6}>
              <Field
                name={'deliveryFee'}
                label={translate('deliveryFee')}
                component={TextField}
                inputProps={{
                  disabled: true
                }}
              />
            </GridItem>

          </GridItem>


          <GridItem xs={8}>
            <Field
              name={'comment'}
              label={translate('comment')}
              component={TextField}
              inputProps={{
                multiline: true,
                rows: 3
              }}
            />
          </GridItem>
          <GridItem xs={4}>
             
              <div className="recommendation_block">
                <div className="recomendation_btn btn-annim">
                  <span>* რეკომენდაცია </span>
                  <div className="recomendation_icon icon-annim">
                    <ErrorOutlineIcon/>
                  </div>
                </div>
                <div className="recomendation_note">
                  <p>გთხოვთ, შეკვეთის დამატებისას გამოიყენოთ ერთ-ერთი მეთოდი: <span className="excel">Excel-ით ატვირთვა</span> ან <span className="add_line">შეკვეთის ხელით დამატება</span>. 
                  ორივეს ერთდორულად გამოყენების შემთხვევაში აიტვირთება მხოლოდ <span className="excel">Excel-ი</span>.
                  </p>
                </div>
              </div>
            

              {permissions.packages_export && 
                <Button href={`http://${ip}/download/packages/export-example`} className={"download_file btn_file"} simple justIcon round> 
                  Excel შაბლონის ჩამოტვირთვა <FileDownload/>
                </Button>
              }
            <br/>
            <br/>
            {permissions.packages_upload &&
              <div class="file-upload">
                  <label for="upload" class="file-upload__label">Excel ფაილის ატვირთვა <FileUploadIcon/></label>
                  <input type="file" name="file-upload" id="upload" class="file-upload__input"
                    accept=".xlsx, .xlsm, .xlsb, .xltx" 
                    onChange={this.onFileChange}  
                  />
              </div>
            }
            <br/>
            {this.state.selectedFileName && <p className="upload_file_name file_name"><DescriptionIcon /> {this.state.selectedFileName}</p>}
            <br/>

          </GridItem>

          {permissions.packages_upload && successStatus == true && this.state.showSelectedFile && <GridItem xs={12} className="upload_successStatus success_block">
              {this.state.selectedFile  ? <h4>ფაილი წარმატებით აიტვირთა</h4> : <h4>წარმატებით აიტვირთა მონაცემები</h4>
              }
            </GridItem>
          }
          {successStatus !== true && fileUploadError.length !== 0 && this.state.showSelectedFile &&  <GridItem xs={12} className="upload_error_sms error_block">
              <h4>შეცდომა ფაილის ატვირთვისას</h4>
              <div className="upload_error_list">
                { errorStatus !== 500 ?

                  fileUploadError.map((item) => <p>{item[0]}</p> )
                :
                  <p>{formSubmitBlankErrors}</p>
                }
              </div>
            </GridItem>
          }
          
          {permissions.packages_upload && 
          <GridItem xs={12}>
            <GridContainer className="order__hader_block">
              <GridItem xs={10}>
                <h3>შეკვეთის ხელით დამატება</h3>

                <div className="recommendation_block">
                  <div className="recomendation_btn btn-annim">
                    <span>* რეკომენდაცია </span>
                    <div className="recomendation_icon icon-annim">
                      <ErrorOutlineIcon/>
                    </div>
                  </div>
                  <div className="recomendation_note">
                    <p>გთხოვთ, შეკვეთის დამატებისას გამოიყენოთ ერთ-ერთი მეთოდი: <span className="excel">Excel-ით ატვირთვა</span> ან <span className="add_line">შეკვეთის ხელით დამატება</span>. 
                    ორივეს ერთდორულად გამოყენების შემთხვევაში აიტვირთება მხოლოდ <span className="excel">Excel-ი</span>.
                    </p>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={2} className="order__table_btn_block">
                <Button onClick={this.addRow} className={"btn_row btn_ add_btn"}> + </Button>
                <Button onClick={this.removeRow} className={"btn_row btn_ remove_btn"}> - </Button>
              </GridItem>
            </GridContainer>


          
            <Table class="table__style">
              <TableHead class="th_style th_col">
                <TableCell class="col_style col_border"><h4>სახელი / გვარი</h4></TableCell>
                <TableCell class="col_style col_border"><h4>საკონტაქტო მობილური</h4></TableCell>
                <TableCell class="col_style col_border"><h4>მისამართი</h4></TableCell>
                <TableCell class="col_style col_border"><h4>ქალაქი</h4></TableCell>
                <TableCell class="col_style col_border"><h4>ნივთი</h4></TableCell>
                <TableCell class="col_style col_border"><h4>რაოდენობა</h4></TableCell>
                <TableCell class="col_style col_border"><h4>თანხა</h4></TableCell>
                <TableCell class="col_style col_border"><h4>კომენტარი</h4></TableCell>
              </TableHead>
              <TableBody>
                {this.state.deliverPackages.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell class="col_style col_border">                     
                      <input 
                          type="text" 
                          name={`recipient_name`}
                          value={item.recipient_name.replace(/[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/]/gi, '')}
                          onChange={ (e) => this.tableChangeHandler(e, index)}
                          className="provider__inp"
                      />
                    </TableCell>
                    <TableCell class="col_style col_border">
                      <input 
                          type="text" 
                          maxLength="9"
                          autocomplete="off"
                          name={`recipient_phone_number`}
                          value={item.recipient_phone_number.replace(/[^0-9.]/g, '')}
                          onChange={ (e) => this.tableChangeHandler(e, index)}
                          className="provider__inp mm"
                      />
                    </TableCell>
                    <TableCell class="col_style col_border">
                      <input 
                          type="text" 
                          name={`recipient_address`}
                          value={item.recipient_address.replace(/[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/]/gi, '')}
                          onChange={ (e) => this.tableChangeHandler(e, index)}
                          className="provider__inp"
                      />
                    </TableCell>
                    <TableCell class="col_style col_border">
                      <ComboField
                          name={`city`}
                          value={item.city}
                          items={city.slice(0).reverse().map((item) => ({ id: item.id, name: item.name }) ) }
                          onChange={ (value) => {
                            this.tableChangeHandler({target: {name: 'city', value }}, index)
                          }}
                          multiple={false}
                      />
                      
                    </TableCell>
                    <TableCell class="col_style col_border">
                      <input 
                          type="text" 
                          name={`title`}
                          value={item.title.replace(/[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/]/gi, '')}
                          onChange={ (e) => this.tableChangeHandler(e, index)}
                          className="provider__inp"
                      />
                    </TableCell>
                    <TableCell class="col_style col_border">
                      <input 
                          type="text"
                          pattern="[0-9]*"
                          name={`quantity`}
                          value={item.quantity.replace(/[^0-9.]/g, '')}
                          onChange={ (e) => this.tableChangeHandler(e, index)}
                          className="provider__inp"
                      />
                    </TableCell>
                    <TableCell class="col_style col_border">
                      <input 
                          type="text"
                          pattern="[0-9]*"
                          name={`price`}
                          value={item.price.replace(/[^0-9.]/g, '')}
                          onChange={ (e) => this.tableChangeHandler(e, index)}
                          className="provider__inp"
                      />
                    </TableCell>
                    <TableCell class="col_style col_border">
                      <input 
                          type="text" 
                          name={`comment`}
                          value={item.comment.replace(/[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/]/gi, '')}
                          onChange={ (e) => this.tableChangeHandler(e, index)}
                          className="provider__inp"
                      />
                    </TableCell>
                  </TableRow>
                )
                )}
              </TableBody>
            </Table>
          
          </GridItem>
          }

          <button type={'submit'} style={{display: 'none'}}>b</button>
        </form>
      </GridContainer>

    )
  }
}

const mapStateToProps = (state) => ({
  providers: providers.getList(state).data,
  districts: districts.getList(state).data,
  branches: branches.getList(state).data
})

export default connect(
  mapStateToProps,
  {
    fetchBranches: branches.fetchList,
    fetchProviders: providers.fetchList,
    fetchDistricts: districts.fetchList,
    changeFormValues: providerPackageForm.create.changeValues,
    changeFormValue: providerPackageForm.create.changeValue,
  }
)(withForm(providerPackageForm.create)(ProviderCreatePackage))