import {createServiceGroup} from "../functions/createGroup";
import {createResource} from "../../helpers";

const transformStatisticsData = (response,inlineData) => {
  const {data} = response.body
  const {name, intervals, from, to, packageCount} = data
  return {
    ...inlineData,
    [`${name}-${from}-${to}`]: {
      info: {name, from, to, packageCount},
      intervals:
        Object.keys(intervals)
          .map(interval => ({
            interval,
            value: intervals[interval]
          }))
    }
  }
}

const removeStat = (type, which) => ({ type, payload: which })

const removeStatisticHandler = (state, { payload }) => {
  let newData = {...state.data}
  delete newData[payload]
  return {
    ...state,
    data: newData
  }
}

export default createServiceGroup({
  name: 'statisticServices',
  items: {
    providers: {
      name: 'providerStatistics',
      additionalActionTypes: {
        REMOVE_STATISTIC: `REMOVE_STATISTIC/PROVIDER`
      },
      additionalActionCreators: {
        removeStat: removeStat
      },
      additionalHandlers: {
        [`REMOVE_STATISTIC/PROVIDER`]: removeStatisticHandler
      }
    },
    couriers: {
      name: 'courierStatistics',
      additionalActionTypes: {
        REMOVE_STATISTIC: `REMOVE_STATISTIC/COURIER`
      },
      additionalActionCreators: {
        removeStat: removeStat
      },
      additionalHandlers: {
        [`REMOVE_STATISTIC/COURIER`]: removeStatisticHandler
      }
    },
    dispatchers: {
      name: 'dispatcherStatistics',
      additionalActionTypes: {
        REMOVE_STATISTIC: `REMOVE_STATISTIC/DISPATCHER`
      },
      additionalActionCreators: {
        removeStat: removeStat
      },
      additionalHandlers: {
        [`REMOVE_STATISTIC/DISPATCHER`]: removeStatisticHandler
      }
    },
    own: {
      name: 'ownStatistics',
      additionalActionTypes: {
        REMOVE_STATISTIC: `REMOVE_STATISTIC/OWN`
      },
      additionalActionCreators: {
        removeStat: removeStat
      },
      additionalHandlers: {
        [`REMOVE_STATISTIC/OWN`]: removeStatisticHandler
      }
    },
  },
  generic: {
    type: 'get',
    createResource,
    initialData: [],
    transformData: transformStatisticsData,
  }
})
