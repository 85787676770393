import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import {Field} from 'ductus'

import DateTimeField from '../../../../components/fields/DateTimeField'
import CheckboxField from '../../../../components/fields/CheckboxField'

import GridItem from '../../../../components/Grid/GridItem'
import GridContainer from '../../../../components/Grid/GridContainer'

import SwitchField from "../../../../components/fields/SwitchField"
import TextField from "../../../../components/fields/TextField"
import Face from "@material-ui/icons/es/Face"


class FinancialConfiguration extends React.Component {

  sendState = () => this.state

  isValidated = () => true

  render() {

    const { getTranslator } = this.props

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <legend>{getTranslator('financialConfiguration')}</legend>

          {/* <Field
            name={'monthlyFee'}
            label={getTranslator('monthlyFee')}
            component={TextField}
            icon={Face}
          /> */}

          {/* <Field
            name={'deliveryFee'}
            label={getTranslator('deliveryFee')}
            component={TextField}
            icon={Face}
          /> */}


          {/* <GridContainer justify="center" align="center" style={{alignItems: 'center', padding: 0}}>
            <GridItem xs={6} sm={6} md={6}>
              <Field
                name={'serviceFee'}
                label={getTranslator('serviceFee')}
                component={TextField}
                icon={Face}
              />

            </GridItem>


            <GridItem xs={6} sm={6} md={6}>
              <Field
                name={'serviceFeeType'}
                label={getTranslator('percentage')}
                component={SwitchField}
                items={{
                  disabledValue: 'FIXED',
                  enabledValue: 'PERCENT'
                }}
              />
            </GridItem>

          </GridContainer> */}

          {/* <Field
            name={'hasDistrictFee'}
            label={getTranslator('hasDistrictFee')}
            component={CheckboxField}
            icon={Face}
          />

          <Field
            name={'invoiceDay'}
            label={getTranslator('invoiceDay')}
            component={TextField}
            icon={Face}
          /> */}

          {/* <Field
            name={'invoiceTime'}
            label={getTranslator('invoiceTime')}
            component={DateTimeField}
            dateFormat={false}
            timeFormat={'HH:mm'}
          /> */}

          {/* <Field
            name={'courierFee'}
            label={getTranslator('courierFee')}
            component={TextField}
            icon={Face}
          />

          <Field
            name={'deactivatedAt'}
            label={getTranslator('shutdownDate')}
            component={DateTimeField}
            dateFormat={'DD-MM-YYYY'}
            timeFormat={'HH:mm'}
          /> */}

          <Field
            name={'times'}
            label={getTranslator('times')}
            component={TextField}
            icon={Face}
          />

          <Field
            name={'isActive'}
            label={getTranslator('active')}
            icon={Face}
            component={CheckboxField}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles()(FinancialConfiguration)