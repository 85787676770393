import { connect } from 'react-redux'
import DistrictPrices from './DistrictPrices'
import { districtServices } from '../../ducks/services'
import {districts} from '../../ducks'
import modal from '../../ducks/modal'

const mapStateToProps = (state) => ({
  districts: districts.getList(state).data,
  allTariff: districtServices.getAllTariff.getData(state),
  storeLoading: districtServices.storeTariffs.getRequestPending(state),
  storeErrors: districtServices.storeTariffs.getRequestError(state),
  updateLoading: districtServices.updateTariffs.getRequestPending(state),
  updateErrors: districtServices.updateTariffs.getRequestError(state),
  getProviderDistrictsData: districtServices.getProviderDistricts.getData(state).data,

})

const mapDispatchToProps = {
  fetchDistricts: districts.fetchList,
  fetchAllTariff: districtServices.getAllTariff.run,
  storeTariffs: districtServices.storeTariffs.run,
  updateTariffs: districtServices.updateTariffs.run,
  getProviderDistricts: districtServices.getProviderDistricts.run,
  openModal: modal.openModal,
  closeModal: modal.closeModal,

}

export default connect(mapStateToProps, mapDispatchToProps)(DistrictPrices)