import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import PanTool from '@material-ui/icons/PanTool'
import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'

import ReactTable from 'react-table'
import qs from 'query-string'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'
import ConfirmSweetAlert from '../../components/SweetAlert/SweetAlert'
import SnackBar from '../../components/Snackbar/Snackbar'
import {AddButton} from '../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"

import {CheckboxField} from "../../components/fields"
import regularFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import CouriersFilterForm from './CouriersFilterForm'
import {courierPermissions} from "../../permissions"
import ListComponent from "../ListComponent"

import {courierForm} from "../../forms"

import AllOut from '@material-ui/icons/AllOut'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import DirectionsBike from '@material-ui/icons/DirectionsBike'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Motorcycle from '@material-ui/icons/Motorcycle'

const courierIcons = {
  PEDESTRIAN: <DirectionsRun/>,
  BIKE: <DirectionsBike/>,
  MOTORCYCLE: <Motorcycle/>,
  CAR: <DirectionsCar/>,
  OTHER: <AllOut/>
}

class CouriersList extends ListComponent {


  submitEdit = (item) => (formValues) => this.props.updateItem(item.id, {...item, ...formValues})

  makeActions = (item) => {
    const {resetEditionFormValues, fetchSinge, removeItem, translate, permissions, userInfo, endWork} = this.props
    return (
      <div className="actions-right">
        {
          permissions[courierPermissions.update] &&
          item.isWorking ?
            <Button
              justIcon
              round
              simple
              onClick={() => this.showAlert({
                title: translate('endWork'),
                confirmText: translate('end'),
                cancelText: translate('close'),
                hideAlert: this.hideAlert,
                confirmFun: () => {
                  this.hideAlert()
                  endWork({params: {path: `couriers/${item.id}/work-end`}})
                  setTimeout(() => {
                    this.fetchList({query: {page: this.state.pageIndex + 1, isActive: 1}})
                  }, 1000)
                },
                warning: true
              })}
              color="secondary"
              className="end"
            >
              <PanTool/>
            </Button>
            : <span style={{width: 41, height: 41, opacity: 0, display: 'inline-flex'}}></span>
        }
        {
          permissions[courierPermissions.update] &&
          <Button
            justIcon
            round
            simple
            onClick={() => {
              resetEditionFormValues()
              this.openFormModal({
                submitText: 'edit',
                submit: this.submitEdit(item),
                form: courierForm.edit,
                duck: 'couriers',
                Component: 'EditCourierForm',
                componentProps: {
                  hubs: userInfo.hubs,
                }
              })
              fetchSinge(item.id)
            }}
            color="warning"
            className="edit"
          >
            <Dvr/>
          </Button>
        }
        {
          permissions[courierPermissions.delete] &&
          <Button
            justIcon
            round
            simple
            onClick={() => this.showAlert({
              title: translate('toDelete', {item: item.name}),
              confirmText: translate('delete'),
              cancelText: translate('close'),
              hideAlert: this.hideAlert,
              confirmFun: () => removeItem(item.id),
              warning: true
            })}
            color="danger"
            className="remove"
          >
            <Close/>
          </Button>
        }
      </div>
    )
  }

  componentDidMount() {
    const {filterFormValues, fetchList, history} = this.props
    const {search} = history.location
    const query = {...filterFormValues, ...qs.parse(search)}
    fetchList({query})
    this.props.history.push({search: qs.stringify(query)})
  }


  render() {

    const {
      data,
      loading,
      userInfo,
      storeItem,
      pagination,
      translate,
      permissions,
      history
    } = this.props
    const {snackBar, pageIndex} = this.state
    const {hubs} = userInfo
    return (
      <GridItem xs={12}>

        <AddButton

          hidden={!permissions[courierPermissions.create]}
          onClick={() => this.openFormModal({
            submitText: 'add',
            submit: storeItem,
            Component: 'CreateCourierForm',
            componentProps: {
              hubs,
            },
            form: courierForm.create
          })}
        >
        </AddButton>

        <Card>
          <CardBody>
            <CouriersFilterForm history={history} translate={translate} hubs={hubs}/>
          </CardBody>
        </Card>

        <GridContainer>
          <Card>
            <CardBody>
              <ReactTable
                page={pageIndex}
                data={data.map(this.dataObject)}
                loading={loading}
                pages={pagination && pagination.totalPages}
                manual
                onPageChange={this.pageChanged}
                defaultPageSize={(pagination && pagination.perPage) || 5}
                pageSize={pagination && pagination.perPage}

                onSortedChange={this.onSortedChange}

                columns={[
                  {
                    Header: translate('name'),
                    accessor: "name"
                  },
                  {
                    Header: translate('phoneNumber', {}),
                    accessor: "phoneNumber",
                    sortable: false,
                  },
                  {
                    Header: translate('phoneNumber', {number: 2}),
                    accessor: "altPhoneNumber"
                  }, {
                    Header: translate('active'),
                    accessor: 'isActive',
                    Cell: row => (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <CheckboxField value={row.value} disabled={true}/>
                      </div>
                    )
                  },
                  {
                    Header: translate('transportationType'),
                    accessor: 'transportationType',
                    Cell: row => (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {
                          courierIcons[row.value && row.value]
                        }
                      </div>
                    )
                  },
                  {
                    Header: translate('actions'),
                    accessor: "actions",
                    sortable: false,
                    width: 130
                  }
                ]}
                previousText={translate('previousText')}
                nextText={translate('nextText')}
                loadingText={translate('loadingText')}
                pageText={translate('pageText')}
                ofText={translate('ofText')}
                showPaginationBottom
                showPageSizeOptions={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>

        </GridContainer>
        {this.state.alert.open && <ConfirmSweetAlert {...this.state.alert} />}
        <SnackBar
          place={'br'}
          color="info"
          icon={AddAlert}
          message={snackBar.message}
          open={snackBar.openPos}
          closeNot={this.closeSnackBar}
          close
        />

      </GridItem>
    )
  }
}


export default withStyles(regularFormsStyle)(CouriersList)