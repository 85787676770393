
import React from 'react'
import StatisticFilter from './StatisticFilter'
import Accordion from '../../components/Accordion/Accordion'
import loading from '../../assets/singleLoading.svg'
import {withStyles } from '@material-ui/core'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'

import StatsTable from './StatsTable'
import StatsChart from './StatsChart'

const styles = () => ({
  root: {
    minWidth: 200,
    // flex: 1,
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  },
  table: {
    // minWidth: 700,
  },
})



const GeneralStatistics = ({ translate, items, fetchItems, addStats, statistics, statisticsLoading, name, classes, remove, hubs, fetchHubs }) => {

  const statisticsKeys = Object.keys(statistics)
  const statisticsMap = statisticsKeys.map((statKey, k) => ({
    title: statistics[statKey].info.name,
    subTitle: `${statistics[statKey].info.from} - ${statistics[statKey].info.to}`,
    expanded: k === (statisticsKeys.length - 1),
    key: statKey,
    remove: () => remove(statKey),
    content:
      <div className={classes.container}>
        <GridContainer>
          <GridItem sm={12} md={5} >
            <StatsTable data={statistics[statKey]} translate={translate} classes={classes} />
          </GridItem>
          <GridItem sm={12} md={7} >
            <StatsChart data={statistics[statKey]} />
          </GridItem>
        </GridContainer>
      </div>
  }))
  return (
    <GridItem xs={12}>
      <StatisticFilter
        translate={translate}
        items={items}
        fetchItems={fetchItems}
        addStats={addStats}
        itemLabel={name}
        own={name === 'own'}
        fetchHubs={fetchHubs}
        hubs={hubs}
        hub={(name === 'providers') || (name === 'couriers') || (name === 'dispatchers')}
      />

      <GridContainer style={{ flexDirection: 'column'}}>
        <Accordion
          collapses={statisticsMap}
        />
        {
          statisticsLoading &&
          <div style={{ display: 'flex', justifyContent: 'center'}}>
            <img src={loading} width={50} height={50} alt={'loading'} />
          </div>
        }
      </GridContainer>
    </GridItem>
  )
}

export default withStyles(styles)(GeneralStatistics)