import {providers} from "../../../ducks";
import {providerForm} from "../../../forms";
import connect from "react-redux/es/connect/connect";

import ProviderForm from './ProviderForm'

const mapStateToProps = (state) => ({
  updateLoading: providers.isUpdateRequestPending(state),
  updateError: providers.getUpdateRequestError(state),
  singleLoading: providers.isSingleRequestPending(state),
  singleError: providers.getSingleRequestError(state),
  singleData: providers.getSingle(state),
  formValues: providerForm.edit.getValues(state)

})
const mapDispatchToProps = {
  fetchSingle: providers.fetchSingle,
  update: providers.update,
  resetForm: providerForm.edit.resetValues,
  changeFormValues: providerForm.edit.changeValues,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderForm)