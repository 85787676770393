import React from 'react'
import { Field, withForm } from "ductus"

import { Face, Home, Dialpad, Phone } from '@material-ui/icons';

// import CheckboxField from '../../components/fields/CheckboxField'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'

import { sizeForm } from "../../forms"

import { TextField, ComboField } from "../../components"


class SizeForm extends React.Component {

  componentDidMount() {
    const {changeValues} = this.props;

    changeValues({
      name: "",
      price: "",
    });
  }

  render() {
    const { districts, translate, handleSubmit, values } = this.props;

    return (
      <GridContainer justify="center">
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(values) }} className={'good-form'}>
          <GridItem xs={12}>
            <h6>{translate('sizeDescription')}</h6>
            <Field
              name={'name'}
              label={translate('sizeName')}
              icon={Face}
              component={TextField}
            />

            <Field
              type={'number'}
              name={'price'}
              label={translate('sizePrice')}
              icon={Dialpad}
              component={TextField}
            />

            {/* <Field
            name={'phoneNumber'}
            label={translate('phoneNumber', {})}
            icon={Phone}
            component={TextField}
          />

          <Field
            name={'districtId'}
            label={translate('district')}
            icon={Face}
            component={ComboField}
            items={districts}
          /> */}

            {/* <Field
            name={'isActive'}
            label={translate('active')}
            icon={Face}
            component={CheckboxField}
          /> */}

          </GridItem>
          <button type={'submit'} style={{ display: 'none' }}>b</button>
        </form>
      </GridContainer>
    );
  }
}


export default withForm(sizeForm.create)(SizeForm)
