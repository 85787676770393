import React from 'react'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import {withForm, Field} from 'ductus'
import {tariffsForm} from '../../forms'
import {ComboField, TextField} from '../../components/fields'

class TariffsForm extends React.Component {

  componentWillMount(){
    const { formValues } = this.props
    this.props.changeValues(formValues)
  }

  render() {
    const {translate, districts} = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={6}>
              <Field
                name={'fromDistrictId'}
                label={'fromDistrict'}
                component={ComboField}
                items={districts}
                disabled={true}
              />
            </GridItem>
            <GridItem xs={6}>
              <Field
                name={'toDistrictId'}
                label={'toDistrict'}
                component={ComboField}
                items={districts}
                disabled={true}
              />
            </GridItem>


          </GridContainer>
          <Field
            name="deliveryFee"
            label={translate('deliveryFee')}
            component={TextField}
          />
          {/* <Field
            name="courierFee"
            label={translate('courierFee')}
            component={TextField}
          />
          <Field
            name="approxTime"
            label={translate('approxTime')}
            component={TextField}
            items={districts}
            disabled={true}
          /> */}
        </GridItem>
      </GridContainer>
    )
  }
}

export default withForm(tariffsForm)(TariffsForm)