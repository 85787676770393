import {
  compose, createRequestHandlerMiddleware, handleFetch,
  withAuthorizationToken,
  withCors,
  withFilledUrl,
  withPathPrefix,
  withRequestHeaders,
  withResponseType
} from "redux-net";
import {ip, port} from "./defaultConfigs";


const transformRequest = compose(
  withCors,
  withResponseType('json'),
  withFilledUrl({
    scheme: 'http',
    host: ip,
    port: port,
  }),
  withPathPrefix('api/'),
  withRequestHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }),
  withAuthorizationToken(store => store.authorization.auth.token),
  (request) => {
    return ({
      ...request,
      body: request.body
          ? JSON.stringify(request.body)
          : null,
    })
  },
)

const transformResponse = (response) => response
const transformErrorResponse = (response) => response.body.errors

export default createRequestHandlerMiddleware(
  handleFetch,
  transformRequest,
  transformResponse,
  transformErrorResponse,
)
