import WarehouseHistory from "./WarehouseHistory";
import connect from "react-redux/es/connect/connect";
import {getLineItems, SORTS_DASHBOARD_INVALIDATE} from "../../../ducks/sortsManagerHistory";
import {sortsDashForm} from "../../../forms";
import sortsManagerHistoryServices from "../../../ducks/services/sortsManagerHistory";

const mapStateToProps = (state) => ({
    pending: state.sortsManagerHistory.dash.pending,
    data: state.sortsManagerHistory.dash.data.data,
    pagination: state.sortsManagerHistory.dash.data.pagination,
    loading: state.sortsManagerHistory.dash.pending,
    filterFormValues: sortsDashForm.getValues(state),
    updateLineItemsPending: sortsManagerHistoryServices.updateLineItems.getRequestPending(state),
    updateLineItemsError: sortsManagerHistoryServices.updateLineItems.getRequestError(state),
    findLineItemsPending: sortsManagerHistoryServices.findLineItems.getRequestPending(state),
    findLineItemsError: sortsManagerHistoryServices.findLineItems.getRequestError(state),
    findLineItemsData: sortsManagerHistoryServices.findLineItems.getData(state).data,
    
    findWarehouseAuditLineItemsData: sortsManagerHistoryServices.findWarehouseAuditLineItems.getData(state).data,
    findWarehouseAuditLineItemsPending: sortsManagerHistoryServices.findWarehouseAuditLineItems.getRequestPending(state),
    findWarehouseAuditLineItemsError: sortsManagerHistoryServices.findWarehouseAuditLineItems.getRequestError(state),
   
})

export default connect(mapStateToProps, {
   fetchList: getLineItems(true),
    findWarehouseAuditLineItems: sortsManagerHistoryServices.findWarehouseAuditLineItems.run,
    updateLineItems: sortsManagerHistoryServices.updateLineItems.run,
    findLineItems: sortsManagerHistoryServices.findLineItems.run,
    findLineItemsInvalidate: sortsManagerHistoryServices.findLineItems.invalidate,
    invalidate: () => ({ type: SORTS_DASHBOARD_INVALIDATE })
})(WarehouseHistory)
