import {warehouseManagersForm, warehouseManagersFilterForm} from "../../forms";
import {warehouse_managers} from "../../ducks";
import connect from "react-redux/es/connect/connect";

import WarehouseManagersList from './WarehouseManagersList'
import modal from "../../ducks/modal"

const mapStateToProps = (state) => ({
  data: warehouse_managers.getList(state) && warehouse_managers.getList(state).data,
  singleData: warehouse_managers.getSingle(state),
  pagination: warehouse_managers.getList(state).pagination,
  loading: warehouse_managers.isListRequestPending(state),
  singleLoading: warehouse_managers.isSingleRequestPending(state),
  storeLoading: warehouse_managers.isStoreRequestPending(state),
  updateLoading: warehouse_managers.isUpdateRequestPending(state),
  removeLoading: warehouse_managers.isRemoveRequestPending(state),
  listErrors: warehouse_managers.getListRequestError(state),
  singleErrors: warehouse_managers.getSingleRequestError(state),
  storeErrors: warehouse_managers.getStoreRequestError(state),
  updateErrors: warehouse_managers.getUpdateRequestError(state),
  removeErrors: warehouse_managers.getRemoveRequestError(state),
  filterFormValues: warehouseManagersFilterForm.getValues(state),

})
const mapDispatchToProps = {
  fetchList: warehouse_managers.fetchList,
  fetchSinge: warehouse_managers.fetchSingle,
  storeItem: warehouse_managers.store,
  updateItem: warehouse_managers.update,
  removeItem: warehouse_managers.remove,
  changeEditFormValues: warehouseManagersForm.edit.changeValues,
  resetCreationFormValues: warehouseManagersForm.create.resetValues,
  resetEditionFormValues: warehouseManagersForm.edit.resetValues,

  openModal: modal.openModal,
  closeModal: modal.closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseManagersList)
