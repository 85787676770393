import createDashboardDucks from "../functions/createDashboardDucks"
import {groupPackageData} from "../../helpers"
import {
  PACKAGE_CREATED,
  PACKAGE_DELETED,
  PACKAGE_PREORDER_SHOW,
  PACKAGE_STATUS_UPDATED,
  PACKAGE_UPDATED, PACKAGES_UPDATED
} from '../../eventTypes'
import {onPackageCreated, onPackageDeleted, onPackagesUpdated, onPackageUpdated} from './helpers'
const changedFalse = d => ({...d, changed: false })

export default createDashboardDucks('current', {
  [PACKAGE_CREATED]: onPackageCreated('CURRENT'),

  [PACKAGE_UPDATED]: onPackageUpdated('CURRENT'),
  [PACKAGES_UPDATED]: onPackagesUpdated('CURRENT'),

  //@todo: remove package status updated

  [PACKAGE_STATUS_UPDATED]: (state, {payload}) => ({
    ...state,
    changed: state.changed ? state.changed + 1 : 1,
    data: {
      ...state.data,
      data: (payload.status === 'PROCESSING' && payload.courier)
          ? [groupPackageData({...payload, changed: false}), ...state.data.data.filter(item => item.id !== payload.id).map(changedFalse) ]
          : (payload.status === 'DELIVERED') || (payload.status === 'REJECTED')
              ? state.data.data.filter(d => d.id !== payload.id).map(changedFalse)
              : state.data.data.map(d => d.id === payload.id ? groupPackageData({ ...payload, changed: true }) : {...d, changed: false })
    }
  }),

  [PACKAGE_DELETED]: onPackageDeleted,

  //@todo: remove package preorder show
  [PACKAGE_PREORDER_SHOW]: (state, { payload }) => {
    const { changed, data: { data } } = state
    const newChanged = payload.courier ? changed ? changed + 1 : 1 : changed || 0

    return ({
      ...state,
      changed: newChanged,
      data: {
        ...state.data,
        data: payload.courier
            ? [groupPackageData({...payload, changed: false }), ...data.filter(item => item.id !== payload.id).map(changedFalse) ]
            : data.map(changedFalse)
      }
    })
  },
})
