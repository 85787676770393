import {createForm} from "ductus"
import {transformToNumber, transformNumber} from "../transformers"

export default createForm({
  statePath: 'forms.filter.recipientsFilterForm',
  name: 'recipientsFilterForm',
  fields: {
    recipientPhoneNumber: {
      transform: transformNumber(9)
    },
    startDate: {},
    endDate: {},
    minPrice: {
      transform: transformToNumber
    },
    maxPrice: {
      transform: transformToNumber
    },
    providerId: {},
    hubId: {},
  },
})
