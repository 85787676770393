import {
  exactLength,
  validateExistence,
  validateNumber,
  numberRange,
  validatePhoneNumber,
  validateExistingEmail,
  existingNumber,
  validateArrayInput
} from "../validators"
import {
  // transformToArrInput,
  // transformToMaxNumber,
  // transformToMinNumber,
  transformToNumber,
  transformValueLengthExact
} from "../transformers"
import {combineValidators, compose} from "ductus"

export default ({
  // system data
  name: {
    validate: validateExistence
  },
  email: {
    validate: validateExistingEmail,
  },
  phoneNumber: {
    transform: compose(
      transformToNumber,
      transformValueLengthExact(9)
    ),
    validate: combineValidators(
      validatePhoneNumber,
      validateExistence
    ),
  },
  address: {
    validate: validateExistence
  },
  // legal data
  legalName: {
    validate: validateExistence
  },
  legalCode: {
    validate: validateExistence
  },
  legalAddress: {
    validate: validateExistence
  },
  bankName: {},
  bankAccountNumber: {
    validate: validateExistence
  },

  bankCode: {},

  // contact person
  contactName: {
    validate: validateExistence
  },
  contactPhoneNumber: {
    transform: compose(
      transformToNumber,
      transformValueLengthExact(9),
    ),
    validate: combineValidators(
      validateNumber,
      exactLength(9),
      validateExistence
    ),
  },
  contactEmail: {
    validate: validateExistingEmail,

  },


  comment: {},

  'finances_read_packages_price': {},
  'packages_preoder': {},
  'packages_show_courier': {},
  'courier_arrival_times': {},
  'packages_select_pickup_district': {},
  'packages_select_delivery_district': {},
  'branches_own_panel': {},
  'courier_accept_notification': {},
  'packages_upload': {},
  'packages_export': {},
  'locations_panel': {},

  hubs: {},

  //  Financial configuration
  monthlyFee: {
    transform: transformToNumber,
    validate: existingNumber,
  },
  deliveryFee: {
    transform: transformToNumber,
    validate: existingNumber,
  },
  serviceFee: {
    transform: transformToNumber,
    validate: existingNumber
  },
  serviceFeeType: {
    initialValue: 'FIXED',
    validate: validateExistence
  },
  hasDistrictFee: {
    initialValue: false,
    validate: validateExistence
  },

  sendInvoice: {
    initialValue: 0,
  },

  invoiceDay: {

  },
  invoiceTime: {},

  courierFee: {
    transform: transformToNumber,
    validate: existingNumber,
  },
  deactivatedAt: {},
  isActive: {
    initialValue: 1
  },
  password: {},
  passwordConfirm: {},
  times: {
    // transform: transformToArrInput,
    validate: validateArrayInput
  },
})
