import {districtForm} from "../../forms";
import {districts} from "../../ducks";
import connect from "react-redux/es/connect/connect";
import modal from '../../ducks/modal'

import DistrictList from './DistrictList'

const mapStateToProps = (state) => ({
  data: districts.getList(state).data,
  singleData: districts.getSingle(state),
  pagination: districts.getList(state).pagination,
  loading: districts.isListRequestPending(state),
  singleLoading: districts.isSingleRequestPending(state),
  storeLoading: districts.isStoreRequestPending(state),
  updateLoading: districts.isUpdateRequestPending(state),
  removeLoading: districts.isRemoveRequestPending(state),
  listErrors: districts.getListRequestError(state),
  singleErrors: districts.getSingleRequestError(state),
  storeErrors: districts.getStoreRequestError(state),
  updateErrors: districts.getUpdateRequestError(state),
  removeErrors: districts.getRemoveRequestError(state),

})
const mapDispatchToProps = {
  fetchList: districts.fetchList,
  fetchSinge: districts.fetchSingle,
  storeItem: districts.store,
  updateItem: districts.update,
  removeItem: districts.remove,
  changeEditFormValues: districtForm.edit.changeValues,
  resetCreationFormValues: districtForm.create.resetValues,
  resetEditionFormValues: districtForm.edit.resetValues,

  openModal: modal.openModal,
  closeModal: modal.closeModal
}


export default connect(mapStateToProps, mapDispatchToProps)(DistrictList)
