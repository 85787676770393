import React from 'react'
import {fromNow} from "../../helpers";

class TimePassed extends React.Component {

  state = {
    timeFrom: 0
  }

  componentDidMount(){
    const { eventTime } = this.props

    if(eventTime){
      this.setState({
        timeFrom: fromNow(eventTime)
      })
      this.timeOut = setInterval(
        this.changeState,
        1000
      )
    }

  }

  componentWillUnmount(){
    clearInterval(this.timeOut)
  }

  componentWillReceiveProps(nextProps){
    nextProps.eventTime && this.changeState(nextProps.eventTime)
  }


  changeState = (eventTime) => this.setState({ timeFrom: fromNow(eventTime || this.props.eventTime) })


  render(){
    const { timeFrom } = this.state
    const hours = Math.abs(timeFrom) >= 60 ? parseInt(timeFrom/60) : ''
    const minutes =  hours ? Math.abs(timeFrom%60) : timeFrom%60

    return (
      this.props.eventTime
        ? <span> {hours ? hours + ' ' + this.props.translate('hour') + ' ' : ''}{minutes} {this.props.translate('min')} </span>
        : <span> --- </span>
    )
  }

}

export default TimePassed
