import React from "react"
import CustomInput from "../CustomInput/CustomInput"
import {withStyles, InputAdornment, FormControl, InputLabel, Select, MenuItem} from "@material-ui/core"

import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx"

import {ComboBox} from '../'


const ComboField = (
  {
    name,
    searchText,
    multiple,
    disabled,
    value,
    items,
    error,
    onChange,
    id = undefined,
    label = '',
    selectionChange = () => {}
  }) => (

  <ComboBox
    success={value && !error}
    error={!!error}
    label={label}
    searchText={searchText}
    onChange={onChange}
    value={value === null ? multiple ? [] : -1 : value}
    // value={value === null ? multiple ? [] : -1 : typeof value === 'string' ? Number(value): value}
    multiple={multiple}
    items={items || []}
    disabled={disabled}
    selectionChange={selectionChange}
  />
)


export default withStyles(customSelectStyle)(ComboField)
