import {courierServices, postServices, dashboard, packages, packageServices, voice, orderHours, districts, post, deliveredToPost, editPackageServices} from "../../ducks"
import { theme } from '../../ducks/preferences'
import { dispatcherPackageForm, packageForm, providerPackageForm} from "../../forms"
// import { sortDash } from '../../ducks/dashSorter'
import connect from "react-redux/es/connect/connect"

import DashBoard from './Dashboard'
import modal from "../../ducks/modal"


import {snackBar } from '../../ducks'
import {SnackBar} from '../../components'

const mapStateToProps = (state) => {
  const currentData = dashboard.current.getData(state);
  const currentPending = dashboard.current.getRequestPending(state);


  const processingData = dashboard.processing.getData(state);

  const postData = dashboard.post.getData(state);
  
  return {
    singleData: packages.getSingle(state),
    singleLoading: packages.isSingleRequestPending(state),
    updateLoading: packages.isUpdateRequestPending(state),
    singleErrors: packages.getSingleRequestError(state),
    updateErrors: packages.getUpdateRequestError(state),
    processing: processingData.data,
    processingPaging: processingData.pagination,
    processingLoading: dashboard.processing.getRequestPending(state),
    current: currentData.data,
    currentPending: currentPending,
    currentPaging: currentData.pagination,
    changedCurrent: state.dashboard.current.changed,

  snackBar: snackBar.getSnackBarConfigs(state),


    // post: dashboard.post.getData(state),
    post: postData.data,
    postPaging: postData.pagination,
    postLoading: dashboard.post.getRequestPending(state),

    selectedTab: dashboard.selectedTab.getSelectedTab(state),
    
    currentLoading: dashboard.current.getRequestPending(state),
    preOrderLoading: dashboard.preOrder.getRequestPending(state),
    attachCourierPending: courierServices.assign.getRequestPending(state),
    supplierPostPending: postServices.assign.getRequestPending(state),
    storeLoading: packages.isStoreRequestPending(state),
    storeErrors: packages.getStoreRequestError(state),

    canCreateOrder: orderHours.getData(state).can,
    filterStatus: state.dashSorter.value,
    themeColors: theme.getColors(state),

    bulkAttachCourierLoading: courierServices.bulkAttach.getRequestPending(state),
    bulkAttachCourierError: courierServices.bulkAttach.getRequestError(state),

    supplierPostLoading: postServices.supplierPost.getRequestPending(state),
    supplierPostError: postServices.supplierPost.getRequestError(state),

    districts: districts.getList(state).data,

    updateLineItemsPending: editPackageServices.updateLineItems.getRequestPending(state),
    updateLineItemsError: editPackageServices.updateLineItems.getRequestError(state),

    //updateLineItemsPending: packages.update.getRequestPending(state),
    updateMakeActionError: packages.getUpdateRequestError(state),

    token: state.authorization.auth.token,
  }
}
const mapDispatchToProps = {
  changeEditFormValuesprovider: providerPackageForm.edit.changeValues,
  changeEditFormValuesdispatcher: dispatcherPackageForm.edit.changeValues,
  resetCreationFormValues: providerPackageForm.create.resetValues,
  resetCreationFormValuesDispatcher: dispatcherPackageForm.create.resetValues,
  resetEditionFormValues: packageForm.edit.resetValues,
  fetchProcessing: dashboard.processing.run,
  fetchCurrent: dashboard.current.run,
  
  fetchPost: dashboard.post.run,
  
  fetchSingle: packages.fetchSingle,
  assignCourier: courierServices.assign.run,
  updateItem: packages.update,

  fetchDistricts: districts.fetchList,

  upatedPostStatus: deliveredToPost.upatedPostStatus.run,

  updateLineItems: editPackageServices.updateLineItems.run,

  changeStatus: packageServices.changeStatus.run,
  toggle: voice.toggle,
  storeItem: packages.store,
  bulkAttachCourier: courierServices.bulkAttach.run,
  supplierPost: postServices.supplierPost.run,
  selectTab: dashboard.selectedTab.selectTab,
  openModal: modal.openModal,
  closeModal: modal.closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps )(DashBoard)
