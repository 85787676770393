import React from "react";
import Button from '@material-ui/core/Button';
import Modal from '../../../components/Modal/Modal'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class WarehouseModal extends React.PureComponent {
   
    render() {
        const {isOpen, onClose, styleModal, auditData } = this.props;
        
        return (
            <Modal
                isOpen={isOpen}
                close={onClose}
                style={styleModal}
                className="modalStyle"
                // small={true}
                content={
                    <div className="modalContainter">
                        <div className="modalRow">
                            <h1 className="modalTitle">ისტორია</h1>
                        </div>
                        <div className="modal_cols dalRow">
                            <Table className="modal_tbl" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>როლი</TableCell>
                                        <TableCell align="right">სტატუსი</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {auditData.statusAudit && auditData.statusAudit.map(item => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <p className="col_text_note">{item.user && item.user.name}</p>
                                            <p className="col_text_time">
                                                <span className="col_text_role">{item.user && item.user.roles}</span>
                                                <span> | </span>
                                                <span className="col_text_time">{item.createdAt}</span>
                                            </p>
                                        </TableCell>
                                        <TableCell align="right">
                                            <p className="col_text_note">{item.status.new}</p>
                                            <p className="col_text_time">{item.createdAt}</p>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            <Table className="modal_tbl" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>როლი</TableCell>
                                        <TableCell align="right">კომენტარი</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {auditData.noteAudit && auditData.noteAudit.map((item, key) => (
                                    <TableRow key={key}>
                                        <TableCell align="right">
                                            <p className="col_text_note">{item.user && item.user.name}</p>
                                            <p className="col_text_time">
                                                <span className="col_text_role">{item.user && item.user.roles}</span>
                                                <span> | </span>
                                                <span className="col_text_time">{item.createdAt}</span>
                                            </p>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="col_text">
                                                <p className="col_text_note">{item.note}</p>
                                                <p className="col_text_time">{item.createdAt}</p>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <div className="rowButtons center">
                            <Button className="closeBtn" onClick={onClose}>დახურვა</Button>
                        </div>

                    </div>
                } 
            />
        )
    };
}


export default WarehouseModal;





/*
* >> Change quantity
*
*/
// import React from "react";
// import Button from '@material-ui/core/Button';
// import { Add, Remove } from '@material-ui/icons';
// import Modal from '../../../components/Modal/Modal'


// class WarehouseModal extends React.PureComponent {
//     constructor(props) {
//         super(props);
//         this.state = props.data;
//     }

//     render() {
//         const {isOpen, onClose, data, styleModal} = this.props;
        
//         const quantity = data && data.quantity;
//         const prevNote = (data && data.comment) ? data && data.comment.note : "";
//         const id = data && data.id;
//         const status = data && data.status;
//         const {changedQuantity, note } = this.state;

//         return (
//             <Modal
//                 isOpen={isOpen}
//                 close={onClose}
//                 style={styleModal}
//                 className="modalStyle"
//                 small={true}
//                 content={
//                     <div className="modalContainter">
//                         <div className="modalRow">
//                             <h1 className="modalTitle">რაოდენობა</h1>
//                         </div>
//                         <div className="modalRow">

//                             <Button className="modalBtn" 
//                                 onClick={() => this.setState({ 
//                                     changedQuantity: Math.max(0, changedQuantity !== null ? changedQuantity - 1 : quantity - 1 ) 
//                                 })}
//                                 disabled={ changedQuantity === null || changedQuantity > 0 ? false : true  }
//                             >
//                                 <Remove />
//                             </Button>

//                             <div className="quantity">
//                                 <p>{ changedQuantity === null ? quantity : changedQuantity }</p>
//                             </div>

//                             <Button className="modalBtn"
//                                 onClick={() => this.setState({ changedQuantity: changedQuantity + 1 })}
//                                 disabled={ changedQuantity !== null && changedQuantity < quantity  ? false : true }
//                             >
//                                 <Add />
//                             </Button>

//                         </div>
//                         <div className="modalRow">
//                             <h3 className="subTitle">კომენტარი</h3>
//                         </div>
//                         <div className="modalRow">
//                             <textarea
//                             rows="4"
//                             cols="50" 
//                             placeholder="დაწერე კომენტარი" 
//                             value={ (this.state.note == null) ? prevNote : this.state.note }
//                             onChange={(e) => this.setState({ note: e.target.value })}
//                             className="note"
//                             >{note}</textarea>
//                         </div>
//                         <div className="rowButtons">
//                             <Button className="closeBtn" onClick={onClose}>დახურვა</Button>
//                             <Button className="saveBtn" 
//                                 onClick={() => {
//                                     let quantity = this.state.changedQuantity;
//                                     let note = this.state.note;
//                                     this.props.updateLineItemsQuantity(id, note, quantity);
                                    
//                                     onClose()
//                                 }}
//                             >
//                                 დადასტურება
//                             </Button>
//                         </div>

//                     </div>
//                 } 
//             />
//         )
//     };
// }


// export default WarehouseModal;


