import React, {Component} from 'react'
import {ReactHeight} from 'react-height'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

class HeightAnimation extends Component {

  state = {
    height: 0
  }

  componentWillUpdate() {
  }

  render() {
    const {children, transitionEnterTimeout, transitionLeaveTimeout} = this.props
    return (
      <ReactHeight onHeightReady={height => this.setState({height})}>
        <ReactCSSTransitionGroup
          transitionName="height"
          transitionEnterTimeout={transitionEnterTimeout}
          transitionLeaveTimeout={transitionLeaveTimeout}
        >
          {children}
        </ReactCSSTransitionGroup>
      </ReactHeight>
    )
  }
}

export default HeightAnimation