import {createForm} from "ductus"
import {transformToNumber} from "../transformers"

export default createForm({
  statePath: 'forms.filter.managersFilterForm',
  name: 'managersFilterForm',
  fields: {
    name: {},
    phoneNumber: {
      transform: transformToNumber
    },
    hub: {},
    isActive: {
      initialValue: 1
    }
  },
})
