

import React from 'react'
import {couriers, hubs} from '../../ducks'
import { statisticServices } from '../../ducks/services'
import { connect } from 'react-redux'

import GeneralStatistics from './GeneralStatistics'

const remove = (payload) => statisticServices.couriers.removeStat( statisticServices.couriers.REMOVE_STATISTIC, payload)


const mapStateToProps = (state) => ({
  items: couriers.getList(state).data,
  hubs: hubs.getList(state).data,
  statistics: statisticServices.couriers.getData(state),
  statisticsLoading: statisticServices.couriers.getRequestPending(state),
  name: 'couriers',
})
const mapDispatchToProps = {
  fetchItems: couriers.fetchList,
  fetchHubs: hubs.fetchList,
  addStats: statisticServices.couriers.run,
  remove
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralStatistics)