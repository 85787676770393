
import {handleActions} from 'redux-actions'
import {voiceConfigs} from "../../defaultConfigs"

const TURN_ON = 'VOICE/TURN_ON'
const TURN_OFF = 'VOICE/TURN_OFF'
const TOGGLER = 'VOICE/TOGGLER'
const CHANGE_VOICE = 'VOICE/CHANGE'

export default ({
  TURN_ON,
  TURN_OFF,
  TOGGLER,
  CHANGE_VOICE,

  turnOn: () => ({ type: TURN_ON }),
  turnOff: () => ({ type: TURN_OFF }),
  toggle: () => ({ type: TOGGLER }),
  changeVoice: (voiceName) => ({ type: CHANGE_VOICE, payload: voiceName }),

  getVoice: (state) => state.preferences && state.preferences.voice.voice,
  getToggle: (state) => state.preferences && state.preferences.voice.toggle,
  getCurrentVoice: (state) => state.preferences && state.preferences.voice.selectedVoice,

  reduce: handleActions({
    [TURN_ON]: (state) => ({ ...state, voice: true }),
    [TURN_OFF]: (state) => ({...state, voice: false }),
    [TOGGLER]: (state) => ({ ...state, toggle:  !state.toggle }),
    [CHANGE_VOICE]: (state, action) => ({ ...state, selectedVoice: action.payload }),
    ['EVENT/OCCURRED']: (state) => ({ ...state, toggle:  !state.toggle }),
  },
    voiceConfigs)
})