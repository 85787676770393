import {createForm} from "ductus"
import {transformToNumber} from "../transformers"

export default createForm({
  statePath: 'forms.filter.warehouseManagersFilterForm',
  name: 'warehouseManagersFilterForm',
  fields: {
    name: {},
    phoneNumber: {
      transform: transformToNumber
    },
    hub: {},
    isActive: {
      initialValue: 1
    }
  },
})
