import React from 'react'
import moment from 'moment'
import {timeTillEvent} from "../../helpers";

const colors = ['#59E300','#FFFF00','#FFD300','#FFB800','#FF9200','#FF7400','#FF4900','#FF0700']

class Countdown extends React.Component {

  state = {
    timeTillEvent: 0
  }

  componentDidMount(){
    this.setState({
      timeTillEvent: timeTillEvent(this.props.eventTime)
    })
    this.timeOut = setInterval(
      this.changeState,
      1000
    )
  }

  componentWillUnmount(){
    clearInterval(this.timeOut)
  }

  componentWillReceiveProps(nextProps){
    nextProps.eventTime && this.changeState(nextProps.eventTime)
  }


  changeState = (eventTime) => this.setState({ timeTillEvent: timeTillEvent(eventTime || this.props.eventTime) })


  chooseColor = (value) =>
    value > 0
      ? 0 : value > -5
      ? 1 : value > -10
        ? 2 : value > -15
          ? 3 : value > -20
            ? 4 : value > -25
              ? 5 : value > -30
                ? 6 : 7


  render(){
    const { timeTillEvent } = this.state
    const hours = Math.abs(timeTillEvent) >= 60 ? parseInt(timeTillEvent/60) : ''
    const minutes =  hours ? Math.abs(timeTillEvent%60) : timeTillEvent%60
    return (
      <span style={{ color: colors[this.chooseColor(timeTillEvent)]}}> {hours ? hours + ' ' + this.props.translate('hour') + ' ' : ''}{minutes} {this.props.translate('min')} </span>
    )
  }
}

export default Countdown
