import React from 'react'
import { withForm } from 'ductus'
import { warehouseManagersFilterForm} from "../../forms"
import {Field} from 'ductus'
import  CheckboxField from "../../components/fields/CheckboxField"
import ComboField from '../../components/fields/ComboField'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'

import {TextField} from "../../components/fields"
import qs from 'query-string'
import { connect } from 'react-redux'
import {warehouse_managers} from "../../ducks"

class WarehouseManagersFilterForm extends React.Component {

  componentWillMount(){
    const {changeValues, history } = this.props
    changeValues(qs.parse(history.location.search))
  }

  render(){
    const { hubs, formValues, filterItems, translate, history } = this.props

    return (
      <GridContainer style={{alignItems: 'center'}}>
        <GridItem xs={12} sm={12} md={3} lg={2}>
          <Field
            name={'name'}
            label={translate('name')}
            component={TextField}
            showSuccess={false}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={3} lg={2}>
          <Field
            name={'phoneNumber'}
            label={translate('phoneNumber', {})}
            component={TextField}
            showSuccess={false}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={3} lg={2}>

          <Field
            name={'hub'}
            label={translate('chooseHub')}
            component={ComboField}
            searchText={translate('search')}
            items={hubs}
          />
        </GridItem>


        <GridItem xs={12} sm={6} md={3} lg={2}>
          <Field
            name={'isActive'}
            label={translate('active')}
            component={CheckboxField}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={3} lg={2}>
          <Button
            color="primary"
            onClick={() => filterItems({ query: formValues }) && history.push({search: qs.stringify(formValues)}) }
          >
            {translate('search')}
          </Button>
        </GridItem>
      </GridContainer>
    )
  }
}


const mapStateToProps = state => ({
  formValues: warehouseManagersFilterForm.getValues(state)
})

export default connect(
  mapStateToProps,
  {
    changeValues: warehouseManagersFilterForm.changeValues,
    filterItems: warehouse_managers.fetchList
  }
)(withForm(warehouseManagersFilterForm)(WarehouseManagersFilterForm))