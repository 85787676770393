
import { createServiceGroup} from "../functions/createGroup";
import {createResource} from "../../helpers";

export default createServiceGroup({
    name: 'sortsManagerServices',
    items: {
        // list: {
        //     requestName: 'fetch',
        //     name: 'fetchRecipients',
        //     resource: '/recipients',
        //     initialData: { data: [] },
        //     // createResource,
        //     transformData: (response) => ({
        //         data: response.body.data,
        //         pagination: response.body.pagination
        //     })
        // },
        updateLineItems: {
            requestName: 'run',
            name: 'updateLineItems',
            createResource,
            initialData: { data: [] },
            type: 'put',
            // createResource,
            // transformData: (response) => {
            //     console.log("AAAAA",response)
            //     return response;
            // }
        },
        findLineItems: {
            requestName: 'run',
            name: 'findLineItems',
            createResource,
            initialData: { data: [] },
            type: 'get',
            // createResource,
            // transformData: (response) => ({
            //     data: response.body.data,
            //     pagination: response.body.pagination
            // })
        },
        /*  [ audit ]  Sort manager dashboard  */
        findAuditLineItems: {
            requestName: 'run',
            name: 'findAuditLineItems',
            createResource,
            initialData: { data: [] },
            type: 'get',
        },
        /*  [ audit ]  Warehouse manager dashboard */
        findWarehouseAuditLineItems: {
            requestName: 'run',
            name: 'findWarehouseAuditLineItems',
            createResource,
            initialData: { data: [] },
            type: 'get',
        },
    },
    generic: {
        // type: 'post'
    }
})
