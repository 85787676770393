
import React from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StatsTable = ({ data, translate, classes }) =>
  <Paper className={classes.root}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell> {translate('interval')}</TableCell>
          <TableCell numeric> {translate('value')} </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.intervals.map( (row, key) => (
          <TableRow key={key}>
            <TableCell>{row.interval}</TableCell>
            <TableCell numeric>{row.value}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell> {translate('packageCount')} </TableCell>
          <TableCell numeric>{data.info.packageCount}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>

export default StatsTable