import React from "react";
import Button from '@material-ui/core/Button';
import Modal from '../../../components/Modal/Modal';

import { Link } from 'react-router-dom'

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import itemMetrics from "../../../ducks/services/itemMetrics";

import connect from "react-redux/es/connect/connect";
import {getLineItems, SORTS_DASHBOARD_INVALIDATE} from "../../../ducks/warehouseManager";
import {sortsDashForm} from "../../../forms";
import sortsManagerServices from "../../../ducks/services/sortsManager";

class WarehouseItemMetricsModal extends React.PureComponent {

    state = {
        open: false,
        itemMetrics: '',
    }

    componentDidMount(){
        const { data } = this.props;
        
        data.metric && data.metric.id && this.setState({itemMetrics: data.metric.id })
    }

    handleChange = (event) => {
        this.setState({ itemMetrics: event.target.value });
    };

    saveItemMetrics = (e) => {
        const {updateLineItems, data, onClose} = this.props;

        let itemId = data.id;
        let itemMetricsId = this.state.itemMetrics;

        updateLineItems({params: {path: `/v3/line-items/${itemId}/item-metrics/${itemMetricsId}`}} )
        
        onClose(e)

    }
    
    render() {
        const { translate, isOpen, onClose, styleModal, data } = this.props;
        
        return (
            <Modal
                isOpen={isOpen}
                close={onClose}
                style={styleModal}
                className="modalStyle"
                small={true}
                content={
                    <div className="modalContainter">
                        <div className="modalRow">
                            <h1 className="modalTitle">{translate('itemMetrics')}</h1>
                        </div>
                        <div className="modal_cols dalRow">
                            {data.provider.itemMetrics.length > 0 ?

                                <Select className=" mu_select style" labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                                    value={this.state.itemMetrics}
                                    onChange={this.handleChange}
                                >
                                    {data.provider.itemMetrics && data.provider.itemMetrics.map(item => (
                                        <MenuItem key={item.id} value={item.id} className="mu_select item">
                                            <p className="col_text_note">{item.name}</p>
                                            <p className="col_text_time">
                                                <span className="col_text_role">{item.price}</span>
                                            </p>
                                        </MenuItem>
                                    ))}
                                </Select>
                                :
                                <div className="not_itemMetrics">
                                    <p className="col_text_note">საზომი ერთეულები არა მითითებული <span className="col_text_role"> {data.provider.name} </span> პროვაიდერზე</p>
                                    
                                    <Link to={`/providers`} className="itemMetrics_link">
                                        პროვაიდერისათვის საზომი ერთეულის მინიჭება
                                    </Link>
                                </div>
                            }
                        </div>
                        <div className="rowButtons space_around">
                            <Button className="closeBtn" onClick={onClose}>დახურვა</Button>
                            {data.provider.itemMetrics.length > 0 &&
                                <Button className="saveBtn" onClick={onClose} onClick={() => this.saveItemMetrics()}>შენახვა</Button>
                            }
                        </div>

                    </div>
                }
            />
        )
    };
}



const mapStateToProps = (state) => ({
    updateLineItemsPending: sortsManagerServices.updateLineItems.getRequestPending(state),
    updateLineItemsError: sortsManagerServices.updateLineItems.getRequestError(state),
    findLineItemsData: sortsManagerServices.findLineItems.getData(state).data,
    findLineItemsPending: sortsManagerServices.findLineItems.getRequestPending(state),
    findLineItemsError: sortsManagerServices.findLineItems.getRequestError(state),
    
});

export default connect(mapStateToProps, {
    updateLineItems: sortsManagerServices.updateLineItems.run,
    findLineItems: sortsManagerServices.findLineItems.run,
    findLineItemsInvalidate: sortsManagerServices.findLineItems.invalidate,
    invalidate: () => ({ type: SORTS_DASHBOARD_INVALIDATE })
})(WarehouseItemMetricsModal)