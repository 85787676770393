import createDashboardDucks from "../functions/createDashboardDucks"
import {groupPackageData} from "../../helpers"
import {
  PACKAGE_CREATED,
  PACKAGE_DELETED,
  PACKAGE_PREORDER_SHOW,
  PACKAGE_STATUS_UPDATED,
  PACKAGE_UPDATED,
  PACKAGES_UPDATED
} from '../../eventTypes'
import {onPackageCreated, onPackageDeleted, onPackagesUpdated, onPackageUpdated} from './helpers'

export default createDashboardDucks('processing', {
  [PACKAGE_CREATED]: onPackageCreated('PROCESSING'),

  //@todo: remove package preorder show
  [PACKAGE_PREORDER_SHOW]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: payload.courier ? state.data : [groupPackageData(payload), ...state.data.filter(item => item.id !== payload.id)]
    }
  }),

  [PACKAGE_UPDATED]: onPackageUpdated('PROCESSING'),
  [PACKAGES_UPDATED]: onPackagesUpdated('PROCESSING'),

  //@todo: remove package status updated

  [PACKAGE_STATUS_UPDATED]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: payload.status === 'ACCEPTED' || (payload.status === 'PROCESSING' && payload.courier)
          ? state.data.data.filter(d => d.id !== payload.id)
          : state.data.data
    }
  }),
  [PACKAGE_DELETED]: onPackageDeleted
})
