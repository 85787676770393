
import {createForm} from "ductus"

import { validateExistingEmail, validatePassword} from './validators'

export default createForm({
  container: 'forms',
  name: 'emailChangeForm',
  // errorActionTypes: [AUTHORIZATION_RECEIVE_ERROR],
  // transformError: transformErrorToFieldsErrors,
  fields: {
    email: {
      validate: validateExistingEmail
    },
    password: {},
  },
})
