import {validateExistence, validateNumber, validatePhoneNumber} from "../validators"
import {combineValidators} from 'ductus'
import {transformNumber, transformToNumber, transformValueLengthExact} from "../transformers"
import {compose} from "ductus/build/index"
export default ({
  branchId: {
    validate: validateExistence
  },
  pickupDistrictId: {
    validate: validateExistence
  },
  pickupAddress: {
    validate: validateExistence
  },
  deliveryDistrictId: {

  },
  recipientName: {
    validate: validateExistence
  },
  recipientPhoneNumber: {
    transform: compose(
      transformToNumber,
      transformValueLengthExact(9)
    ),
    validate: combineValidators(
      validatePhoneNumber,
      validateExistence
    ),
  },
  recipientAddress: {
    validate: validateExistence
  },
  recipientCash: {
    transform: transformNumber
  },
  comment: {},
  paymentType: {
    initialValue: 'CASH'
  },
  packagePrice: {
    validate: combineValidators(
      validateNumber,
      validateExistence
    )
  },
  visibleAfter: {

  }
})