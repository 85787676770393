import {createForm} from 'ductus'
import {transformErrorToFieldsErrors} from "../helpers"
import * as fields from './fields'
import * as ducks from '../ducks'

export const create = (name, statePath, formName, extra = { extraFields: [], errorActionTypes: {}}) => createForm({
  name: `${name}_${formName}`,
  statePath: `${statePath ? statePath + '.' : ''}${name}.${formName}`,
  errorActionTypes: [...(ducks[name] ? [formName === 'create' ? ducks[name].STORE_RECEIVE_ERROR : ducks[name].UPDATE_RECEIVE_ERROR] : []), extra.errorActionTypes[formName] ],
  fields: { ...(fields[name] ? fields[name] : fields[`${name}${formName}`]), ...extra.extraFields},
  transformError: extra.transformError || transformErrorToFieldsErrors,
})

export const divideForms = (name, statePath = '', extra) => ({
  create: create(name, statePath, 'create', extra),
  edit: create(name, statePath, 'edit', extra)
})

export const createForms = ({forms = [], formsContainer}) =>
  forms.reduce((reduction, form) => ({
    ...reduction,
    [typeof form === 'string' ? form : form.name]:
      typeof form === 'string'
        ? divideForms(form, formsContainer)
        : divideForms(form.name, formsContainer, form) ,
  }), {})

