import { combineReducers } from 'redux'
import {
  couriersFilterForm, dispatchersFilterForm,
  managersFilterForm,
  warehouseManagersFilterForm,
  packagesFilterForm,
  providerFilterForm,
  sizesFilterForm,
  hubFilterForm,
  branchesFilterForm,
  branchesProviderFilterForm,
  recipientsFilterForm,
  sortsDashForm,
} from "../forms";

export default combineReducers({
  couriersFilterForm: couriersFilterForm.reduce,
  providerFilterForm: providerFilterForm.reduce,
  sizesFilterForm: sizesFilterForm.reduce,
  hubFilterForm: hubFilterForm.reduce,
  branchesFilterForm: branchesFilterForm.reduce,
  branchesProviderFilterForm: branchesProviderFilterForm.reduce,
  packagesFilterForm: packagesFilterForm.reduce,
  managersFilterForm: managersFilterForm.reduce,
  warehouseManagersFilterForm: warehouseManagersFilterForm.reduce,
  dispatchersFilterForm: dispatchersFilterForm.reduce,
  recipientsFilterForm: recipientsFilterForm.reduce,
  sortsDashForm: sortsDashForm.reduce,
})
