import React from "react"
import {withStyles, FormControlLabel, Checkbox} from "@material-ui/core"
import { Check } from '@material-ui/icons'

import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";


const CheckboxField = (
  {
    name,
    value,
    error,
    disabled = false,
    onChange,
    id = undefined,
    label = '',
    formControlProps = {fullWidth: true},
    inputProps = {},
    classes
  }) => (
  <div className={classes.checkboxAndRadio}>
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          tabIndex={-1}
          checked={Number(value)}
          onClick={(e) => onChange(Number(!value), e)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked
          }}
        />
      }
      classes={{
        label: classes.label
      }}
      label={label}
    />
  </div>
)


export default withStyles(customCheckboxRadioSwitch)(CheckboxField)
