import React from "react"
import {withStyles, FormControlLabel, Switch} from "@material-ui/core"

import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";


const SwitchField = ({name, value, items, error, onChange,disabled = false, type = 'text', id = undefined, label = '', classes}) => (
  <FormControlLabel
    style={{ width: '100%'}}
    fullWidth={true}
    control={
      <Switch
        disabled={disabled}
        checked={(value === items.enabledValue)}
        onChange={() => onChange(value === items.enabledValue ? items.disabledValue : items.enabledValue )}
        value={label}
        id={id}
        classes={{
          switchBase: classes.switchBase,
          checked: classes.switchChecked,
          icon: classes.switchIcon,
          iconChecked: classes.switchIconChecked,
          bar: classes.switchBar
        }}
      />
    }
    classes={{
      label: classes.label
    }}
    label={label}
  />
)


export default withStyles(customCheckboxRadioSwitch)(SwitchField)
