import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {Field} from 'ductus'

import GridItem from '../../../../components/Grid/GridItem'
import GridContainer from '../../../../components/Grid/GridContainer'

import ComboField from "../../../../components/fields/ComboField"

class Hubs extends React.Component {

  isValidated = () => true

  sendState = () => this.state

  render() {
    const { hubs, getTranslator } = this.props
    return (
      <GridContainer justify="center">
        <GridItem xs={11} sm={11} md={11} lg={10}>
          <legend> {getTranslator('hubs')} </legend>
          <Field
            name={'hubs'}
            multiple={true}
            label={getTranslator('chooseHubs')}
            component={ComboField}
            items={hubs}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles({})(Hubs)