import React, {Fragment} from "react"
import PropTypes from "prop-types"
import {NavLink} from "react-router-dom"
import cx from "classnames"
import withStyles from "@material-ui/core/styles/withStyles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Hidden from "@material-ui/core/Hidden"
import Collapse from "@material-ui/core/Collapse"
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx"
import avatar from "../../assets/img/avatar.png"
import Button from "../CustomButtons/Button";

// let ps;

class SidebarWrapper extends React.Component {
  // componentDidMount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //   }
  // }

  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  // }

  render() {
    const {className, user, headerLinks, links} = this.props;
    return (
      <div className={`${className} scroll scroll4`} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true,

    }
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  renderSidebarWrapper = ({ className, user, links, image }) =>
    <Fragment>
      {this.renderBrand()}

      <SidebarWrapper
        className={className}
        user={user}
        links={links}
      />
      {image !== undefined ? (
        <div
          className={this.props.classes.background}
          style={{backgroundImage: "url(" + image + ")"}}
        />
      ) : null}
    </Fragment>

  renderBrand = () => {
    const { classes: { img, logoMini, logo, whiteAfter, logoNormal, logoNormalSidebarMini }, logoText, bgColor, miniActive } = this.props

    const logoClasses =
      logo +
      " " +
      cx({
        [whiteAfter]: bgColor === "white"
      })

    const logoNorm =
      logoNormal +
      " " +
      cx({
        [logoNormalSidebarMini]:
        miniActive && this.state.miniActive
      });

    return (
      <div className={logoClasses}>
        <a href="#" className={logoMini}>
          <img src={this.props.logo} alt="logo" className={img}/>
        </a>
        <a href="#" className={logoNorm}>
            EXTRA Logistic
        </a>
      </div>
    )
  }

  render() {

    const {
      classes,
      color,
      image,
      routes,
      bgColor,
      userInfo,
      translate,
      permissions,
      invalidateToken,
    } = this.props

    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      })

    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
        this.props.miniActive && this.state.miniActive,
      })

    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      })

    const caret = classes.caret
    const collapseItemMini = classes.collapseItemMini
    const photo = classes.photo

    let user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..."/>
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={ <p className={ classes.userName }>{userInfo && userInfo.name}</p> }
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      classes.userCaret +
                      " " +
                      (this.state.openAvatar ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/profile"
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>
                      {"MP"}
                    </span>
                    <ListItemText
                      primary={translate('myProfile')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/settings"
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>
                      {"S"}
                    </span>
                    <ListItemText
                      primary={translate('settings')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>

                {/*<ListItem className={classes.collapseItem}>*/}
                  {/*<NavLink*/}
                    {/*to="/district_prices"*/}
                    {/*className={*/}
                      {/*classes.itemLink + " " + classes.userCollapseLinks*/}
                    {/*}*/}
                  {/*>*/}
                    {/*<span className={collapseItemMini}>*/}
                      {/*{"P"}*/}
                    {/*</span>*/}
                    {/*<ListItemText*/}
                      {/*primary={translate('districtPrices')}*/}
                      {/*disableTypography={true}*/}
                      {/*className={collapseItemText}*/}
                    {/*/>*/}
                  {/*</NavLink>*/}
                {/*</ListItem>*/}

                <ListItem className={classes.collapseItem}>
                  <NavLink
                    onClick={invalidateToken}
                    to="#"
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>
                      {"E"}
                    </span>
                    <ListItemText
                      primary={translate('exit')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    )

    let links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect || prop.hide || (prop.perm && !permissions[prop.perm])) {
            return null;
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [" " + classes.collapseActive]: this.activeRoute(prop.path)
              });
            const itemText =
              classes.itemText +
              " " +
              cx({
                [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive
              });
            const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
                [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive
              });
            const {caret, itemIcon} = classes
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to={"#"}
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <ListItemIcon className={itemIcon}>
                    <prop.icon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={this.props.translate(prop.name)}
                    secondary={
                      <b
                        className={
                          caret +
                          " " +
                          (this.state[prop.state] ? classes.caretActive : "")
                        }
                      />
                    }
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + " " + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect || (prop.perm && !permissions[prop.perm])) {
                        return null;
                      }
                      const navLinkClasses =
                        classes.collapseItemLink +
                        " " +
                        cx({
                          [" " + classes[color]]: this.activeRoute(prop.path)
                        });
                      const {collapseItemMini} = classes
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                            <ListItemText
                              primary={this.props.translate(prop.name)}
                              disableTypography={true}
                              className={collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </ListItem>
            );
          }
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes[color]]: this.activeRoute(prop.path)
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive
            });
          const {itemIcon} = classes
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  <prop.icon/>
                </ListItemIcon>
                <ListItemText
                  primary={translate(prop.name)}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    )

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
        this.props.miniActive && this.state.miniActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
        this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
      })

    const SidebarWrapper = this.renderSidebarWrapper({ className: sidebarWrapper, user, links, image })

    return (
      <div ref="mainPanel">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {SidebarWrapper}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            // onMouseOver={() => this.setState({miniActive: false})}
            // onMouseOut={() => this.setState({miniActive: true})}
            anchor={"left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {SidebarWrapper}
          </Drawer>
        </Hidden>
      </div>
    );
  }


}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
};


export default withStyles(sidebarStyle)(Sidebar)
