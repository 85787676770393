import React from 'react'
import {ComboField, DateTimeField} from "../../components/fields"
import Card from "../../components/Card/Card"
import CardBody from "../../components/Card/CardBody"
import {Button} from '../../components'
import moment from 'moment';

class FinanceFilter extends React.Component {

  state = {
    from: '',
    to: '',
    items: [],
    allItems: [],
    periodView: 'day',
    hubs: [],
    value: 0,
    hubValue: 0,
    itemLabel: '',
    first: true,
  }

  componentDidMount() {
    const {fetchItems, fetchHubs, hub} = this.props

    hub && fetchHubs({query: {'without-pagination': 1}})
    !this.props.own && fetchItems({query: {'without-pagination': 1}})
  }

  componentWillReceiveProps({items, hubs}) {
    this.setState({
      allItems: (this.state.first && items && items.length) ? items : this.state.allItems,
      first: !(this.state.first || (items && items.length)),
      items,
      hubs
    })
  }

  addButtonClicked = () => {
    const {from, to, value, periodView} = this.state
    const {addFinances, itemLabel, own} = this.props

    const path = own ? 'finances' : `finances/${itemLabel}/${value}`

    addFinances({params: { path }, query: {from, to, periodView }})
  }


  hubSelected = (hubValue) => {
    this.setState({hubValue})
    hubValue
      ? this.props.fetchItems({query: {'without-pagination': 1, hubId: hubValue}})
      : this.setState({ items: this.state.allItems })
  }

  periodCHanged = (periodView) => this.setState(({ periodView }))

  render() {
    const {translate, itemLabel, hub, own} = this.props
    const {from, to, items, hubs} = this.state

    return (
      <Card>
        <CardBody>
          <div className={'filter-flex-container'}>
            <div style={{display: 'flex'}}>
              <div style={{flex: 1, minWidth: 140, maxWidth: 400, paddingRight: 10}}>
                <DateTimeField
                  name={'from'}
                  label={translate('from')}
                  dateFormat={'DD-MM-YYYY'}
                  timeFormat={'HH:mm'}
                  value={from}
                  onChange={(value) => this.setState({from: value})}
                />
              </div>

              <div style={{flex: 1, minWidth: 140, maxWidth: 400, paddingLeft: 10}}>
                <DateTimeField
                  name={'to'}
                  label={translate('to')}
                  dateFormat={'DD-MM-YYYY'}
                  timeFormat={'HH:mm'}
                  value={to}
                  onChange={(value) => {

                    if(moment(value).format('HH:mm:ss') === '00:00:00'){
                      this.setState({to: value.slice(0, 11) + '23:59:59'})
                    } else {
                      this.setState({to: value})
                    }
                  }}
                />
              </div>
            </div>

            <div style={{flex: 1, minWidth: 200, maxWidth: 300}}>
              <ComboField
                label={translate('periodView')}
                items={[
                  { id: 'day', name: translate('day')},
                  { id: 'week', name: translate('week')},
                  { id: 'month', name: translate('month')},
                ]}
                value={this.state.periodView}
                onChange={this.periodCHanged}
              />
            </div>


            {
              hub &&
              <div style={{flex: 1, minWidth: 200, maxWidth: 300}}>
                <ComboField
                  label={translate('hub')}
                  items={hubs}
                  value={this.state.hubValue}
                  onChange={this.hubSelected}
                />

              </div>
            }

            {
              !own &&
              <div style={{flex: 1, minWidth: 200, maxWidth: 300}}>
                <ComboField
                  label={translate(itemLabel)}
                  items={items}
                  value={this.state.value}
                  onChange={(value) => this.setState({value})}
                />

              </div>
            }

            <div style={{width: 100}}>
              <Button color={'primary'} onClick={this.addButtonClicked} style={{width: 30, height: 30}}> <i
                className="fas fa-plus"></i> </Button>
            </div>

          </div>
        </CardBody>
      </Card>
    )
  }
}

export default FinanceFilter
