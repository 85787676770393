import React from 'react'
import { Link } from 'react-router-dom'

import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import ReactTable from 'react-table'
import ProviderFilterForm from '../ProviderFilterForm'
import {branchPermissions, providerPermissions} from '../../../permissions'
import qs from "query-string";
import ListComponent from "../../ListComponent"

import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import Button from '../../../components/CustomButtons/Button'
import CardBody from '../../../components/Card/CardBody'
import Card from '../../../components/Card/Card'
import Modal from '../../../components/Modal/Modal'
import ConfirmSweetAlert from '../../../components/SweetAlert/SweetAlert'
import SnackBar from '../../../components/Snackbar/Snackbar'
import { AddButton } from '../../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"


class ProviderList extends ListComponent {
  makeActions = (item) => {
    const { removeItem, history, translate, permissions} = this.props
    return (
      <div className="actions-right">
        {
          permissions[providerPermissions.update] &&
          <Button
            justIcon
            round
            simple
            onClick={() => history.push(`/providers/${item.id}/edit`)}
            color="warning"
            className="edit"
          >
            <Dvr/>
          </Button>
        }

        {
          permissions[providerPermissions.delete] &&
          <Button
            justIcon
            round
            simple
            onClick={() => this.showAlert({
              title: translate('toDelete', { item: item.name}),
              confirmText: translate('delete'),
              cancelText: translate('close'),
              hideAlert: this.hideAlert,
              confirmFun: () => removeItem(item.id),
              warning: true
            })}
            color="danger"
            className="remove"
          >
            <Close/>
          </Button>
        }
      </div>
    )
  }

  componentDidMount() {
    const {filterFormValues, fetchList, history } = this.props
    const { search } = history.location
    const query = {...filterFormValues, ...qs.parse(search) }
    fetchList({ query })
    this.props.history.push({ search: qs.stringify(query) })
  }

  render() {
    const {data, loading, userInfo, pagination, storeLoading, updateLoading, singleLoading, translate, permissions, history} = this.props
    const { modal, snackBar } = this.state
    const { hubs } = userInfo
    

    return (
      <GridItem xs={12} sm={12} md={12}>

        <Card>
          <CardBody>
            <ProviderFilterForm history={history} translate={translate} userHubs={hubs} />
          </CardBody>
        </Card>
        
        <Link to={'/providers/add'}>
          <AddButton
            hidden={!permissions[providerPermissions.create]}
            onClick={() => {}}
          >
          </AddButton>
        </Link>

        <GridContainer>
          <Card>
            <CardBody className="provider_table tb">
              {

                <ReactTable
                  data={data && data.map(item => ({
                    ...item,
                    br: item.id,
                    actions: this.makeActions(item)
                  }))}
                  loading={loading}
                  page={this.state.pageIndex}
                  pages={pagination && pagination.totalPages}
                  manual
                  onPageChange={this.pageChanged}
                  defaultPageSize={(pagination && pagination.perPage) || 5 }
                  pageSize={pagination && pagination.perPage}

                  onSortedChange={this.onSortedChange}
                  
                  columns={[
                    {
                      Header: translate('name'),
                      accessor: "name"
                    },
                    {
                      Header: translate('branches'),
                      accessor: 'id',
                      Cell: row => (
                        permissions[branchPermissions.read] &&
                        <Link to={`/providers/${row.value}/branches`} style={{padding: '20px'}}>
                          {translate('branches')}
                        </Link>

                      )
                    },
                    {
                      Header: translate('districts'),
                      accessor: 'id',
                      Cell: row => (
                        permissions[branchPermissions.read] && 
                        <div className="line_name">
                          <Link to={`/providers/${row.value}/district`} style={{padding: '20px'}}>
                            {translate('districts')} 
                          </Link>
                          {
                            row.row._original.countDistricts == 0 &&
                          <div className="provider_no_info">
                            <ErrorOutlineIcon className="icon"/>
                            <div className="provider_no_info_txt">
                              <p>ინფორმაცია არ მოიძებნება</p>
                            </div>
                          </div> 
                          }
                        </div>
                      )
                    },
                    {
                      Header: translate('tariffs'),
                      accessor: 'id',
                      Cell: row => (
                        permissions[branchPermissions.read] && 
                        <div className="line_name">
                          <Link to={`/providers/${row.value}/tariffs`} style={{padding: '20px'}}>
                            {translate('tariffs')}
                          </Link>
                          {
                            row.row._original.countDistrictTariffs == 0 &&
                            <div className="provider_no_info">
                              <ErrorOutlineIcon className="icon"/>
                              <div className="provider_no_info_txt">
                                <p>ინფორმაცია არ მოიძებნება</p>
                              </div>
                            </div> 
                          }
                        </div>
                      )
                    },
                    {
                      Header: translate('itemMetrics'),
                      accessor: 'id',
                      Cell: row => (
                        permissions[branchPermissions.read] && 
                        <div className="line_name">
                          <Link to={`/providers/${row.value}/item-metrics`} style={{padding: '20px'}}>
                            {translate('itemMetrics')}
                          </Link>
                          {
                            row.row._original.countItemMetrics == 0 &&
                            <div className="provider_no_info">
                              <ErrorOutlineIcon className="icon"/>
                              <div className="provider_no_info_txt">
                                <p>ინფორმაცია არ მოიძებნება</p>
                              </div>
                            </div>
                          } 
                        </div>
                      )
                    },
                    {
                      Header: translate('actions'),
                      accessor: "actions"
                    }
                  ]}
                  previousText={translate('previousText')}
                  nextText={translate('nextText')}
                  loadingText={translate('loadingText')}
                  pageText={translate('pageText')}
                  ofText={translate('ofText')}
                  showPaginationBottom
                  showPageSizeOptions={false}
                  className="-striped -highlight"
                />

              }
            </CardBody>
          </Card>

        </GridContainer>
        <Modal
          loading={storeLoading || updateLoading}
          modalLoading={singleLoading}
          {...modal}
        />
        {this.state.alert.open && <ConfirmSweetAlert {...this.state.alert} />}
        <SnackBar
          place={'br'}
          color="info"
          icon={AddAlert}
          message={snackBar.message}
          open={snackBar.openPos}
          closeNot={this.closeSnackBar}
        />
      </GridItem>

    )
  }
}

export default ProviderList

