import React from 'react'
import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'

import ReactTable from 'react-table'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'


import ConfirmSweetAlert from '../../components/SweetAlert/SweetAlert'
import SnackBar from '../../components/Snackbar/Snackbar'
import {AddButton} from '../../components/CustomButtons/AddButton'
import AddAlert from "@material-ui/icons/AddAlert"

import CreateDistrictForm from './CreateDistrictForm'
import EditDistrictForm from './EditDistrictForm'

import {districtPermissions} from '../../permissions'
import ListComponent from "../ListComponent";
import {districtForm} from '../../forms'

class DistrictList extends ListComponent {

  makeActions = (item) => {
    const {resetEditionFormValues, fetchSinge, updateItem, removeItem, translate, permissions} = this.props
    return (
      <div className="actions-right">
        {/* use this button to add a like kind of action */}
        {permissions[districtPermissions.update] &&
        <Button
          justIcon
          round
          simple
          onClick={() => {
            resetEditionFormValues() ||
            this.openFormModal({
              submitText: 'edit',
              submit: (formValues) => updateItem(item.id, {...item, ...formValues}),
              Component: 'EditDistrictForm',
              form: districtForm.edit,
              duck: 'districts',
            })
            || fetchSinge(item.id)
          }}
          color="warning"
          className="edit"
        >
          <Dvr/>
        </Button>}


        {permissions[districtPermissions.delete] &&
        <Button
          justIcon
          round
          simple
          onClick={() => this.showAlert({
            title: translate('toDelete', {item: item.name}),
            confirmText: translate('delete'),
            cancelText: translate('close'),
            hideAlert: this.hideAlert,
            confirmFun: () => removeItem(item.id),
            warning: true
          })}
          color="danger"
          className="remove"
        >
          <Close/>
        </Button>
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.fetchList({query: {isActive: 1}})
  }

  componentWillUpdate(nextProps) {
    const {
      storeLoading,
      updateLoading,
      removeLoading,
      singleLoading,
      changeEditFormValues,
      translate,
      loading,
      resetCreationFormValues,
      resetEditionFormValues,
    } = this.props

    const {pagination, singleErrors, storeErrors, updateErrors, removeErrors} = nextProps

    if ((storeLoading && !nextProps.storeLoading) && !storeErrors) {
      this.props.closeModal()

      resetCreationFormValues()
      this.fetchList({query: {page: 1, isActive: 1}})
      this.setState({pageIndex: 0})

    } else if (updateLoading && !nextProps.updateLoading && !updateErrors) {

      this.props.closeModal()
      resetEditionFormValues()
      this.fetchList({query: {page: this.state.pageIndex + 1, isActive: 1}})
    }

    if (removeLoading && !nextProps.removeLoading && !removeErrors) {
      if ((this.state.pageIndex + 1 >= pagination.totalPages) && (pagination.total % pagination.perPage === 1)) {
        this.fetchList({query: {page: this.state.pageIndex, isActive: 1}})
        this.setState({pageIndex: this.state.pageIndex - 1})
      } else {
        this.fetchList({query: {page: this.state.pageIndex + 1, isActive: 1}})
      }

      this.setState({
        alert: {open: false},
        snackBar: {openPos: 'bl', message: translate('deleted')}
      })
    }

    if (singleLoading && !nextProps.singleLoading && !singleErrors) {
      nextProps.singleData && changeEditFormValues(nextProps.singleData)
    }

    if (loading && !nextProps.loading) {
      this.setState({
        pageIndex: nextProps.pagination.currentPage - 1
      })
    }
  }


  render() {
    const {data, loading, storeItem, pagination, translate, permissions} = this.props
    const {snackBar, alert} = this.state

    return (
      <GridItem xs={12}>

        <AddButton
          hidden={!permissions[districtPermissions.create]}
          onClick={() => this.openFormModal({
            submitText: 'add',
            submit: storeItem,
            Component: 'CreateDistrictForm',
            componentProps: {},
            form: districtForm.create
          })}
        >
        </AddButton>

        <GridContainer>
          <Card>
            <CardBody>
              {

                <ReactTable
                  data={data && data.map(item => ({
                    ...item,
                    actions: this.makeActions(item)
                  }))}
                  loading={loading}
                  pages={pagination && pagination.totalPages}
                  manual
                  onPageChange={this.pageChanged}
                  defaultPageSize={(pagination && pagination.perPage) || 5}
                  pageSize={pagination && pagination.perPage}

                  onSortedChange={this.onSortedChange}
                  page={this.state.pageIndex}
                  columns={[
                    {
                      Header: translate('name'),
                      accessor: "name"
                    },
                    {
                      Header: translate('actions'),
                      accessor: "actions"
                    }
                  ]}
                  previousText={translate('previousText')}
                  nextText={translate('nextText')}
                  loadingText={translate('loadingText')}
                  pageText={translate('pageText')}
                  ofText={translate('ofText')}
                  showPaginationBottom
                  showPageSizeOptions={false}
                  className="-striped -highlight"

                />

              }
            </CardBody>
          </Card>

        </GridContainer>

        {alert.open && <ConfirmSweetAlert {...alert} />}
        <SnackBar
          place={'br'}
          color="info"
          icon={AddAlert}
          message={snackBar.message}
          open={snackBar.openPos}
          closeNot={this.closeSnackBar}
          close
        />
      </GridItem>

    )
  }
}

export default DistrictList
