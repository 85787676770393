import React from 'react'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'

const AddButton = ({ onClick, hidden, }) => (
  <SpeedDial
    ariaLabel={'Add Button'}
    style={{
      position: 'fixed',
      marginLeft: 50,
      bottom: 50,
    }}
    hidden={hidden}
    icon={<SpeedDialIcon/>}
    onClick={onClick}
    open={false}
  >
  </SpeedDial>
)

export {AddButton}