import createDashboardDucks from "../functions/createDashboardDucks";
import {groupPackageData} from "../../helpers";
import {
  PACKAGE_CREATED,
  PACKAGE_PREORDER_SHOW,
  PACKAGE_DELETED,
  PACKAGE_UPDATED,
  PACKAGE_PREORDER_CREATED, PACKAGES_UPDATED
} from '../../eventTypes'
import {onPackageCreated, onPackageDeleted, onPackagesUpdated, onPackageUpdated} from './helpers'

export default createDashboardDucks('post', {
  [PACKAGE_CREATED]: onPackageCreated('POST'),

  //@todo: remove package preorder showZ
  [PACKAGE_PREORDER_SHOW]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.filter(d => d.id !== payload.id )
    }
  }),
  //@todo: remove package preorder created
  [PACKAGE_PREORDER_CREATED]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: [groupPackageData({...payload, changed: false}), ...state.data.data]
    }
  }),
  [PACKAGE_UPDATED]: onPackageUpdated('POST'),
  [PACKAGES_UPDATED]: onPackagesUpdated('POST'),

  [PACKAGE_DELETED]: onPackageDeleted
})
