import {dispatcherForm, dispatchersFilterForm} from "../../forms";
import {dispatchers} from "../../ducks"
import connect from "react-redux/es/connect/connect";

import DispatchersList from './DispatchersList'
import modal from "../../ducks/modal"

const mapStateToProps = (state) => ({
  data: dispatchers.getList(state) && dispatchers.getList(state).data,
  singleData: dispatchers.getSingle(state),
  pagination: dispatchers.getList(state).pagination,
  loading: dispatchers.isListRequestPending(state),
  singleLoading: dispatchers.isSingleRequestPending(state),
  storeLoading: dispatchers.isStoreRequestPending(state),
  updateLoading: dispatchers.isUpdateRequestPending(state),
  removeLoading: dispatchers.isRemoveRequestPending(state),
  listErrors: dispatchers.getListRequestError(state),
  singleErrors: dispatchers.getSingleRequestError(state),
  storeErrors: dispatchers.getStoreRequestError(state),
  updateErrors: dispatchers.getUpdateRequestError(state),
  removeErrors: dispatchers.getRemoveRequestError(state),
  filterFormValues: dispatchersFilterForm.getValues(state),

})

const mapDispatchToProps = {
  fetchList: dispatchers.fetchList,
  fetchSinge: dispatchers.fetchSingle,
  storeItem: dispatchers.store,
  updateItem: dispatchers.update,
  removeItem: dispatchers.remove,
  changeEditFormValues: dispatcherForm.edit.changeValues,
  resetCreationFormValues: dispatcherForm.create.resetValues,
  resetEditionFormValues: dispatcherForm.edit.resetValues,

  openModal: modal.openModal,
  closeModal: modal.closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchersList)
