import {createRequestReducer} from "crud-factory";
import {
    SORTS_DASHBOARD_ERROR,
    SORTS_DASHBOARD_REQUEST,
    SORTS_DASHBOARD_RESPONSE,
    SORTS_DASHBOARD_INVALIDATE
} from "./actionTypes";

export default createRequestReducer({
    begin: SORTS_DASHBOARD_REQUEST,
    success: SORTS_DASHBOARD_RESPONSE,
    error: SORTS_DASHBOARD_ERROR,
    invalidate: SORTS_DASHBOARD_INVALIDATE,
    dataKey: 'data',
    transformData: (response) => response.body,
    transformError: (error) => error
})
