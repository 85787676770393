import React from 'react'

import ReactTable from 'react-table'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import CardBody from '../../components/Card/CardBody'
import Card from '../../components/Card/Card'

import ListComponent from '../ListComponent'
import RecipientFilterForm from './Filter'
import qs from "query-string"
import {Link} from 'react-router-dom'

class RecipientList extends ListComponent {
  componentDidMount() {

    this.props.fetchList({ query: { isActive: 1 }})

    const {filterFormValues, fetchList, history } = this.props
    const { search } = history.location
    const query = {...filterFormValues, ...qs.parse(search) }
    fetchList({ query })
    this.props.history.push({ search: qs.stringify(query) })
  }

  render() {
    const {data, loading,fetchList, pagination, history, userInfo, translate} = this.props
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <RecipientFilterForm translate={translate} role={userInfo.role} history={history} />
          </CardBody>
        </Card>

        <GridContainer>
          <Card>
            <CardBody>
              {
                <ReactTable
                  data={data && data}
                  loading={loading}
                  pages={pagination && pagination.totalPages}
                  manual
                  onPageChange={this.pageChanged}
                  defaultPageSize={(pagination && pagination.perPage) || 5 }
                  pageSize={pagination && pagination.perPage}

                  onSortedChange={this.onSortedChange}
                  page={this.state.pageIndex}
                  columns={[
                    {
                      Header: translate('phoneNumber', {}),
                      accessor: "recipientPhoneNumber",
                      Cell: row => (
                        <Link to={`/packages?recipientPhoneNumber=${row.value}`}>
                          {row.value}
                        </Link>
                      )
                    },
                    {
                      Header: translate('packages'),
                      accessor: "packageCount"
                    }
                  ]}
                  previousText={translate('previousText')}
                  nextText={translate('nextText')}
                  loadingText={translate('loadingText')}
                  pageText={translate('pageText')}
                  ofText={translate('ofText')}
                  showPaginationBottom
                  showPageSizeOptions={false}
                  className="-striped -highlight"

                />
              }
            </CardBody>
          </Card>
        </GridContainer>
      </GridItem>

    )
  }
}

export default RecipientList
