
import { createServiceGroup} from "../functions/createGroup"
import {createResource } from "../../helpers"

export default createServiceGroup({
  name: 'itemMetricsServices',
  items: {
    getAllItemMetrics: {
      name: 'getAllItemMetrics',
      createResource,
      type: 'get',
    },
    getItemMetrics: {
      name: 'getItemMetrics',
      createResource,
      type: 'get',
    },
    createItemMetrics: {
      name: 'createItemMetrics',
      createResource,
      type: 'post',
    },
    updateItemMetrics: {
      name: 'updateItemMetrics',
      createResource,
      type: 'put'
    },
    deleteItemMetrics: {
      name: 'deleteItemMetrics',
      createResource,
      type: 'post'
    }
  },
  generic: {
    type: 'post'
  }
})
