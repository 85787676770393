import {sizesFilterForm, sizeForm} from "../../forms";
import {sizes, districts} from "../../ducks";
import connect from "react-redux/es/connect/connect";
import { itemMetricsServices } from '../../ducks';


import SizeList from './SizeList'
import modal from "../../ducks/modal"

const mapStateToProps = (state) => ({
  creationFormValues: sizeForm.create.getValues(state),
  creationFormValid: sizeForm.create.isValid(state),
  editionFormValid: sizeForm.edit.isValid(state),
  editionFormValues: sizeForm.edit.getValues(state),
  data: sizes.getList(state) && sizes.getList(state).data,
  getAllItemMetrics: itemMetricsServices.getAllItemMetrics.getData(state),
  deleteItemMetrics: itemMetricsServices.deleteItemMetrics.getData(state),

  singleData: sizes.getSingle(state),
  pagination: sizes.getList(state).pagination,
  loading: sizes.isListRequestPending(state),
  singleLoading: sizes.isSingleRequestPending(state),
  storeLoading: sizes.isStoreRequestPending(state),
  updateLoading: sizes.isUpdateRequestPending(state),
  removeLoading: sizes.isRemoveRequestPending(state),
  listErrors: sizes.getListRequestError(state),
  singleErrors: sizes.getSingleRequestError(state),
  storeErrors: sizes.getStoreRequestError(state),
  updateErrors: sizes.getUpdateRequestError(state),
  removeErrors: sizes.getRemoveRequestError(state),
  districts: districts.getList(state).data,
  filterFormValues: sizesFilterForm.getValues(state),
})

const mapDispatchToProps = {
  fetchList: sizes.fetchList,
  fetchSinge: sizes.fetchSingle,
  storeItem: sizes.store,
  updateItem: sizes.update,
  removeItem: sizes.remove,
  changeEditFormValues: sizeForm.edit.changeValues,
  resetCreationFormValues: sizeForm.create.resetValues,
  resetEditionFormValues: sizeForm.edit.resetValues,
  fetchDistricts: districts.fetchList,

  getAllItemMetrics: itemMetricsServices.getAllItemMetrics.run,
  deleteItemMetrics: itemMetricsServices.deleteItemMetrics.run,


  openModal: modal.openModal,
  closeModal: modal.closeModal
}


export default connect(mapStateToProps, mapDispatchToProps)(SizeList)

