import createModules from "./functions/createModules"
import {createGroup} from "./functions/createGroup"
import {createResource, hubIds, transformPackResponse} from '../helpers'
import {post, compose} from 'redux-net'
export {reduceAuthorization, verifyAuth} from './authorization'
export {default as dashboard} from './dashboard/index'
export {default as snackBar} from './snackBar.js'
export {default as userInfo } from './user'
export {default as orderHours } from './orderHours'
export {default as userHubs } from './userHubs'

export * from './services'

export * from './preferences'

export const packageStatuses = createGroup({
  name: 'packageStatuses',
  resource: '/packages',
  generic: {},
  createResource,
  type: 'post'
})

export const { warehouse_managers, managers, dispatchers, couriers, providers, districts, hubs, sizes, branches, packages } = createModules({
  modules: {
    warehouse_managers: {},
    managers: {},
    dispatchers: {},
    couriers: {},
    providers: {
      list: {},
      single: {
        initiallyPending: false,
        transformData: compose(
          (data) => ({...data, times: data.times.join(',')}),
          hubIds,
        )
      }
    },
    districts: {},
    hubs: {},
    sizes: {
      createResource,
    },
    branches: {
      createResource,
    },
    packages: {
      list: {
        transformData: transformPackResponse,
      },
    }
  },

  generic: {
    list: {
      initialData: {
        pagination: {totalPages: 0, currentPage: 0, perPage: 5},
        data: []
      },
      transformData: (response, state) => ({
        ...state,
        data: response.body.data,
        pagination: response.body.pagination,
      })
    },
    single: {
      initiallyPending: false,
      transformData: hubIds,
    }
  },
  createResource: createResource
})

// console.log("districs >> ", districts);