import React, { Fragment } from 'react'
import GridItem from "../../../components/Grid/GridItem"
import {Field, withForm} from "ductus"
import ComboField from "../../../components/fields/ComboField"
import TextField from '../../../components/fields/TextField'

import GridContainer from "../../../components/Grid/GridContainer"
import DateTimeField from "../../../components/fields/DateTimeField"
import {recipientsFilterForm} from "../../../forms"

const Form =  ({ translate, providers, hubs }) => (
  <Fragment>
    <GridItem xs={12} sm={12} md={4} lg={4}>
      <Field
        name={'recipientPhoneNumber'}
        label={translate('phoneNumber', {})}
        component={TextField}
        showSuccess={false}
      />
    </GridItem>

      <GridItem xs={12} sm={6} md={4} lg={4} style={{ display: 'flex', padding: '0 !important' }}>
          <GridContainer style={{alignItems: 'center'}}>
              <GridItem xs={12}>
                  <small style={{color: '#AAAAAA', fontSize: 15}}> {translate('priceRange')} </small>
              </GridItem>

              <GridItem xs={6}>
                  <Field
                      name={'minPrice'}
                      // label={translate('minPrice')}
                      component={TextField}
                      showSuccess={false}
                  />
              </GridItem>

              <GridItem xs={6}>
                  <Field
                      name={'maxPrice'}
                      // label={translate('maxPrice')}
                      component={TextField}
                      showSuccess={false}
                  />
              </GridItem>

          </GridContainer>
      </GridItem>

      <GridItem xs={12} sm={6} md={4} lg={4} style={{ display: 'flex', padding: '0 !important' }}>
          <GridContainer style={{alignItems: 'center'}}>
              <GridItem xs={12}>
                  <small style={{color: '#AAAAAA', fontSize: 15}}> {translate('dateRange')} </small>
              </GridItem>

              <GridItem xs={6}>
                  <Field
                      name={'startDate'}
                      component={DateTimeField}
                      dateFormat={'DD-MM-YYYY'}
                      timeFormat={false}
                  />
              </GridItem>

              <GridItem xs={6}>
                  <Field
                      name={'endDate'}
                      component={DateTimeField}
                      dateFormat={'DD-MM-YYYY'}
                      timeFormat={false}
                  />
              </GridItem>

          </GridContainer>
      </GridItem>

    <GridItem xs={12} sm={12} md={3} lg={4}>
      <Field
        name={'providerId'}
        label={translate('provider')}
        component={ComboField}
        items={providers}
      />
    </GridItem>

    <GridItem xs={12} sm={12} md={3} lg={4}>
      <Field
        name={'hubId'}
        label={translate('hub')}
        component={ComboField}
        items={hubs}
      />
    </GridItem>
  </Fragment>
)

export default withForm(recipientsFilterForm)(Form)
