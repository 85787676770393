import React from 'react'
import {withForm, Field} from 'ductus'

import {hubForm} from '../../forms'
import {CheckboxField, DateTimeField, TextField } from '../../components/fields'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'

import { Face, DeviceHub } from '@material-ui/icons'

const HubForm = ({translate, values}) => (
  <GridContainer>
    <GridItem xs={12}>
      <Field
        name={'name'}
        label={translate('name')}
        icon={DeviceHub}
        component={TextField}
        required={true}
      />

      {/* <Field
        name={'isClosed'}
        label={translate('closed')}
        icon={Face}
        component={CheckboxField}
      /> */}
        
    </GridItem>
    <GridItem xs={12} style={{margin: "40px 0 0 0"}}>


      {
        !values.isClosed ? null :
        <div style={{marginBottom: 15}}>
          <Field
            name={'closeReason'}
            label={translate('closeReason')}
            icon={Face}
            component={TextField}
            inputProps={{
              multiline: true,
              rows: 3
            }
            }
          />
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name={'closeStartDate'}
                label={translate('closeStartDate')}
                icon={Face}
                component={DateTimeField}
                dateFormat={'DD-MM-YYYY'}
                timeFormat={'HH:mm'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name={'closeEndDate'}
                label={translate('closeEndDate')}
                icon={Face}
                component={DateTimeField}
                dateFormat={'DD-MM-YYYY'}
                timeFormat={'HH:mm'}
              />
            </GridItem>
          </GridContainer>
        </div>
      }
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Field
            name={'opensAt'}
            label={translate('opens')}
            component={DateTimeField}
            dateFormat={false}
            timeFormat={'HH:mm'}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Field
            name={'closesAt'}
            label={translate('closes')}
            component={DateTimeField}
            dateFormat={false}
            timeFormat={'HH:mm'}
          />
        </GridItem>
      </GridContainer>

      <Field
        name={'daily'}
        label={translate('daily')}
        component={CheckboxField}
      />

      <Field
        name={'isActive'}
        label={translate('active')}
        component={CheckboxField}
      />
    </GridItem>
  </GridContainer>
)

export default withForm(hubForm.create)(HubForm)